import React, { useState } from "react";

import clsx from "clsx";
import axiosInstance from "./axiosConfig";
import {
    X,
    User,
    Phone,
    DollarSign,
    Search,
    Filter,
    ShoppingCart,
    Share2,
    Zap,
    Loader2,
    CheckCircle2,
    XCircle,
    Ghost, // Importe o ícone Ghost para representar o Snapchat
} from "lucide-react";
import DOMPurify from "dompurify";

const createComponent = (Tag, base) =>
    React.forwardRef(({ className, ...props }, ref) => (
        <Tag ref={ref} className={clsx(base, className)} {...props} />
    ));

const Button = React.forwardRef(
    ({ className, variant = "default", size = "default", ...props }, ref) => (
        <button
            type="button"
            ref={ref}
            className={clsx(
                "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
                variant === "default" &&
                    "bg-primary text-primary-foreground hover:bg-primary/90",
                variant === "destructive" &&
                    "bg-destructive text-destructive-foreground hover:bg-destructive/90",
                variant === "outline" &&
                    "border border-input bg-transparent hover:bg-accent hover:text-accent-foreground",
                variant === "secondary" &&
                    "bg-secondary text-secondary-foreground hover:bg-secondary/80",
                variant === "ghost" &&
                    "hover:bg-accent hover:text-accent-foreground bg-transparent",
                variant === "link" &&
                    "text-primary underline-offset-4 hover:underline",
                size === "default" && "h-10 px-4 py-2",
                size === "sm" && "h-9 rounded-md px-3",
                size === "lg" && "h-11 rounded-md px-8",
                size === "icon" && "h-10 w-10",
                className,
            )}
            {...props}
        />
    ),
);

const Input = React.forwardRef(
    ({ className, type = "text", ...props }, ref) => {
        const [isFocused, setIsFocused] = useState(false);

        return (
            <div className="relative w-full">
                <Search
                    className={`absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 transition-colors duration-200 ${
                        isFocused ? "text-zinc-100" : "text-zinc-500"
                    }`}
                />
                <input
                    ref={ref}
                    type={type}
                    className={`w-full h-11 bg-zinc-800 border text-sm text-zinc-100 rounded-xl pl-10 pr-4 placeholder:text-zinc-500 focus:outline-none transition-colors duration-200 border-transparent hover:border-zinc-600 ${
                        isFocused && "border-zinc-700"
                    }`}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    {...props}
                />
            </div>
        );
    },
);

const Card = createComponent(
    "div",
    "rounded-lg border bg-card text-card-foreground shadow-sm transition-colors duration-300 ease-in-out hover:shadow-lg hover:-translate-y-1",
);
const CardHeader = createComponent("div", "flex flex-col space-y-1.5 p-4");
const CardTitle = createComponent(
    "h3",
    "text-base font-semibold leading-none tracking-tight",
);
const CardContent = createComponent("div", "p-4 pt-0");
const CardFooter = createComponent("div", "flex items-center p-4 pt-0");

// Modal com o mesmo estilo do Documents.js
const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
    return (
        <div
            className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4 overflow-y-auto"
            onClick={onClose}
        >
            <div
                className="relative w-full max-w-lg min-h-[200px] bg-card text-card-foreground rounded-xl p-6 my-8 sm:my-0"
                onClick={(e) => e.stopPropagation()}
            >
                <button
                    onClick={onClose}
                    className="absolute right-4 top-4 text-muted-foreground hover:text-foreground transition-colors"
                >
                    <X className="h-5 w-5" />
                </button>
                {children}
            </div>
        </div>
    );
};

class BuyLogins extends React.Component {
    state = {
        logins: [],
        loading: false,
        error: null,
        searchQuery: "",
        selectedLogin: null,
        isModalOpen: false,
        isProcessing: false,
        showFilters: false,
        cart: [],
        purchaseMode: "instant",
        currentPage: "comprar-logins",
        isQuickBuyModalOpen: false,
        loginToBuy: null,
        isProcessingQuickBuy: false,
        quickBuyError: null,
        quickBuySuccess: false,
        alertMessage: null,
        alertType: null,
        areIconsTooSmall: false,
    };

    componentDidMount() {
        const storedMode = localStorage.getItem("purchaseMode") || "instant";
        this.setState({ purchaseMode: storedMode }, () => {
            this.fetchLogins();
            this.fetchCart();
        });
    }

    handleSearchChange = (e) =>
        this.setState({ searchQuery: e.target.value }, this.fetchLogins);

    fetchLogins = async () => {
        this.setState({ loading: true, error: null });
        try {
            const params = this.state.searchQuery
                ? "search=" + encodeURIComponent(this.state.searchQuery)
                : "";
            const { data } = await axiosInstance.get("/get_logins?" + params);
            this.setState({ logins: data.logins || [], loading: false });
        } catch {
            this.setState({
                error: "Falha ao carregar os logins.",
                loading: false,
                logins: [],
            });
        }
    };

    normalizeCart = (cartArray) => {
        return cartArray
            .map((item) => {
                if (typeof item === "string") {
                    return item;
                } else if (item._id) {
                    return item._id;
                } else {
                    return null;
                }
            })
            .filter(Boolean);
    };

    fetchCart = async () => {
        try {
            const response = await axiosInstance.get("/cart");
            const cartFromServer = response.data.cart || [];
            const cartIds = this.normalizeCart(cartFromServer);
            this.setState({ cart: cartIds });
            localStorage.setItem("cart", JSON.stringify(cartIds));
        } catch (error) {
            console.error("Erro ao buscar o carrinho de logins:", error);
            this.showAlert(
                "Erro ao carregar o carrinho de logins. Tente novamente mais tarde.",
                "error",
            );
        }
    };

    showAlert = (message, type) => {
        this.setState({
            alertMessage: message,
            alertType: type,
        });

        setTimeout(() => {
            this.setState({ alertMessage: null, alertType: null });
        }, 3000);
    };

    addToCart = async (login) => {
        try {
            const response = await axiosInstance.get("/cart");
            const serverCart = this.normalizeCart(response.data.cart || []);

            this.setState({ cart: serverCart });
            localStorage.setItem("cart", JSON.stringify(serverCart));

            const loginId = login._id;
            if (serverCart.includes(loginId)) {
                this.showAlert(
                    `O login ${login.platform} já está no carrinho.`,
                    "error",
                );
                return;
            }

            const newCart = [...serverCart, loginId];
            const postResponse = await axiosInstance.post("/cart", {
                cart: newCart,
            });
            if (postResponse.status >= 200 && postResponse.status < 300) {
                const updatedCart = this.normalizeCart(
                    postResponse.data.cart || newCart,
                );
                this.setState({ cart: updatedCart });
                this.props.setCart(updatedCart);
                localStorage.setItem("cart", JSON.stringify(updatedCart));
                this.showAlert(
                    `Login ${login.platform} adicionado ao carrinho!`,
                    "success",
                );
            } else {
                const errorMessage =
                    postResponse.data.error || "Erro desconhecido.";
                this.showAlert(`Erro: ${errorMessage}`, "error");
            }
        } catch (error) {
            console.error("Erro ao atualizar o carrinho de logins:", error);
            this.showAlert(
                "Erro ao adicionar o login ao carrinho. Tente novamente mais tarde.",
                "error",
            );
        }
    };

    removeFromCart = async (loginId) => {
        try {
            const response = await axiosInstance.get("/cart");
            const serverCart = this.normalizeCart(response.data.cart || []);

            this.setState({ cart: serverCart });
            localStorage.setItem("cart", JSON.stringify(serverCart));

            const newCart = serverCart.filter((id) => id !== loginId);
            const postResponse = await axiosInstance.post("/cart", {
                cart: newCart,
            });

            if (postResponse.status >= 200 && postResponse.status < 300) {
                const updatedCart = this.normalizeCart(
                    postResponse.data.cart || newCart,
                );
                this.setState({ cart: updatedCart });
                this.props.setCart(updatedCart);
                localStorage.setItem("cart", JSON.stringify(updatedCart));
                this.showAlert("Item removido do carrinho.", "success");
            } else {
                const errorMessage =
                    postResponse.data.error || "Erro desconhecido.";
                this.showAlert(`Erro: ${errorMessage}`, "error");
            }
        } catch (error) {
            console.error("Erro ao atualizar o carrinho de logins:", error);
            this.showAlert(
                "Erro ao remover o item do carrinho. Tente novamente.",
                "error",
            );
        }
    };

    toggleFilters = () => {
        this.setState((prev) => ({ showFilters: !prev.showFilters }));
    };

    shareLoginLink = (login) => {
        const loginId = login._id;
        const link = `${window.location.origin}/?login=${loginId}`;
        navigator.clipboard
            .writeText(link)
            .then(() => {
                this.showAlert(
                    "Link copiado para a área de transferência!",
                    "success",
                );
            })
            .catch((err) => {
                console.error("Falha ao copiar o link: ", err);
                this.showAlert("Falha ao copiar o link.", "error");
            });
    };

    updateCartOnServer = async (newCart) => {
        try {
            const response = await axiosInstance.post("/cart", {
                cart: newCart,
            });
            if (response.status >= 200 && response.status < 300) {
                return true;
            } else {
                this.showAlert(
                    "Erro ao atualizar carrinho no servidor.",
                    "error",
                );
            }
        } catch (error) {
            console.error("Erro ao atualizar carrinho no servidor:", error);
            this.showAlert("Erro ao atualizar carrinho no servidor.", "error");
        }
        return false;
    };

    handleQuickBuy = (login) => {
        this.setState({
            isQuickBuyModalOpen: true,
            loginToBuy: login,
            quickBuyError: null,
            quickBuySuccess: false,
        });
    };

    handleConfirmQuickBuy = async () => {
        if (!this.state.loginToBuy) return;
        this.setState({ isProcessingQuickBuy: true, quickBuyError: null });
        try {
            const { loginToBuy } = this.state;
            const response = await axiosInstance.post("/purchase_login", {
                logins: [loginToBuy._id],
            });
            if (response.status >= 200 && response.status < 300) {
                this.setState({
                    quickBuySuccess: true,
                    isProcessingQuickBuy: false,
                });
            } else {
                this.setState({
                    quickBuyError: "Erro ao finalizar a compra.",
                    isProcessingQuickBuy: false,
                });
            }
        } catch (err) {
            console.error("Erro ao realizar compra rápida:", err);
            this.setState({
                quickBuyError: "Erro ao realizar a compra rápida.",
                isProcessingQuickBuy: false,
            });
        }
    };

    handleCancelQuickBuy = () => {
        this.setState({
            isQuickBuyModalOpen: false,
            loginToBuy: null,
            quickBuyError: null,
            quickBuySuccess: false,
            isProcessingQuickBuy: false,
        });
    };

    removeCard = (id) => {
        this.setState((prevState) => ({
            cart: prevState.cart.filter((cartId) => cartId !== id),
        }));
    };

    renderAlert() {
        const { alertMessage, alertType } = this.state;
        if (!alertMessage) return null;

        const Icon = alertType === "success" ? CheckCircle2 : XCircle;
        const title =
            alertType === "success"
                ? "Tudo certo!"
                : "Ops, algo não saiu como esperado";

        return (
            <div className="fixed top-20 right-4 z-50 w-full max-w-xl mx-auto">
                <div className="relative bg-zinc-50 dark:bg-zinc-900 border border-zinc-200 dark:border-zinc-800 shadow-[0_1px_6px_0_rgba(0,0,0,0.02)] rounded-xl p-4">
                    <div className="flex gap-3">
                        <div className="p-2 rounded-full">
                            <div className="rounded-full bg-white dark:bg-zinc-900">
                                <Icon className="h-6 w-6 text-zinc-500 dark:text-zinc-500" />
                            </div>
                        </div>
                        <div className="space-y-0.5">
                            <h3 className="text-sm font-medium text-zinc-700 dark:text-zinc-300">
                                {title}
                            </h3>
                            <p className="text-[13px] text-zinc-500 dark:text-zinc-400">
                                {alertMessage}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderQuickBuyModal() {
        const {
            isQuickBuyModalOpen,
            loginToBuy,
            isProcessingQuickBuy,
            quickBuyError,
            quickBuySuccess,
            areIconsTooSmall,
        } = this.state;

        return (
            <Modal
                isOpen={isQuickBuyModalOpen}
                onClose={this.handleCancelQuickBuy}
            >
                <div className="space-y-6">
                    {!quickBuySuccess ? (
                        <>
                            <h2 className="text-xl font-semibold leading-none tracking-tight">
                                Confirmação da Compra
                            </h2>
                            {loginToBuy && (
                                <div className="space-y-3 bg-secondary/50 rounded-lg p-4">
                                    {/* Linha da plataforma com ícone (caso seja Snapchat) */}
                                    <p className="flex items-center text-sm">
                                        {/* Se a plataforma for Snapchat, exibe o ícone Ghost */}
                                        {loginToBuy.platform.toLowerCase() ===
                                        "snapchat" ? (
                                            <Ghost className="h-4 w-4 mr-2 text-muted-foreground" />
                                        ) : (
                                            <User className="h-4 w-4 mr-2 text-muted-foreground" />
                                        )}
                                        Plataforma: {loginToBuy.platform}
                                    </p>

                                    {loginToBuy.cpf_cnpj && (
                                        <p className="flex items-center text-sm">
                                            <User className="h-4 w-4 mr-2 text-muted-foreground" />
                                            {areIconsTooSmall
                                                ? `${loginToBuy.cpf_cnpj}`
                                                : `CPF/CNPJ: ${loginToBuy.cpf_cnpj}`}
                                        </p>
                                    )}
                                    {loginToBuy.telefone && (
                                        <p className="flex items-center text-sm">
                                            <Phone className="h-4 w-4 mr-2 text-muted-foreground" />
                                            {areIconsTooSmall
                                                ? `${loginToBuy.telefone}`
                                                : `Telefone: ${loginToBuy.telefone}`}
                                        </p>
                                    )}
                                    <p className="flex items-center text-sm">
                                        <DollarSign className="h-4 w-4 mr-2 text-muted-foreground" />
                                        {areIconsTooSmall
                                            ? `${parseFloat(
                                                  loginToBuy.price,
                                              ).toFixed(2)}`
                                            : `Preço: R$${parseFloat(
                                                  loginToBuy.price,
                                              ).toFixed(2)}`}
                                    </p>
                                </div>
                            )}
                            <p className="text-sm">
                                Deseja comprar agora o login{" "}
                                <strong>{loginToBuy?.platform}</strong> por R$
                                {loginToBuy
                                    ? parseFloat(loginToBuy.price).toFixed(2)
                                    : "0,00"}
                                ?
                            </p>
                            {quickBuyError && (
                                <p className="text-sm text-destructive">
                                    {quickBuyError}
                                </p>
                            )}
                            <div className="flex justify-end gap-2 pt-2 border-t border-border">
                                <Button
                                    variant="outline"
                                    onClick={this.handleCancelQuickBuy}
                                    disabled={isProcessingQuickBuy}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    variant="default"
                                    onClick={this.handleConfirmQuickBuy}
                                    disabled={isProcessingQuickBuy}
                                >
                                    {isProcessingQuickBuy
                                        ? "Processando..."
                                        : "Confirmar"}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className="space-y-6">
                            <div className="flex flex-col items-center text-center space-y-4">
                                <CheckCircle2 className="h-12 w-12 text-green-500" />
                                <h2 className="text-xl font-semibold leading-none tracking-tight">
                                    Compra realizada com sucesso!
                                </h2>
                                {loginToBuy && (
                                    <div className="space-y-3 bg-secondary/50 rounded-lg p-4">
                                        <p className="flex items-center text-sm">
                                            {loginToBuy.platform.toLowerCase() ===
                                            "snapchat" ? (
                                                <Ghost className="h-4 w-4 mr-2 text-muted-foreground" />
                                            ) : (
                                                <User className="h-4 w-4 mr-2 text-muted-foreground" />
                                            )}
                                            Plataforma: {loginToBuy.platform}
                                        </p>
                                        {loginToBuy.cpf_cnpj && (
                                            <p className="flex items-center text-sm">
                                                <User className="h-4 w-4 mr-2 text-muted-foreground" />
                                                {areIconsTooSmall
                                                    ? `${loginToBuy.cpf_cnpj}`
                                                    : `CPF/CNPJ: ${loginToBuy.cpf_cnpj}`}
                                            </p>
                                        )}
                                        {loginToBuy.telefone && (
                                            <p className="flex items-center text-sm">
                                                <Phone className="h-4 w-4 mr-2 text-muted-foreground" />
                                                {areIconsTooSmall
                                                    ? `${loginToBuy.telefone}`
                                                    : `Telefone: ${loginToBuy.telefone}`}
                                            </p>
                                        )}
                                        <p className="flex items-center text-sm">
                                            <DollarSign className="h-4 w-4 mr-2 text-muted-foreground" />
                                            {areIconsTooSmall
                                                ? `${parseFloat(
                                                      loginToBuy.price,
                                                  ).toFixed(2)}`
                                                : `Preço: R$${parseFloat(
                                                      loginToBuy.price,
                                                  ).toFixed(2)}`}
                                        </p>
                                    </div>
                                )}
                                <Button
                                    variant="default"
                                    onClick={this.handleCancelQuickBuy}
                                >
                                    Fechar
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        );
    }

    render() {
        const {
            logins,
            loading,
            error,
            searchQuery,
            showFilters,
            cart,
            purchaseMode,
        } = this.state;

        return (
            <div className="flex flex-col h-screen bg-background text-foreground">
                {this.renderAlert()}
                {this.renderQuickBuyModal()}

                <div className="flex-1 overflow-y-auto p-4">
                    <div className="container mx-auto px-2 sm:px-4">
                        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 pb-2 mb-4 border-b border-input">
                            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold tracking-tight">
                                Logins
                            </h1>
                        </div>

                        <div className="mb-6">
                            <div className="flex items-center gap-2">
                                <Input
                                    type="search"
                                    placeholder="Pesquisar..."
                                    value={searchQuery}
                                    onChange={this.handleSearchChange}
                                />
                                <Button
                                    variant="outline"
                                    size="icon"
                                    onClick={this.toggleFilters}
                                    className="ml-2"
                                >
                                    <Filter className="h-5 w-5" />
                                </Button>
                                {purchaseMode === "cart" && (
                                    <Button
                                        variant="outline"
                                        size="icon"
                                        className="relative bg-secondary text-secondary-foreground hover:bg-accent hover:text-accent-foreground p-1.5 ml-2"
                                        onClick={() =>
                                            this.props.setCurrentPage(
                                                "finalizar-compra",
                                            )
                                        }
                                    >
                                        <ShoppingCart className="h-5 w-5" />
                                        {cart.length > 0 && (
                                            <span className="absolute -top-2 -right-2 bg-destructive text-destructive-foreground text-xs rounded-full h-5 w-5 flex items-center justify-center">
                                                {cart.length}
                                            </span>
                                        )}
                                    </Button>
                                )}
                                {purchaseMode === "instant" && (
                                    <Button
                                        variant="outline"
                                        size="icon"
                                        className="relative bg-secondary text-secondary-foreground hover:bg-accent hover:text-accent-foreground p-1.5 ml-2"
                                        onClick={() =>
                                            this.showAlert(
                                                "Modo Instantâneo",
                                                "success",
                                            )
                                        }
                                    >
                                        <Zap className="h-5 w-5" />
                                    </Button>
                                )}
                            </div>
                            {showFilters && (
                                <div className="mt-2 px-6">
                                    <p className="text-sm text-muted-foreground">
                                        Filtros extras (personalizar conforme
                                        necessidade)...
                                    </p>
                                </div>
                            )}
                        </div>
                        {loading && (
                            <p className="text-center">Carregando logins...</p>
                        )}
                        {error && (
                            <p className="text-center text-red-500">{error}</p>
                        )}
                        <div className="grid gap-4 sm:gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                            {logins.length > 0
                                ? logins.map((login, i) => (
                                      <Card
                                          key={i}
                                          className="w-full relative overflow-hidden rounded-lg shadow-lg transition-all duration-300 ease-in-out hover:shadow-xl hover:-translate-y-1"
                                      >
                                          <div className="relative z-10 p-4 sm:p-6">
                                              <CardHeader className="p-0">
                                                  <CardTitle className="flex items-center justify-between text-base sm:text-lg font-semibold">
                                                      {login.platform}
                                                  </CardTitle>
                                              </CardHeader>
                                              <CardContent className="mt-4 p-0">
                                                  {login.cpf_cnpj && (
                                                      <p className="flex items-center mb-2 text-sm">
                                                          <User className="h-5 w-5 mr-2" />
                                                          {`CPF/CNPJ: ${login.cpf_cnpj}`}
                                                      </p>
                                                  )}
                                                  {login.telefone && (
                                                      <p className="flex items-center mb-2 text-sm">
                                                          <Phone className="h-5 w-5 mr-2" />
                                                          {`Telefone: ${login.telefone}`}
                                                      </p>
                                                  )}
                                                  <p className="flex items-center mb-2 text-sm">
                                                      <DollarSign className="h-5 w-5 mr-2" />
                                                      {`Preço: R$${parseFloat(login.price).toFixed(2)}`}
                                                  </p>
                                              </CardContent>
                                              <CardFooter className="flex items-center justify-between p-0 mt-2">
                                                  <Button
                                                      variant="ghost"
                                                      size="icon"
                                                      onClick={() =>
                                                          this.shareLoginLink(
                                                              login,
                                                          )
                                                      }
                                                      className="hover:bg-accent hover:text-accent-foreground rounded-full"
                                                  >
                                                      <Share2 className="h-5 w-5" />
                                                      <span className="sr-only">
                                                          Compartilhar Link
                                                      </span>
                                                  </Button>

                                                  {this.state.purchaseMode ===
                                                      "cart" && (
                                                      <Button
                                                          size="icon"
                                                          className="bg-customGreen text-customGreen-foreground rounded-full hover:bg-customGreen-hover"
                                                          onClick={() =>
                                                              this.addToCart(
                                                                  login,
                                                              )
                                                          }
                                                      >
                                                          <ShoppingCart className="h-5 w-5" />
                                                          <span className="sr-only">
                                                              Adicionar ao
                                                              carrinho
                                                          </span>
                                                      </Button>
                                                  )}

                                                  {this.state.purchaseMode ===
                                                      "instant" && (
                                                      <Button
                                                          size="icon"
                                                          variant="ghost"
                                                          onClick={() =>
                                                              this.handleQuickBuy(
                                                                  login,
                                                              )
                                                          }
                                                          className="hover:bg-accent hover:text-accent-foreground rounded-full"
                                                      >
                                                          <Zap className="h-5 w-5" />
                                                          <span className="sr-only">
                                                              Compra Instantânea
                                                          </span>
                                                      </Button>
                                                  )}
                                              </CardFooter>
                                          </div>
                                      </Card>
                                  ))
                                : !loading &&
                                  !error && (
                                      <p className="col-span-full text-center">
                                          Nenhum login encontrado.
                                      </p>
                                  )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BuyLogins;

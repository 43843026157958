import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
    Loader2,
    Folder,
    Users,
    Lightbulb,
    ChevronDown,
    Tag,
    ArrowRight,
    Trash2,
    Pencil,
    ExternalLink,
    Code2,
    AlertCircle,
    Activity,
    Package,
    ChevronRight,
    TrendingUp,
    Link as LucideLink,
} from "lucide-react";
import { toast } from "react-toastify";
import axiosInstance from "./axiosConfig";
import EditPlanModal from './EditPlanModal';
import EditConsultaModal from './EditConsultaModal';

const cnButton = (...classes) => classes.filter(Boolean).join(" ");



// Componente de animação no scroll
const ScrollReveal = ({ children, className = "" }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [ref, setRef] = useState(null);

    useEffect(() => {
        if (!ref) return;
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(ref);
                }
            },
            {
                threshold: 0.1,
                rootMargin: "0px",
            },
        );
        observer.observe(ref);
        return () => {
            if (ref) {
                observer.unobserve(ref);
            }
        };
    }, [ref]);

    return (
        <div
            ref={setRef}
            className={`transform transition-all duration-1000 ${
                isVisible
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 translate-y-8"
            } ${className}`}
        >
            {children}
        </div>
    );
};

const DeleteConfirmationModal = ({
    isOpen,
    onClose,
    onConfirm,
    itemName,
    itemType = "plano",
}) => {
    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center"
            style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}
        >
            <div className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-zinc-900 border border-zinc-800 rounded-xl p-6 max-w-md w-full mx-4">
                <h3 className="text-lg font-medium text-zinc-100 mb-2">
                    Confirmar Exclusão
                </h3>
                <p className="text-zinc-400 mb-4">
                    Tem certeza que deseja excluir{" "}
                    {itemType === "plano" ? "o plano" : "a consulta"} "
                    {itemName}"? Esta ação não pode ser desfeita.
                </p>
                <div className="flex justify-end gap-3">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 text-sm text-zinc-400 hover:text-zinc-300 transition-colors"
                    >
                        Cancelar
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-4 py-2 text-sm bg-red-600 hover:bg-red-700 text-white rounded-lg transition-colors"
                    >
                        Confirmar Exclusão
                    </button>
                </div>
            </div>
        </div>
    );
};

const Button = React.forwardRef(
    ({ children, className, disabled, loading, ...props }, ref) => {
        return (
            <button
                ref={ref}
                disabled={disabled || loading}
                {...props}
                className={cnButton(
                    "relative h-9 px-4 md:h-9 md:px-4",
                    "bg-white dark:bg-zinc-900/20",
                    "dark:text-zinc-100",
                    "border border-zinc-200 dark:border-zinc-800",
                    "shadow-sm",
                    "hover:shadow-md hover:-translate-y-0.5",
                    "hover:bg-zinc-50 dark:hover:bg-zinc-900",
                    "active:translate-y-0",
                    "transition-all duration-200",
                    "after:absolute after:inset-0",
                    "after:rounded-[inherit] after:opacity-0",
                    "after:border after:border-zinc-900/10 dark:after:border-white/10",
                    "after:transition-opacity after:duration-300",
                    "hover:after:opacity-100",
                    "disabled:pointer-events-none disabled:opacity-50",
                    className,
                )}
            >
                {loading && (
                    <Loader2 className="w-4 h-4 animate-spin mr-2 inline-block" />
                )}
                {children}
            </button>
        );
    },
);
Button.displayName = "Button";

// Três bolinhas animadas indicando "carregando"
function BouncingDots() {
    return (
        <div className="flex items-center justify-center space-x-1 text-zinc-400 dark:text-zinc-500">
            <span className="inline-block w-2 h-2 rounded-full bg-current animate-bounce"></span>
            <span className="inline-block w-2 h-2 rounded-full bg-current animate-bounce [animation-delay:0.2s]"></span>
            <span className="inline-block w-2 h-2 rounded-full bg-current animate-bounce [animation-delay:0.4s]"></span>
        </div>
    );
}

function ApiSuppliers() {
    // =================== Status Fornecedor ========================
    const [supplierApproved, setSupplierApproved] = useState(false);
    const [supplierStatus, setSupplierStatus] = useState(null);
    const [statusLoading, setStatusLoading] = useState(true);

    // =================== Nome de Fornecedor (para o cadastro) ========================
    const [supplierNameRequest, setSupplierNameRequest] = useState("");
    const [loadingRegister, setLoadingRegister] = useState(false);

    // =================== Plataforma IA ========================
    const [userQuery, setUserQuery] = useState("");
    const [aiResponse, setAiResponse] = useState(null);
    const [aiLoading, setAiLoading] = useState(false);

    // =================== Consultas do Fornecedor ========================
    const [consultasFornecedor, setConsultasFornecedor] = useState([]);
    const [loadingConsultasFornecedor, setLoadingConsultasFornecedor] =
        useState(false);
    const [errorConsultasFornecedor, setErrorConsultasFornecedor] =
        useState(null);

    const [editConsultaModalOpen, setEditConsultaModalOpen] = useState(false);
    const [editingConsulta, setEditingConsulta] = useState(null);
    const [editConsultaLoading, setEditConsultaLoading] = useState(false);

    // =================== Estatísticas do Fornecedor ========================
    const [supplierStats, setSupplierStats] = useState(null);
    const [loadingStats, setLoadingStats] = useState(false);
    const [errorStats, setErrorStats] = useState(null);

    // =================== Criação de Planos (Campos) ========================
    const [planName, setPlanName] = useState("");
    const [planDuration, setPlanDuration] = useState("");
    const [planAllowedModules, setPlanAllowedModules] = useState([]);
    const [planPrice, setPlanPrice] = useState("");
    const [planDailyLimit, setPlanDailyLimit] = useState("");
    const [planDescription, setPlanDescription] = useState("");
    const [loadingCreatePlan, setLoadingCreatePlan] = useState(false);

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editingPlan, setEditingPlan] = useState(null);
    const [editLoading, setEditLoading] = useState(false);

    // =================== Lista de Planos do Fornecedor ========================
    const [plansList, setPlansList] = useState([]);
    const [loadingPlans, setLoadingPlans] = useState(false);
    const [errorPlans, setErrorPlans] = useState(null);

    const [openDropdown, setOpenDropdown] = useState(null);

    const toggleDropdown = (consultaName) => {
        setOpenDropdown((prev) =>
            prev === consultaName ? null : consultaName,
        );
    };

    const [deleteModal, setDeleteModal] = useState({
        show: false,
        planId: null,
        planName: "",
        consultaName: "",
        type: "", // 'plano' ou 'consulta'
    });

    /* ============================================================
   Verificar status do fornecedor no backend
   ============================================================ */
    useEffect(() => {
        const fetchSupplierStatus = async () => {
            setStatusLoading(true);
            try {
                const resp = await axiosInstance.get("/dev/list_supplier_apis");
                setSupplierApproved(resp.data.is_supplier_approved);
                setSupplierStatus(resp.data.supplier_status);
            } catch (err) {
                console.error("Erro ao obter status do fornecedor:", err);
            } finally {
                setStatusLoading(false);
            }
        };
        fetchSupplierStatus();
    }, []);

    /* ============================================================
   Carregar consultas do fornecedor, se aprovado
   ============================================================ */
    useEffect(() => {
        const fetchConsultasFornecedor = async () => {
            try {
                setLoadingConsultasFornecedor(true);
                setErrorConsultasFornecedor(null);
                const resp = await axiosInstance.get("/consultas_fornecedor");
                setConsultasFornecedor(resp.data.consultas || []);
            } catch (err) {
                console.error("Erro ao carregar as consultas:", err);
                setErrorConsultasFornecedor(
                    "Não foi possível carregar suas consultas.",
                );
            } finally {
                setLoadingConsultasFornecedor(false);
            }
        };
        if (supplierApproved) {
            fetchConsultasFornecedor();
        }
    }, [supplierApproved]);

    /* ============================================================
   Carregar estatísticas do fornecedor, se aprovado
   ============================================================ */
    useEffect(() => {
        const fetchSupplierStats = async () => {
            setLoadingStats(true);
            setErrorStats(null);
            try {
                const resp = await axiosInstance.get("/dev/supplier_stats");
                setSupplierStats(resp.data);
            } catch (err) {
                console.error("Erro ao carregar estatísticas:", err);
                setErrorStats("Não foi possível carregar as estatísticas.");
            } finally {
                setLoadingStats(false);
            }
        };
        if (supplierApproved) {
            fetchSupplierStats();
        }
    }, [supplierApproved]);

    /* ============================================================
   Carregar planos do fornecedor, se aprovado
   ============================================================ */
    useEffect(() => {
        const fetchPlans = async () => {
            setLoadingPlans(true);
            setErrorPlans(null);
            try {
                const resp = await axiosInstance.get(
                    "/dev/list_supplier_plans",
                );
                setPlansList(resp.data.plans || []);
            } catch (err) {
                console.error("Erro ao carregar os planos:", err);
                setErrorPlans("Não foi possível carregar seus planos.");
            } finally {
                setLoadingPlans(false);
            }
        };
        if (supplierApproved) {
            fetchPlans();
        }
    }, [supplierApproved]);

    /* ============================================================
   Solicitar registro como fornecedor
   ============================================================ */
    const handleRegisterSupplier = async () => {
        setLoadingRegister(true);
        try {
            await axiosInstance.post("/dev/register_supplier", {
                supplier_display_name: supplierNameRequest,
            });
            toast.success("Solicitação enviada com sucesso!");
            // Recarrega status
            const resp = await axiosInstance.get("/dev/list_supplier_apis");
            setSupplierApproved(resp.data.is_supplier_approved);
            setSupplierStatus(resp.data.supplier_status);
        } catch (err) {
            console.error(err);
            if (err.response?.data?.error) {
                toast.error(err.response.data.error);
            } else {
                toast.error("Erro ao registrar fornecedor.");
            }
        } finally {
            setLoadingRegister(false);
        }
    };

    /* ============================================================
   Plataforma IA
   ============================================================ */
    const handleAskAi = async () => {
        if (!userQuery.trim()) return;
        setAiLoading(true);
        setAiResponse(null);

        try {
            const resp = await axiosInstance.post("/chat", {
                user_input: userQuery,
            });
            setAiResponse(resp.data.assistant || null);

            // Após sucesso, atualiza a lista de consultas
            const consultasResp = await axiosInstance.get(
                "/consultas_fornecedor",
            );
            setConsultasFornecedor(consultasResp.data.consultas || []);
        } catch (err) {
            console.error(err);
            if (err.response?.data?.error) {
                toast.error(err.response.data.error);
            } else {
                toast.error("Erro ao se comunicar com a IA.");
            }
        } finally {
            setAiLoading(false);
        }
    };

    /* ============================================================
   Criar novo plano
   ============================================================ */
    const handleCreatePlan = async () => {
        if (!planName.trim() || !planDuration.trim() || !planPrice.trim()) {
            toast.error("Nome do plano, duração e valor são obrigatórios.");
            return;
        }

        setLoadingCreatePlan(true);
        try {
            await axiosInstance.post("/dev/create_supplier_plan", {
                plan_name: planName,
                duration_days: Number(planDuration),
                allowed_modules: planAllowedModules,
                price: Number(planPrice),
                daily_limit: Number(planDailyLimit),
                description: planDescription,
            });
            toast.success("Plano criado com sucesso!");

            // Limpa campos
            setPlanName("");
            setPlanDuration("");
            setPlanAllowedModules([]);
            setPlanPrice("");
            setPlanDailyLimit("");
            setPlanDescription("");

            // Recarrega lista de planos
            const resp = await axiosInstance.get("/dev/list_supplier_plans");
            setPlansList(resp.data.plans || []);
        } catch (err) {
            console.error(err);
            if (err.response?.data?.error) {
                toast.error(err.response.data.error);
            } else {
                toast.error("Erro ao criar plano.");
            }
        } finally {
            setLoadingCreatePlan(false);
        }
    };

    // ==================== Render ====================
    return (
        <div className="min-h-screen p-4 sm:p-6 md:p-8 bg-background">
            <div className="max-w-7xl mx-auto space-y-8">
                {statusLoading ? (
                    <ScrollReveal>
                        <div className="rounded-xl border border-zinc-800 bg-zinc-900 shadow-lg p-6 space-y-4">
                            <div className="flex items-center justify-center">
                                <BouncingDots />
                            </div>
                            <p className="text-center text-sm text-zinc-400">
                                Carregando status...
                            </p>
                        </div>
                    </ScrollReveal>
                ) : (
                    <>
                        {!supplierStatus || supplierStatus === "none" ? (
                            <ScrollReveal>
                                <div className="relative w-full">
                                    <div className="min-h-screen flex items-center justify-center">
                                        <div className="relative max-w-5xl mx-auto px-4 py-12">
                                            <div className="absolute top-0 left-0 w-24 h-24 bg-gradient-to-br from-rose-200 to-rose-400 rounded-full blur-2xl opacity-20 animate-pulse" />
                                            <div className="absolute bottom-0 right-0 w-32 h-32 bg-gradient-to-br from-violet-200 to-violet-400 rounded-full blur-2xl opacity-20 animate-pulse" />

                                            <ScrollReveal>
                                                <div className="relative">
                                                    <span className="block text-sm font-medium text-rose-400 mb-4 tracking-widest uppercase">
                                                        Feito em Segundos
                                                    </span>
                                                    <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white leading-tight">
                                                        Torne-se um
                                                        <span className="block mt-2">
                                                            <span className="italic font-serif shimmer-text">
                                                                fornecedor
                                                            </span>{" "}
                                                            da Secret
                                                        </span>
                                                    </h1>
                                                    <div className="mt-8 flex items-center gap-4">
                                                        <div className="h-[2px] w-12 bg-zinc-700" />
                                                        <span className="text-zinc-400 text-lg">
                                                            Criado com precisão
                                                        </span>
                                                    </div>
                                                </div>
                                            </ScrollReveal>
                                        </div>
                                    </div>

                                    <style>{`
                                    .shimmer-text {
                                        --shimmer-color-start: #f1f5f9;
                                        --shimmer-color-mid: #9333EA;
                                        background: linear-gradient(
                                            90deg,
                                            var(--shimmer-color-start) 0%,
                                            var(--shimmer-color-start) 40%,
                                            var(--shimmer-color-mid) 50%,
                                            var(--shimmer-color-start) 60%,
                                            var(--shimmer-color-start) 100%
                                        );
                                        background-size: 200% 100%;
                                        -webkit-background-clip: text;
                                        background-clip: text;
                                        color: transparent;
                                        animation: shimmer 2.5s infinite linear;
                                    }

                                    @keyframes shimmer {
                                        0% {
                                            background-position: 100% 0;
                                        }
                                        100% {
                                            background-position: -100% 0;
                                        }
                                    }
                                    `}</style>
                                </div>

                                <div className="w-full max-w-md mx-auto rounded-3xl overflow-hidden bg-zinc-900 border-2 border-zinc-800/50 shadow-xl">
                                    <div className="space-y-4 px-8 pt-10 text-center">
                                        <div className="space-y-2">
                                            <div className="mx-auto w-10 h-10 rounded-full bg-blue-900/20 flex items-center justify-center">
                                                <Lightbulb className="w-5 h-5 text-blue-400" />
                                            </div>
                                            <h3 className="text-2xl font-bold text-white">
                                                Torne-se um Fornecedor
                                            </h3>
                                            <p className="text-base text-zinc-400">
                                                Informe como deseja ser chamado
                                            </p>
                                        </div>
                                    </div>

                                    <div className="p-8 pt-6 space-y-6">
                                        <div className="space-y-2">
                                            <input
                                                type="text"
                                                placeholder="Nome de fornecedor (ex: Pro Consultas)"
                                                value={supplierNameRequest}
                                                onChange={(e) =>
                                                    setSupplierNameRequest(
                                                        e.target.value,
                                                    )
                                                }
                                                className="w-full h-10 px-4 rounded-xl bg-zinc-800/50 text-zinc-100 placeholder:text-zinc-500 border border-zinc-800 focus:outline-none focus:ring-2 focus:ring-blue-500/20 transition-all"
                                            />
                                        </div>

                                        <button
                                            onClick={handleRegisterSupplier}
                                            disabled={loadingRegister}
                                            className="w-full h-10 rounded-xl flex items-center justify-center gap-2 bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors"
                                        >
                                            Solicitar Registro
                                            <ArrowRight className="w-4 h-4" />
                                        </button>
                                    </div>
                                    <div className="px-8 pb-10" />
                                </div>
                            </ScrollReveal>
                        ) : supplierStatus === "pending" &&
                          !supplierApproved ? (
                            <ScrollReveal>
                                <div className="rounded-xl border border-zinc-800 bg-zinc-900 shadow-lg hover:shadow-xl transition-all duration-300 p-6 space-y-4">
                                    <h2 className="text-xl font-semibold text-white">
                                        Aguardando aprovação
                                    </h2>
                                    <p className="text-sm text-zinc-400">
                                        Sua solicitação de registro como
                                        fornecedor está em análise. Aguarde até
                                        que seja aprovada para usar a
                                        plataforma.
                                    </p>
                                </div>
                            </ScrollReveal>
                        ) : supplierApproved ? (
                            <div className="space-y-6">
                                {/* Plataforma Interativa */}
                                <ScrollReveal>
                                    <div className="mx-auto max-w-5xl group relative overflow-hidden bg-zinc-900 border border-zinc-800 rounded-2xl transition-all duration-300 hover:shadow-2xl">
                                        {/* Header */}
                                        <div className="p-4 flex items-center justify-between border-b border-zinc-800">
                                            <div className="flex items-center gap-3">
                                                <div className="w-8 h-8 rounded-full bg-zinc-800 flex items-center justify-center">
                                                    <Lightbulb className="w-4 h-4 text-zinc-400" />
                                                </div>
                                                <div>
                                                    <h3 className="text-sm font-medium text-zinc-100">
                                                        Plataforma Interativa
                                                    </h3>
                                                    <p className="text-xs text-zinc-400">
                                                        Envie suas documentações
                                                        por aqui, uma de cada
                                                        vez. Se estiver
                                                        processando, aparecerão
                                                        3 bolinhas.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Content */}
                                        <div className="p-4 space-y-6">
                                            <textarea
                                                className="w-full bg-zinc-800 border text-sm text-zinc-100 rounded-xl p-4 placeholder:text-zinc-500 focus:outline-none transition-colors duration-200 border-transparent hover:border-zinc-600 focus:border-zinc-700 resize-none h-28"
                                                value={userQuery}
                                                onChange={(e) =>
                                                    setUserQuery(e.target.value)
                                                }
                                                placeholder="Envie aqui a documentação..."
                                            />

                                            <Button
                                                onClick={handleAskAi}
                                                loading={aiLoading}
                                                className="transform hover:scale-105 transition-transform duration-200"
                                            >
                                                Enviar
                                            </Button>

                                            {aiLoading && (
                                                <div className="flex justify-center mt-2">
                                                    <BouncingDots />
                                                </div>
                                            )}

                                            {aiResponse && !aiLoading && (
                                                <div className="rounded-md border border-zinc-800 bg-zinc-800 p-4 text-sm text-zinc-200 whitespace-pre-wrap">
                                                    <strong>
                                                        Resposta da IA:
                                                    </strong>
                                                    <br />
                                                    {aiResponse}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </ScrollReveal>

                                {/* Suas Consultas Cadastradas */}
                                {/* Suas Consultas Cadastradas */}
                                <ScrollReveal>
                                    <div className="mx-auto max-w-5xl group relative overflow-hidden bg-zinc-900 border border-zinc-800 rounded-2xl transition-all duration-300 hover:shadow-2xl hover:border-zinc-700">
                                        {/* Header */}
                                        <div className="p-6 flex items-center justify-between border-b border-zinc-800 bg-zinc-900/50">
                                            <div className="flex items-center gap-4">
                                                <div className="w-10 h-10 rounded-xl bg-zinc-800 flex items-center justify-center">
                                                    <Tag className="w-5 h-5 text-violet-400" />
                                                </div>
                                                <div>
                                                    <h3 className="text-base font-medium text-zinc-100">
                                                        Suas Consultas Cadastradas
                                                    </h3>
                                                    <p className="text-sm text-zinc-400">
                                                        Gerencie e visualize todas as suas consultas cadastradas.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Content */}
                                        <div className="p-6 space-y-6">
                                            {loadingConsultasFornecedor && (
                                                <div className="flex items-center justify-center mt-2">
                                                    <BouncingDots />
                                                </div>
                                            )}

                                            {errorConsultasFornecedor && (
                                                <p className="text-red-400">
                                                    {errorConsultasFornecedor}
                                                </p>
                                            )}

                                            {!loadingConsultasFornecedor &&
                                                !errorConsultasFornecedor &&
                                                consultasFornecedor.length === 0 && (
                                                    <div className="text-center py-8">
                                                        <p className="text-zinc-400">
                                                            Nenhuma consulta cadastrada.
                                                        </p>
                                                    </div>
                                                )}

                                            {!loadingConsultasFornecedor &&
                                                !errorConsultasFornecedor &&
                                                consultasFornecedor.length > 0 && (
                                                    <ul className="space-y-3">
                                                        {consultasFornecedor.map((c) => (
                                                            <li
                                                                key={c.consulta_name}
                                                                className="group/item relative transform transition-all duration-200 bg-zinc-900 border border-zinc-800 hover:border-zinc-700 rounded-xl overflow-hidden"
                                                            >
                                                                <div className="p-4">
                                                                    <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-3">
                                                                        <div className="flex items-center gap-3">
                                                                            <div className="flex items-center gap-2">
                                                                                <button
                                                                                    onClick={() =>
                                                                                        setDeleteModal({
                                                                                            show: true,
                                                                                            consultaName: c.consulta_name,
                                                                                            type: "consulta",
                                                                                        })
                                                                                    }
                                                                                    className="text-red-500 hover:text-red-400 transition-all duration-200 p-2 rounded-lg hover:bg-zinc-800"
                                                                                    title="Excluir consulta"
                                                                                >
                                                                                    <Trash2 className="w-4 h-4" />
                                                                                </button>
                                                                                <button
                                                                                    onClick={() => {
                                                                                        setEditingConsulta(c);
                                                                                        setEditConsultaModalOpen(true);
                                                                                    }}
                                                                                    className="text-violet-500 hover:text-violet-400 transition-all duration-200 p-2 rounded-lg hover:bg-zinc-800"
                                                                                    title="Editar consulta"
                                                                                >
                                                                                    <Pencil className="w-4 h-4" />
                                                                                </button>
                                                                                <span className="font-medium text-zinc-100">
                                                                                    {c.consulta_name}
                                                                                </span>
                                                                            </div>
                                                                            {/* Badge para o método da requisição */}
                                                                            <span className="px-2 py-1 text-xs font-medium rounded-md bg-zinc-800 text-zinc-300">
                                                                                {c.method}
                                                                            </span>
                                                                        </div>
                                                                        {/* Exibe os campos obrigatórios (se houver) */}
                                                                        {c.required_fields &&
                                                                            c.required_fields.length > 0 && (
                                                                                <div className="flex items-center gap-2 text-sm text-zinc-400">
                                                                                    <Code2 className="w-4 h-4" />
                                                                                    <span>
                                                                                        Campos:{" "}
                                                                                        {c.required_fields.join(
                                                                                            ", "
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                    </div>

                                                                    {/* Dropdown com estilo aprimorado para o response_example */}
                                                                    {c.response_example && (
                                                                        <div className="mt-4">
                                                                            <button
                                                                                onClick={() =>
                                                                                    toggleDropdown(c.consulta_name)
                                                                                }
                                                                                className="w-full flex items-center gap-2 text-violet-300 hover:text-violet-200 transition-colors duration-200 text-sm font-medium bg-zinc-800/30 hover:bg-zinc-800/50 p-2 rounded-lg border border-zinc-800/50 hover:border-violet-900/50"
                                                                            >
                                                                                <ChevronDown
                                                                                    className={`w-4 h-4 transition-transform duration-200 ${
                                                                                        openDropdown === c.consulta_name
                                                                                            ? "rotate-180"
                                                                                            : ""
                                                                                    }`}
                                                                                />
                                                                                <span>Exemplo de Retorno</span>
                                                                                <ExternalLink className="w-3 h-3 ml-1" />
                                                                            </button>

                                                                            <div
                                                                                className={`mt-3 overflow-hidden transition-all duration-300 ${
                                                                                    openDropdown === c.consulta_name
                                                                                        ? "max-h-96 opacity-100"
                                                                                        : "max-h-0 opacity-0"
                                                                                }`}
                                                                            >
                                                                                <pre className="whitespace-pre-wrap break-words text-sm bg-zinc-800/50 p-4 rounded-lg border border-zinc-700 font-mono text-zinc-300">
                                                                                    {c.response_example}
                                                                                </pre>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                        </div>
                                    </div>
                                </ScrollReveal>

                                <ScrollReveal>
                                  <div className="mx-auto max-w-5xl">
                                    <div className="group relative overflow-hidden bg-zinc-900 border border-zinc-800 rounded-2xl transition-all duration-300 hover:shadow-[0_0_25px_rgba(124,58,237,0.05)]">
                                      {/* Gradient Overlay */}
                                      <div className="absolute inset-0 bg-gradient-to-r from-violet-500/5 via-transparent to-emerald-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />

                                      {/* Header */}
                                      <div className="relative p-6 flex items-center justify-between border-b border-zinc-800/80 bg-zinc-900/50">
                                        <div className="flex items-center gap-4">
                                          <div className="relative">
                                            <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-violet-500/5 to-emerald-500/5 flex items-center justify-center border border-zinc-800/80">
                                              <TrendingUp className="w-6 h-6 text-zinc-100" />
                                            </div>
                                            <div className="absolute inset-0 rounded-xl bg-gradient-to-br from-violet-500/5 to-emerald-500/5 blur-[1px] opacity-20" />
                                          </div>
                                          <div>
                                            <div className="flex items-center gap-2">
                                              <h3 className="text-xl font-semibold text-zinc-100">
                                                Suas Estatísticas
                                              </h3>
                                              <ChevronRight className="w-4 h-4 text-zinc-600" />
                                            </div>
                                            <p className="text-sm text-zinc-400 mt-0.5">
                                              Visualize suas métricas e desempenho
                                            </p>
                                          </div>
                                        </div>
                                        <div className="hidden md:flex items-center gap-2 px-3 py-1.5 rounded-full bg-zinc-800/50 border border-zinc-700/50">
                                          <div className="w-2 h-2 rounded-full bg-emerald-500 animate-pulse" />
                                          <span className="text-xs text-zinc-300">Atualizado em tempo real</span>
                                        </div>
                                      </div>

                                      {/* Content */}
                                      <div className="p-6 space-y-6">
                                        {loadingStats && (
                                          <div className="flex items-center justify-center h-64">
                                            <BouncingDots />
                                          </div>
                                        )}

                                        {errorStats && (
                                          <div className="flex items-center justify-center h-64">
                                            <p className="text-red-400">{errorStats}</p>
                                          </div>
                                        )}

                                        {!loadingStats && supplierStats && (
                                          <>
                                            {/* Overview Cards */}
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                              <div className="border border-zinc-800 p-5 rounded-lg space-y-3 hover:border-zinc-700/50 transition-all duration-300 bg-zinc-900/50">
                                                <h3 className="font-semibold text-white text-lg">Consultas Realizadas</h3>
                                                <div className="space-y-2">
                                                  <div className="flex justify-between items-center">
                                                    <span className="text-zinc-300 font-medium">Total de Consultas</span>
                                                    <span className="text-zinc-100 font-semibold">{supplierStats.overview.consultas.total_calls}</span>
                                                  </div>
                                                  <div className="flex justify-between items-center">
                                                    <span className="text-zinc-300 font-medium">Ganhos com Consultas</span>
                                                    <span className="text-zinc-100 font-semibold">R$ {supplierStats.overview.consultas.total_earnings.toFixed(2)}</span>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="border border-zinc-800 p-5 rounded-lg space-y-3 hover:border-zinc-700/50 transition-all duration-300 bg-zinc-900/50">
                                                <h3 className="font-semibold text-white text-lg">Planos Vendidos</h3>
                                                <div className="space-y-2">
                                                  <div className="flex justify-between items-center">
                                                    <span className="text-zinc-300 font-medium">Total de Planos</span>
                                                    <span className="text-zinc-100 font-semibold">{supplierStats.overview.plans.total_purchases}</span>
                                                  </div>
                                                  <div className="flex justify-between items-center">
                                                    <span className="text-zinc-300 font-medium">Ganhos com Planos</span>
                                                    <span className="text-zinc-100 font-semibold">R$ {supplierStats.overview.plans.total_earnings.toFixed(2)}</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            {/* Section Divider */}
                                            <div className="flex items-center gap-2">
                                              <div className="h-px flex-1 bg-gradient-to-r from-zinc-800 via-zinc-700/50 to-zinc-800" />
                                              <span className="text-xs font-medium text-zinc-500 px-2">DETALHAMENTO</span>
                                              <div className="h-px flex-1 bg-gradient-to-r from-zinc-800 via-zinc-700/50 to-zinc-800" />
                                            </div>

                                            {/* Detailed Stats */}
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                              {/* Ganhos por Consulta */}
                                              <div className="border border-zinc-800 p-5 rounded-lg hover:border-zinc-700/50 transition-all duration-300 bg-zinc-900/50">
                                                <div className="flex items-center gap-2 mb-4">
                                                  <div className="p-2 rounded-lg bg-zinc-800/50">
                                                    <Activity className="w-5 h-5 text-zinc-300" />
                                                  </div>
                                                  <h3 className="font-semibold text-white text-lg">Ganhos por Consulta</h3>
                                                </div>
                                                {supplierStats.by_consulta.length === 0 ? (
                                                  <p className="text-sm text-zinc-400">Nenhum registro encontrado.</p>
                                                ) : (
                                                  <ul className="space-y-2">
                                                    {supplierStats.by_consulta.map((item) => (
                                                      <li
                                                        key={item.consulta_name}
                                                        className="transform hover:translate-x-2 transition-transform duration-200 p-3 rounded-md hover:bg-zinc-800/50 border border-zinc-800/50"
                                                      >
                                                        <div className="flex justify-between items-center">
                                                          <div className="space-y-1">
                                                            <span className="text-zinc-200 font-medium block">
                                                              {item.consulta_name}
                                                            </span>
                                                            <span className="text-zinc-500 text-sm block">
                                                              {item.calls_count} chamadas
                                                            </span>
                                                          </div>
                                                          <span className="text-emerald-400/90 font-medium bg-emerald-500/5 px-3 py-1 rounded-full border border-emerald-500/10">
                                                            R$ {item.earnings.toFixed(2)}
                                                          </span>
                                                        </div>
                                                      </li>
                                                    ))}
                                                  </ul>
                                                )}
                                              </div>

                                              {/* Ganhos por Plano */}
                                              <div className="border border-zinc-800 p-5 rounded-lg hover:border-zinc-700/50 transition-all duration-300 bg-zinc-900/50">
                                                <div className="flex items-center gap-2 mb-4">
                                                  <div className="p-2 rounded-lg bg-zinc-800/50">
                                                    <Package className="w-5 h-5 text-zinc-300" />
                                                  </div>
                                                  <h3 className="font-semibold text-white text-lg">Ganhos por Plano</h3>
                                                </div>
                                                {supplierStats.by_plan.length === 0 ? (
                                                  <p className="text-sm text-zinc-400">Nenhum registro encontrado.</p>
                                                ) : (
                                                  <ul className="space-y-2">
                                                    {supplierStats.by_plan.map((item) => (
                                                      <li
                                                        key={item.plan_name}
                                                        className="transform hover:translate-x-2 transition-transform duration-200 p-3 rounded-md hover:bg-zinc-800/50 border border-zinc-800/50"
                                                      >
                                                        <div className="flex justify-between items-center">
                                                          <div className="space-y-1">
                                                            <span className="text-zinc-200 font-medium block">
                                                              {item.plan_name}
                                                            </span>
                                                            <span className="text-zinc-500 text-sm block">
                                                              {item.purchases_count} vendas
                                                            </span>
                                                          </div>
                                                          <span className="text-emerald-400/90 font-medium bg-emerald-500/5 px-3 py-1 rounded-full border border-emerald-500/10">
                                                            R$ {item.earnings.toFixed(2)}
                                                          </span>
                                                        </div>
                                                      </li>
                                                    ))}
                                                  </ul>
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </ScrollReveal>

                                {/* Criar Novo Plano */}
                                <ScrollReveal>
                                    <div className="mx-auto max-w-5xl">
                                        <div className="group relative overflow-hidden bg-zinc-900 border border-zinc-800 rounded-2xl transition-all duration-300 hover:shadow-[0_0_30px_rgba(124,58,237,0.1)]">
                                            {/* Header */}
                                            <div className="p-6 flex items-center justify-between border-b border-zinc-800">
                                                <div className="flex items-center gap-4">
                                                    <div className="w-10 h-10 rounded-full bg-violet-500/10 flex items-center justify-center">
                                                        <Folder className="w-5 h-5 text-violet-500" />
                                                    </div>
                                                    <div>
                                                        <h3 className="text-lg font-medium text-zinc-100">
                                                            Criar Novo Plano
                                                        </h3>
                                                        <p className="text-sm text-zinc-400">
                                                            Defina os detalhes
                                                            do plano
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Form Content */}
                                            <div className="p-6 space-y-6">
                                                {/* Nome do Plano */}
                                                <div className="space-y-2">
                                                    <label className="text-sm font-medium text-zinc-100">
                                                        Nome do Plano
                                                    </label>
                                                    <input
                                                        type="text"
                                                        placeholder="Ex: Plano Premium"
                                                        value={planName}
                                                        onChange={(e) =>
                                                            setPlanName(
                                                                e.target.value,
                                                            )
                                                        }
                                                        className="w-full h-10 px-4 bg-zinc-800 border-0 text-sm text-zinc-100 placeholder:text-zinc-500 rounded-xl focus:outline-none focus:ring-2 focus:ring-violet-500/20"
                                                    />
                                                </div>

                                                {/* Duração em Dias */}
                                                <div className="space-y-2">
                                                    <label className="text-sm font-medium text-zinc-100">
                                                        Duração (dias)
                                                    </label>
                                                    <input
                                                        type="number"
                                                        placeholder="Ex: 30"
                                                        value={planDuration}
                                                        onChange={(e) =>
                                                            setPlanDuration(
                                                                e.target.value,
                                                            )
                                                        }
                                                        className="w-full h-10 px-4 bg-zinc-800 border-0 text-sm text-zinc-100 placeholder:text-zinc-500 rounded-xl focus:outline-none focus:ring-2 focus:ring-violet-500/20"
                                                    />
                                                </div>

                                                {/* Módulos Permitidos */}
                                                <div className="space-y-2">
                                                    <label className="text-sm font-medium text-zinc-100">
                                                        Módulos de Consultas
                                                        Permitidos
                                                    </label>
                                                    <div className="p-4 bg-zinc-800/50 rounded-xl space-y-3">
                                                        {consultasFornecedor.length ===
                                                        0 ? (
                                                            <p className="text-sm text-zinc-400">
                                                                Nenhuma consulta
                                                                disponível
                                                            </p>
                                                        ) : (
                                                            consultasFornecedor.map(
                                                                (c) => {
                                                                    const checked =
                                                                        planAllowedModules.includes(
                                                                            c.consulta_name,
                                                                        );
                                                                    return (
                                                                        <label
                                                                            key={
                                                                                c.consulta_name
                                                                            }
                                                                            className="flex items-center gap-3 text-sm text-zinc-300 hover:text-zinc-200 transition-colors cursor-pointer group/checkbox"
                                                                        >
                                                                            <div className="relative flex items-center justify-center">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={
                                                                                        checked
                                                                                    }
                                                                                    onChange={() => {
                                                                                        if (
                                                                                            checked
                                                                                        ) {
                                                                                            setPlanAllowedModules(
                                                                                                (
                                                                                                    prev,
                                                                                                ) =>
                                                                                                    prev.filter(
                                                                                                        (
                                                                                                            m,
                                                                                                        ) =>
                                                                                                            m !==
                                                                                                            c.consulta_name,
                                                                                                    ),
                                                                                            );
                                                                                        } else {
                                                                                            setPlanAllowedModules(
                                                                                                (
                                                                                                    prev,
                                                                                                ) => [
                                                                                                    ...prev,
                                                                                                    c.consulta_name,
                                                                                                ],
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                    className="w-5 h-5 border-2 border-zinc-700 rounded-md bg-zinc-800 checked:bg-violet-500 checked:border-violet-500 appearance-none transition-colors cursor-pointer group-hover/checkbox:border-violet-500/50"
                                                                                />
                                                                                <svg
                                                                                    className={`absolute w-3 h-3 pointer-events-none transition-opacity ${
                                                                                        checked
                                                                                            ? "opacity-100"
                                                                                            : "opacity-0"
                                                                                    }`}
                                                                                    viewBox="0 0 17 12"
                                                                                    fill="none"
                                                                                    stroke="white"
                                                                                    strokeWidth="3"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                >
                                                                                    <path d="M1 4.5L6 9.5L15.5 1" />
                                                                                </svg>
                                                                            </div>
                                                                            <span>
                                                                                {
                                                                                    c.consulta_name
                                                                                }
                                                                            </span>
                                                                        </label>
                                                                    );
                                                                },
                                                            )
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Preço */}
                                                <div className="space-y-2">
                                                    <label className="text-sm font-medium text-zinc-100">
                                                        Preço (R$)
                                                    </label>
                                                    <input
                                                        type="number"
                                                        placeholder="Ex: 100"
                                                        value={planPrice}
                                                        onChange={(e) =>
                                                            setPlanPrice(
                                                                e.target.value,
                                                            )
                                                        }
                                                        className="w-full h-10 px-4 bg-zinc-800 border-0 text-sm text-zinc-100 placeholder:text-zinc-500 rounded-xl focus:outline-none focus:ring-2 focus:ring-violet-500/20"
                                                    />
                                                </div>

                                                {/* Limite Diário */}
                                                <div className="space-y-2">
                                                    <label className="text-sm font-medium text-zinc-100">
                                                        Limite Diário de
                                                        Consultas
                                                    </label>
                                                    <input
                                                        type="number"
                                                        placeholder="Ex: 10"
                                                        value={planDailyLimit}
                                                        onChange={(e) =>
                                                            setPlanDailyLimit(
                                                                e.target.value,
                                                            )
                                                        }
                                                        className="w-full h-10 px-4 bg-zinc-800 border-0 text-sm text-zinc-100 placeholder:text-zinc-500 rounded-xl focus:outline-none focus:ring-2 focus:ring-violet-500/20"
                                                    />
                                                </div>

                                                {/* Descrição do Plano */}
                                                <div className="space-y-2">
                                                    <label className="text-sm font-medium text-zinc-100">
                                                        Descrição
                                                    </label>
                                                    <textarea
                                                        placeholder="Ex: Acesso a vários módulos por 30 dias..."
                                                        value={planDescription}
                                                        onChange={(e) =>
                                                            setPlanDescription(
                                                                e.target.value,
                                                            )
                                                        }
                                                        className="w-full min-h-[80px] p-4 resize-none bg-zinc-800 border-0 rounded-xl text-sm text-zinc-100 placeholder:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-violet-500/20"
                                                    />
                                                </div>
                                            </div>

                                            {/* Footer */}
                                            <div className="p-6 border-t border-zinc-800">
                                                <div className="flex flex-col-reverse sm:flex-row gap-3">
                                                    <button
                                                        onClick={() => {
                                                            setPlanName("");
                                                            setPlanDuration("");
                                                            setPlanAllowedModules(
                                                                [],
                                                            );
                                                            setPlanPrice("");
                                                            setPlanDailyLimit(
                                                                "",
                                                            );
                                                            setPlanDescription(
                                                                "",
                                                            );
                                                        }}
                                                        className="px-4 py-2 text-zinc-400 hover:text-zinc-300 hover:bg-zinc-800 rounded-xl transition-colors"
                                                    >
                                                        Cancelar
                                                    </button>
                                                    <button
                                                        onClick={
                                                            handleCreatePlan
                                                        }
                                                        disabled={
                                                            loadingCreatePlan
                                                        }
                                                        className="px-4 py-2 bg-violet-600 hover:bg-violet-700 text-white rounded-xl transition-colors flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
                                                    >
                                                        {loadingCreatePlan && (
                                                            <Loader2 className="w-4 h-4 animate-spin" />
                                                        )}
                                                        <Users className="w-4 h-4" />
                                                        <span>Criar Plano</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ScrollReveal>

                                <ScrollReveal>
                                    <div className="mx-auto max-w-5xl">
                                        <div className="group relative overflow-hidden bg-zinc-900 border border-zinc-800 rounded-2xl transition-all duration-300 hover:shadow-[0_0_30px_rgba(124,58,237,0.1)]">
                                            <div className="p-6 flex items-center justify-between border-b border-zinc-800">
                                                <div className="flex items-center gap-4">
                                                    <div className="w-10 h-10 rounded-full bg-violet-500/10 flex items-center justify-center">
                                                        <ChevronDown className="w-5 h-5 text-violet-500" />
                                                    </div>
                                                    <div>
                                                        <h3 className="text-lg font-medium text-zinc-100">
                                                            Seus Planos
                                                        </h3>
                                                        <p className="text-sm text-zinc-400">
                                                            Visualize e gerencie
                                                            seus planos ativos
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-6">
                                                {loadingPlans ? (
                                                    <div className="flex flex-col items-center justify-center py-8">
                                                        <Loader2 className="w-8 h-8 text-violet-500 animate-spin mb-4" />
                                                        <p className="text-zinc-400">
                                                            Carregando seus
                                                            planos...
                                                        </p>
                                                    </div>
                                                ) : errorPlans ? (
                                                    <div className="flex items-center justify-center gap-3 p-4 bg-red-500/10 rounded-lg">
                                                        <AlertCircle className="w-5 h-5 text-red-500" />
                                                        <p className="text-red-400">
                                                            {errorPlans}
                                                        </p>
                                                    </div>
                                                ) : plansList.length === 0 ? (
                                                    <div className="text-center py-8">
                                                        <div className="w-16 h-16 rounded-full bg-zinc-800 mx-auto flex items-center justify-center mb-4">
                                                            <AlertCircle className="w-8 h-8 text-zinc-400" />
                                                        </div>
                                                        <p className="text-zinc-400">
                                                            Nenhum plano criado
                                                            ainda.
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <ul className="space-y-4">
                                                        {plansList.map(
                                                            (plan) => (
                                                                <li
                                                                    key={
                                                                        plan.plan_id
                                                                    }
                                                                    className="group relative transform hover:translate-x-2 transition-all duration-300 bg-zinc-800/50 rounded-xl p-4 hover:bg-zinc-800"
                                                                >
                                                                    <div className="flex flex-col lg:flex-row lg:items-center gap-4">
                                                                        <div className="flex-1">
                                                                            <div className="flex items-center gap-3 mb-2">
                                                                                <div className="flex items-center gap-2">
                                                                                    <button
                                                                                        onClick={() =>
                                                                                            setDeleteModal(
                                                                                                {
                                                                                                    show: true,
                                                                                                    planId: plan.plan_id,
                                                                                                    planName:
                                                                                                        plan.plan_name,
                                                                                                    type: "plano",
                                                                                                },
                                                                                            )
                                                                                        }
                                                                                        className="text-red-500 hover:text-red-400 transition-colors p-2 rounded-lg hover:bg-zinc-700"
                                                                                        title="Excluir plano"
                                                                                    >
                                                                                        <Trash2 className="w-4 h-4" />
                                                                                    </button>
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            setEditingPlan(
                                                                                                plan,
                                                                                            );
                                                                                            setEditModalOpen(
                                                                                                true,
                                                                                            );
                                                                                        }}
                                                                                        className="text-violet-500 hover:text-violet-400 transition-colors p-2 rounded-lg hover:bg-zinc-700"
                                                                                        title="Editar plano"
                                                                                    >
                                                                                        <Pencil className="w-4 h-4" />
                                                                                    </button>
                                                                                </div>
                                                                                <span className="font-semibold text-zinc-100 text-lg">
                                                                                    {
                                                                                        plan.plan_name
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            {plan.description && (
                                                                                <p className="text-sm text-zinc-400 mb-3">
                                                                                    {
                                                                                        plan.description
                                                                                    }
                                                                                </p>
                                                                            )}
                                                                        </div>

                                                                        <div className="flex flex-col lg:flex-row gap-4 lg:items-center">
                                                                            <div className="px-4 py-2 rounded-lg bg-violet-500/10 border border-violet-500/20">
                                                                                <span className="text-violet-400 font-medium">
                                                                                    R${" "}
                                                                                    {
                                                                                        plan.price
                                                                                    }
                                                                                </span>
                                                                            </div>

                                                                            <div className="flex flex-col gap-1">
                                                                                <div className="text-sm text-zinc-300">
                                                                                    <span className="text-zinc-400">
                                                                                        Duração:
                                                                                    </span>{" "}
                                                                                    {
                                                                                        plan.duration_days
                                                                                    }{" "}
                                                                                    dias
                                                                                </div>
                                                                                <div className="text-sm text-zinc-300">
                                                                                    <span className="text-zinc-400">
                                                                                        Limite
                                                                                        diário:
                                                                                    </span>{" "}
                                                                                    {
                                                                                        plan.daily_limit
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="mt-3 flex flex-wrap gap-2">
                                                                        {plan.allowed_modules.map(
                                                                            (
                                                                                module,
                                                                                index,
                                                                            ) => (
                                                                                <span
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    className="px-2 py-1 text-xs rounded-full bg-zinc-700 text-zinc-300"
                                                                                >
                                                                                    {
                                                                                        module
                                                                                    }
                                                                                </span>
                                                                            ),
                                                                        )}
                                                                    </div>
                                                                </li>
                                                            ),
                                                        )}
                                                    </ul>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </ScrollReveal>
                            </div>
                        ) : null}
                    </>
                )}
                <EditPlanModal
                    isOpen={editModalOpen}
                    onClose={() => {
                        setEditModalOpen(false);
                        setEditingPlan(null);
                    }}
                    onSave={async (updatedPlan) => {
                        try {
                            setEditLoading(true);
                            const response = await axiosInstance.post(
                                `/dev/edit_supplier_plan/${editingPlan.plan_id}`,
                                updatedPlan,
                            );

                            if (response.status === 200) {
                                toast.success("Plano atualizado com sucesso!");
                                const plansResp = await axiosInstance.get(
                                    "/dev/list_supplier_plans",
                                );
                                setPlansList(plansResp.data.plans || []);
                                setEditModalOpen(false);
                                setEditingPlan(null);
                            }
                        } catch (err) {
                            toast.error(
                                err.response?.data?.error ||
                                "Erro ao atualizar plano",
                            );
                        } finally {
                            setEditLoading(false);
                        }
                    }}
                    plan={editingPlan}
                    consultasFornecedor={consultasFornecedor}
                    loading={editLoading}
                />
                <DeleteConfirmationModal
                    isOpen={deleteModal.show}
                    onClose={() =>
                        setDeleteModal({
                            show: false,
                            planId: null,
                            planName: "",
                            consultaName: "",
                            type: "",
                        })
                    }
                    onConfirm={async () => {
                        try {
                            if (deleteModal.type === "plano") {
                                await axiosInstance.delete(
                                    `/dev/delete_plan/${deleteModal.planId}`,
                                );
                                toast.success("Plano deletado com sucesso!");
                                const resp = await axiosInstance.get(
                                    "/dev/list_supplier_plans",
                                );
                                setPlansList(resp.data.plans || []);
                            } else if (deleteModal.type === "consulta") {
                                const response = await axiosInstance.delete(
                                    `/dev/delete_consulta/${deleteModal.consultaName}`,
                                );
                                setConsultasFornecedor((prev) =>
                                    prev.filter(
                                        (c) =>
                                            c.consulta_name !==
                                            deleteModal.consultaName,
                                    ),
                                );
                                if (response.data.plans_count > 0) {
                                    toast.info(
                                        `Consulta removida de ${response.data.plans_count} ${
                                            response.data.plans_count === 1
                                                ? "plano"
                                                : "planos"
                                        }: ${response.data.affected_plans.join(", ")}`,
                                    );
                                    const plansResp = await axiosInstance.get(
                                        "/dev/list_supplier_plans",
                                    );
                                    setPlansList(plansResp.data.plans || []);
                                }
                                toast.success("Consulta deletada com sucesso!");
                            }
                        } catch (err) {
                            console.error(err);
                            toast.error(
                                err.response?.data?.error ||
                                    `Erro ao deletar ${deleteModal.type}`,
                            );
                        } finally {
                            setDeleteModal({
                                show: false,
                                planId: null,
                                planName: "",
                                consultaName: "",
                                type: "",
                            });
                        }
                    }}
                    itemName={
                        deleteModal.type === "plano"
                            ? deleteModal.planName
                            : deleteModal.consultaName
                    }
                    itemType={deleteModal.type}
                />
                <EditConsultaModal
                    isOpen={editConsultaModalOpen}
                    onClose={() => {
                        setEditConsultaModalOpen(false);
                        setEditingConsulta(null);
                    }}
                    onSave={async (updatedConsulta) => {
                        try {
                            setEditConsultaLoading(true);
                            const response = await axiosInstance.post(
                                `/dev/edit_consulta/${editingConsulta.consulta_name}`,
                                updatedConsulta
                            );

                            if (response.status === 200) {
                                toast.success("Consulta atualizada com sucesso!");
                                const consultasResp = await axiosInstance.get("/consultas_fornecedor");
                                setConsultasFornecedor(consultasResp.data.consultas || []);
                                setEditConsultaModalOpen(false);
                                setEditingConsulta(null);
                            }
                        } catch (err) {
                            toast.error(
                                err.response?.data?.error ||
                                "Erro ao atualizar consulta"
                            );
                        } finally {
                            setEditConsultaLoading(false);
                        }
                    }}
                    consulta={editingConsulta}
                    loading={editConsultaLoading}
                />
            </div>


        </div>
    );
}

export default ApiSuppliers;

import React from "react";
import { Key, Check, Copy, Globe } from "lucide-react";

const cnButton = (...classes) => classes.filter(Boolean).join(" ");

function useCopyToClipboard({ timeout = 1000 } = {}) {
  const [isCopied, setIsCopied] = React.useState(false);

  const copyToClipboard = (value) => {
    if (typeof window === "undefined" || !navigator.clipboard?.writeText)
      return;
    navigator.clipboard.writeText(value).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), timeout);
    });
  };

  return { isCopied, copyToClipboard };
}

const SmallCopyButton = ({ textToCopy, successDuration = 1000, className }) => {
  const { isCopied, copyToClipboard } = useCopyToClipboard({
    timeout: successDuration,
  });

  const handleCopy = () => {
    if (!isCopied) copyToClipboard(textToCopy);
  };

  return (
    <button
      onClick={handleCopy}
      className={cnButton(
        "group",
        "p-1 text-xs h-auto w-auto",
        "bg-transparent",
        "text-emerald-600 dark:text-emerald-300",
        className,
      )}
      aria-label="Copiar para a área de transferência"
    >
      {isCopied ? (
        <Check className="w-4 h-4 text-emerald-500" />
      ) : (
        <Copy className="w-4 h-4 transition-transform duration-200 group-hover:scale-110" />
      )}
    </button>
  );
};

const HSDocumentation = ({ vendorToken }) => {
  const BASE_URL = "https://api.astrashopbot.cc";

  return (
    <div className="space-y-8">
      <div className="p-6 rounded-2xl border border-zinc-200 dark:border-zinc-800 bg-white dark:bg-zinc-900">
        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <Key className="w-5 h-5 text-purple-500" />
            <h3 className="text-xl font-semibold">Seu Token de API</h3>
          </div>
          <div className="p-4 rounded-lg bg-zinc-50 dark:bg-zinc-800 font-mono text-sm flex items-center justify-between">
            <div className="break-all">{vendorToken}</div>
            <SmallCopyButton textToCopy={vendorToken} />
          </div>
        </div>
      </div>

      <div className="space-y-6">
        {[
          {
            title: "Add Hash",
            endpoint: "/api/dev/add_hash",
            method: "POST",
            description: "Adicione novas hashes de cartão ao marketplace",
            body: {
              hashes: [
                {
                  hash: "blake3_hash_128_chars",
                  bin: "123456",
                  month: "12",
                  year: "24",
                },
                {
                  hash: "blake3_hash_128_chars",
                  bin: "654321",
                  month: "01",
                  year: "26",
                },
              ],
              filename: "cards.txt",
              line_count: 100,
              identified_cards: 95,
              unidentified_cards: 5,
            },
            responseExample: {
              results: [
                {
                  hash: "blake3_hash_128_chars",
                  status: "success",
                  id: "inserted_hash_id",
                },
                {
                  hash: "blake3_hash_128_chars",
                  status: "error",
                  message: "Hash already exists",
                },
              ],
              file_id: "inserted_file_id",
            },
            authRequired: true,
          },
          {
            title: "Verify Hash",
            endpoint: "/api/dev/verify_hash",
            method: "POST",
            description: "Verifique se uma hash existe e obtenha seu status",
            body: {
              hash: "blake3_hash_128_chars",
            },
            responseExample: {
              exists: true,
              hash: "blake3_hash_128_chars",
              status: "active",
              created_at: "2024-11-18T12:00:00.000Z",
              vendor_name: "Vendor Name",
              endpoint_url: "https://vendor-endpoint.com",
            },
            authRequired: true,
          },
          {
            title: "Get Stats",
            endpoint: "/api/dev/stats",
            method: "GET",
            description: "Obtenha estatísticas sobre suas hashes",
            responseExample: {
              total_hashes: 100,
              active_hashes: 80,
              inactive_hashes: 20,
              new_hashes_24h: 5,
              uploaded_files: [
                {
                  file_id: "file_id_1",
                  filename: "cards1.txt",
                  line_count: 100,
                  identified_cards: 95,
                  unidentified_cards: 5,
                  timestamp: "2024-11-18T12:00:00.000Z",
                },
                {
                  file_id: "file_id_2",
                  filename: "cards2.txt",
                  line_count: 200,
                  identified_cards: 190,
                  unidentified_cards: 10,
                  timestamp: "2024-11-19T14:30:00.000Z",
                },
              ],
              timestamp: "2024-11-27T15:45:30.123456Z",
            },
            authRequired: true,
          },
        ].map((route) => (
          <div
            key={route.endpoint}
            className="p-6 rounded-2xl border border-zinc-200 dark:border-zinc-800 bg-white dark:bg-zinc-900"
          >
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Globe className="w-5 h-5 text-blue-500" />
                  <h3 className="text-lg font-semibold">{route.title}</h3>
                </div>
                <span
                  className={`px-3 py-1 rounded-full text-sm font-medium ${
                    route.method === "GET"
                      ? "bg-green-100 dark:bg-green-900 text-green-700 dark:text-green-300"
                      : "bg-blue-100 dark:bg-blue-900 text-blue-700 dark:text-blue-300"
                  }`}
                >
                  {route.method}
                </span>
              </div>

              <p className="text-sm text-zinc-600 dark:text-zinc-400">
                {route.description}
              </p>

              <div className="space-y-2">
                <div className="text-sm font-medium text-zinc-600 dark:text-zinc-400">
                  Endpoint
                </div>
                <div className="p-3 rounded-lg bg-zinc-50 dark:bg-zinc-800 font-mono text-sm">
                  {route.endpoint}
                </div>
              </div>

              {route.body && (
                <div className="space-y-2">
                  <div className="text-sm font-medium text-zinc-600 dark:text-zinc-400">
                    Request Body
                  </div>
                  <div className="p-3 rounded-lg bg-zinc-50 dark:bg-zinc-800 font-mono text-sm">
                    {JSON.stringify(route.body, null, 2)}
                  </div>
                </div>
              )}

              <div className="space-y-2">
                <div className="text-sm font-medium text-zinc-600 dark:text-zinc-400">
                  Exemplo de Requisição
                </div>
                <div className="relative p-3 rounded-lg bg-zinc-50 dark:bg-zinc-800 font-mono text-sm">
                  <SmallCopyButton
                    textToCopy={`curl -X ${
                      route.method
                    } ${BASE_URL}${route.endpoint} -H "Authorization: Bearer ${
                      vendorToken
                    }" -H "Content-Type: application/json" ${
                      route.body ? `-d '${JSON.stringify(route.body)}'` : ""
                    }`}
                    className="absolute top-2 right-2"
                  />
                  <div className="whitespace-pre-wrap overflow-x-auto">
                    {`curl -X ${route.method} ${BASE_URL}${route.endpoint} \\
  -H "Authorization: Bearer ${vendorToken || "your_token_here"}" \\
  -H "Content-Type: application/json" ${
    route.body
      ? `\\
  -d '${JSON.stringify(route.body)}'`
      : ""
  }`}
                  </div>
                </div>
              </div>

              {route.responseExample && (
                <div className="space-y-2">
                  <div className="text-sm font-medium text-zinc-600 dark:text-zinc-400">
                    Exemplo de Resposta
                  </div>
                  <div className="p-3 rounded-lg bg-zinc-50 dark:bg-zinc-800 font-mono text-sm">
                    {JSON.stringify(route.responseExample, null, 2)}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HSDocumentation;

import React, { useState, useEffect } from "react";
import {
    Hash,
    Key,
    Users,
    Plus,
    ChevronDown,
    Shield,
    DollarSign,
    Download,
} from "lucide-react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

const Button = ({ children, className, ...props }) => (
    <button className={`inline-flex items-center ${className}`} {...props}>
        {children}
    </button>
);

const Badge = ({ children, className, ...props }) => (
    <span
        className={`inline-flex items-center px-2 py-0.5 text-xs font-medium rounded-full ${className}`}
        {...props}
    >
        {children}
    </span>
);

const UploadedFilesList = ({ uploadedFiles = [], vendorToken }) => {
    const [expandedItems, setExpandedItems] = useState([]);

    const toggleItem = (index) => {
        setExpandedItems((prev) =>
            prev.includes(index)
                ? prev.filter((i) => i !== index)
                : [...prev, index],
        );
    };

    const handleDownload = async () => {
        try {
            const response = await fetch(
                "https://api.astrashopbot.cc/api/get_my_hashes",
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${vendorToken}`,
                        "Content-Type": "application/json",
                    },
                },
            );

            if (!response.ok) {
                console.error("Erro ao baixar as hashes");
                return;
            }

            const data = await response.json();

            const blob = new Blob([JSON.stringify(data, null, 2)], {
                type: "application/json",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "hashes.json";
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Erro ao baixar as hashes:", error);
        }
    };

    if (!uploadedFiles || uploadedFiles.length === 0) {
        return (
            <div className="w-full max-w-3xl mx-auto mt-8">
                <div className="rounded-2xl border border-zinc-200 dark:border-zinc-800 overflow-hidden">
                    <div className="bg-gradient-to-r from-zinc-100 to-zinc-50 dark:from-zinc-900 dark:to-zinc-800/50 p-6">
                        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4">
                            <div>
                                <h2 className="text-xl font-semibold text-zinc-900 dark:text-zinc-100">
                                    Arquivos Enviados
                                </h2>
                                <p className="text-sm text-zinc-500 dark:text-zinc-400">
                                    Lista de arquivos enviados recentemente
                                </p>
                            </div>
                            <Button
                                className="mt-4 sm:mt-0 border border-zinc-300 dark:border-zinc-700 text-sm px-4 py-2 rounded"
                                onClick={handleDownload}
                            >
                                <Download className="w-4 h-4 mr-2" />
                                Download
                            </Button>
                        </div>
                        <div className="flex flex-wrap items-center gap-4 text-sm text-zinc-500 dark:text-zinc-400">
                            <div className="flex items-center gap-1">
                                <Users className="w-4 h-4" />
                                {uploadedFiles.length} arquivos
                            </div>
                            <div className="flex items-center gap-1">
                                <Shield className="w-4 h-4" />
                                Total de Linhas:{" "}
                                {uploadedFiles.reduce(
                                    (sum, file) => sum + file.line_count,
                                    0,
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="p-6">
                        <p className="text-zinc-600 dark:text-zinc-400">
                            Nenhum arquivo enviado.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="w-full max-w-3xl mx-auto mt-8">
            <div className="rounded-2xl border border-zinc-200 dark:border-zinc-800 overflow-hidden">
                <div className="bg-gradient-to-r from-zinc-100 to-zinc-50 dark:from-zinc-900 dark:to-zinc-800/50 p-6">
                    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4">
                        <div>
                            <h2 className="text-xl font-semibold text-zinc-900 dark:text-zinc-100">
                                Arquivos Enviados
                            </h2>
                            <p className="text-sm text-zinc-500 dark:text-zinc-400">
                                Lista de arquivos enviados recentemente
                            </p>
                        </div>
                        <Button
                            className="mt-4 sm:mt-0 border border-zinc-300 dark:border-zinc-700 text-sm px-4 py-2 rounded"
                            onClick={handleDownload}
                        >
                            <Download className="w-4 h-4 mr-2" />
                            Download
                        </Button>
                    </div>
                    <div className="flex flex-wrap items-center gap-4 text-sm text-zinc-500 dark:text-zinc-400">
                        <div className="flex items-center gap-1">
                            <Users className="w-4 h-4" />
                            {uploadedFiles.length} arquivos
                        </div>
                        <div className="flex items-center gap-1">
                            <Shield className="w-4 h-4" />
                            Total de Linhas:{" "}
                            {uploadedFiles.reduce(
                                (sum, file) => sum + file.line_count,
                                0,
                            )}
                        </div>
                    </div>
                </div>

                <div className="divide-y divide-zinc-200 dark:divide-zinc-800">
                    {uploadedFiles.map((file, index) => (
                        <div key={index} className="bg-white dark:bg-zinc-900">
                            <button
                                onClick={() => toggleItem(index)}
                                className="w-full flex flex-col p-4 hover:bg-zinc-50 dark:hover:bg-zinc-800/50 transition-colors"
                            >
                                <div className="flex items-center justify-between mb-3">
                                    <div className="flex items-center gap-2">
                                        <h3 className="text-sm font-medium text-zinc-900 dark:text-zinc-100">
                                            {file.filename}
                                        </h3>
                                        <span className="text-sm text-zinc-500 dark:text-zinc-400">
                                            {new Date(
                                                file.timestamp,
                                            ).toLocaleDateString()}
                                        </span>
                                    </div>
                                    <ChevronDown
                                        className={`w-5 h-5 text-zinc-500 transition-transform ${
                                            expandedItems.includes(index)
                                                ? "rotate-180"
                                                : ""
                                        }`}
                                    />
                                </div>

                                <div className="flex flex-wrap items-center gap-2">
                                    <Badge className="bg-zinc-100 dark:bg-zinc-800 text-zinc-700 dark:text-zinc-400">
                                        {file.line_count} linhas
                                    </Badge>
                                    <Badge className="bg-emerald-100 dark:bg-emerald-800 text-emerald-700 dark:text-emerald-400">
                                        {file.identified_cards} identificados
                                    </Badge>
                                    {file.unidentified_cards > 0 && (
                                        <Badge className="bg-red-100 dark:bg-red-800 text-red-700 dark:text-red-400">
                                            {file.unidentified_cards} não
                                            identificados
                                        </Badge>
                                    )}
                                    {file.sold_count > 0 && (
                                        <Badge className="bg-blue-100 dark:bg-blue-800 text-blue-700 dark:text-blue-400">
                                            {file.sold_count} vendidos
                                        </Badge>
                                    )}
                                </div>
                            </button>

                            {expandedItems.includes(index) && (
                                <div className="px-4 pb-4 space-y-3">
                                    <div className="text-sm text-zinc-600 dark:text-zinc-400">
                                        <p>
                                            Data de envio:{" "}
                                            {new Date(
                                                file.timestamp,
                                            ).toLocaleString()}
                                        </p>
                                    </div>
                                    <div className="flex flex-wrap gap-4">
                                        <div className="flex items-center gap-1">
                                            <DollarSign className="w-4 h-4 text-yellow-500" />
                                            <span>
                                                Total a Vender: R${" "}
                                                {file.total_price_active
                                                    ? file.total_price_active.toFixed(
                                                          2,
                                                      )
                                                    : "0.00"}
                                            </span>
                                        </div>
                                        <div className="flex items-center gap-1">
                                            <DollarSign className="w-4 h-4 text-green-500" />
                                            <span>
                                                Total Vendido: R${" "}
                                                {file.total_price_sold
                                                    ? file.total_price_sold.toFixed(
                                                          2,
                                                      )
                                                    : "0.00"}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const UserProgressChart = ({ salesHistory }) => {
    const dailySales = salesHistory?.daily_sales || [];

    const data = {
        labels: dailySales.map((sale) => {
            if (sale.period && sale.period.startsWith("20")) {
                const date = new Date(sale.period + "T00:00:00");
                return date.toLocaleDateString("pt-BR", {
                    day: "2-digit",
                    month: "2-digit",
                });
            }
            return sale.period;
        }),
        datasets: [
            {
                label: "Total de Vendas (R$)",
                data: dailySales.map((sale) => sale.total_sales),
                borderColor: "rgb(59, 130, 246)",
                backgroundColor: "rgba(59, 130, 246, 0.1)",
                borderWidth: 2,
                pointRadius: 3,
                fill: false,
                yAxisID: "sales",
            },
            {
                label: "Quantidade de Vendas",
                data: dailySales.map((sale) => sale.count),
                borderColor: "rgb(16, 185, 129)",
                backgroundColor: "rgba(16, 185, 129, 0.1)",
                borderWidth: 2,
                pointRadius: 3,
                fill: false,
                yAxisID: "quantity",
            },
        ],
    };

    const options = {
        responsive: true,
        interaction: {
            mode: "index",
            intersect: false,
        },
        scales: {
            sales: {
                type: "linear",
                position: "left",
                title: {
                    display: true,
                    text: "Total de Vendas (R$)",
                },
            },
            quantity: {
                type: "linear",
                position: "right",
                title: {
                    display: true,
                    text: "Quantidade",
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                text: "Histórico de Vendas Diárias",
            },
        },
    };

    return (
        <div className="p-4 bg-white dark:bg-zinc-900 rounded-xl">
            <Line data={data} options={options} />
        </div>
    );
};

const UserProfile = ({
    vendorToken,
    stats,
    profileImage,
    onVendorAuthChange,
}) => {
    const [currentStats, setCurrentStats] = useState(stats || {});
    const [selectedTab, setSelectedTab] = useState("statistics");

    const fetchStats = async () => {
        try {
            const response = await fetch(
                "https://api.astrashopbot.cc/api/dev/stats",
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${vendorToken}`,
                        "Content-Type": "application/json",
                    },
                },
            );
            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.error_code === "INVALID_TOKEN") {
                    onVendorAuthChange(false);
                    return;
                }
                throw new Error("Erro na resposta da rede");
            }
            const data = await response.json();
            setCurrentStats(data);
        } catch (error) {
            console.error("Erro ao buscar estatísticas:", error);
        }
    };

    useEffect(() => {
        fetchStats();
        const interval = setInterval(() => {
            fetchStats();
        }, 3000);
        return () => clearInterval(interval);
    }, [vendorToken]);

    const menuItems = [
        {
            icon: <Hash className="w-4 h-4 text-amber-500" />,
            label: "Total Hashes",
            value: currentStats?.total_hashes || 0,
            desc: `${currentStats?.active_hashes || 0} ativos`,
        },
        {
            icon: <DollarSign className="w-4 h-4 text-yellow-500" />,
            label: "Total a Vender",
            value: `R$ ${currentStats?.total_price_all?.toFixed(2) || "0.00"}`,
            desc: "Previsão de valor total das hashes",
        },
        {
            icon: <DollarSign className="w-4 h-4 text-green-500" />,
            label: "Total Vendido",
            value: `R$ ${currentStats?.total_price_sold?.toFixed(2) || "0.00"}`,
            desc: "Valor total das hashes vendidas",
        },
        {
            icon: <DollarSign className="w-4 h-4 text-purple-500" />,
            label: "Comissão",
            value: `${currentStats?.commission_rate || 100}%`,
            desc: "Porcentagem da comissão do vendedor",
        },
    ];

    return (
        <div className="w-full max-w-3xl mx-auto mt-6">
            <div className="relative p-6 rounded-2xl border border-zinc-200 dark:border-zinc-800 bg-gradient-to-b from-white to-zinc-50/50 dark:from-zinc-900 dark:to-zinc-900/50">
                <div className="flex items-start justify-between mb-6">
                    <div className="flex gap-4">
                        <div className="flex flex-col items-center">
                            {profileImage ? (
                                <img
                                    src={profileImage}
                                    alt="User Profile"
                                    className="w-16 h-16 rounded-xl ring-2 ring-zinc-100 dark:ring-zinc-800 object-cover"
                                />
                            ) : (
                                <div className="w-16 h-16 rounded-xl ring-2 ring-zinc-100 dark:ring-zinc-800 bg-gradient-to-br from-purple-500 to-blue-500 flex items-center justify-center">
                                    <Key className="w-8 h-8 text-white" />
                                </div>
                            )}
                            <span className="mt-2 px-2 py-0.5 text-xs font-medium bg-gradient-to-r from-amber-100 to-amber-200 dark:from-amber-900/50 dark:to-amber-800/50 text-amber-700 dark:text-amber-400 border-amber-200/50 dark:border-amber-800/50 rounded-full">
                                Vendor
                            </span>
                        </div>
                        <div className="space-y-1">
                            <h3 className="text-lg font-semibold text-zinc-900 dark:text-zinc-100">
                                HashGuard API
                            </h3>
                            <p className="text-sm text-zinc-500">
                                Conta de Vendedor
                            </p>
                            <p
                                className="text-sm text-zinc-400 truncate max-w-[300px]"
                                title={vendorToken}
                            >
                                {vendorToken.substring(0, 20)}...
                            </p>
                        </div>
                    </div>
                </div>

                <div className="mb-6">
                    <div className="flex border-b border-zinc-200 dark:border-zinc-800">
                        <button
                            onClick={() => setSelectedTab("statistics")}
                            className={`mr-6 pb-2 ${
                                selectedTab === "statistics"
                                    ? "border-b-2 border-blue-500 text-blue-500"
                                    : "text-zinc-500"
                            }`}
                        >
                            Estatísticas
                        </button>
                        <button
                            onClick={() => setSelectedTab("chart")}
                            className={`pb-2 ${
                                selectedTab === "chart"
                                    ? "border-b-2 border-blue-500 text-blue-500"
                                    : "text-zinc-500"
                            }`}
                        >
                            Gráfico
                        </button>
                    </div>
                </div>

                {selectedTab === "statistics" && (
                    <div className="space-y-4">
                        {menuItems.map((item) => (
                            <div
                                key={item.label}
                                className="p-4 rounded-xl bg-zinc-50 dark:bg-zinc-800/50 border border-zinc-200/50 dark:border-zinc-800/50"
                            >
                                <div className="flex items-center justify-between mb-2">
                                    <div className="flex items-center gap-2">
                                        {item.icon}
                                        <span className="text-sm font-medium">
                                            {item.label}
                                        </span>
                                    </div>
                                    <span className="text-lg font-semibold">
                                        {item.value}
                                    </span>
                                </div>
                                <p className="text-xs text-zinc-400">
                                    {item.desc}
                                </p>
                            </div>
                        ))}
                    </div>
                )}

                {selectedTab === "chart" && (
                    <UserProgressChart
                        salesHistory={currentStats?.sales_history}
                    />
                )}
            </div>

            <UploadedFilesList
                uploadedFiles={currentStats?.uploaded_files || []}
                vendorToken={vendorToken}
            />
        </div>
    );
};

export default UserProfile;

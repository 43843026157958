import React from "react";
import {
  CreditCard,
  Package,
  Clock,
  Shield,
  ArrowRight,
  Loader2,
  Check,
  AlertCircle,
} from "lucide-react";

const PurchaseConfirmationModal = ({
  isOpen,
  onClose,
  planData,
  isLoading,
  onConfirmPurchase,
  message,
}) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4"
      onClick={handleOverlayClick}
    >
      <div className="group relative overflow-hidden w-full max-w-md bg-zinc-900 border border-zinc-800 rounded-2xl transition-all duration-300 hover:shadow-[0_8px_30px_rgb(0,0,0,0.2)]">
        {/* Mensagem de feedback */}
        {message.text && (
          <div
            className={`absolute top-0 left-0 right-0 p-3 text-sm text-center font-medium
            ${
              message.type === "success"
                ? "bg-emerald-600 text-white"
                : "bg-red-500/20 text-red-400"
            }`}
          >
            <div className="flex items-center justify-center gap-2">
              {message.type === "success" ? (
                <Check className="h-4 w-4" />
              ) : (
                <AlertCircle className="h-4 w-4" />
              )}
              {message.text}
            </div>
          </div>
        )}

        <div className="p-6 border-b border-zinc-800">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-8 h-8 rounded-full bg-emerald-900/20 flex items-center justify-center">
              <Package className="w-4 h-4 text-emerald-400" />
            </div>
            <div>
              <h3 className="text-sm font-medium text-zinc-100">
                Confirmação de Compra
              </h3>
              <p className="text-xs text-zinc-400">Complete sua compra</p>
            </div>
          </div>
          <div className="text-2xl font-bold text-zinc-100">
            R$ {planData?.price}
          </div>
        </div>

        <div className="px-6 py-4 border-b border-zinc-800">
          <div className="flex gap-4">
            <div className="w-16 h-16 rounded-xl bg-zinc-800/50 flex items-center justify-center flex-shrink-0">
              <Package className="w-8 h-8 text-zinc-400" />
            </div>
            <div className="flex-1">
              <h4 className="font-medium text-zinc-100">
                {planData?.plan_name}
              </h4>
              <p className="text-sm text-zinc-400 mt-1">
                {planData?.description || "Plano de API"}
              </p>
              <div className="flex items-center gap-4 mt-3">
                {planData?.duration_days && (
                  <div className="flex items-center gap-2">
                    <Clock className="w-4 h-4 text-zinc-500" />
                    <span className="text-sm text-zinc-300">
                      {planData.duration_days} dias
                    </span>
                  </div>
                )}
                {planData?.daily_limit && (
                  <div className="text-sm text-zinc-300">
                    Limite: {planData.daily_limit}/dia
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="p-6 space-y-4">
          <div className="flex items-center gap-3 p-3 bg-zinc-800/50 rounded-xl">
            <div className="h-8 w-8 rounded-full bg-zinc-700 flex items-center justify-center">
              <CreditCard className="h-4 w-4 text-zinc-400" />
            </div>
            <div className="flex-1">
              <p className="text-sm font-medium text-zinc-100">
                Pagamento via Saldo
              </p>
              <p className="text-xs text-zinc-400">
                Será debitado do seu saldo
              </p>
            </div>
          </div>

          <div className="space-y-2 py-3">
            <div className="flex justify-between text-sm">
              <span className="text-zinc-400">Preço</span>
              <span className="text-zinc-100">R$ {planData?.price}</span>
            </div>
            <div className="flex justify-between font-medium pt-2 border-t border-zinc-800">
              <span className="text-zinc-100">Total</span>
              <span className="text-zinc-100">R$ {planData?.price}</span>
            </div>
          </div>

          <button
            onClick={onConfirmPurchase}
            disabled={isLoading || message.type === "success"}
            className="w-full group relative flex items-center justify-center bg-gradient-to-r from-emerald-600 to-emerald-500 text-white h-11 rounded-xl text-sm font-medium transition-all duration-300 hover:opacity-90 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <span className="flex items-center gap-2">
              {isLoading ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <Shield className="h-4 w-4" />
              )}
              {isLoading ? "Processando..." : "Confirmar Compra"}
              {!isLoading && (
                <ArrowRight className="h-4 w-4 transition-transform group-hover:translate-x-1" />
              )}
            </span>
          </button>

          <button
            onClick={onClose}
            disabled={isLoading}
            className="w-full text-center text-xs text-zinc-400 hover:text-zinc-300 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Cancelar
          </button>

          <p className="text-center text-xs text-zinc-400">
            Pagamento processado com segurança
          </p>
        </div>
      </div>
    </div>
  );
};

export default PurchaseConfirmationModal;

import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "./axiosConfig";
import clsx from "clsx";
import { FiSearch } from "react-icons/fi";
import { useMediaQuery } from "react-responsive";
import {
    X,
    ChevronDown,
    ChevronUp,
    CreditCard,
    Copy,
    Check,
    Calendar,
    Shield,
    User,
    Fingerprint,
    Landmark,
    Globe,
    Tag,
    Layers,
    Loader2,
    MapPin,
    UserCircle,
    Hash,
    DollarSign,
    Image,
    FileText,
    Eye,
    Download,
    IdCard,
    Gift,
      PartyPopper,
      Sparkles,
} from "lucide-react";
import { useUserStore } from "./store";

/* ------------------------------------------------------------
   Estilo de botão (para a aba de "Cards")
------------------------------------------------------------ */
const Button = React.forwardRef(
    ({ className, variant = "default", size = "default", ...props }, ref) => (
        <button
            type="button"
            ref={ref}
            className={clsx(
                "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
                variant === "default" &&
                    "bg-primary text-primary-foreground hover:bg-primary/90",
                variant === "destructive" &&
                    "bg-destructive text-destructive-foreground hover:bg-destructive/90",
                variant === "outline" &&
                    "border border-input bg-transparent hover:bg-accent hover:text-accent-foreground",
                variant === "secondary" &&
                    "bg-secondary text-secondary-foreground hover:bg-secondary/80",
                variant === "ghost" &&
                    "hover:bg-accent hover:text-accent-foreground bg-transparent",
                variant === "link" &&
                    "text-primary underline-offset-4 hover:underline",
                size === "default" && "h-10 px-4 py-2",
                size === "sm" && "h-9 rounded-md px-3",
                size === "lg" && "h-11 rounded-md px-8",
                size === "icon" && "h-10 w-10",
                className,
            )}
            {...props}
        />
    ),
);
Button.displayName = "Button";

/* ------------------------------------------------------------
   Cards e tabelas (para a aba de "Cards")
------------------------------------------------------------ */
const categoryStyles = {
    mastercard:
        "from-orange-600/10 via-orange-600/5 to-orange-600/0 text-orange-400",
    visa: "from-blue-600/10 via-blue-600/5 to-blue-600/0 text-blue-400",
    amex: "from-emerald-600/10 via-emerald-600/5 to-emerald-600/0 text-emerald-400",
};

const Card = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={clsx(
            "rounded-lg border bg-card text-card-foreground shadow-sm",
            className,
        )}
        {...props}
    />
));
Card.displayName = "Card";

const CardHeader = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={clsx("flex flex-col space-y-1.5 p-6", className)}
        {...props}
    />
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef(({ className, ...props }, ref) => (
    <h3
        ref={ref}
        className={clsx(
            "text-2xl font-semibold leading-none tracking-tight",
            className,
        )}
        {...props}
    />
));
CardTitle.displayName = "CardTitle";

const CardContent = React.forwardRef(({ className, ...props }, ref) => (
    <div ref={ref} className={clsx("p-6 pt-0", className)} {...props} />
));
CardContent.displayName = "CardContent";

const Table = React.forwardRef(({ className, ...props }, ref) => (
    <div className="w-full overflow-auto">
        <table
            ref={ref}
            className={clsx("w-full caption-bottom text-sm", className)}
            {...props}
        />
    </div>
));
Table.displayName = "Table";

const TableHeader = React.forwardRef(({ className, ...props }, ref) => (
    <thead
        ref={ref}
        className={clsx("[&_tr]:border-b", className)}
        {...props}
    />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef(({ className, ...props }, ref) => (
    <tbody
        ref={ref}
        className={clsx("[&_tr:last-child]:border-0", className)}
        {...props}
    />
));
TableBody.displayName = "TableBody";

const TableHead = React.forwardRef(({ className, ...props }, ref) => (
    <th
        ref={ref}
        className={clsx(
            "h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0",
            className,
        )}
        {...props}
    />
));
TableHead.displayName = "TableHead";

const TableRow = React.forwardRef(({ className, ...props }, ref) => (
    <tr
        ref={ref}
        className={clsx(
            "border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted",
            className,
        )}
        {...props}
    />
));
TableRow.displayName = "TableRow";

const TableCell = React.forwardRef(({ className, ...props }, ref) => (
    <td
        ref={ref}
        className={clsx(
            "p-4 align-middle [&:has([role=checkbox])]:pr-0",
            className,
        )}
        {...props}
    />
));
TableCell.displayName = "TableCell";

/* ------------------------------------------------------------
   Modal (para a aba de "Cards")
------------------------------------------------------------ */
const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div
            className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-start pt-24"
            onClick={handleOverlayClick}
            style={{ overscrollBehavior: "contain" }}
        >
            <div className="relative bg-background rounded-lg shadow-lg w-11/12 max-w-lg p-6 max-h-[90vh] overflow-y-auto">
                <button
                    onClick={onClose}
                    className="absolute top-3 right-3 text-muted-foreground hover:text-foreground"
                >
                    <X className="h-6 w-6" />
                </button>
                {children}
            </div>
        </div>
    );
};

/* ===================================================================
   ABA DE DOCUMENTOS (com Filtro de Tempo) - DocsHistory
=================================================================== */

/* ----------------- IndexedDB Helpers ----------------- */
function openDB() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open("myDocsDB", 1);
        request.onupgradeneeded = (e) => {
            const db = e.target.result;
            if (!db.objectStoreNames.contains("docFiles")) {
                db.createObjectStore("docFiles", { keyPath: "cpf" });
            }
        };
        request.onsuccess = (e) => resolve(e.target.result);
        request.onerror = (e) => reject(e.target.error);
    });
}

async function saveFilesToIndexedDB(cpf, files) {
    const db = await openDB();
    return new Promise((resolve, reject) => {
        const tx = db.transaction("docFiles", "readwrite");
        const store = tx.objectStore("docFiles");
        store.put({ cpf, files });
        tx.oncomplete = () => resolve(true);
        tx.onerror = () => reject(tx.error);
    });
}

async function getFilesFromIndexedDB(cpf) {
    const db = await openDB();
    return new Promise((resolve, reject) => {
        const tx = db.transaction("docFiles", "readonly");
        const store = tx.objectStore("docFiles");
        const request = store.get(cpf);
        request.onsuccess = () => {
            if (request.result) {
                resolve(request.result.files);
            } else {
                resolve(null);
            }
        };
        request.onerror = () => reject(request.error);
    });
}

function toDataUrl(base64, ext) {
    const lowerExt = ext.toLowerCase();
    if (["jpg", "jpeg", "png"].includes(lowerExt)) {
        return `data:image/${lowerExt};base64,${base64}`;
    } else if (lowerExt === "pdf") {
        return `data:application/pdf;base64,${base64}`;
    }
    return `data:application/octet-stream;base64,${base64}`;
}

/* ----------------- Componentes de UI da aba "Docs" ----------------- */
const DocsButton = React.forwardRef(
    ({ className, variant = "default", size = "default", ...props }, ref) => {
        const classes = clsx(
            "inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
            {
                "bg-primary text-primary-foreground hover:bg-primary/90":
                    variant === "default",
                "bg-destructive text-destructive-foreground hover:bg-destructive/90":
                    variant === "destructive",
                "border border-input bg-background hover:bg-accent hover:text-accent-foreground":
                    variant === "outline",
                "bg-secondary text-secondary-foreground hover:bg-secondary/80":
                    variant === "secondary",
                "hover:bg-accent hover:text-accent-foreground":
                    variant === "ghost",
                "text-primary underline-offset-4 hover:underline":
                    variant === "link",
            },
            {
                "h-10 px-4 py-2 text-sm": size === "default",
                "h-9 rounded-md px-3 text-sm": size === "sm",
                "h-11 rounded-md px-6 text-base": size === "lg",
                "h-10 w-10": size === "icon",
            },
            className,
        );
        return <button className={classes} ref={ref} {...props} />;
    },
);
DocsButton.displayName = "DocsButton";

const DocsCard = React.forwardRef(({ className, ...props }, ref) => {
    return (
        <div
            ref={ref}
            className={clsx(
                "rounded-xl border bg-card text-card-foreground shadow p-6",
                className,
            )}
            {...props}
        />
    );
});
DocsCard.displayName = "DocsCard";

const DocsModal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
    return (
        <div
            className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4"
            onClick={onClose}
        >
            <div
                className="bg-card text-card-foreground rounded-xl p-6 w-full max-w-lg relative max-h-[90vh] overflow-y-auto"
                onClick={(e) => e.stopPropagation()}
            >
                <button
                    onClick={onClose}
                    className="absolute right-4 top-4 text-muted-foreground hover:text-foreground transition-colors"
                >
                    <X className="h-5 w-5" />
                </button>
                {children}
            </div>
        </div>
    );
};

/* ----------------- Componente principal da aba "Docs" ----------------- */
function DocsHistory() {
    const [docs, setDocs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(50);
    const [totalDocs, setTotalDocs] = useState(0);

    // Filtro de tempo na aba Docs
    const [docTimeFilter, setDocTimeFilter] = useState("all");
    const [isDocTimeFilterMenuOpen, setIsDocTimeFilterMenuOpen] =
        useState(false);
    const docMenuRef = useRef(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [selectedDocFiles, setSelectedDocFiles] = useState([]);
    const [filesLoading, setFilesLoading] = useState(false);
    const [previewStates, setPreviewStates] = useState({});

    // Token (ajuste conforme a sua aplicação)
    const token = localStorage.getItem("token");

    /* ----------------- useEffect e fetch da listagem de docs ----------------- */
    useEffect(() => {
        fetchMyDocs(currentPage);
        // eslint-disable-next-line
    }, []);

    // Fechar menu ao clicar fora
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                docMenuRef.current &&
                !docMenuRef.current.contains(event.target)
            ) {
                setIsDocTimeFilterMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [docMenuRef]);

    /* ----------------- Carregar docs da API ----------------- */
    const fetchMyDocs = async (page = 1) => {
        try {
            setLoading(true);
            const response = await fetch(
                `https://api.astrashopbot.cc/api/my_docs?page=${page}&per_page=${perPage}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            const data = await response.json();
            if (data.error) {
                setError(data.error);
            } else {
                setDocs(data.docs || []);
                setTotalDocs(data.total_docs || 0);
                setCurrentPage(data.page || 1);
            }
        } catch (err) {
            setError("Erro ao carregar documentos");
        } finally {
            setLoading(false);
        }
    };

    /* ----------------- IndexedDB (caching dos arquivos) ----------------- */
    const fetchDocFiles = async (cpf) => {
        setFilesLoading(true);
        // 1. Tenta cache local
        const cached = await getFilesFromIndexedDB(cpf);
        if (cached) {
            setSelectedDocFiles(cached);
            setFilesLoading(false);
            return;
        }
        // 2. Se não tiver no cache, busca na API
        try {
            const response = await fetch(
                `https://api.astrashopbot.cc/api/doc_files?cpf=${cpf}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            const data = await response.json();
            if (data.error) {
                console.error(data.error);
                setSelectedDocFiles([]);
            } else {
                await saveFilesToIndexedDB(cpf, data.files || []);
                setSelectedDocFiles(data.files || []);
            }
        } catch (err) {
            console.error("Erro ao carregar arquivos do documento", err);
            setSelectedDocFiles([]);
        } finally {
            setFilesLoading(false);
        }
    };

    /* ----------------- Função para abrir modal de arquivos ----------------- */
    const handleViewFiles = (doc) => {
        setSelectedDoc(doc);
        setSelectedDocFiles([]);
        setIsModalOpen(true);
        fetchDocFiles(doc.cpf);
    };

    /* ----------------- Filtro de datas (purchase_date) ----------------- */
    const filterDocsByTime = (docsList) => {
        const now = new Date();
        return docsList
            .filter((doc) => {
                const docDate = new Date(doc.purchase_date);
                if (docTimeFilter === "all") return true;
                else if (docTimeFilter === "month") {
                    return (
                        docDate.getMonth() === now.getMonth() &&
                        docDate.getFullYear() === now.getFullYear()
                    );
                } else if (docTimeFilter === "week") {
                    const currentDay = now.getDay();
                    const diff = currentDay === 0 ? 6 : currentDay - 1;
                    const startOfWeek = new Date(
                        now.getFullYear(),
                        now.getMonth(),
                        now.getDate() - diff,
                        0,
                        0,
                        0,
                    );
                    const endOfWeek = new Date(
                        startOfWeek.getFullYear(),
                        startOfWeek.getMonth(),
                        startOfWeek.getDate() + 6,
                        23,
                        59,
                        59,
                    );
                    return docDate >= startOfWeek && docDate <= endOfWeek;
                } else if (docTimeFilter === "day") {
                    const startOfDay = new Date(
                        now.getFullYear(),
                        now.getMonth(),
                        now.getDate(),
                        0,
                        0,
                        0,
                    );
                    const endOfDay = new Date(
                        now.getFullYear(),
                        now.getMonth(),
                        now.getDate(),
                        23,
                        59,
                        59,
                    );
                    return docDate >= startOfDay && docDate <= endOfDay;
                }
                return true;
            })
            .sort(
                (a, b) => new Date(b.purchase_date) - new Date(a.purchase_date),
            );
    };

    const filteredDocs = filterDocsByTime(docs);
    const totalPages = Math.ceil(totalDocs / perPage);

    /* ----------------- Ícone baseado na extensão ----------------- */
    const FileIcon = ({ ext }) => {
        const lowerExt = ext.toLowerCase();
        if (lowerExt === "pdf")
            return <FileText className="h-4 w-4 shrink-0" />;
        if (["jpg", "jpeg", "png"].includes(lowerExt))
            return <Image className="h-4 w-4 shrink-0" />;
        return <FileText className="h-4 w-4 shrink-0" />;
    };

    return (
        <div className="min-h-screen p-4 sm:p-6 md:p-8 bg-background">
            <div className="max-w-7xl mx-auto space-y-8">
                {/* Cabeçalho (filtro + título) */}
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 pb-2 mb-4 border-b border-input">
                    <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold tracking-tight">
                        Documentos
                    </h1>

                    {/* Filtro de Tempo (Docs) */}
                    <div className="relative" ref={docMenuRef}>
                        <DocsButton
                            variant="outline"
                            onClick={() =>
                                setIsDocTimeFilterMenuOpen(
                                    !isDocTimeFilterMenuOpen,
                                )
                            }
                            className="gap-1.5"
                        >
                            <span>
                                {docTimeFilter === "all"
                                    ? "Todos"
                                    : docTimeFilter === "month"
                                      ? "Este Mês"
                                      : docTimeFilter === "week"
                                        ? "Esta Semana"
                                        : "Hoje"}
                            </span>
                            <ChevronDown className="w-3 h-3" />
                        </DocsButton>

                        {isDocTimeFilterMenuOpen && (
                            <div className="absolute top-full left-0 mt-1 bg-white dark:bg-zinc-800 rounded-md shadow-lg py-1 z-50 border border-black/10 dark:border-white/10">
                                {[
                                    { value: "all", label: "Todos" },
                                    { value: "month", label: "Este Mês" },
                                    { value: "week", label: "Esta Semana" },
                                    { value: "day", label: "Hoje" },
                                ].map((option) => (
                                    <button
                                        key={option.value}
                                        type="button"
                                        onClick={() => {
                                            setDocTimeFilter(option.value);
                                            setIsDocTimeFilterMenuOpen(false);
                                        }}
                                        className="w-full px-3 py-1.5 text-left hover:bg-black/5 dark:hover:bg-white/5 flex items-center gap-2 text-sm transition-colors"
                                    >
                                        <span>{option.label}</span>
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                {/* Loading/Erro */}
                {loading && (
                    <div className="flex justify-center items-center py-12">
                        <Loader2 className="h-8 w-8 animate-spin" />
                    </div>
                )}
                {error && (
                    <div className="text-center py-12 text-destructive">
                        {error}
                    </div>
                )}

                {/* Lista de Docs Filtrados */}
                {!loading && !error && filteredDocs.length > 0 && (
                    <>
                        <div className="grid gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                            {filteredDocs.map((doc) => {
                                let tipoFormatado = doc.tipo;
                                if (doc.tipo === "mix")
                                    tipoFormatado = "RG ou CNH";
                                else if (doc.tipo === "rg")
                                    tipoFormatado = "RG";
                                else if (doc.tipo === "cnh")
                                    tipoFormatado = "CNH";

                                return (
                                    <DocsCard key={doc._id}>
                                        <div className="flex flex-col h-full">
                                            {/* Nome + Preço */}
                                            <div className="flex items-center justify-between mb-5">
                                                <div className="flex items-center gap-2">
                                                    <User className="h-4 w-4 shrink-0 text-muted-foreground" />
                                                    <div className="max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap">
                                                        <span className="text-sm font-semibold truncate">
                                                            {doc.nome}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex items-center text-sm font-medium">
                                                    <DollarSign className="h-4 w-4 mr-1 shrink-0" />
                                                    <span>R$ 10,00</span>
                                                </div>
                                            </div>

                                            {/* Informações do Documento */}
                                            <div className="flex flex-col space-y-3 flex-1">
                                                {/* CPF */}
                                                <div className="flex items-center gap-2">
                                                    <Hash className="h-4 w-4 shrink-0 text-muted-foreground" />
                                                    <code className="font-mono text-xs bg-secondary px-1.5 py-0.5 rounded">
                                                        {doc.cpf}
                                                    </code>
                                                </div>

                                                {/* Idade + Sexo */}
                                                <div className="flex items-center gap-2">
                                                    <UserCircle className="h-4 w-4 shrink-0 text-muted-foreground" />
                                                    <span>
                                                        {doc.idade} anos |{" "}
                                                        {doc.sexo}
                                                    </span>
                                                </div>

                                                {/* Tipo (RG, CNH, Mix) */}
                                                <div className="flex items-center gap-2">
                                                    <IdCard className="h-4 w-4 shrink-0 text-muted-foreground" />
                                                    <span>{tipoFormatado}</span>
                                                </div>

                                                {/* Localização */}
                                                <div className="flex items-start gap-2">
                                                    <MapPin className="h-4 w-4 shrink-0 text-muted-foreground mt-0.5" />
                                                    <span className="break-words">
                                                        {doc.localizacao}
                                                    </span>
                                                </div>

                                                {/* Data da compra (purchase_date) */}
                                                <div className="flex items-center gap-2">
                                                    <Calendar className="h-4 w-4 shrink-0 text-muted-foreground" />
                                                    <span className="text-xs text-muted-foreground">
                                                        {new Date(
                                                            doc.purchase_date,
                                                        ).toLocaleString()}
                                                    </span>
                                                </div>
                                            </div>

                                            {/* Botão "Ver Arquivos" */}
                                            <div className="mt-5">
                                                <DocsButton
                                                    variant="secondary"
                                                    className="w-full"
                                                    onClick={() =>
                                                        handleViewFiles(doc)
                                                    }
                                                >
                                                    Ver Arquivos
                                                </DocsButton>
                                            </div>
                                        </div>
                                    </DocsCard>
                                );
                            })}
                        </div>

                        {/* Paginação */}
                        <div className="flex justify-center items-center gap-4 mt-6">
                            <DocsButton
                                variant="outline"
                                onClick={() => fetchMyDocs(currentPage - 1)}
                                disabled={currentPage <= 1}
                            >
                                Anterior
                            </DocsButton>
                            <span className="text-sm">
                                Página {currentPage} de{" "}
                                {Math.max(totalPages, 1)}
                            </span>
                            <DocsButton
                                variant="outline"
                                onClick={() => fetchMyDocs(currentPage + 1)}
                                disabled={currentPage >= totalPages}
                            >
                                Próxima
                            </DocsButton>
                        </div>
                    </>
                )}

                {/* Nenhum doc */}
                {!loading && !error && filteredDocs.length === 0 && (
                    <div className="text-center py-12 text-muted-foreground">
                        Nenhum documento encontrado.
                    </div>
                )}
            </div>

            {/* Modal para Exibir Arquivos */}
            <DocsModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                <div className="space-y-6">
                    <h2 className="text-xl font-semibold">
                        Arquivos do CPF {selectedDoc?.cpf}
                    </h2>

                    {filesLoading && (
                        <div className="text-center py-4 text-muted-foreground flex flex-col items-center gap-2">
                            <Loader2 className="h-5 w-5 animate-spin" />
                            <span>Descriptografando arquivos...</span>
                        </div>
                    )}

                    {!filesLoading && selectedDocFiles.length > 0 && (
                        <ul className="space-y-4">
                            {selectedDocFiles.map((fileObj) => {
                                const filename = fileObj.filename;
                                const ext = filename.split(".").pop() || "";
                                const showPreview = previewStates[filename];

                                let dataUrl = fileObj.content_base64;
                                if (!dataUrl.startsWith("data:")) {
                                    dataUrl = toDataUrl(dataUrl, ext);
                                }

                                const isImage = ["jpg", "jpeg", "png"].includes(
                                    ext.toLowerCase(),
                                );
                                const isPDF = ext.toLowerCase() === "pdf";

                                return (
                                    <li
                                        key={filename}
                                        className="border-b pb-4 last:border-b-0"
                                    >
                                        <div className="flex items-center gap-2 mb-3">
                                            <FileIcon ext={ext} />
                                            <span className="break-words font-medium">
                                                {filename}
                                            </span>
                                        </div>

                                        <div className="flex flex-wrap gap-2">
                                            <a
                                                href={dataUrl}
                                                download={filename}
                                                className="shrink-0"
                                            >
                                                <DocsButton
                                                    variant="secondary"
                                                    size="sm"
                                                    className="gap-2"
                                                >
                                                    <Download className="h-4 w-4" />
                                                    Baixar
                                                </DocsButton>
                                            </a>

                                            {(isImage || isPDF) && (
                                                <DocsButton
                                                    variant="outline"
                                                    size="sm"
                                                    onClick={() =>
                                                        setPreviewStates(
                                                            (prev) => ({
                                                                ...prev,
                                                                [filename]:
                                                                    !prev[
                                                                        filename
                                                                    ],
                                                            }),
                                                        )
                                                    }
                                                    className="gap-2"
                                                >
                                                    <Eye className="h-4 w-4" />
                                                    {showPreview
                                                        ? "Ocultar Prévia"
                                                        : "Mostrar Prévia"}
                                                </DocsButton>
                                            )}
                                        </div>

                                        {showPreview && (
                                            <div className="mt-4">
                                                {isImage && (
                                                    <img
                                                        src={dataUrl}
                                                        alt={filename}
                                                        className="rounded-lg border max-w-full h-auto"
                                                    />
                                                )}
                                                {isPDF && (
                                                    <embed
                                                        src={`${dataUrl}#toolbar=0&navpanes=0&scrollbar=0`}
                                                        type="application/pdf"
                                                        className="w-full h-96 rounded-lg border"
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    )}

                    {!filesLoading && selectedDocFiles.length === 0 && (
                        <div className="text-center py-4 text-muted-foreground">
                            Nenhum arquivo encontrado para este CPF.
                        </div>
                    )}
                </div>
            </DocsModal>
        </div>
    );
}


const Confetti = () => {
  return (
    <div className="absolute inset-0 pointer-events-none">
      {[...Array(50)].map((_, i) => (
        <div
          key={i}
          className="absolute animate-confetti"
          style={{
            left: `${Math.random() * 100}%`,
            top: `-10%`,
            animationDelay: `${Math.random() * 3}s`,
            backgroundColor: ['#FFD700', '#FF69B4', '#00CED1', '#FF4500', '#9370DB'][Math.floor(Math.random() * 5)],
            width: '8px',
            height: '8px',
            transform: `rotate(${Math.random() * 360}deg)`,
          }}
        />
      ))}
    </div>
  );
};



/* ===================================================================
   COMPONENTE PRINCIPAL: MinhasCompras (duas abas)
   Agora sincronizado com o store (purchasesTab).
=================================================================== */
const MinhasCompras = () => {
    const {
        purchasesTab,
        setPurchasesTab, // <-- para trocar a aba global
    } = useUserStore();

    // Em vez de usar "cards" como default fixo, usamos o que estiver no store:
    const [activeTab, setActiveTab] = useState(purchasesTab);

    useEffect(() => {
        // Sempre que o purchasesTab no store mudar, atualizamos local
        setActiveTab(purchasesTab);
    }, [purchasesTab]);

    // Quando o usuário clicar manualmente numa aba, alteramos em ambos
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setPurchasesTab(tab);
    };

    /* ------------------------------------------------------------
     Estados e lógica para compras de Cards
  ------------------------------------------------------------ */
    const [purchaseHistoryCards, setPurchaseHistoryCards] = useState([]);
    const [loadingCards, setLoadingCards] = useState(true);
    const [errorCards, setErrorCards] = useState(null);

    const [selectedPurchase, setSelectedPurchase] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [expandedId, setExpandedId] = useState(null);
    const [timeFilter, setTimeFilter] = useState("all");
    const [isTimeFilterMenuOpen, setIsTimeFilterMenuOpen] = useState(false);
    const [copiedFields, setCopiedFields] = useState({});
    const isSmallScreen = useMediaQuery({ maxWidth: 768 });
    const menuRef = useRef(null);

    const [isGiftModalOpen, setIsGiftModalOpen] = useState(false);
    const [redeemedGifts, setRedeemedGifts] = useState({});
    const [showSparkles, setShowSparkles] = useState(false);
    const [claimMessage, setClaimMessage] = useState(null);
    const [claimError, setClaimError] = useState(null);



    // Gifts disponíveis no servidor
    const [availableGifts, setAvailableGifts] = useState([]);

    // Dados do gift atual (código e valor)
    const [giftCode, setGiftCode] = useState(null);
    const [giftAmount, setGiftAmount] = useState(0);

    useEffect(() => {
      const fetchAvailableGifts = async () => {
        try {
          const response = await axiosInstance.get('/available-gifts');
          // Ajuste se a resposta vier diferente
          setAvailableGifts(response.data.available_gifts || []);
        } catch (error) {
          console.error('Erro ao buscar gifts ativos:', error);
        }
      };

      fetchAvailableGifts();
    }, []);



    const handleGiftClick = async (purchase, e) => {
      e.stopPropagation();
      if (redeemedGifts[purchase.id]) return;

      try {
        setGiftAmount(purchase.giftAmount || 0);
        setRedeemedGifts((prev) => ({ ...prev, [purchase.id]: true }));
        setIsGiftModalOpen(true);
        setShowSparkles(true);
        setTimeout(() => setShowSparkles(false), 3000);
      } catch (error) {
        console.error('Error redeeming gift:', error);
      }
    };



    /* ------------------------------------------------------------
     useEffect para buscar compras de Cards
  ------------------------------------------------------------ */
    useEffect(() => {
        const fetchPurchaseHistoryCards = async () => {
            try {
                const response = await axiosInstance.get("/purchase-history");
                setPurchaseHistoryCards(response.data.purchase_history);
                setLoadingCards(false);
            } catch (err) {
                console.error(
                    "Erro ao buscar histórico de compras (Cards):",
                    err,
                );
                setErrorCards(
                    "Falha ao carregar o histórico de compras de Cards.",
                );
                setLoadingCards(false);
            }
        };
        fetchPurchaseHistoryCards();
    }, []);

    /* ------------------------------------------------------------
     Copiar para a área de transferência (Cards)
  ------------------------------------------------------------ */
    const copyToClipboard = (purchaseId, fieldName, text) => {
        navigator.clipboard.writeText(text);
        const key = `${purchaseId}-${fieldName}`;
        setCopiedFields((prev) => ({ ...prev, [key]: true }));

        setTimeout(() => {
            setCopiedFields((prev) => ({ ...prev, [key]: false }));
        }, 2000);
    };

    /* ------------------------------------------------------------
     Abrir/Fechar modal (para Cards)
  ------------------------------------------------------------ */
    const openModal = (purchase) => {
        setSelectedPurchase(purchase);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedPurchase(null);
        setIsModalOpen(false);
    };

    /* ------------------------------------------------------------
     Expandir/colapsar linha
  ------------------------------------------------------------ */
    const toggleExpand = (id) => {
        setExpandedId(expandedId === id ? null : id);
    };

    /* ------------------------------------------------------------
     Clique fora para fechar menu de filtros (Cards)
  ------------------------------------------------------------ */
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsTimeFilterMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    /* ------------------------------------------------------------
     Filtrar e ordenar purchases (Cards)
  ------------------------------------------------------------ */
    const filterAndSortPurchases = (purchases) => {
        const now = new Date();
        return purchases
            .filter((p) => {
                const purchaseDate = new Date(p.purchase_date);
                if (timeFilter === "all") {
                    return true;
                } else if (timeFilter === "month") {
                    return (
                        purchaseDate.getMonth() === now.getMonth() &&
                        purchaseDate.getFullYear() === now.getFullYear()
                    );
                } else if (timeFilter === "week") {
                    const currentDay = now.getDay();
                    const diff = currentDay === 0 ? 6 : currentDay - 1;
                    const startOfWeek = new Date(
                        now.getFullYear(),
                        now.getMonth(),
                        now.getDate() - diff,
                        0,
                        0,
                        0,
                    );
                    const endOfWeek = new Date(
                        startOfWeek.getFullYear(),
                        startOfWeek.getMonth(),
                        startOfWeek.getDate() + 6,
                        23,
                        59,
                        59,
                    );
                    return (
                        purchaseDate >= startOfWeek && purchaseDate <= endOfWeek
                    );
                } else if (timeFilter === "day") {
                    const startOfDay = new Date(
                        now.getFullYear(),
                        now.getMonth(),
                        now.getDate(),
                        0,
                        0,
                        0,
                    );
                    const endOfDay = new Date(
                        now.getFullYear(),
                        now.getMonth(),
                        now.getDate(),
                        23,
                        59,
                        59,
                    );
                    return (
                        purchaseDate >= startOfDay && purchaseDate <= endOfDay
                    );
                }
                return true;
            })
            .sort(
                (a, b) => new Date(b.purchase_date) - new Date(a.purchase_date),
            );
    };

    const renderCardPurchases = () => {
        const filteredAndSortedPurchases =
            filterAndSortPurchases(purchaseHistoryCards);

        if (loadingCards) {
            return (
                <div className="flex items-center justify-center h-64">
                    <Loader2 className="h-8 w-8 animate-spin" />
                </div>
            );
        }
        if (errorCards) return <div>{errorCards}</div>;

        return (
            <div className="w-full max-w-4xl mx-auto bg-neutral-900 border border-zinc-800 rounded-3xl shadow-xl backdrop-blur-xl">
              {
                              availableGifts.length > 0 && purchaseHistoryCards.length > 0 && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      const gift = availableGifts[0];
                      setGiftCode(gift.code);
                      setGiftAmount(gift.amount);
                      setIsGiftModalOpen(true);
                      setClaimMessage(null);
                      setClaimError(null);
                    }}
                    className="
                      absolute
                      -right-10
                      -top-10
                      w-24
                      h-24
                      bg-gradient-to-br
                      from-yellow-400
                      via-yellow-500
                      to-yellow-600
                      rounded-2xl
                      shadow-lg
                      flex
                      items-center
                      justify-center
                      transition-all
                      duration-300
                      z-10
                      group
                      animate-gift-entrance
                    "
                  >
                    <div
                      className="
                        absolute
                        inset-0
                        rounded-2xl
                        bg-gradient-to-br
                        from-yellow-200
                        to-transparent
                        opacity-50
                        group-hover:opacity-0
                        transition-opacity
                        duration-300
                      "
                    />
                    <Gift
                      className="
                        w-12
                        h-12
                        text-white
                        transform
                        -rotate-12
                        group-hover:rotate-0
                        transition-transform
                        duration-300
                      "
                    />
                    <div
                      className="
                        absolute
                        -inset-1
                        bg-gradient-to-br
                        from-yellow-400
                        to-yellow-600
                        rounded-2xl
                        opacity-30
                        blur
                        group-hover:opacity-60
                        transition-opacity
                        duration-300
                        -z-10
                      "
                    />
                    <div
                      className="
                        absolute
                        inset-0
                        rounded-2xl
                        bg-gradient-to-br
                        from-yellow-400/20
                        to-yellow-600/20
                        opacity-0
                        group-hover:opacity-100
                        transition-opacity
                        duration-300
                        animate-pulse
                      "
                    />
                  </button>

                )
              }


                <div className="p-6">
                    {/* Cabeçalho: filtros e contagem */}
                    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-3 mb-6">
                        {/* Título + contagem */}
                        <div className="flex flex-col gap-1">
                            <h2 className="text-xl font-semibold text-zinc-100">
                                Histórico de Compras
                            </h2>
                            <p className="text-sm text-zinc-500">
                                {filteredAndSortedPurchases.length} Compras
                            </p>
                        </div>

                        {/* Botão/menu lateral de filtro de tempo */}
                        <div className="relative" ref={menuRef}>
                            <button
                                type="button"
                                onClick={() =>
                                    setIsTimeFilterMenuOpen(
                                        !isTimeFilterMenuOpen,
                                    )
                                }
                                className="flex items-center gap-1.5 hover:bg-black/5 dark:hover:bg-white/5
                                       rounded-lg px-3 py-1.5 bg-zinc-800 border border-zinc-700
                                       text-sm text-zinc-400 focus:outline-none focus:border-zinc-600"
                            >
                                <span>
                                    {timeFilter === "all"
                                        ? "Todos"
                                        : timeFilter === "month"
                                          ? "Este Mês"
                                          : timeFilter === "week"
                                            ? "Esta Semana"
                                            : "Hoje"}
                                </span>
                                <ChevronDown className="w-3 h-3 ml-0.5" />
                            </button>

                            {isTimeFilterMenuOpen && (
                                <div
                                    className="absolute top-full left-0 mt-1 bg-white dark:bg-zinc-800
                                         rounded-md shadow-lg py-1 z-50 border border-black/10 dark:border-white/10"
                                >
                                    {[
                                        { value: "all", label: "Todos" },
                                        { value: "month", label: "Este Mês" },
                                        { value: "week", label: "Esta Semana" },
                                        { value: "day", label: "Hoje" },
                                    ].map((option) => (
                                        <button
                                            key={option.value}
                                            type="button"
                                            onClick={() => {
                                                setTimeFilter(option.value);
                                                setIsTimeFilterMenuOpen(false);
                                            }}
                                            className="w-full px-3 py-1.5 text-left hover:bg-black/5
                                             dark:hover:bg-white/5 flex items-center gap-2 text-sm
                                             transition-colors"
                                        >
                                            <span>{option.label}</span>
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Visualização para telas pequenas */}
                    {isSmallScreen ? (
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Data</TableHead>
                                    <TableHead>BIN</TableHead>
                                    <TableHead>Ações</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {filteredAndSortedPurchases.map(
                                    (purchase, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {new Date(
                                                    purchase.purchase_date,
                                                ).toLocaleString()}
                                            </TableCell>
                                            <TableCell>
                                                {purchase.bin}
                                            </TableCell>
                                            <TableCell>
                                                <button
                                                    onClick={() =>
                                                        openModal(purchase)
                                                    }
                                                >
                                                    <FiSearch />
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    ),
                                )}
                            </TableBody>
                        </Table>
                    ) : (
                        /* Visualização para telas maiores */
                        <div className="space-y-4">
                            {filteredAndSortedPurchases.map((purchase) => (
                                <div
                                    key={purchase.id}
                                    onClick={() => toggleExpand(purchase.id)}
                                    className="group relative flex flex-col gap-2 p-3 -mx-3 rounded-2xl transition-all duration-300 ease-out hover:bg-zinc-800/50 hover:shadow-sm border border-transparent hover:border-zinc-700/50 cursor-pointer"
                                >
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-4">
                                            <div
                                                className={`relative w-12 h-12 flex items-center justify-center rounded-2xl bg-gradient-to-br ${
                                                    categoryStyles[
                                                        purchase.vendor?.toLowerCase()
                                                    ] || categoryStyles.visa
                                                } dark:from-blue-500/20 dark:via-blue-500/10 dark:to-transparent dark:text-blue-400 transition-all duration-300 group-hover:scale-105 group-hover:shadow-md border border-zinc-200/50 dark:border-zinc-700/50 shadow-sm`}
                                            >
                                                <CreditCard className="w-5 h-5" />
                                            </div>

                                            <div className="space-y-1 min-w-0">
                                                <h3 className="text-base font-medium text-zinc-100 flex items-center gap-2">
                                                    <span className="font-mono">
                                                        {purchase.bin}
                                                    </span>
                                                    <span className="text-zinc-500">
                                                        |
                                                    </span>
                                                    <span className="truncate">
                                                        {purchase.nome}
                                                    </span>
                                                </h3>
                                                <p className="text-sm text-zinc-400">
                                                    {new Date(
                                                        purchase.purchase_date,
                                                    ).toLocaleString()}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex items-center gap-3">
                                            <div className="flex items-center gap-1 pr-2">
                                                <span className="text-base font-semibold text-zinc-100">
                                                    R${" "}
                                                    {purchase.price.toFixed(2)}
                                                </span>
                                            </div>
                                            <div className="p-2 hover:bg-zinc-700/50 rounded-full transition-colors duration-200">
                                                {expandedId === purchase.id ? (
                                                    <ChevronUp className="w-5 h-5 text-zinc-400" />
                                                ) : (
                                                    <ChevronDown className="w-5 h-5 text-zinc-400" />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {expandedId === purchase.id && (
                                        <div className="mt-4 pl-16 pr-4 space-y-3 text-sm border-l border-zinc-800">
                                            <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                                                {/* Número */}
                                                <div className="space-y-1">
                                                    <p className="text-zinc-500">
                                                        Número
                                                    </p>
                                                    <div className="flex items-center">
                                                        <p className="text-zinc-100 font-mono">
                                                            {purchase.number}
                                                        </p>
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                copyToClipboard(
                                                                    purchase.id,
                                                                    "number",
                                                                    purchase.number,
                                                                );
                                                            }}
                                                            className="ml-2"
                                                        >
                                                            {copiedFields[
                                                                `${purchase.id}-number`
                                                            ] ? (
                                                                <Check className="w-4 h-4 text-green-500" />
                                                            ) : (
                                                                <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* Validade */}
                                                <div className="space-y-1">
                                                    <p className="text-zinc-500">
                                                        Validade
                                                    </p>
                                                    <div className="flex items-center">
                                                        <p className="text-zinc-100 font-mono">
                                                            {purchase.month}/
                                                            {purchase.year}
                                                        </p>
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                copyToClipboard(
                                                                    purchase.id,
                                                                    "validade",
                                                                    `${purchase.month}/${purchase.year}`,
                                                                );
                                                            }}
                                                            className="ml-2"
                                                        >
                                                            {copiedFields[
                                                                `${purchase.id}-validade`
                                                            ] ? (
                                                                <Check className="w-4 h-4 text-green-500" />
                                                            ) : (
                                                                <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* CVV */}
                                                <div className="space-y-1">
                                                    <p className="text-zinc-500">
                                                        CVV
                                                    </p>
                                                    <div className="flex items-center">
                                                        <p className="text-zinc-100 font-mono">
                                                            {purchase.cvv}
                                                        </p>
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                copyToClipboard(
                                                                    purchase.id,
                                                                    "cvv",
                                                                    purchase.cvv,
                                                                );
                                                            }}
                                                            className="ml-2"
                                                        >
                                                            {copiedFields[
                                                                `${purchase.id}-cvv`
                                                            ] ? (
                                                                <Check className="w-4 h-4 text-green-500" />
                                                            ) : (
                                                                <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* Nome */}
                                                <div className="space-y-1">
                                                    <p className="text-zinc-500">
                                                        Nome
                                                    </p>
                                                    <div className="flex items-center">
                                                        <p className="text-zinc-100">
                                                            {purchase.nome}
                                                        </p>
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                copyToClipboard(
                                                                    purchase.id,
                                                                    "nome",
                                                                    purchase.nome,
                                                                );
                                                            }}
                                                            className="ml-2"
                                                        >
                                                            {copiedFields[
                                                                `${purchase.id}-nome`
                                                            ] ? (
                                                                <Check className="w-4 h-4 text-green-500" />
                                                            ) : (
                                                                <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* CPF */}
                                                <div className="space-y-1">
                                                    <p className="text-zinc-500">
                                                        CPF
                                                    </p>
                                                    <div className="flex items-center">
                                                        <p className="text-zinc-100 font-mono">
                                                            {purchase.cpf}
                                                        </p>
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                copyToClipboard(
                                                                    purchase.id,
                                                                    "cpf",
                                                                    purchase.cpf,
                                                                );
                                                            }}
                                                            className="ml-2"
                                                        >
                                                            {copiedFields[
                                                                `${purchase.id}-cpf`
                                                            ] ? (
                                                                <Check className="w-4 h-4 text-green-500" />
                                                            ) : (
                                                                <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* Banco */}
                                                <div className="space-y-1">
                                                    <p className="text-zinc-500">
                                                        Banco
                                                    </p>
                                                    <div className="flex items-center">
                                                        <p className="text-zinc-100">
                                                            {purchase.bank}
                                                        </p>
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                copyToClipboard(
                                                                    purchase.id,
                                                                    "banco",
                                                                    purchase.bank,
                                                                );
                                                            }}
                                                            className="ml-2"
                                                        >
                                                            {copiedFields[
                                                                `${purchase.id}-banco`
                                                            ] ? (
                                                                <Check className="w-4 h-4 text-green-500" />
                                                            ) : (
                                                                <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* País */}
                                                <div className="space-y-1">
                                                    <p className="text-zinc-500">
                                                        País
                                                    </p>
                                                    <div className="flex items-center">
                                                        <p className="text-zinc-100">
                                                            {purchase.country}
                                                        </p>
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                copyToClipboard(
                                                                    purchase.id,
                                                                    "pais",
                                                                    purchase.country,
                                                                );
                                                            }}
                                                            className="ml-2"
                                                        >
                                                            {copiedFields[
                                                                `${purchase.id}-pais`
                                                            ] ? (
                                                                <Check className="w-4 h-4 text-green-500" />
                                                            ) : (
                                                                <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* Tipo */}
                                                <div className="space-y-1">
                                                    <p className="text-zinc-500">
                                                        Tipo
                                                    </p>
                                                    <div className="flex items-center">
                                                        <p className="text-zinc-100">
                                                            {purchase.type}
                                                        </p>
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                copyToClipboard(
                                                                    purchase.id,
                                                                    "tipo",
                                                                    purchase.type,
                                                                );
                                                            }}
                                                            className="ml-2"
                                                        >
                                                            {copiedFields[
                                                                `${purchase.id}-tipo`
                                                            ] ? (
                                                                <Check className="w-4 h-4 text-green-500" />
                                                            ) : (
                                                                <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* Nível */}
                                                <div className="space-y-1">
                                                    <p className="text-zinc-500">
                                                        Nível
                                                    </p>
                                                    <div className="flex items-center">
                                                        <p className="text-zinc-100">
                                                            {purchase.level}
                                                        </p>
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                copyToClipboard(
                                                                    purchase.id,
                                                                    "nivel",
                                                                    purchase.level,
                                                                );
                                                            }}
                                                            className="ml-2"
                                                        >
                                                            {copiedFields[
                                                                `${purchase.id}-nivel`
                                                            ] ? (
                                                                <Check className="w-4 h-4 text-green-500" />
                                                            ) : (
                                                                <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Modal de detalhes (para telas pequenas, via ícone de busca) */}
                    {selectedPurchase && (
                        <Modal isOpen={isModalOpen} onClose={closeModal}>
                            <h2 className="text-xl font-bold mb-6">
                                Detalhes da Compra
                            </h2>
                            <div className="space-y-4 text-base">
                                {/* Número */}
                                <div className="flex items-center gap-2 flex-wrap">
                                    <CreditCard className="h-5 w-5 text-zinc-400" />
                                    <span className="text-sm text-zinc-500">
                                        Número:
                                    </span>
                                    <span className="font-mono text-zinc-100">
                                        {selectedPurchase.number}
                                    </span>
                                    <button
                                        onClick={() =>
                                            copyToClipboard(
                                                selectedPurchase.id,
                                                "number",
                                                selectedPurchase.number,
                                            )
                                        }
                                    >
                                        {copiedFields[
                                            `${selectedPurchase.id}-number`
                                        ] ? (
                                            <Check className="w-4 h-4 text-green-500" />
                                        ) : (
                                            <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                        )}
                                    </button>
                                </div>

                                {/* Validade */}
                                <div className="flex items-center gap-2 flex-wrap">
                                    <Calendar className="h-5 w-5 text-zinc-400" />
                                    <span className="text-sm text-zinc-500">
                                        Validade:
                                    </span>
                                    <span className="font-mono text-zinc-100">
                                        {selectedPurchase.month}/
                                        {selectedPurchase.year}
                                    </span>
                                    <button
                                        onClick={() =>
                                            copyToClipboard(
                                                selectedPurchase.id,
                                                "validade",
                                                `${selectedPurchase.month}/${selectedPurchase.year}`,
                                            )
                                        }
                                    >
                                        {copiedFields[
                                            `${selectedPurchase.id}-validade`
                                        ] ? (
                                            <Check className="w-4 h-4 text-green-500" />
                                        ) : (
                                            <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                        )}
                                    </button>
                                </div>

                                {/* CVV */}
                                <div className="flex items-center gap-2 flex-wrap">
                                    <Shield className="h-5 w-5 text-zinc-400" />
                                    <span className="text-sm text-zinc-500">
                                        CVV:
                                    </span>
                                    <span className="font-mono text-zinc-100">
                                        {selectedPurchase.cvv}
                                    </span>
                                    <button
                                        onClick={() =>
                                            copyToClipboard(
                                                selectedPurchase.id,
                                                "cvv",
                                                selectedPurchase.cvv,
                                            )
                                        }
                                    >
                                        {copiedFields[
                                            `${selectedPurchase.id}-cvv`
                                        ] ? (
                                            <Check className="w-4 h-4 text-green-500" />
                                        ) : (
                                            <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                        )}
                                    </button>
                                </div>

                                {/* Nome */}
                                <div className="flex items-center gap-2 flex-wrap">
                                    <User className="h-5 w-5 text-zinc-400" />
                                    <span className="text-sm text-zinc-500">
                                        Nome:
                                    </span>
                                    <span className="text-zinc-100">
                                        {selectedPurchase.nome}
                                    </span>
                                    <button
                                        onClick={() =>
                                            copyToClipboard(
                                                selectedPurchase.id,
                                                "nome",
                                                selectedPurchase.nome,
                                            )
                                        }
                                    >
                                        {copiedFields[
                                            `${selectedPurchase.id}-nome`
                                        ] ? (
                                            <Check className="w-4 h-4 text-green-500" />
                                        ) : (
                                            <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                        )}
                                    </button>
                                </div>

                                {/* CPF */}
                                <div className="flex items-center gap-2 flex-wrap">
                                    <Fingerprint className="h-5 w-5 text-zinc-400" />
                                    <span className="text-sm text-zinc-500">
                                        CPF:
                                    </span>
                                    <span className="font-mono text-zinc-100">
                                        {selectedPurchase.cpf}
                                    </span>
                                    <button
                                        onClick={() =>
                                            copyToClipboard(
                                                selectedPurchase.id,
                                                "cpf",
                                                selectedPurchase.cpf,
                                            )
                                        }
                                    >
                                        {copiedFields[
                                            `${selectedPurchase.id}-cpf`
                                        ] ? (
                                            <Check className="w-4 h-4 text-green-500" />
                                        ) : (
                                            <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                        )}
                                    </button>
                                </div>

                                {/* Banco */}
                                <div className="flex items-center gap-2 flex-wrap">
                                    <Landmark className="h-5 w-5 text-zinc-400" />
                                    <span className="text-sm text-zinc-500">
                                        Banco:
                                    </span>
                                    <span className="text-zinc-100">
                                        {selectedPurchase.bank}
                                    </span>
                                    <button
                                        onClick={() =>
                                            copyToClipboard(
                                                selectedPurchase.id,
                                                "banco",
                                                selectedPurchase.bank,
                                            )
                                        }
                                    >
                                        {copiedFields[
                                            `${selectedPurchase.id}-banco`
                                        ] ? (
                                            <Check className="w-4 h-4 text-green-500" />
                                        ) : (
                                            <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                        )}
                                    </button>
                                </div>

                                {/* País */}
                                <div className="flex items-center gap-2 flex-wrap">
                                    <Globe className="h-5 w-5 text-zinc-400" />
                                    <span className="text-sm text-zinc-500">
                                        País:
                                    </span>
                                    <span className="text-zinc-100">
                                        {selectedPurchase.country}
                                    </span>
                                    <button
                                        onClick={() =>
                                            copyToClipboard(
                                                selectedPurchase.id,
                                                "pais",
                                                selectedPurchase.country,
                                            )
                                        }
                                    >
                                        {copiedFields[
                                            `${selectedPurchase.id}-pais`
                                        ] ? (
                                            <Check className="w-4 h-4 text-green-500" />
                                        ) : (
                                            <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                        )}
                                    </button>
                                </div>

                                {/* Tipo */}
                                <div className="flex items-center gap-2 flex-wrap">
                                    <Tag className="h-5 w-5 text-zinc-400" />
                                    <span className="text-sm text-zinc-500">
                                        Tipo:
                                    </span>
                                    <span className="text-zinc-100">
                                        {selectedPurchase.type}
                                    </span>
                                    <button
                                        onClick={() =>
                                            copyToClipboard(
                                                selectedPurchase.id,
                                                "tipo",
                                                selectedPurchase.type,
                                            )
                                        }
                                    >
                                        {copiedFields[
                                            `${selectedPurchase.id}-tipo`
                                        ] ? (
                                            <Check className="w-4 h-4 text-green-500" />
                                        ) : (
                                            <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                        )}
                                    </button>
                                </div>

                                {/* Nível */}
                                <div className="flex items-center gap-2 flex-wrap">
                                    <Layers className="h-5 w-5 text-zinc-400" />
                                    <span className="text-sm text-zinc-500">
                                        Nível:
                                    </span>
                                    <span className="text-zinc-100">
                                        {selectedPurchase.level}
                                    </span>
                                    <button
                                        onClick={() =>
                                            copyToClipboard(
                                                selectedPurchase.id,
                                                "nivel",
                                                selectedPurchase.level,
                                            )
                                        }
                                    >
                                        {copiedFields[
                                            `${selectedPurchase.id}-nivel`
                                        ] ? (
                                            <Check className="w-4 h-4 text-green-500" />
                                        ) : (
                                            <Copy className="w-4 h-4 text-zinc-400 hover:text-zinc-100" />
                                        )}
                                    </button>
                                </div>
                            </div>
                            <Button
                                onClick={closeModal}
                                variant="default"
                                className="mt-6"
                            >
                                Fechar
                            </Button>
                        </Modal>
                    )}


                    <Modal
                      isOpen={isGiftModalOpen}
                      onClose={() => {
                        setIsGiftModalOpen(false);
                        // Limpa mensagens ao fechar o modal:
                        setClaimMessage(null);
                        setClaimError(null);
                      }}
                    >
                      <div className="relative text-center overflow-hidden">
                        <Confetti />
                        <div className="relative">
                          {/* Ícone de presente pulsante */}
                          <div className="w-24 h-24 bg-gradient-to-br from-yellow-400 via-yellow-500 to-yellow-600 rounded-full mx-auto mb-6 flex items-center justify-center animate-pulse">
                            <PartyPopper className="w-12 h-12 text-white" />
                            {showSparkles && (
                              <>
                                <Sparkles className="absolute top-0 left-0 w-6 h-6 text-yellow-300 animate-ping" />
                                <Sparkles className="absolute bottom-0 right-0 w-6 h-6 text-yellow-300 animate-ping" />
                              </>
                            )}
                          </div>

                          {/* Título e valor */}
                          <h2 className="text-3xl font-bold bg-gradient-to-r from-yellow-400 via-yellow-300 to-yellow-400 text-transparent bg-clip-text animate-gradient">
                            Parabéns!
                          </h2>
                          <p className="text-xl">
                            Você encontrou um presente de <span className="font-bold text-yellow-400">R$ {giftAmount.toFixed(2)}</span>
                          </p>
                          <p className="text-zinc-400 mb-4">Código: {giftCode}</p>

                          {/* Mensagens de sucesso/erro */}
                          {claimMessage && (
                            <p className="text-sm text-green-400 mb-2">{claimMessage}</p>
                          )}
                          {claimError && (
                            <p className="text-sm text-red-400 mb-2">{claimError}</p>
                          )}

                          {/* Botão de Resgatar */}
                          <Button
                            onClick={async () => {
                              try {
                                // Limpa mensagens antes da requisição
                                setClaimMessage(null);
                                setClaimError(null);

                                // Chamada para resgatar o gift
                                const res = await axiosInstance.post('/claim-gift', { code: giftCode });

                                if (res.data && res.data.message) {
                                  setClaimMessage(res.data.message);

                                  // Remove o gift resgatado do estado local
                                  setAvailableGifts((prevGifts) =>
                                    prevGifts.filter((g) => g.code !== giftCode)
                                  );
                                }
                              } catch (error) {
                                if (error.response && error.response.data && error.response.data.error) {
                                  setClaimError(error.response.data.error);
                                } else {
                                  setClaimError('Erro desconhecido ao resgatar gift.');
                                }
                              }
                            }}
                            className="w-full bg-gradient-to-r from-yellow-500 via-yellow-600 to-yellow-500 text-white hover:from-yellow-600 hover:via-yellow-700 hover:to-yellow-600 transition-all duration-300"
                          >
                            Resgatar agora
                          </Button>
                        </div>
                      </div>
                    </Modal>



                </div>

                {/* Rodapé (botão de ver mais) */}
                <div className="p-4 border-t border-zinc-800">
                    <button
                        type="button"
                        className="w-full py-2.5 px-4 rounded-xl text-sm font-medium text-zinc-400 hover:bg-zinc-800 transition-colors duration-200"
                    >
                        Ver Todas as Transações
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className="w-full mx-auto">
            {/* Botões de troca de aba, agora sincronizados via store */}
            <div className="flex gap-4 mb-6 justify-center">
                <Button
                    variant="outline"
                    onClick={() => handleTabChange("cards")}
                    className={clsx(
                        // Em telas pequenas, usa `px-2 py-1 text-xs`. Em telas maiores, volta ao normal.
                        "px-2 py-1 text-xs sm:px-4 sm:py-2 sm:text-sm",
                        activeTab === "cards" && "bg-zinc-800 text-zinc-100",
                    )}
                >
                    Cartões
                </Button>
                <Button
                    variant="outline"
                    onClick={() => handleTabChange("docs")}
                    className={clsx(
                        "px-2 py-1 text-xs sm:px-4 sm:py-2 sm:text-sm",
                        activeTab === "docs" && "bg-zinc-800 text-zinc-100",
                    )}
                >
                    Documentos
                </Button>
            </div>

            {/* Conteúdo de cada aba */}
            {activeTab === "cards" && renderCardPurchases()}
            {activeTab === "docs" && <DocsHistory />}
        </div>
    );
};

export default MinhasCompras;

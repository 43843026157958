import React, { useState, useEffect } from "react";
import { X, Loader2 } from "lucide-react";

const EditConsultaModal = ({ isOpen, onClose, onSave, consulta, loading }) => {
  const [formData, setFormData] = useState({
    method: "",
    required_fields: "",
    response_example: "",
    authentication: {},
    headers: {},
    body: {},
  });

  useEffect(() => {
    if (consulta) {
      setFormData({
        method: consulta.method || "",
        required_fields: consulta.required_fields
          ? consulta.required_fields.join(", ")
          : "",
        response_example: consulta.response_example || "",
        authentication: consulta.authentication || {},
        headers: consulta.headers || {},
        body: consulta.body || {},
      });
    }
  }, [consulta]);

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    const processedData = {
      ...formData,
      required_fields: formData.required_fields
        .split(",")
        .map((field) => field.trim())
        .filter(Boolean),
    };
    onSave(processedData);
  };

  return (
    <div className="fixed inset-0 bg-black/50 z-50">
      <div className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-2xl bg-zinc-900 border border-zinc-800 rounded-xl p-6">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-lg font-medium text-zinc-100">
            Editar Consulta: {consulta?.consulta_name}
          </h3>
          <button
            onClick={onClose}
            className="text-zinc-400 hover:text-zinc-300 transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-4">
            {/* Método */}
            <div>
              <label className="block text-sm font-medium text-zinc-300 mb-1">
                Método
              </label>
              <select
                value={formData.method}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, method: e.target.value }))
                }
                className="w-full bg-zinc-800 border-zinc-700 rounded-lg text-zinc-100 text-sm"
              >
                <option value="GET">GET</option>
                <option value="POST">POST</option>
                <option value="PUT">PUT</option>
                <option value="DELETE">DELETE</option>
              </select>
            </div>

            {/* Campos Obrigatórios */}
            <div>
              <label className="block text-sm font-medium text-zinc-300 mb-1">
                Campos Obrigatórios (separados por vírgula)
              </label>
              <input
                type="text"
                value={formData.required_fields}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    required_fields: e.target.value,
                  }))
                }
                className="w-full bg-zinc-800 border-zinc-700 rounded-lg text-zinc-100 text-sm"
                placeholder="cpf, nome, telefone"
              />
            </div>

            {/* Exemplo de Resposta */}
            <div>
              <label className="block text-sm font-medium text-zinc-300 mb-1">
                Exemplo de Resposta (JSON)
              </label>
              <textarea
                value={formData.response_example}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    response_example: e.target.value,
                  }))
                }
                className="w-full h-32 bg-zinc-800 border-zinc-700 rounded-lg text-zinc-100 text-sm font-mono"
                placeholder="{\n  'exemplo': 'valor'\n}"
              />
            </div>
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm text-zinc-400 hover:text-zinc-300 transition-colors"
              disabled={loading}
            >
              Cancelar
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 text-sm bg-violet-600 hover:bg-violet-700 text-white rounded-lg transition-colors flex items-center gap-2 disabled:opacity-50"
            >
              {loading && <Loader2 className="w-4 h-4 animate-spin" />}
              Salvar Alterações
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditConsultaModal;

import React, { useState, forwardRef, useEffect } from "react";
import {
  Loader2,
  Search,
  Check,
  Users2,
  Crown,
  Shield,
  Star,
  Zap,
  Sparkles,
  Package,
  AlertCircle,
  Component,
  Timer,
  ArrowRight,
  ArrowLeft,
} from "lucide-react";
import axiosInstance from "./axiosConfig";
import PurchaseConfirmationModal from "./PurchaseConfirmationModal";
import RenderDataCards from "./RenderDataCards";
import CryptoJS from "crypto-js";

// Função auxiliar para descriptografia
const decryptAES = (ciphertext, iv) => {
  try {
    const SECRET_KEY_BASE64 = "oKXzeNzPfkTVniZNaOt5RA==";
    const key = CryptoJS.enc.Base64.parse(SECRET_KEY_BASE64);
    const ivWordArray = CryptoJS.enc.Base64.parse(iv);
    const encryptedWordArray = CryptoJS.enc.Base64.parse(ciphertext);

    // Validate input lengths
    if (!ivWordArray || ivWordArray.sigBytes !== 16) {
      throw new Error("Invalid IV length");
    }

    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encryptedWordArray },
      key,
      {
        iv: ivWordArray,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      },
    );

    // Check if decryption was successful
    const decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);
    if (!decryptedStr) {
      throw new Error("Decryption resulted in empty string");
    }

    return JSON.parse(decryptedStr);
  } catch (error) {
    console.error("Decryption error:", error);
    throw error;
  }
};

const encryptAES = (data) => {
  try {
    const SECRET_KEY_BASE64 = "oKXzeNzPfkTVniZNaOt5RA==";
    const key = CryptoJS.enc.Base64.parse(SECRET_KEY_BASE64);
    const iv = CryptoJS.lib.WordArray.random(16);

    // Converte o objeto para string JSON
    const jsonStr = JSON.stringify(data);

    // Criptografa
    const encrypted = CryptoJS.AES.encrypt(jsonStr, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    // Retorna o objeto com IV e ciphertext em base64
    return {
      iv: CryptoJS.enc.Base64.stringify(iv),
      ciphertext: encrypted.toString(),
    };
  } catch (error) {
    console.error("Encryption error:", error);
    throw error;
  }
};

const cn = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const Input = forwardRef(({ className, type = "text", ...props }, ref) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="relative w-full">
      <input
        ref={ref}
        type={type}
        className={`w-full h-11 bg-zinc-800 border text-sm text-zinc-100 rounded-xl px-4 placeholder:text-zinc-500 focus:outline-none transition-colors duration-200 border-transparent hover:border-zinc-600 ${
          isFocused && "border-zinc-700"
        }`}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        {...props}
      />
    </div>
  );
});
Input.displayName = "Input";

const SearchButton = ({ onClick, isLoading }) => {
  const [progress, setProgress] = useState(0);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    let progressInterval;

    if (isLoading && progress < 85) {
      progressInterval = setInterval(() => {
        setProgress((prev) => Math.min(prev + 5, 85)); // Aumentei de 2 para 5
      }, 25); // Reduzi de 50 para 25ms
    }

    if (!isLoading && progress > 0) {
      clearInterval(progressInterval);
      setProgress(100);
      setIsComplete(true);

      setTimeout(() => {
        setIsComplete(false);
        setProgress(0);
      }, 1500);
    }

    return () => clearInterval(progressInterval);
  }, [isLoading, progress]);

  const handleClick = () => {
    setProgress(0);
    onClick();
  };

  return (
    <button
      onClick={handleClick}
      disabled={isLoading || isComplete}
      className={`
        relative h-12 px-6 rounded-lg flex items-center justify-center gap-2 text-sm font-medium
        transition-all duration-300 overflow-hidden
        ${
          isComplete || isLoading
            ? "bg-emerald-600/20 w-[180px] border border-emerald-500"
            : "bg-zinc-900 hover:bg-zinc-800 active:scale-[0.98] w-[140px] border border-zinc-800 shadow-[0_1px_2px_rgba(0,0,0,0.1)] hover:shadow-[0_4px_12px_rgba(0,0,0,0.1)]"
        }
      `}
    >
      <div
        className="absolute left-0 top-0 h-full bg-emerald-500/20 transition-transform duration-300 origin-left"
        style={{
          transform: `scaleX(${progress / 100})`,
          width: "100%",
          opacity: isLoading || progress > 0 ? 1 : 0,
        }}
      />

      <div
        className={`relative flex items-center gap-2 ${isComplete || isLoading ? "text-emerald-500" : "text-white"}`}
      >
        {isComplete ? (
          <>
            <Check className="w-4 h-4" />
            <span>Concluído!</span>
          </>
        ) : (
          <>
            {isLoading ? (
              <svg
                className="animate-spin h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="3"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            ) : (
              <Search className="w-4 h-4" />
            )}
            <span>
              {isLoading ? `${Math.min(99, progress)}%` : "Consultar"}
            </span>
          </>
        )}
      </div>
    </button>
  );
};
/* ============================================================
   Subcomponentes internos
   ============================================================ */
function Card({ children, className = "", ...props }) {
  return (
    <div
      className={
        "rounded-xl border bg-card text-card-foreground shadow-sm " +
        "transition-all duration-300 hover:shadow-lg " +
        className
      }
      {...props}
    >
      {children}
    </div>
  );
}

function Button({
  children,
  className = "",
  variant = "default",
  size = "default",
  ...props
}) {
  const baseClasses =
    "inline-flex items-center justify-center rounded-md font-medium " +
    "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 " +
    "transition-colors disabled:pointer-events-none disabled:opacity-50 ";

  let variantClasses = "";
  if (variant === "default") {
    variantClasses = "bg-primary text-primary-foreground hover:bg-primary/90";
  } else if (variant === "secondary") {
    variantClasses =
      "bg-secondary text-secondary-foreground hover:bg-secondary/80";
  }

  let sizeClasses = "";
  if (size === "default") sizeClasses = "h-10 px-4 text-sm";
  if (size === "lg") sizeClasses = "h-11 px-8 text-base";
  if (size === "sm") sizeClasses = "h-9 px-3 text-sm";

  return (
    <button
      className={`${baseClasses} ${variantClasses} ${sizeClasses} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
}

function ScrollArea({ children, className = "", ...props }) {
  return (
    <div className={`overflow-auto ${className}`} {...props}>
      {children}
    </div>
  );
}

function Badge({ children, className = "", variant = "default", ...props }) {
  const variantClasses =
    variant === "secondary"
      ? "bg-secondary text-secondary-foreground"
      : "bg-primary/20 text-primary hover:bg-primary/30";

  return (
    <span
      className={
        "inline-flex items-center rounded-full px-2 py-1 text-xs font-semibold " +
        variantClasses +
        " " +
        className
      }
      {...props}
    >
      {children}
    </span>
  );
}

function ActivePlanCard({ planData }) {
  // Função para formatar o tempo em "Xd Xh Xm Xs" a partir da expiration_date
  const getFormattedTime = () => {
    // Se não houver expiration_date, não temos como calcular
    if (!planData?.plan?.expiration_date) {
      return "Sem dados de tempo";
    }

    const expiration = new Date(planData.plan.expiration_date).getTime();
    const now = new Date().getTime();
    const diff = expiration - now;

    if (diff <= 0) return "Expirado";

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  // Guardamos o estado inicial do tempo chamando a função imediatamente
  const [timeRemaining, setTimeRemaining] = useState(getFormattedTime());

  useEffect(() => {
    // Só inicia o intervalo se houver plano ativo
    if (planData?.has_active_plan) {
      const interval = setInterval(() => {
        setTimeRemaining(getFormattedTime());
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [planData]);

  // Se não houver plano ou não estiver ativo, retorna null (depois de definir os Hooks)
  if (!planData || !planData.has_active_plan) {
    return null;
  }

  const { plan } = planData;

  return (
    <Card className="backdrop-blur-sm bg-background/80 p-6">
      <div className="flex justify-between items-start mb-6">
        <div className="flex items-center gap-3">
          <div className="w-10 h-10 rounded-full bg-emerald-100 dark:bg-emerald-900/20 flex items-center justify-center">
            <Package className="w-5 h-5 text-emerald-600 dark:text-emerald-400" />
          </div>
          <div>
            <h3 className="font-medium text-lg text-zinc-900 dark:text-zinc-100">
              {plan.plan_name}
            </h3>
            <p className="text-sm text-zinc-500 dark:text-zinc-400">
              {plan.supplier?.display_name || "Plano Ativo"}
            </p>
          </div>
        </div>
        <Badge variant="secondary">
          {plan.status === "active" ? "Ativo" : plan.status}
        </Badge>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Tempo Restante */}
        <div className="space-y-3">
          <div className="flex items-center gap-2">
            <Timer className="w-4 h-4 text-primary" />
            <span className="text-sm font-medium text-zinc-900 dark:text-zinc-100">
              Tempo Restante
            </span>
          </div>
          <div className="text-2xl font-bold text-primary">{timeRemaining}</div>
        </div>

        {/* Uso Diário */}
        <div className="space-y-3">
          <div className="flex items-center gap-2">
            <Zap className="w-4 h-4 text-primary" />
            <span className="text-sm font-medium text-zinc-900 dark:text-zinc-100">
              Uso Diário
            </span>
          </div>
          <div className="flex items-end gap-2">
            <span className="text-2xl font-bold text-primary">
              {plan.usage.today}
            </span>
            <span className="text-sm text-zinc-500 dark:text-zinc-400 mb-1">
              de {plan.daily_limit} disponíveis
            </span>
          </div>
        </div>
      </div>

      {plan.allowed_modules && plan.allowed_modules.length > 0 && (
        <div className="mt-6">
          <div className="flex items-center gap-2 mb-3">
            <Component className="w-4 h-4 text-primary" />
            <span className="text-sm font-medium text-zinc-900 dark:text-zinc-100">
              Módulos Permitidos
            </span>
          </div>
          <div className="flex flex-wrap gap-2">
            {plan.allowed_modules.map((module, index) => (
              <Badge key={index} variant="secondary" className="px-3 py-1">
                {module}
              </Badge>
            ))}
          </div>
        </div>
      )}
    </Card>
  );
}

// TabButton Component
function TabButton({ active, onClick, icon, label }) {
  return (
    <button
      onClick={onClick}
      className={cn(
        // Base styles
        "relative px-6 py-3 text-sm font-medium",
        "transition-all duration-500 ease-spring",
        "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary/50",

        // Group styles for icon and text alignment
        "group flex items-center gap-3 justify-center",
        "w-[160px]",

        // Active and hover states
        active
          ? ["text-primary"]
          : ["text-muted-foreground", "hover:text-primary/80"],
      )}
    >
      {/* Icon wrapper with animations */}
      <span
        className={cn(
          "transition-all duration-500 ease-spring",
          "group-hover:scale-110",
          active && "animate-bounce-subtle",
        )}
      >
        {icon}
      </span>
      {/* Text with slide effect */}
      <span
        className={cn(
          "transition-colors duration-500",
          active
            ? "text-primary"
            : "text-muted-foreground group-hover:text-primary/80",
        )}
      >
        {label}
      </span>
    </button>
  );
}

/* ============================================================
   Componente Principal: Consultas com Sistema de Abas
   ============================================================ */
export default function Consultas() {
  const [consultas, setConsultas] = useState([]);
  const [loadingConsultas, setLoadingConsultas] = useState(false);
  const [errorConsultas, setErrorConsultas] = useState(null);

  const [search, setSearch] = useState("");
  const [selectedConsulta, setSelectedConsulta] = useState(null);
  const [formData, setFormData] = useState({});

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(null);
  const [resultado, setResultado] = useState(null);

  const [plans, setPlans] = useState([]);
  const [loadingPlans, setLoadingPlans] = useState(false);
  const [errorPlans, setErrorPlans] = useState(null);

  const [activePlan, setActivePlan] = useState(null);
  const [loadingActivePlan, setLoadingActivePlan] = useState(false);

  const [supplierPlansMap, setSupplierPlansMap] = useState({});
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [hoveredPlan, setHoveredPlan] = useState(null);

  // Estados para o modal de compra
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);
  const [selectedPlanToPurchase, setSelectedPlanToPurchase] = useState(null);
  const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);
  const [purchaseMessage, setPurchaseMessage] = useState({
    type: "",
    text: "",
  });

  const [isFocused, setIsFocused] = useState(false);

  // Estado para controlar a aba ativa: 'consultas' ou 'planos'
  const [activeTab, setActiveTab] = useState("consultas");

  const fetchActivePlan = async () => {
    try {
      setLoadingActivePlan(true);
      const response = await axiosInstance.get("/active-plan");
      setActivePlan(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Erro ao buscar plano ativo:", error);
    } finally {
      setLoadingActivePlan(false);
    }
  };

  const fetchConsultas = async () => {
    try {
      setLoadingConsultas(true);
      const response = await axiosInstance.get("/consultas");
      const data = response.data;
      if (data.error) {
        setErrorConsultas(data.error);
        setConsultas([]);
      } else {
        setConsultas(data.consultas || []);
        setErrorConsultas(null);
      }
    } catch (err) {
      setErrorConsultas("Erro ao carregar as consultas");
    } finally {
      setLoadingConsultas(false);
    }
  };

  const fetchPlans = async () => {
    try {
      setLoadingPlans(true);
      const response = await axiosInstance.get("/list_all_plans");
      const data = response.data;
      if (data.error) {
        setErrorPlans(data.error);
        setPlans([]);
      } else {
        const allPlans = data.plans || [];
        setPlans(allPlans);
        setErrorPlans(null);

        const grouped = {};
        for (const p of allPlans) {
          const supName = p.supplier_display_name || "Fornecedor Desconhecido";
          if (!grouped[supName]) {
            grouped[supName] = [];
          }
          grouped[supName].push(p);
        }
        setSupplierPlansMap(grouped);
      }
    } catch (err) {
      setErrorPlans("Erro ao carregar os planos");
    } finally {
      setLoadingPlans(false);
    }
  };

  const handleSubmitConsulta = async () => {
    if (!selectedConsulta) return;
    setLoadingSubmit(true);
    setErrorSubmit(null);
    setResultado(null);

    try {
      // Prepara os dados para enviar
      const dataToEncrypt = {
        consulta_name: selectedConsulta.consulta_name,
        params: formData,
      };

      // Criptografa os dados
      const encryptedData = encryptAES(dataToEncrypt);

      // Envia os dados criptografados
      const response = await axiosInstance.post("/consulta", encryptedData);

      // Se houver erro na resposta
      if (response.data.error) {
        try {
          // Tenta descriptografar a mensagem de erro
          const decryptedError = decryptAES(
            response.data.ciphertext,
            response.data.iv,
          );
          setErrorSubmit(decryptedError.error);
        } catch (decryptError) {
          // Se não conseguir descriptografar, usa o erro original
          setErrorSubmit(response.data.error);
        }
        setResultado(null);
      } else {
        // Descriptografa o resultado
        const decryptedResult = decryptAES(
          response.data.ciphertext,
          response.data.iv,
        );
        setResultado(decryptedResult);
      }
    } catch (err) {
      // Trata erros de criptografia/descriptografia ou da requisição
      if (err.response?.data?.ciphertext && err.response?.data?.iv) {
        try {
          const decryptedError = decryptAES(
            err.response.data.ciphertext,
            err.response.data.iv,
          );
          setErrorSubmit(decryptedError.error || "Erro ao realizar consulta");
        } catch (decryptError) {
          setErrorSubmit("Erro ao processar resposta da consulta");
        }
      } else {
        setErrorSubmit("Erro ao realizar consulta");
      }
    } finally {
      setLoadingSubmit(false);
    }
  };

  const handleSelectConsulta = (consulta) => {
    setSelectedConsulta(consulta);
    setResultado(null);
    setErrorSubmit(null);

    // Inicializa o formData com campos vazios baseados nos campos requeridos
    const initialFormData = {};
    if (consulta.required_fields && Array.isArray(consulta.required_fields)) {
      consulta.required_fields.forEach((field) => {
        initialFormData[field] = "";
      });
    }
    setFormData(initialFormData);
  };

  const handlePurchasePlan = async () => {
    if (!selectedPlanToPurchase) return;

    try {
      setIsPurchaseLoading(true);
      setPurchaseMessage({ type: "", text: "" });

      const response = await axiosInstance.post("/purchase_plan", {
        plan_id: selectedPlanToPurchase.plan_public_id,
      });

      setPurchaseMessage({
        type: "success",
        text: "Plano comprado com sucesso!",
      });

      setTimeout(() => {
        setIsPurchaseModalOpen(false);
        setSelectedPlanToPurchase(null);
        setPurchaseMessage({ type: "", text: "" });
      }, 1500);

      await fetchActivePlan();

      await fetchConsultas();
    } catch (error) {
      setPurchaseMessage({
        type: "error",
        text: error.response?.data?.error || "Erro ao processar a compra",
      });
    } finally {
      setIsPurchaseLoading(false);
    }
  };

  useEffect(() => {
    fetchConsultas();
    fetchPlans();
    fetchActivePlan();
  }, []);

  const filteredConsultas = consultas.filter((c) =>
    c.consulta_name.toLowerCase().includes(search.toLowerCase()),
  );

  const supplierNames = Object.keys(supplierPlansMap);
  const selectedSupplierPlans = supplierPlansMap[selectedSupplier] || [];

  const getFilteredConsultas = () => {
    // Primeiro filtra por pesquisa
    let filtered = consultas.filter((c) =>
      c.consulta_name.toLowerCase().includes(search.toLowerCase()),
    );

    // Se não tiver plano ativo, não mostra nenhuma consulta
    if (!activePlan?.has_active_plan) {
      return [];
    }

    // Se não tiver módulos permitidos, não mostra nenhuma consulta
    const allowedModules = activePlan?.plan?.allowed_modules || [];
    if (allowedModules.length === 0) {
      return [];
    }

    // Filtra com base nos módulos permitidos
    filtered = filtered.filter((consulta) => {
      // Se a consulta não tem módulo definido, não mostra
      if (!consulta.module) return false;

      // Verifica se o módulo da consulta está na lista de permitidos
      return allowedModules.includes(consulta.module);
    });

    return filtered;
  };

  return (
    <div className="min-h-screen bg-background p-4 sm:p-6 md:p-8">
      <div className="max-w-7xl mx-auto space-y-16">
        {/* Seção do Plano Ativo */}
        {loadingActivePlan ? (
          <div className="flex justify-center items-center py-12">
            <Loader2 className="h-8 w-8 animate-spin text-primary" />
          </div>
        ) : (
          <ActivePlanCard planData={activePlan} />
        )}

        {/* New Tab System */}
        <div className="flex items-center justify-center">
          <div className="relative flex gap-4">
            {/* Animated underline indicator */}
            <div
              className={cn(
                "absolute h-[2px] w-[40px]",
                "transition-all duration-500 ease-spring",
                "bg-primary",
                "will-change-transform",
                activeTab === "consultas"
                  ? "translate-x-[75px] -bottom-[1px]"
                  : "translate-x-[244px] -bottom-[1px]",
              )}
            />

            <TabButton
              active={activeTab === "consultas"}
              onClick={() => setActiveTab("consultas")}
              icon={
                <Search
                  className={cn(
                    "w-4 h-4 transition-transform duration-500",
                    "group-hover:rotate-12",
                  )}
                />
              }
              label="Consultas"
            />

            <TabButton
              active={activeTab === "planos"}
              onClick={() => setActiveTab("planos")}
              icon={
                <Package
                  className={cn(
                    "w-4 h-4 transition-transform duration-500",
                    "group-hover:-rotate-12",
                  )}
                />
              }
              label="Planos"
            />
          </div>
        </div>

        {/* Conteúdo Condicional baseado na aba ativa */}
        {activeTab === "consultas" && (
          <section>
            {!selectedConsulta ? (
              // Lista de Consultas
              <>
                <div className="text-center mb-12">
                  <h1 className="text-4xl font-bold tracking-tight mb-4 bg-gradient-to-r from-primary/50 to-primary bg-clip-text text-transparent">
                    Consultas
                  </h1>
                  <p className="text-muted-foreground text-lg">
                    Explore e execute consultas específicas
                  </p>
                </div>

                <div className="relative max-w-md mx-auto mb-8">
                  <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-zinc-500" />
                  <input
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Pesquisar consultas..."
                    className="w-full h-10 bg-zinc-800 border text-sm text-zinc-100 rounded-xl pl-10 pr-4 placeholder:text-zinc-500 focus:outline-none transition-colors duration-200 border-transparent hover:border-zinc-600"
                  />
                </div>

                {loadingConsultas && (
                  <div className="flex justify-center items-center py-12">
                    <Loader2 className="h-8 w-8 animate-spin text-primary" />
                  </div>
                )}

                {errorConsultas && (
                  <div className="text-center py-12 text-destructive">
                    <p className="font-medium">{errorConsultas}</p>
                  </div>
                )}

                {!loadingConsultas && !errorConsultas && (
                  <>
                    {!activePlan?.has_active_plan ? (
                      <div className="text-center py-12">
                        <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-amber-100 dark:bg-amber-900/20 mb-4">
                          <AlertCircle className="w-6 h-6 text-amber-600 dark:text-amber-400" />
                        </div>
                        <p className="text-lg font-medium text-zinc-900 dark:text-zinc-100 mb-2">
                          Nenhum Plano Ativo
                        </p>
                        <p className="text-muted-foreground max-w-md mx-auto">
                          Você precisa ter um plano ativo para acessar as
                          consultas. Escolha um plano abaixo para começar.
                        </p>
                      </div>
                    ) : getFilteredConsultas().length === 0 ? (
                      <div className="text-center py-12">
                        <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-amber-100 dark:bg-amber-900/20 mb-4">
                          <AlertCircle className="w-6 h-6 text-amber-600 dark:text-amber-400" />
                        </div>
                        <p className="text-lg font-medium text-zinc-900 dark:text-zinc-100 mb-2">
                          Nenhuma Consulta Disponível
                        </p>
                        <p className="text-muted-foreground max-w-md mx-auto">
                          Seu plano atual não dá acesso a{" "}
                          {search ? "esta consulta" : "nenhuma consulta"}.
                          Considere fazer upgrade para um plano com mais
                          recursos.
                        </p>
                      </div>
                    ) : (
                      <div className="grid gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 max-w-6xl mx-auto">
                        {getFilteredConsultas().map((c) => (
                          <Card
                            key={c.consulta_name}
                            onClick={() => handleSelectConsulta(c)}
                            className={`group cursor-pointer rounded-lg border border-border bg-card p-6 hover:shadow-xl hover:shadow-ring/5 hover:border-muted transition-all duration-300 ${
                              selectedConsulta?.consulta_name ===
                              c.consulta_name
                                ? "ring-2 ring-primary shadow-xl shadow-primary/20"
                                : ""
                            }`}
                          >
                            <div className="flex flex-col h-full space-y-6">
                              <div className="space-y-4">
                                <div className="flex items-start justify-between">
                                  <div className="flex items-center gap-3">
                                    <div className="w-10 h-10 rounded-lg bg-secondary flex items-center justify-center group-hover:bg-accent transition-colors duration-300">
                                      <Search className="w-5 h-5 text-secondary-foreground" />
                                    </div>
                                    <div>
                                      <h2 className="text-lg font-semibold text-card-foreground group-hover:text-foreground transition-colors">
                                        {c.consulta_name}
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="space-y-4">
                                {c.description && (
                                  <p className="text-sm text-muted-foreground line-clamp-2">
                                    {c.description}
                                  </p>
                                )}
                              </div>
                              <div className="mt-auto pt-4">
                                <Button
                                  variant={
                                    selectedConsulta?.consulta_name ===
                                    c.consulta_name
                                      ? "default"
                                      : "secondary"
                                  }
                                  className="w-full group-hover:bg-primary group-hover:text-primary-foreground transition-all duration-300"
                                >
                                  {selectedConsulta?.consulta_name ===
                                  c.consulta_name ? (
                                    <>
                                      <Check className="mr-2 h-4 w-4" />
                                      Selecionado
                                    </>
                                  ) : (
                                    "Selecionar"
                                  )}
                                </Button>
                              </div>
                            </div>
                          </Card>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              // Área de Consulta Selecionada com Botão de Voltar
              <Card className="max-w-4xl mx-auto backdrop-blur-xl bg-background/80 border-accent/20 shadow-2xl shadow-accent/5">
                <div className="p-6 sm:p-8">
                  <div className="mb-8">
                    <Button
                      variant="ghost"
                      onClick={() => setSelectedConsulta(null)}
                      className="group flex items-center text-muted-foreground hover:text-foreground transition-colors"
                    >
                      <ArrowLeft className="mr-2 h-4 w-4 transition-transform group-hover:-translate-x-1" />
                      Voltar
                    </Button>
                  </div>

                  <div className="space-y-8">
                    <div>
                      <h2 className="text-2xl font-semibold tracking-tight mb-1">
                        {selectedConsulta.consulta_name}
                      </h2>
                      <p className="text-sm text-muted-foreground">
                        Preencha os campos abaixo para realizar a consulta
                      </p>
                    </div>

                    <div className="space-y-6">
                      {selectedConsulta.required_fields?.map((field) => (
                        <div key={field}>
                          <label className="text-sm font-medium mb-2 block text-muted-foreground">
                            {field.toUpperCase()}
                          </label>
                          <Input
                            value={formData[field] || ""}
                            onChange={(e) =>
                              setFormData((prev) => ({
                                ...prev,
                                [field]: e.target.value,
                              }))
                            }
                            placeholder={`Digite o ${field.toUpperCase()}`}
                          />
                        </div>
                      ))}

                      <SearchButton
                        onClick={handleSubmitConsulta}
                        isLoading={loadingSubmit}
                      />
                    </div>

                    {errorSubmit && (
                      <div className="p-4 rounded-lg bg-destructive/10 border border-destructive/20 text-destructive animate-in fade-in duration-300">
                        <p className="text-sm">{errorSubmit}</p>
                      </div>
                    )}

                    {resultado && (
                      <div className="mt-8 animate-in fade-in slide-in-from-bottom-4 duration-500">
                        <div className="flex items-center justify-between mb-6 pb-4 border-b border-border">
                          <div>
                            <h3 className="text-lg font-semibold">Resultado</h3>
                            <span className="text-sm text-muted-foreground">
                              {(() => {
                                const countRecords = (obj) => {
                                  if (Array.isArray(obj)) return obj.length;
                                  if (obj && typeof obj === "object") {
                                    const arrays = Object.values(obj).filter(
                                      Array.isArray,
                                    );
                                    if (arrays.length > 0) {
                                      return arrays.reduce(
                                        (total, arr) => total + arr.length,
                                        0,
                                      );
                                    }
                                    return Object.values(obj).reduce(
                                      (total, value) => {
                                        if (
                                          typeof value === "object" &&
                                          value !== null
                                        ) {
                                          return total + countRecords(value);
                                        }
                                        return total;
                                      },
                                      0,
                                    );
                                  }
                                  return 0;
                                };

                                const count = countRecords(resultado);
                                return `${count} ${count === 1 ? "registro encontrado" : "registros encontrados"}`;
                              })()}
                            </span>
                          </div>
                        </div>
                        <div className="rounded-md">
                          <RenderDataCards data={resultado} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            )}
          </section>
        )}

        {activeTab === "planos" && (
          <section>
            <div className="text-center mb-12">
              <h2 className="text-4xl font-bold tracking-tight mb-4 bg-gradient-to-r from-primary/50 to-primary bg-clip-text text-transparent">
                Fornecedores & Planos
              </h2>
              <p className="text-muted-foreground text-lg">
                Selecione um fornecedor para visualizar seus planos
              </p>
            </div>

            {loadingPlans && (
              <div className="flex justify-center items-center py-12">
                <Loader2 className="h-8 w-8 animate-spin text-primary" />
              </div>
            )}

            {errorPlans && (
              <div className="text-center py-12 text-destructive">
                <p className="font-medium">{errorPlans}</p>
              </div>
            )}

            {!loadingPlans && !errorPlans && supplierNames.length === 0 && (
              <div className="text-center py-12">
                <p className="text-muted-foreground">
                  Nenhum fornecedor ou plano disponível.
                </p>
              </div>
            )}

            {!loadingPlans && !errorPlans && supplierNames.length > 0 && (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {supplierNames.map((supplierName) => (
                  <Card
                    key={supplierName}
                    className={`group relative overflow-hidden transition-all duration-500 hover:shadow-2xl hover:shadow-primary/20 ${
                      selectedSupplier === supplierName
                        ? "ring-2 ring-primary shadow-xl shadow-primary/20"
                        : ""
                    } cursor-pointer backdrop-blur-sm bg-background/80`}
                    onClick={() => setSelectedSupplier(supplierName)}
                  >
                    <div className="p-8">
                      <div className="absolute top-4 right-4">
                        <Badge variant="secondary" className="backdrop-blur-sm">
                          <Crown className="h-4 w-4 text-primary mr-1" />
                          Premium
                        </Badge>
                      </div>

                      <h3 className="text-2xl font-bold mb-3 group-hover:text-primary transition-colors">
                        {supplierName}
                      </h3>

                      <div className="flex items-center gap-4 mb-6">
                        <div className="flex items-center gap-2">
                          <Users2 className="h-4 w-4 text-primary" />
                          <span className="text-sm text-muted-foreground">
                            {supplierPlansMap[supplierName].length} plano(s)
                          </span>
                        </div>
                      </div>

                      <Button
                        variant={
                          selectedSupplier === supplierName
                            ? "default"
                            : "secondary"
                        }
                        className="w-full group-hover:bg-primary group-hover:text-primary-foreground transition-all duration-300"
                      >
                        {selectedSupplier === supplierName ? (
                          <>
                            <Check className="mr-2 h-4 w-4" />
                            Selecionado
                          </>
                        ) : (
                          "Selecionar"
                        )}
                      </Button>
                    </div>
                  </Card>
                ))}
              </div>
            )}

            {selectedSupplier && (
              <section className="animate-in fade-in-50 duration-700 mt-12">
                <div className="text-center mb-8">
                  <h2 className="text-3xl font-bold tracking-tight mb-2">
                    Planos de {selectedSupplier}
                  </h2>
                </div>

                {selectedSupplierPlans.length === 0 ? (
                  <p className="text-center text-muted-foreground">
                    Nenhum plano encontrado para este fornecedor.
                  </p>
                ) : (
                  <ScrollArea className="w-full rounded-lg">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-1">
                      {selectedSupplierPlans.map((plan) => (
                        <Card
                          key={plan.plan_public_id}
                          className={`group relative overflow-hidden transition-all duration-500 hover:shadow-2xl bg-gradient-to-br from-primary/5 via-primary/10 to-primary/5`}
                          onMouseEnter={() =>
                            setHoveredPlan(plan.plan_public_id)
                          }
                          onMouseLeave={() => setHoveredPlan(null)}
                        >
                          <div className="p-8">
                            <div className="absolute top-6 right-6">
                              <Badge className="bg-primary/20 text-primary hover:bg-primary/30">
                                <Sparkles className="h-4 w-4 mr-1" />
                                Premium
                              </Badge>
                            </div>

                            <h3 className="text-3xl font-bold mb-2 group-hover:text-primary transition-colors">
                              {plan.plan_name}
                            </h3>

                            <div className="mb-8">
                              <div className="flex items-baseline gap-2">
                                <span className="text-4xl font-bold">
                                  R$ {plan.price}
                                </span>
                                {plan.duration_days && (
                                  <span className="text-muted-foreground">
                                    {plan.duration_days >= 30
                                      ? `/${Math.floor(plan.duration_days / 30)} mês(es)`
                                      : `/${plan.duration_days} dia(s)`}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="space-y-4 mb-8">
                              {plan.daily_limit && (
                                <div className="flex items-center gap-3 group/feature">
                                  <div className="rounded-full p-1.5 bg-primary/10 text-primary group-hover/feature:bg-primary group-hover/feature:text-primary-foreground transition-colors">
                                    <Shield className="h-4 w-4" />
                                  </div>
                                  <span className="text-sm group-hover/feature:text-primary transition-colors">
                                    Limite Diário: {plan.daily_limit}
                                  </span>
                                </div>
                              )}
                              {plan.description && (
                                <div className="flex items-center gap-3 group/feature">
                                  <div className="rounded-full p-1.5 bg-primary/10 text-primary group-hover/feature:bg-primary group-hover/feature:text-primary-foreground transition-colors">
                                    <Star className="h-4 w-4" />
                                  </div>
                                  <span className="text-sm group-hover/feature:text-primary transition-colors">
                                    {plan.description}
                                  </span>
                                </div>
                              )}
                              {plan.allowed_modules &&
                                plan.allowed_modules.length > 0 && (
                                  <div className="flex items-center gap-3 group/feature">
                                    <div className="rounded-full p-1.5 bg-primary/10 text-primary group-hover/feature:bg-primary group-hover/feature:text-primary-foreground transition-colors">
                                      <Component className="h-4 w-4" />
                                    </div>
                                    <div className="flex flex-wrap gap-2">
                                      {plan.allowed_modules.map(
                                        (module, index) => (
                                          <Badge
                                            key={index}
                                            variant="secondary"
                                            className="text-xs"
                                          >
                                            {module}
                                          </Badge>
                                        ),
                                      )}
                                    </div>
                                  </div>
                                )}
                            </div>

                            <Button
                              variant="default"
                              size="lg"
                              className="w-full bg-primary hover:bg-primary/90 transition-all duration-300"
                              onClick={() => {
                                setPurchaseMessage({
                                  type: "",
                                  text: "",
                                });
                                setSelectedPlanToPurchase(plan);
                                setIsPurchaseModalOpen(true);
                              }}
                            >
                              Escolher Plano
                            </Button>
                          </div>

                          {/* Efeito de brilho no hover */}
                          <div className="absolute inset-0 opacity-0 group-hover:opacity-100 pointer-events-none transition-opacity duration-500">
                            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-primary/5 to-transparent animate-shimmer" />
                          </div>
                        </Card>
                      ))}
                    </div>
                  </ScrollArea>
                )}
              </section>
            )}

            {/* Modal de Confirmação de Compra */}
            <PurchaseConfirmationModal
              isOpen={isPurchaseModalOpen}
              onClose={() => {
                setIsPurchaseModalOpen(false);
                setSelectedPlanToPurchase(null);
                setPurchaseMessage({ type: "", text: "" });
              }}
              planData={selectedPlanToPurchase}
              isLoading={isPurchaseLoading}
              onConfirmPurchase={handlePurchasePlan}
              message={purchaseMessage}
            />
          </section>
        )}

        {/* Modal de Confirmação de Compra (mantido fora das abas) */}
        {activeTab === "consultas" && (
          <PurchaseConfirmationModal
            isOpen={isPurchaseModalOpen}
            onClose={() => {
              setIsPurchaseModalOpen(false);
              setSelectedPlanToPurchase(null);
              setPurchaseMessage({ type: "", text: "" });
            }}
            planData={selectedPlanToPurchase}
            isLoading={isPurchaseLoading}
            onConfirmPurchase={handlePurchasePlan}
            message={purchaseMessage}
          />
        )}
      </div>
    </div>
  );
}

import React from "react";

const Card = ({ children, className = "", ...props }) => {
  return (
    <div
      className={`rounded-xl border border-zinc-800 text-zinc-100 backdrop-blur-sm shadow-lg transition-all duration-300 hover:shadow-xl hover:shadow-zinc-900/20 hover:border-zinc-700 w-full ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

const RenderDataCards = ({ data, level = 0 }) => {
  if (data == null) {
    return <span className="italic text-zinc-500">null</span>;
  }

  if (typeof data !== "object") {
    return <span className="text-zinc-100">{String(data)}</span>;
  }

  if (Array.isArray(data)) {
    return (
      <div className="grid gap-3 w-full">
        {data.map((item, idx) => (
          <div
            key={idx}
            className="pl-4 border-l border-zinc-800 transition-colors duration-200 hover:border-zinc-700"
          >
            <RenderDataCards data={item} level={level + 1} />
          </div>
        ))}
      </div>
    );
  }

  const entries = Object.entries(data);

  if (level === 0) {
    return (
      <div className="grid gap-8 w-full">
        {entries.map(([key, value]) => (
          <div key={key} className="w-full">
            <div className="text-2xl font-semibold text-zinc-100 tracking-tight mb-4">
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </div>
            <div
              className={
                Array.isArray(value)
                  ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
                  : "grid-cols-1"
              }
            >
              <RenderDataCards data={value} level={level + 1} />
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <Card className="w-full">
      <div className="p-5 space-y-4">
        {entries.map(([key, value]) => {
          if (value === null || typeof value === "object") {
            return (
              <div key={key} className="space-y-2 w-full">
                <div className="flex items-center gap-2">
                  <div className="h-px flex-1 bg-zinc-800" />
                  <div className="text-sm font-medium text-zinc-400">
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </div>
                  <div className="h-px flex-1 bg-zinc-800" />
                </div>
                <div className="pl-4 border-l border-zinc-800 w-full">
                  <RenderDataCards data={value} level={level + 1} />
                </div>
              </div>
            );
          }

          return (
            <div
              key={key}
              className="group space-y-1.5 transition-colors duration-200"
            >
              <div className="text-sm font-medium text-zinc-500 group-hover:text-zinc-400 transition-colors duration-200">
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </div>
              <div className="text-zinc-100 font-medium">{String(value)}</div>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default RenderDataCards;

import React from "react";
import { Upload, File } from "lucide-react";

const HSConvert = ({
  isDragging,
  handleDragIn,
  handleDragOut,
  handleDrag,
  handleDrop,
  processFile,
  selectedFile,
  loading,
  cnButton,
  fileContent,
  identifiedCards,
  unidentifiedCards,
  successfulHashes,
  errorHashes,
  handleClearFile,
  Btn01,
  sending,
  hashesOutput,
  vendorToken,
  vendorPendingApproval,
  handleSendHashes,
  Button,
}) => {
  return (
    <div className="space-y-8">
      <div className="p-6 rounded-2xl border border-zinc-200 dark:border-zinc-800 bg-white dark:bg-zinc-900">
        <h2 className="text-xl font-semibold mb-4">
          Converter Cartões para Hashes
        </h2>

        {!selectedFile ? (
          <div
            className={cnButton(
              "border-2 border-dashed rounded-lg p-8 text-center transition-colors",
              isDragging
                ? "border-blue-500 bg-blue-500/10"
                : "border-zinc-200 dark:border-zinc-700",
              loading ? "opacity-50" : "hover:border-blue-500",
              "bg-zinc-50 dark:bg-zinc-800",
            )}
            onDragEnter={handleDragIn}
            onDragLeave={handleDragOut}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <div className="flex flex-col items-center justify-center space-y-4">
              <Upload className="w-12 h-12 text-zinc-400 dark:text-zinc-500" />
              <div>
                <p className="text-lg font-medium">
                  Arraste e solte seu arquivo aqui ou
                </p>
                <label className="cursor-pointer text-blue-500 hover:text-blue-400">
                  <span>selecione um arquivo</span>
                  <input
                    type="file"
                    className="hidden"
                    onChange={(e) => processFile(e.target.files[0])}
                    accept=".txt"
                  />
                </label>
              </div>
              <p className="text-sm text-zinc-500 dark:text-zinc-400">
                Apenas arquivos .txt são aceitos
              </p>
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <File size={24} className="text-blue-500" />
                <div>
                  <p className="text-sm font-medium text-zinc-700 dark:text-zinc-300">
                    {selectedFile.name}
                  </p>
                  <p className="text-sm text-zinc-500 dark:text-zinc-400">
                    {(selectedFile.size / 1024).toFixed(2)} KB
                  </p>
                </div>
              </div>
              <Btn01 onClick={handleClearFile}>Realizar Novo Upload</Btn01>
            </div>

            <div className="space-y-2">
              <h3 className="font-medium">Estatísticas:</h3>
              <ul className="list-disc list-inside space-y-1 text-sm">
                <li>
                  Número de linhas: {fileContent.trim().split("\n").length}
                </li>
                <li>Número de cartões identificados: {identifiedCards}</li>
                <li>
                  Número de cartões não identificados: {unidentifiedCards}
                </li>
                <li>
                  Número de hashes enviadas com sucesso: {successfulHashes}
                </li>
                <li>Número de hashes com erros: {errorHashes}</li>
              </ul>
            </div>

            <div className="mt-4 flex justify-center">
              <Button
                onClick={handleSendHashes}
                disabled={
                  sending ||
                  hashesOutput.length === 0 ||
                  !vendorToken ||
                  vendorPendingApproval
                }
                loading={sending}
              >
                Enviar Hashes
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HSConvert;


import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import clsx from "clsx";
import { jwtDecode } from "jwt-decode";
import axiosInstance, { setAuthToken } from "./axiosConfig";
import CryptoJS from "crypto-js";

import DOMPurify from "dompurify";
import MinhasCompras from "./MinhasCompras";
import {
  Menu as MenuIcon,
  X,
  ShoppingCart,
  CreditCard,
  User,
  Lock,
  Calendar,
  Trash2,
  Landmark,
  CheckCircle,
  Share2,
  Zap,
  Sparkles,
  ArrowLeft,
  DollarSign,
  XCircle,
  Loader2,
  AlertTriangle,
  Award,
  FileText,
  Globe,
  Hash,
} from "lucide-react";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
} from "react-icons/fa";
import AstraShopLogin from "./AstraShopLogin";
import Menu from "./Menu";
import FinalizarCompra from "./FinalizarCompra";
import AdvancedSearch from "./AdvancedSearch";
import UserSettings from "./UserSettings";
import PaymentMethods from "./PaymentMethods";
import CardTableView from "./CardTableView";
import HashGuard from "./HashGuard";
import Documents from "./Documents";
import History from "./History";
import Marketplace from "./Marketplace";
import BuyLogins from "./buyLogins";
import Consultas from "./Consultas";
import ApiSuppliers from "./ApiSuppliers";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useUserStore } from "./store";

// ---------- BUTTON ----------
const Button = React.forwardRef(
  ({ className, variant = "default", size = "default", ...props }, ref) => {
    const classes = clsx(
      "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
      {
        "bg-primary text-primary-foreground hover:bg-primary/90":
          variant === "default",
        "bg-destructive text-destructive-foreground hover:bg-destructive/90":
          variant === "destructive",
        "border border-input bg-transparent hover:bg-accent hover:text-accent-foreground":
          variant === "outline",
        "bg-secondary text-secondary-foreground hover:bg-secondary/80":
          variant === "secondary",
        "hover:bg-accent hover:text-accent-foreground bg-transparent":
          variant === "ghost",
        "text-primary underline-offset-4 hover:underline": variant === "link",
      },
      {
        "h-10 px-4 py-2": size === "default",
        "h-9 rounded-md px-3": size === "sm",
        "h-11 rounded-md px-8": size === "lg",
        "h-10 w-10": size === "icon",
      },
      className,
    );
    return <button className={classes} ref={ref} {...props} />;
  },
);
Button.displayName = "Button";

// ---------- INPUT ----------
const Input = React.forwardRef(
  ({ className, type = "text", ...props }, ref) => {
    const classes = clsx(
      "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
      className,
    );
    return <input type={type} className={classes} ref={ref} {...props} />;
  },
);
Input.displayName = "Input";

// ---------- CARD ----------
const Card = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={clsx(
      "rounded-lg border bg-card text-card-foreground shadow-sm transition-all duration-500 ease-in-out hover:shadow-xl hover:-translate-y-2 hover:scale-[1.02]",
      className,
    )}
    {...props}
  />
));
Card.displayName = "Card";

const CardHeader = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={clsx("flex flex-col space-y-1.5 p-4", className)}
    {...props}
  />
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={clsx(
      "text-base font-semibold leading-none tracking-tight",
      className,
    )}
    {...props}
  />
));
CardTitle.displayName = "CardTitle";

const CardContent = React.forwardRef(({ className, ...props }, ref) => (
  <div ref={ref} className={clsx("p-4 pt-0", className)} {...props} />
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={clsx("flex items-center p-4 pt-0", className)}
    {...props}
  />
));
CardFooter.displayName = "CardFooter";

// ---------- MODAL GENÉRICO ----------
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black/50 flex items-start sm:items-center justify-center z-50 p-4 overflow-y-auto"
      onClick={handleOverlayClick}
      style={{ overscrollBehavior: "contain" }}
    >
      <div
        className="relative w-full max-w-md min-h-[200px] bg-card text-card-foreground rounded-xl p-6 my-8 sm:my-0"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-muted-foreground hover:text-foreground transition-colors"
        >
          <X className="h-5 w-5" />
        </button>
        {children}
      </div>
    </div>
  );
};

// ---------- HELPERS ----------
const getBrandIcon = (brand) => {
  switch (brand.toLowerCase()) {
    case "visa":
      return <FaCcVisa className="h-6 w-6" />;
    case "mastercard":
      return <FaCcMastercard className="h-6 w-6" />;
    case "american express":
    case "amex":
      return <FaCcAmex className="h-6 w-6" />;
    case "discover":
      return <FaCcDiscover className="h-6 w-6" />;
    default:
      return <CreditCard className="h-6 w-6" />;
  }
};

const getCardId = (card) => card._id || card.id;

const CardInterface = ({
  currentPage,
  setCurrentPage,
  onLogout,
  username,
  Button,
  Input,
  purchaseMode,
  updatePurchaseMode,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [cart, setCart] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menuLayout, setMenuLayout] = useState(
    localStorage.getItem("menuLayout") || "vertical",
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState([]);

  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [isProcessing, setIsProcessing] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [showError, setShowError] = useState(false);
  const [purchaseStatus, setPurchaseStatus] = useState("idle");
  const [isSmartBuyModalOpen, setIsSmartBuyModalOpen] = useState(false);
  const [similarCards, setSimilarCards] = useState([]);

  const [isQuickBuyModalOpen, setIsQuickBuyModalOpen] = useState(false);
  const [cardToBuy, setCardToBuy] = useState(null);
  const [isProcessingQuickBuy, setIsProcessingQuickBuy] = useState(false);
  const [quickBuyError, setQuickBuyError] = useState(null);
  const [quickBuySuccess, setQuickBuySuccess] = useState(false);

  const [visualizationMode, setVisualizationMode] = useState(null);

  const storeFetchUserSettings = useUserStore(
    (state) => state.fetchUserSettings,
  );

  const { balance, setBalance } = useUserStore();

  // 1) ESTADO E EFFECT PARA CONTROLAR O MODAL
  const [showConsultasModal, setShowConsultasModal] = useState(false);

  useEffect(() => {
    const hasSeenConsultasModal = localStorage.getItem("hasSeenConsultasModal");
    if (!hasSeenConsultasModal) {
      setShowConsultasModal(true);
    }
  }, []);

  function handleCloseConsultasModal() {
    localStorage.setItem("hasSeenConsultasModal", "true");
    setShowConsultasModal(false);
  }

  // ---------- Estados para Modal de Erro/ Sucesso em addToCart ----------
  const [addToCartErrorModalOpen, setAddToCartErrorModalOpen] = useState(false);
  const [addToCartErrorMessage, setAddToCartErrorMessage] = useState("");

  const [addToCartSuccessModalOpen, setAddToCartSuccessModalOpen] =
    useState(false);
  const [addToCartSuccessMessage, setAddToCartSuccessMessage] = useState("");

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "menuLayout") {
        setMenuLayout(e.newValue || "vertical");
      }
    };
    window.addEventListener("storage", handleStorageChange);
    const checkLayoutInterval = setInterval(() => {
      const currentLayout = localStorage.getItem("menuLayout") || "vertical";
      if (currentLayout !== menuLayout) {
        setMenuLayout(currentLayout);
      }
    }, 100);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      clearInterval(checkLayoutInterval);
    };
  }, [menuLayout]);

  const fetchVisualizationMode = async () => {
    try {
      const data = await storeFetchUserSettings();
      setVisualizationMode(data.settings.visualizationMode || "card");
    } catch (error) {
      console.error("Erro ao buscar modo de visualização:", error);
      setVisualizationMode("card");
    }
  };

  useEffect(() => {
    fetchVisualizationMode();
    const interval = setInterval(fetchVisualizationMode, 5000);
    return () => clearInterval(interval);
  }, []);

  const removeCard = (cardId) => {
    setCards((prevCards) =>
      prevCards.filter((card) => getCardId(card) !== cardId),
    );
  };

  const normalizeCart = (cartArray) => {
    return cartArray
      .map((item) => {
        if (typeof item === "string") {
          return item;
        } else if (item._id) {
          return item._id;
        }
        return null;
      })
      .filter(Boolean);
  };

  const fetchCart = async () => {
    try {
      const response = await axiosInstance.get("/cart");
      const cartFromServer = response.data.cart;
      const cartIds = normalizeCart(cartFromServer);
      setCart(cartIds);
      localStorage.setItem("cart", JSON.stringify(cartIds));
    } catch (error) {
      console.error("Erro ao buscar o carrinho:", error);
      toast.error("Erro ao carregar o carrinho. Tente novamente mais tarde.");
    }
  };

  const fetchCards = async (pageNumber = 1) => {
    setLoading(true);
    setError(null);
    try {
      const params = new URLSearchParams();
      params.append("page", pageNumber);
      params.append("limit", 100);

      if (searchQuery) {
        params.append("search", searchQuery);
      }
      if (filters.length > 0) {
        const validFilters = filters.filter((f) => f.value);
        if (validFilters.length > 0) {
          params.append("filters", JSON.stringify(validFilters));
        }
      }

      const response = await axiosInstance.get(
        `/get-cards?${params.toString()}`,
      );
      const fetchedCards = response.data?.cards || [];
      const hasMorePages = fetchedCards.length >= 100;

      setCards((prev) =>
        pageNumber === 1 ? fetchedCards : [...prev, ...fetchedCards],
      );
      setPage(pageNumber);
      setTotalPages(hasMorePages ? pageNumber + 1 : pageNumber);
    } catch (err) {
      console.error("Erro ao buscar cartões:", err);
      setError("Falha ao carregar os cartões.");
      setCards([]);
      setPage(1);
      setTotalPages(1);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (purchaseMode === "cart") {
      fetchCart();
    }
  }, [purchaseMode]);

  useEffect(() => {
    setPage(1);
    fetchCards(1);
  }, [searchQuery, filters]);

  useEffect(() => {
    fetchCards(1);
  }, []);

  const handleLoadMore = () => {
    if (page < totalPages && !loading) {
      fetchCards(page + 1);
    }
  };

  const addToCart = async (card) => {
    try {
      const response = await axiosInstance.get("/cart");
      const serverCart = normalizeCart(response.data.cart);
      setCart(serverCart);
      localStorage.setItem("cart", JSON.stringify(serverCart));

      const cardId = getCardId(card);

      // Se já estiver no carrinho
      if (serverCart.includes(cardId)) {
        setAddToCartErrorMessage(
          `O item com BIN ${card.bin} já está no carrinho.`,
        );
        setAddToCartErrorModalOpen(true);
        return;
      }

      const newCart = [...serverCart, cardId];

      const postResponse = await axiosInstance.post("/cart", {
        cart: newCart,
      });

      if (postResponse.status >= 200 && postResponse.status < 300) {
        const updatedCart = normalizeCart(postResponse.data.cart || newCart);
        setCart(updatedCart);
        localStorage.setItem("cart", JSON.stringify(updatedCart));
        setAddToCartSuccessMessage(
          `Item com BIN ${card.bin} adicionado ao carrinho!`,
        );
        setAddToCartSuccessModalOpen(true);
      } else {
        throw new Error(
          postResponse.data.error || "Erro ao atualizar carrinho",
        );
      }
    } catch (error) {
      console.error("Erro ao atualizar o carrinho:", error);

      if (
        error.response?.data?.error ===
        "Saldo insuficiente para adicionar os itens ao carrinho."
      ) {
        setAddToCartErrorMessage(error.response.data.error);
      } else {
        setAddToCartErrorMessage(
          "Falha ao atualizar o carrinho. Tente novamente mais tarde.",
        );
      }
      setAddToCartErrorModalOpen(true);
    }
  };

  const removeFromCart = async (cardId) => {
    try {
      const response = await axiosInstance.get("/cart");
      const serverCart = normalizeCart(response.data.cart);
      setCart(serverCart);

      const newCart = serverCart.filter((id) => id !== cardId);
      const postResponse = await axiosInstance.post("/cart", {
        cart: newCart,
      });

      if (postResponse.status >= 200 && postResponse.status < 300) {
        const updatedCart = normalizeCart(postResponse.data.cart || newCart);
        setCart(updatedCart);
        localStorage.setItem("cart", JSON.stringify(updatedCart));
        toast.info("Item removido do carrinho.");
      } else {
        const errorMessage = postResponse.data.error || "Erro desconhecido.";
        toast.error(`Erro: ${errorMessage}`);
      }
    } catch (error) {
      console.error("Erro ao atualizar o carrinho:", error);
      toast.error("Erro ao remover o item do carrinho. Tente novamente.");
    }
  };

  const updateCartOnServer = async (newCart) => {
    try {
      await axiosInstance.post("/cart", { cart: newCart });
    } catch (error) {
      console.error("Erro ao atualizar o carrinho:", error);
    }
  };

  const openModal = (card) => {
    setSelectedCard(card);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedCard(null);
    setIsModalOpen(false);
  };

  // ---------- SmartBuy ----------
  const resetSmartBuyStates = () => {
    setIsSmartBuyModalOpen(false);
    setSimilarCards([]);
    setCurrentStep(0);
    setCurrentCardIndex(0);
    setPurchaseStatus("idle");
    setShowError(false);
    setCardToBuy(null);
    setIsProcessing(false);
  };

  const handleSmartBuy = async (card) => {
    try {
      resetSmartBuyStates();
      const similarFilters = [
        { type: "bin", value: card.bin },
        { type: "level", value: card.level },
        { type: "expiry", value: `${card.month}/${card.year}` },
      ];
      const params = new URLSearchParams();
      params.append("filters", JSON.stringify(similarFilters));
      const response = await axiosInstance.get(
        `/get-cards?${params.toString()}`,
      );
      const foundSimilarCards = response.data.cards
        .filter((c) => c._id !== card._id)
        .slice(0, 3);
      setSimilarCards(foundSimilarCards);
      setCardToBuy(card);
      setIsSmartBuyModalOpen(true);
    } catch (error) {
      console.error("Erro ao buscar cartões similares:", error);
      toast.error("Erro ao buscar cartões similares");
    }
  };

  const processSmartBuy = async (originalCard) => {
    setIsProcessing(true);
    setPurchaseStatus("idle");
    setShowError(false);
    setCurrentStep(0);
    setCurrentCardIndex(0);

    const allCards = [originalCard, ...similarCards];

    for (let i = 0; i < allCards.length; i++) {
      setCurrentCardIndex(i);
      setCurrentStep(i);
      setPurchaseStatus("processing");
      setShowError(false);

      try {
        const response = await axiosInstance.post("/purchase", {
          cards: [allCards[i]._id],
        });

        if (response.data.purchased_cards.length > 0) {
          setBalance(response.data.new_balance);
          setPurchaseStatus("success");
          setCards((prevCards) =>
            prevCards.filter((c) => c._id !== allCards[i]._id),
          );
          toast.success("Compra realizada com sucesso!");
          await new Promise((resolve) => setTimeout(resolve, 2000));
          setIsSmartBuyModalOpen(false);
          setCurrentPage("minhas-compras");
          break;
        } else {
          setPurchaseStatus("error");
          setShowError(true);

          if (response.data.failed_cards?.[0]?.error) {
            toast.error(
              `Falha na tentativa: ${response.data.failed_cards[0].error}`,
            );
          }
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }
      } catch (error) {
        setPurchaseStatus("error");
        setShowError(true);
        toast.error(
          error.response?.data?.error || "Erro ao processar a compra",
        );
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    }
    setIsProcessing(false);
    setIsSmartBuyModalOpen(false);
    setPurchaseStatus("idle");
  };

  // ---------- Compra Rápida ----------
  const handleQuickBuy = (card) => {
    setCardToBuy(card);
    setIsQuickBuyModalOpen(true);
    setQuickBuyError(null);
    setQuickBuySuccess(false);
  };

  const confirmQuickBuy = async () => {
    setIsProcessingQuickBuy(true);
    setQuickBuyError(null);
    setQuickBuySuccess(false);
    try {
      const response = await axiosInstance.post("/purchase", {
        cards: [cardToBuy._id],
      });

      if (response.status === 200) {
        const { new_balance, purchased_cards, failed_cards } = response.data;

        if (purchased_cards.includes(cardToBuy._id)) {
          setBalance(new_balance);
          toast.success("Compra realizada com sucesso!");
          setQuickBuySuccess(true);
          setCards((prevCards) =>
            prevCards.filter((c) => c._id !== cardToBuy._id),
          );
          setIsQuickBuyModalOpen(false);
          setCardToBuy(null);
          setCurrentPage("minhas-compras");
        }

        if (failed_cards.some((c) => c.card_id === cardToBuy._id)) {
          const failedCard = failed_cards.find(
            (c) => c.card_id === cardToBuy._id,
          );
          const errorMessage = failedCard
            ? failedCard.error
            : "Erro desconhecido";
          setQuickBuyError(`Erro ao finalizar compra: ${errorMessage}`);
          toast.error(`Erro ao finalizar compra: ${errorMessage}`);
          setCards((prevCards) =>
            prevCards.filter((c) => c._id !== cardToBuy._id),
          );
        }
      } else {
        const { error } = response.data;
        const msg = error || "Erro desconhecido";
        setQuickBuyError(`Erro ao finalizar compra: ${msg}`);
        toast.error(`Erro ao finalizar compra: ${msg}`);
        setCards((prevCards) =>
          prevCards.filter((c) => c._id !== cardToBuy._id),
        );
      }
    } catch (error) {
      console.error("Erro ao processar a compra:", error);
      if (error.response?.data?.error) {
        setQuickBuyError(
          `Erro ao finalizar compra: ${error.response.data.error}`,
        );
        toast.error(`Erro ao finalizar compra: ${error.response.data.error}`);
      } else {
        setQuickBuyError("Ocorreu um erro ao processar sua compra.");
        toast.error("Ocorreu um erro ao processar sua compra.");
      }
      setCards((prevCards) => prevCards.filter((c) => c._id !== cardToBuy._id));
    } finally {
      setIsProcessingQuickBuy(false);
    }
  };

  const shareCardLink = (card) => {
    const cardId = getCardId(card);
    const link = `${window.location.origin}/?card=${cardId}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Link copiado para a área de transferência!");
      })
      .catch((err) => {
        console.error("Falha ao copiar o link: ", err);
      });
  };

  const handleAddBalance = () => {
    setCurrentPage("add-balance");
  };

  const formattedBalance = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(balance || 0);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "cart") {
        const newCart = JSON.parse(event.newValue) || [];
        setCart(newCart);
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div className="min-h-screen bg-background text-foreground flex flex-col lg:flex-row">
      <div
        className={clsx(
          "flex items-center justify-between p-4 lg:hidden",
          menuLayout === "horizontal" && "hidden",
        )}
      >
        <Button variant="outline" size="icon" onClick={() => setMenuOpen(true)}>
          <MenuIcon className="h-6 w-6" />
        </Button>
      </div>

      <div
        className={clsx(
          "flex flex-1",
          menuLayout === "horizontal" && "mt-16",
          menuLayout === "vertical" && "lg:pl-64 2xl:pl-96", // Alterado de pl-72 para pl-96
        )}
      >
        <Menu
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          setCurrentPage={setCurrentPage}
          onLogout={onLogout}
          currentPage={currentPage} // Adicione esta prop
          username={username}
        />

        <div className="flex-1 overflow-y-auto p-4">
          {currentPage === "settings" ? (
            <div className="flex flex-col items-center">
              <UserSettings
                onClose={() => setCurrentPage("main")}
                updatePurchaseMode={updatePurchaseMode}
                currentMode={purchaseMode}
              />
            </div>
          ) : currentPage === "minhas-compras" ? (
            <div className="container mx-auto px-2 sm:px-4">
              <MinhasCompras />
            </div>
          ) : currentPage === "documents" ? (
            <div className="container mx-auto px-2 sm:px-4">
              <Documents setCurrentPage={setCurrentPage} />
            </div>
          ) : currentPage === "history" ? (
            <div className="container mx-auto px-2 sm:px-4">
              <History />
            </div>
          ) : currentPage === "consultas" ? (
            <div className="container mx-auto px-2 sm:px-4">
              <Consultas />
            </div>
          ) : currentPage === "api-suppliers" ? (
            <div className="container mx-auto px-2 sm:px-4">
              <ApiSuppliers setCurrentPage={setCurrentPage} />
            </div>
          ) : currentPage === "hashguard" ? (
            <div className="container mx-auto px-2 sm:px-4">
              <HashGuard setCurrentPage={setCurrentPage} />
            </div>
          ) : currentPage === "marketplace" ? (
            <div className="container mx-auto px-2 sm:px-4">
              <Marketplace setCurrentPage={setCurrentPage} />
            </div>
          ) : currentPage === "buy-logins" ? (
            <div className="container mx-auto px-2 sm:px-4">
              <BuyLogins
                setCurrentPage={setCurrentPage}
                cart={cart}
                setCart={setCart}
              />
            </div>
          ) : currentPage === "finalizar-compra" ? (
            <div className="container mx-auto px-2 sm:px-4">
              <div className="mb-4">
                <Button
                  variant="ghost"
                  onClick={() => setCurrentPage("main")}
                  className="flex items-center"
                >
                  <ArrowLeft className="h-5 w-5 mr-2" />
                  Voltar
                </Button>
              </div>
              <FinalizarCompra
                cart={cart}
                setCart={setCart}
                onClose={() => setCurrentPage("main")}
                username={username}
                updateCartOnServer={updateCartOnServer}
                removeCard={removeCard}
                setCurrentPage={setCurrentPage}
              />
            </div>
          ) : currentPage === "add-balance" ? (
            <PaymentMethods setCurrentPage={setCurrentPage} />
          ) : (
            <div className="container mx-auto px-2 sm:px-4">
              {username && (
                <div className="mb-4">
                  <p className="text-lg font-semibold">
                    Bem-vindo, {username}!
                  </p>
                </div>
              )}

              <div className="mb-6 flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4">
                <div className="w-full lg:w-2/3">
                  <AdvancedSearch
                    onSearchChange={setSearchQuery}
                    onFiltersChange={setFilters}
                    Button={Button}
                    Input={Input}
                    purchaseMode={purchaseMode}
                    cart={cart}
                    cards={cards}
                    removeFromCart={removeFromCart}
                    getCardId={getCardId}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
                <div className="flex gap-2 items-center">
                  <div className="flex items-center bg-secondary rounded-md overflow-hidden">
                    <span className="px-4 py-2 font-semibold text-secondary-foreground">
                      Saldo: {formattedBalance}
                    </span>
                    <Button
                      onClick={handleAddBalance}
                      className="p-2 bg-accent hover:bg-accent-foreground text-accent-foreground hover:text-accent transition-colors rounded-md sm:rounded-l-none"
                      title={`Saldo: ${formattedBalance}`}
                    >
                      <DollarSign className="h-5 w-5" />
                    </Button>
                  </div>
                </div>
              </div>

              {loading && (
                <div className="flex justify-center items-center py-12">
                  <Loader2 className="h-8 w-8 animate-spin" />
                </div>
              )}
              {error && <p className="text-center text-red-500">{error}</p>}

              {visualizationMode === "table" ? (
                <CardTableView
                  cards={cards}
                  purchaseMode={purchaseMode}
                  onShare={shareCardLink}
                  onAddToCart={addToCart}
                  onQuickBuy={handleQuickBuy}
                  onSmartBuy={handleSmartBuy}
                  getBrandIcon={getBrandIcon}
                  openModal={openModal}
                />
              ) : (
                <div className="grid gap-4 sm:gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                  {cards?.length > 0 ? (
                    cards.map((card, index) => (
                      <Card
                        key={index}
                        className="bg-card text-card-foreground cursor-pointer w-full relative overflow-hidden rounded-lg shadow-lg transition-all duration-300 ease-in-out hover:shadow-xl hover:-translate-y-1"
                        onClick={() => openModal(card)}
                      >
                        <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
                          <div className="text-[30rem] transform scale-150 text-gray-300 opacity-10 pointer-events-none">
                            {getBrandIcon(card.vendor)}
                          </div>
                        </div>
                        <div className="relative z-10 p-4 sm:p-6">
                          <CardHeader className="p-0">
                            <CardTitle className="flex items-center justify-between text-base sm:text-lg">
                              <span className="flex items-center">
                                {getBrandIcon(card.vendor)}
                                <span className="ml-2 font-semibold">
                                  BIN: {card.bin}
                                </span>
                              </span>
                              <span className="text-sm md:text-base font-medium bg-gray-200 text-gray-800 px-2 py-1 rounded-full">
                                {card.level}
                              </span>
                            </CardTitle>
                          </CardHeader>
                          <CardContent className="mt-4 p-0">
                            <p className="flex items-center mb-2 text-sm">
                              <User className="h-5 w-5 mr-2 flex-shrink-0" />
                              <span className="truncate">
                                Nome: {card.nome}
                              </span>
                            </p>
                            <p className="flex items-center mb-2 text-sm">
                              <Lock className="h-5 w-5 mr-2 flex-shrink-0" />
                              <span className="truncate">CPF: {card.cpf}</span>
                            </p>
                            <p className="flex items-center mb-2 text-sm">
                              <Calendar className="h-5 w-5 mr-2 flex-shrink-0" />
                              <span>
                                Validade: {card.month}/{card.year}
                              </span>
                            </p>
                            <p className="flex items-center mb-2 text-sm">
                              <Landmark className="h-5 w-5 mr-2 flex-shrink-0" />
                              <span className="truncate">
                                Banco: {card.bank}
                              </span>
                            </p>
                            <p className="flex items-center mb-2 text-sm">
                              <DollarSign className="h-5 w-5 mr-2 flex-shrink-0" />
                              <span className="truncate">
                                Preço: R$ {parseFloat(card.price).toFixed(2)}
                              </span>
                            </p>
                          </CardContent>

                          <CardFooter className="flex justify-between items-center p-0">
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                shareCardLink(card);
                              }}
                              className="h-10 w-10 hover:bg-accent hover:text-accent-foreground rounded-full transition-colors"
                            >
                              <Share2 className="h-5 w-5" />
                              <span className="sr-only">Compartilhar Link</span>
                            </Button>
                            {purchaseMode === "cart" ? (
                              <Button
                                size="icon"
                                className="h-10 w-10 bg-customGreen text-customGreen-foreground rounded-full flex items-center justify-center transition-transform duration-200 hover:bg-customGreen-hover hover:scale-110"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  addToCart(card);
                                }}
                              >
                                <ShoppingCart className="h-5 w-5 animate-pulse" />
                                <span className="sr-only">
                                  Adicionar ao carrinho
                                </span>
                              </Button>
                            ) : purchaseMode === "smart" ? (
                              <Button
                                size="icon"
                                variant="ghost"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSmartBuy(card);
                                }}
                                className="h-10 w-10 hover:bg-accent hover:text-accent-foreground rounded-full transition-colors bg-blue-50"
                              >
                                <Sparkles className="h-5 w-5 text-blue-500" />
                                <span className="sr-only">
                                  Compra Inteligente
                                </span>
                              </Button>
                            ) : (
                              <Button
                                size="icon"
                                variant="ghost"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleQuickBuy(card);
                                }}
                                className="h-10 w-10 hover:bg-accent hover:text-accent-foreground rounded-full transition-colors"
                              >
                                <Zap className="h-5 w-5" />
                                <span className="sr-only">
                                  Compra Instantânea
                                </span>
                              </Button>
                            )}
                          </CardFooter>
                        </div>
                      </Card>
                    ))
                  ) : !loading && !error ? (
                    <p className="col-span-full text-center">
                      Nenhum cartão encontrado.
                    </p>
                  ) : null}
                </div>
              )}

              {!loading && cards?.length > 0 && (
                <div className="flex justify-center mt-6">
                  <Button onClick={handleLoadMore}>Carregar Mais</Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Modal de Detalhes do Card */}
      {selectedCard && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <h2 className="text-2xl font-bold mb-4">Detalhes do Cartão</h2>
          <p className="mb-2">
            <strong>BIN:</strong> {DOMPurify.sanitize(selectedCard.bin)}
          </p>
          <p className="mb-2">
            <strong>Validade:</strong> {DOMPurify.sanitize(selectedCard.month)}/
            {DOMPurify.sanitize(selectedCard.year)}
          </p>
          <p className="mb-2">
            <strong>Vendor:</strong> {DOMPurify.sanitize(selectedCard.vendor)}
          </p>
          <p className="mb-2">
            <strong>Tipo:</strong> {DOMPurify.sanitize(selectedCard.type)}
          </p>
          <p className="mb-2">
            <strong>Nível:</strong> {DOMPurify.sanitize(selectedCard.level)}
          </p>
          <p className="mb-2">
            <strong>Banco:</strong> {DOMPurify.sanitize(selectedCard.bank)}
          </p>
          <p className="mb-2">
            <strong>País:</strong> {DOMPurify.sanitize(selectedCard.country)}
          </p>
          <p className="mb-2">
            <strong>Nome:</strong> {DOMPurify.sanitize(selectedCard.nome)}
          </p>
          <p className="mb-2">
            <strong>CPF:</strong> {DOMPurify.sanitize(selectedCard.cpf)}
          </p>
        </Modal>
      )}

      {/* Modal de Compra Instantânea */}
      {isQuickBuyModalOpen && cardToBuy && (
        <Modal
          isOpen={isQuickBuyModalOpen}
          onClose={() => {
            if (!isProcessingQuickBuy) {
              setIsQuickBuyModalOpen(false);
              setCardToBuy(null);
              setQuickBuyError(null);
              setQuickBuySuccess(false);
            }
          }}
        >
          {!quickBuySuccess && !quickBuyError && (
            <>
              <div className="flex items-center gap-2 mb-4">
                <FileText className="h-5 w-5 shrink-0 text-muted-foreground" />
                <h2 className="text-xl font-semibold leading-none tracking-tight">
                  Resumo da Compra
                </h2>
              </div>
              <Card className="p-6 bg-secondary/30 space-y-4 transition-all duration-500 ease-in-out hover:bg-secondary/40">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <CreditCard className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      BIN
                    </span>
                  </div>
                  <span className="font-medium">
                    {DOMPurify.sanitize(cardToBuy.bin)}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Calendar className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      Validade
                    </span>
                  </div>
                  <span className="font-medium">
                    {DOMPurify.sanitize(cardToBuy.month)}/
                    {DOMPurify.sanitize(cardToBuy.year)}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <CreditCard className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      Vendor
                    </span>
                  </div>
                  <span className="font-medium">
                    {DOMPurify.sanitize(cardToBuy.vendor)}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <FileText className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      Tipo
                    </span>
                  </div>
                  <span className="font-medium">
                    {DOMPurify.sanitize(cardToBuy.type)}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Award className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      Nível
                    </span>
                  </div>
                  <span className="font-medium">
                    {DOMPurify.sanitize(cardToBuy.level)}
                  </span>
                </div>
                <div className="flex items-center justify-between min-w-0">
                  <div className="flex items-center gap-2 flex-shrink-0">
                    <Landmark className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      Banco
                    </span>
                  </div>
                  <span
                    className="
                                          font-medium
                                          overflow-hidden
                                          text-ellipsis
                                          whitespace-nowrap
                                          min-w-0
                                          max-w-[160px]
                                          inline-block
                                          text-right
                                          ml-2
                                        "
                  >
                    {DOMPurify.sanitize(cardToBuy.bank)}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Globe className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      País
                    </span>
                  </div>
                  <span className="font-medium">
                    {DOMPurify.sanitize(cardToBuy.country)}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <User className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      Nome
                    </span>
                  </div>
                  <span className="font-medium">
                    {DOMPurify.sanitize(cardToBuy.nome)}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Hash className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      CPF
                    </span>
                  </div>
                  <span className="font-medium">
                    {DOMPurify.sanitize(cardToBuy.cpf)}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <DollarSign className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      Preço
                    </span>
                  </div>
                  <span className="font-medium">
                    R$ {parseFloat(cardToBuy.price).toFixed(2)}
                  </span>
                </div>
              </Card>

              {isProcessingQuickBuy && (
                <p className="mt-4 text-blue-600">Processando compra...</p>
              )}
              <div className="flex justify-end mt-4">
                {balance < parseFloat(cardToBuy.price) ? (
                  <Button
                    variant="default"
                    onClick={() => {
                      setIsQuickBuyModalOpen(false);
                      setCurrentPage("add-balance");
                    }}
                  >
                    Adicionar Saldo
                  </Button>
                ) : (
                  <Button
                    variant="default"
                    onClick={confirmQuickBuy}
                    disabled={isProcessingQuickBuy}
                  >
                    Confirmar Compra
                  </Button>
                )}
              </div>
            </>
          )}

          {quickBuySuccess && (
            <>
              <h2 className="text-2xl font-bold mb-4">Compra Realizada</h2>
              <p className="mb-2">
                A compra do cartão com BIN {DOMPurify.sanitize(cardToBuy.bin)}{" "}
                foi realizada com sucesso!
              </p>
              <div className="flex justify-end mt-4">
                <Button
                  variant="default"
                  onClick={() => {
                    setIsQuickBuyModalOpen(false);
                    setCardToBuy(null);
                    setQuickBuyError(null);
                    setQuickBuySuccess(false);
                  }}
                >
                  Fechar
                </Button>
              </div>
            </>
          )}

          {quickBuyError && (
            <>
              <div className="flex items-center gap-2 mb-4">
                <XCircle className="h-5 w-5 text-destructive" />
                <h2 className="text-xl font-semibold leading-none tracking-tight text-destructive">
                  Erro na Compra
                </h2>
              </div>
              <p className="mb-4">{quickBuyError}</p>
              <Card className="p-6 bg-secondary/30 space-y-4 hover:shadow-none hover:translate-y-0 transition-none">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <CreditCard className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      BIN
                    </span>
                  </div>
                  <span className="font-medium">
                    {DOMPurify.sanitize(cardToBuy.bin)}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Calendar className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      Validade
                    </span>
                  </div>
                  <span className="font-medium">
                    {DOMPurify.sanitize(cardToBuy.month)}/
                    {DOMPurify.sanitize(cardToBuy.year)}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <CreditCard className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      Vendor
                    </span>
                  </div>
                  <span className="font-medium">
                    {DOMPurify.sanitize(cardToBuy.vendor)}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <FileText className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      Tipo
                    </span>
                  </div>
                  <span className="font-medium">
                    {DOMPurify.sanitize(cardToBuy.type)}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Award className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      Nível
                    </span>
                  </div>
                  <span className="font-medium">
                    {DOMPurify.sanitize(cardToBuy.level)}
                  </span>
                </div>
                <div className="flex items-center justify-between min-w-0">
                  <div className="flex items-center gap-2 flex-shrink-0">
                    <Landmark className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      Banco
                    </span>
                  </div>
                  <span
                    className="
                                          font-medium
                                          overflow-hidden
                                          text-ellipsis
                                          whitespace-nowrap
                                          min-w-0
                                          max-w-[160px]
                                          inline-block
                                          text-right
                                          ml-2
                                        "
                  >
                    {DOMPurify.sanitize(cardToBuy.bank)}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Globe className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      País
                    </span>
                  </div>
                  <span className="font-medium">
                    {DOMPurify.sanitize(cardToBuy.country)}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <User className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      Nome
                    </span>
                  </div>
                  <span className="font-medium">
                    {DOMPurify.sanitize(cardToBuy.nome)}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Hash className="h-4 w-4 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground flex-shrink-0">
                      CPF
                    </span>
                  </div>
                  <span className="font-medium">
                    {DOMPurify.sanitize(cardToBuy.cpf)}
                  </span>
                </div>
              </Card>
              <div className="flex justify-end mt-4">
                <Button
                  variant="outline"
                  onClick={() => {
                    setIsQuickBuyModalOpen(false);
                    setCardToBuy(null);
                    setQuickBuyError(null);
                    setQuickBuySuccess(false);
                  }}
                  disabled={isProcessingQuickBuy}
                >
                  Fechar
                </Button>
              </div>
            </>
          )}
        </Modal>
      )}

      {/* Modal de Compra Inteligente (SmartBuy) */}
      <SmartBuyModal
        isOpen={isSmartBuyModalOpen}
        onClose={resetSmartBuyStates}
        originalCard={cardToBuy}
        similarCards={similarCards}
        currentStep={currentStep}
        currentCardIndex={currentCardIndex}
        purchaseStatus={purchaseStatus}
        showError={showError}
        onConfirm={processSmartBuy}
      />

      {/* Modal de ERRO em addToCart */}
      <Modal
        isOpen={addToCartErrorModalOpen}
        onClose={() => setAddToCartErrorModalOpen(false)}
      >
        <h2 className="text-2xl font-bold mb-4">Erro ao Adicionar</h2>
        <p className="mb-4">{addToCartErrorMessage}</p>
        <div className="flex justify-end">
          <Button
            variant="default"
            onClick={() => setAddToCartErrorModalOpen(false)}
          >
            Fechar
          </Button>
        </div>
      </Modal>

      {/* Modal de SUCESSO em addToCart */}
      <Modal
        isOpen={addToCartSuccessModalOpen}
        onClose={() => setAddToCartSuccessModalOpen(false)}
      >
        <h2 className="text-2xl font-bold mb-4">Item Adicionado</h2>
        <p className="mb-4">{addToCartSuccessMessage}</p>
        <div className="flex justify-end">
          <Button
            variant="default"
            onClick={() => setAddToCartSuccessModalOpen(false)}
          >
            Fechar
          </Button>
        </div>
      </Modal>

      <Modal isOpen={showConsultasModal} onClose={handleCloseConsultasModal}>
        <h2 className="text-xl font-bold mb-4">Consultas Disponíveis</h2>
        <p className="mb-4">
          Agora temos consultas de dados disponíveis! Clique no botão abaixo
          para conferir.
        </p>
        {/* Botão de fechar (x) já está no Modal, pois chamamos onClose ao clicar. */}

        <div className="flex justify-end mt-4 gap-2">
          <Button
            onClick={() => {
              // Fecha modal e vai para "consultas"
              handleCloseConsultasModal();
              setCurrentPage("consultas");
            }}
          >
            Consultas
          </Button>
        </div>
      </Modal>
    </div>
  );
};

// ---------- SMART BUY MODAL ----------
const SmartBuyModal = ({
  isOpen,
  onClose,
  originalCard,
  similarCards = [],
  currentStep,
  currentCardIndex,
  purchaseStatus,
  showError,
  onConfirm,
}) => {
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setHasConfirmed(false);
      setIsProcessing(false);
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      setHasConfirmed(false);
      setIsProcessing(false);
    };
  }, []);

  if (!isOpen || !originalCard) return null;

  const allCards = [originalCard, ...similarCards];
  const currentCard = allCards[currentCardIndex];
  const isLastAttempt = currentStep === allCards.length - 1;
  const allAttemptsFailed = isLastAttempt && purchaseStatus === "error";

  const handleConfirm = () => {
    setHasConfirmed(true);
    setIsProcessing(true);
    onConfirm(originalCard);
  };

  const handleClose = () => {
    if (!isProcessing) {
      setHasConfirmed(false);
      setIsProcessing(false);
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 p-3 md:p-4">
      <div className="fixed inset-0 bg-black/80" onClick={handleClose} />
      <div className="relative bg-card w-full max-w-lg rounded-lg overflow-y-auto max-h-[90vh] md:max-h-[85vh]">
        {!hasConfirmed ? (
          <div className="flex flex-col">
            <div className="bg-background p-4 md:p-6 flex items-start justify-between sticky top-0 z-10">
              <div className="flex items-center gap-2 md:gap-3">
                <Sparkles className="w-5 h-5 md:w-6 md:h-6 text-primary" />
                <div>
                  <h2 className="text-lg md:text-xl font-semibold text-card-foreground">
                    Sistema Smart Buy
                  </h2>
                  <p className="text-xs md:text-sm text-muted-foreground">
                    Sistema avançado de compra inteligente
                  </p>
                </div>
              </div>
              <button
                onClick={handleClose}
                className="text-muted-foreground hover:text-card-foreground"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <div className="p-4 md:p-6 space-y-4 md:space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div className="bg-background rounded-lg p-3 md:p-4">
                  <div className="flex gap-2 md:gap-3 items-start">
                    <Sparkles className="w-5 h-5 text-primary shrink-0" />
                    <div>
                      <h3 className="font-medium text-sm md:text-base text-card-foreground">
                        Processo Inteligente
                      </h3>
                      <p className="text-xs md:text-sm text-muted-foreground">
                        Checagem automática de até {similarCards.length} cartões
                        similares
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-background rounded-lg p-3 md:p-4">
                  <div className="flex gap-2 md:gap-3 items-start">
                    <CheckCircle className="w-5 h-5 text-primary shrink-0" />
                    <div>
                      <h3 className="font-medium text-sm md:text-base text-card-foreground">
                        Garantia de Eficiência
                      </h3>
                      <p className="text-xs md:text-sm text-muted-foreground">
                        Processo otimizado com pausa automática
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-background rounded-lg p-3 md:p-4">
                <h3 className="text-xs md:text-sm font-medium text-muted-foreground mb-3 md:mb-4">
                  Cartão Principal
                </h3>
                <div className="grid grid-cols-2 gap-x-4 md:gap-x-12 gap-y-3 md:gap-y-4">
                  <div className="flex items-center gap-2">
                    <CreditCard className="w-4 h-4 md:w-5 md:h-5 text-muted-foreground shrink-0" />
                    <div>
                      <p className="text-xs text-muted-foreground">BIN</p>
                      <p className="text-sm md:text-base font-medium text-card-foreground">
                        {originalCard.bin}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <Calendar className="w-4 h-4 md:w-5 md:h-5 text-muted-foreground shrink-0" />
                    <div>
                      <p className="text-xs text-muted-foreground">Validade</p>
                      <p className="text-sm md:text-base font-medium text-card-foreground">
                        {originalCard.month}/{originalCard.year}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <Award className="w-4 h-4 md:w-5 md:h-5 text-muted-foreground shrink-0" />
                    <div>
                      <p className="text-xs text-muted-foreground">Nível</p>
                      <p className="text-sm md:text-base font-medium text-card-foreground">
                        {originalCard.level}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <Landmark className="w-4 h-4 md:w-5 md:h-5 text-muted-foreground shrink-0" />
                    <div>
                      <p className="text-xs text-muted-foreground">Banco</p>
                      <p className="text-sm md:text-base font-medium text-card-foreground truncate max-w-[120px] md:max-w-[180px]">
                        {originalCard.bank}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {similarCards?.length > 0 && (
                <div className="bg-background rounded-lg p-3 md:p-4 flex items-start gap-2 md:gap-3">
                  <AlertTriangle className="w-4 h-4 md:w-5 md:h-5 text-primary shrink-0" />
                  <div>
                    <h4 className="text-sm md:text-base font-medium text-card-foreground">
                      {similarCards.length} Cartões Alternativos
                    </h4>
                    <p className="text-xs md:text-sm text-muted-foreground mt-1">
                      Cartões similares serão testados automaticamente
                    </p>
                  </div>
                </div>
              )}

              <div className="flex justify-end gap-2 md:gap-3 pt-4 border-t border-border">
                <Button
                  variant="ghost"
                  onClick={handleClose}
                  className="text-sm"
                >
                  Cancelar
                </Button>
                <Button onClick={handleConfirm} className="gap-2 text-sm">
                  <Sparkles className="w-4 h-4" />
                  Iniciar Smart Buy
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-4 md:p-6 space-y-4 md:space-y-6">
            <div className="flex flex-col items-center">
              <div className="w-14 h-14 md:w-16 md:h-16 rounded-full bg-background flex items-center justify-center mb-3 md:mb-4">
                {purchaseStatus === "success" ? (
                  <CheckCircle className="w-7 h-7 md:w-8 md:h-8 text-customGreen" />
                ) : allAttemptsFailed ? (
                  <XCircle className="w-7 h-7 md:w-8 md:h-8 text-destructive" />
                ) : (
                  <Loader2 className="w-7 h-7 md:w-8 md:h-8 text-primary animate-spin" />
                )}
              </div>
              <h3 className="text-base md:text-lg font-medium text-center mb-4 text-card-foreground px-2">
                {purchaseStatus === "success"
                  ? "Compra Realizada com Sucesso!"
                  : allAttemptsFailed
                    ? "Todas as tentativas foram realizadas"
                    : purchaseStatus === "error"
                      ? "Buscando próximo cartão..."
                      : "Processando transação..."}
              </h3>
              <div className="w-full max-w-xs md:max-w-sm">
                <div className="h-2 w-full bg-background rounded-full overflow-hidden">
                  <div
                    className={`h-full transition-all duration-500 rounded-full ${
                      purchaseStatus === "success"
                        ? "bg-customGreen"
                        : allAttemptsFailed
                          ? "bg-destructive"
                          : "bg-primary"
                    }`}
                    style={{
                      width: `${((currentStep + 1) * 100) / allCards.length}%`,
                    }}
                  />
                </div>
                <p className="text-xs md:text-sm text-center text-muted-foreground mt-2">
                  {purchaseStatus === "success"
                    ? "Smart Buy finalizado com sucesso!"
                    : allAttemptsFailed
                      ? "Processo finalizado"
                      : `Tentativa ${currentStep + 1} de ${allCards.length}`}
                </p>
              </div>
            </div>

            {currentCard && (
              <div className="bg-background rounded-lg p-3 md:p-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <CreditCard className="w-4 h-4 md:w-5 md:h-5 text-muted-foreground" />
                    <div>
                      <h4 className="text-sm md:text-base font-medium text-card-foreground flex items-center gap-2">
                        {currentCard.bin}
                        {showError && (
                          <XCircle className="w-4 h-4 text-destructive" />
                        )}
                        {purchaseStatus === "success" && (
                          <CheckCircle className="w-4 h-4 text-customGreen" />
                        )}
                      </h4>
                      <p className="text-xs md:text-sm text-muted-foreground">
                        Válido até {currentCard.month}/{currentCard.year}
                      </p>
                    </div>
                  </div>
                  <div
                    className={`px-2 md:px-3 py-1 rounded-full text-xs font-medium ${
                      purchaseStatus === "success"
                        ? "bg-background/50 text-customGreen"
                        : showError
                          ? "bg-background/50 text-destructive"
                          : "bg-background/50 text-primary"
                    }`}
                  >
                    {purchaseStatus === "success"
                      ? "Aprovado"
                      : showError
                        ? "Falhou"
                        : "Processando"}
                  </div>
                </div>
              </div>
            )}

            {(allAttemptsFailed || purchaseStatus === "success") && (
              <div className="flex justify-center">
                <Button
                  onClick={handleClose}
                  variant="outline"
                  className="w-full max-w-[160px] md:max-w-[200px] text-sm"
                >
                  Fechar
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

// ---------- SHARED CARD MODAL ----------
const SharedCardModal = ({ cardId, onClose }) => {
  const [card, setCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchCardDetails = async () => {
      try {
        const response = await axiosInstance.get(`/card/${cardId}`);
        setCard(response.data);
      } catch (err) {
        console.error("Erro ao obter detalhes do cartão:", err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchCardDetails();
  }, [cardId]);

  if (loading || error || !card) return null;

  return (
    <Modal isOpen={true} onClose={onClose}>
      <h2 className="text-2xl font-bold mb-4">
        Detalhes do Cartão Compartilhado
      </h2>
      <div className="mb-2">
        <strong>BIN:</strong> {DOMPurify.sanitize(card.bin)}
      </div>
      <div className="mb-2">
        <strong>Validade:</strong> {DOMPurify.sanitize(card.month)}/
        {DOMPurify.sanitize(card.year)}
      </div>
      <div className="mb-2">
        <strong>Vendor:</strong> {DOMPurify.sanitize(card.vendor)}
      </div>
      <div className="mb-2">
        <strong>Tipo:</strong> {DOMPurify.sanitize(card.type)}
      </div>
      <div className="mb-2">
        <strong>Nível:</strong> {DOMPurify.sanitize(card.level)}
      </div>
      <div className="mb-2">
        <strong>Banco:</strong> {DOMPurify.sanitize(card.bank)}
      </div>
      <div className="mb-2">
        <strong>País:</strong> {DOMPurify.sanitize(card.country)}
      </div>
      <div className="mb-2">
        <strong>Nome:</strong> {DOMPurify.sanitize(card.nome)}
      </div>
      <div className="mb-2">
        <strong>CPF:</strong> {DOMPurify.sanitize(card.cpf)}
      </div>
      <Button variant="default" onClick={onClose} className="mt-4">
        Fechar
      </Button>
    </Modal>
  );
};

// ---------- MAIN CONTENT ----------
const MainContent = ({
  currentPage,
  setCurrentPage,
  onLogout,
  username,
  purchaseMode,
  updatePurchaseMode,
}) => {
  const location = useLocation();
  const [sharedCardId, setSharedCardId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { fetchBalance } = useUserStore();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const cardId = params.get("card");
    if (cardId) {
      setSharedCardId(cardId);
      setIsModalOpen(true);
    } else {
      setSharedCardId(null);
      setIsModalOpen(false);
    }
  }, [location.search]);

  return (
    <>
      <CardInterface
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        onLogout={onLogout}
        username={username}
        Button={Button}
        Input={Input}
        purchaseMode={purchaseMode}
        updatePurchaseMode={updatePurchaseMode}
      />
      {sharedCardId && isModalOpen && (
        <SharedCardModal
          cardId={sharedCardId}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

// ---------- APP ----------
const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [currentPage, setCurrentPage] = useState("main");
  const [username, setUsername] = useState(null);
  const [purchaseMode, setPurchaseMode] = useState(null);
  const [appLoading, setAppLoading] = useState(true);

  const { balance, setBalance, fetchBalance } = useUserStore();

  const { fetchUserData } = useUserStore();

  useEffect(() => {
    if (isAuthenticated) {
      const initializeUserData = async () => {
        const data = await fetchUserData();
        if (data.success) {
          setPurchaseMode(data.purchaseMode);
        }
      };
      initializeUserData();
    } else {
      setPurchaseMode("cart");
      setBalance(0);
      setCurrentPage("main");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    checkAuthentication();
    const interval = setInterval(checkAuthentication, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const checkAuthentication = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setIsAuthenticated(false);
      setUsername(null);
      setAppLoading(false);
      return;
    }
    try {
      const decoded = jwtDecode(token);
      if (decoded.exp * 1000 < Date.now()) {
        localStorage.removeItem("token");
        setAuthToken(null);
        setIsAuthenticated(false);
        setUsername(null);
        setAppLoading(false);
        return;
      }
      setAuthToken(token);
      setIsAuthenticated(true);
      setUsername(decoded.user);
    } catch (error) {
      console.error("Erro ao verificar autenticação:", error);
      localStorage.removeItem("token");
      setAuthToken(null);
      setIsAuthenticated(false);
      setUsername(null);
    }
    setAppLoading(false);
  };

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUsername(decoded.user);
        setAuthToken(token);
      } catch (error) {
        console.error("Erro ao decodificar o token:", error);
        setUsername(null);
        setAuthToken(null);
      }
    }
    setCurrentPage("main");
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    setUsername(null);
    setCurrentPage("main");
    setAuthToken(null);
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserData();
    } else {
      setPurchaseMode("cart");
      setBalance(0);
      setCurrentPage("main");
    }
  }, [isAuthenticated]);

  function decryptAES(ciphertext, iv) {
    try {
      const SECRET_KEY_BASE64 = "oKXzeNzPfkTVniZNaOt5RA==";
      const key = CryptoJS.enc.Base64.parse(SECRET_KEY_BASE64);
      const ivWordArray = CryptoJS.enc.Base64.parse(iv);
      const encryptedWordArray = CryptoJS.enc.Base64.parse(ciphertext);

      // Validate input lengths
      if (!ivWordArray || ivWordArray.sigBytes !== 16) {
        throw new Error("Invalid IV length");
      }

      const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: encryptedWordArray },
        key,
        {
          iv: ivWordArray,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        },
      );

      // Check if decryption was successful
      const decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);
      if (!decryptedStr) {
        throw new Error("Decryption resulted in empty string");
      }

      // Verify the result is valid UTF-8
      if (!isValidUTF8(decryptedStr)) {
        throw new Error("Decrypted data is not valid UTF-8");
      }

      return decryptedStr;
    } catch (error) {
      console.error("Decryption error:", error);
      throw error;
    }
  }

  // Helper function to check if a string is valid UTF-8
  function isValidUTF8(str) {
    try {
      return Boolean(str && decodeURIComponent(escape(str)));
    } catch {
      return false;
    }
  }

  const updatePurchaseMode = (mode) => {
    setPurchaseMode(mode);
    localStorage.setItem("purchaseMode", mode);
  };

  if (appLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Carregando...
      </div>
    );
  }

  return (
    <Router>
      <div className="app-container">
        {isAuthenticated ? (
          <MainContent
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onLogout={handleLogout}
            username={username}
            purchaseMode={purchaseMode}
            updatePurchaseMode={updatePurchaseMode}
          />
        ) : (
          <AstraShopLogin onLoginSuccess={handleLoginSuccess} />
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        style={{
          top: "7rem",
          right: "1rem",
        }}
      />
    </Router>
  );
};

export default App;

// Menu.js
import React, { useState, useEffect, useRef, forwardRef } from "react";
import clsx from "clsx";
import {
  Home,
  Settings,
  LogOut,
  ShoppingBag,
  DollarSign,
  LayoutPanelLeft,
  LayoutPanelTop,
  Hash,
  FileText,
  CreditCard,
  User as UserIcon,
  Search as SearchIcon,
  IdCard,
  ChevronDown,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import axiosInstance from "./axiosConfig";
import { useUserStore } from "./store";

// Botão básico
const Button = forwardRef(
  ({ className, variant = "default", size = "default", ...props }, ref) => {
    const classes = clsx(
      "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors",
      "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2",
      "disabled:pointer-events-none disabled:opacity-50",
      {
        "bg-primary text-primary-foreground hover:bg-primary/90":
          variant === "default",
        "bg-destructive text-destructive-foreground hover:bg-destructive/90":
          variant === "destructive",
        "border border-input bg-background hover:bg-accent hover:text-accent-foreground":
          variant === "outline",
        "bg-secondary text-secondary-foreground hover:bg-secondary/80":
          variant === "secondary",
        "hover:bg-accent hover:text-accent-foreground": variant === "ghost",
        "text-primary underline-offset-4 hover:underline": variant === "link",
      },
      {
        "h-9 px-4 py-2": size === "default",
        "h-8 rounded-md px-3": size === "sm",
        "h-10 rounded-md px-8": size === "lg",
        "h-9 w-9": size === "icon",
        "h-6 w-6 p-0.5": size === "compact",
      },
      className,
    );
    return <button className={classes} ref={ref} {...props} />;
  },
);
Button.displayName = "Button";

// Dropdown do usuário
const UserDropdown = ({ username, balance, profileImage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative flex items-center flex-shrink-0" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="relative group flex items-center"
      >
        <div className="relative">
          <img
            src={profileImage || "/profile.png"}
            alt="Perfil"
            className="w-6 h-6 rounded-full transition-transform group-hover:scale-105"
          />
          <div className="absolute bottom-0 right-0 w-3 h-3 rounded-full bg-zinc-900/95 flex items-center justify-center">
            <ChevronDown
              className={clsx(
                "h-2 w-2 text-zinc-400 transition-transform",
                isOpen && "rotate-180",
              )}
            />
          </div>
        </div>
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 15 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.15 }}
            className="absolute right-0 top-full w-48 rounded-lg bg-[#18181B]/90 backdrop-blur-md shadow-lg border border-zinc-800/50 py-2 px-3 mt-2"
          >
            <div className="space-y-2">
              <p className="text-sm text-zinc-100">{username}</p>
              <div className="flex items-center gap-1 text-zinc-400">
                <DollarSign className="h-3 w-3" />
                <p className="text-xs">R$ {balance.toFixed(2)}</p>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const Menu = ({
  menuOpen,
  setMenuOpen,
  setCurrentPage,
  currentPage,
  onLogout,
  username,
}) => {
  const [profileImage, setProfileImage] = useState("");
  const [menuLayout, setMenuLayout] = useState(() => {
    return localStorage.getItem("menuLayout") || "vertical";
  });

  const menuRef = useRef(null);
  const dockRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const { balance, fetchUserBalance } = useUserStore();

  // Atualiza saldo em loop
  useEffect(() => {
    fetchUserBalance();
    const interval = setInterval(fetchUserBalance, 5000);
    return () => clearInterval(interval);
  }, [fetchUserBalance]);

  // Busca imagem do perfil
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axiosInstance.get("/user-info");
        const { user_info } = response.data;
        if (user_info) setProfileImage(user_info.profile_image);
      } catch (error) {
        console.error("Erro ao buscar informações do usuário:", error);
      }
    };
    if (username) fetchUserInfo();
  }, [username]);

  // Bloqueia scroll em layout vertical
  useEffect(() => {
    if (menuOpen && menuLayout === "vertical") {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflow = "";
      document.body.style.height = "";
    }
    return () => {
      document.body.style.overflow = "";
      document.body.style.height = "";
    };
  }, [menuOpen, menuLayout]);

  const isItemActive = (itemPage) => currentPage === itemPage;

  let menuItems = [
    {
      icon: <Home className="h-4 w-4" />,
      text: "Início",
      page: "main",
      onClick: () => {
        setCurrentPage("main");
        setMenuOpen(false);
      },
    },
    {
      icon: <DollarSign className="h-4 w-4" />,
      text: "Adicionar saldo",
      page: "add-balance",
      onClick: () => {
        setCurrentPage("add-balance");
        setMenuOpen(false);
      },
    },
    {
      icon: <SearchIcon className="h-4 w-4" />,
      text: "Consultas",
      page: "consultas",
      onClick: () => {
        setCurrentPage("consultas");
        setMenuOpen(false);
      },
    },
    {
      icon: <IdCard className="h-4 w-4" />,
      text: "Documentos",
      page: "documents",
      onClick: () => {
        setCurrentPage("documents");
        setMenuOpen(false);
      },
    },
    {
      icon: <ShoppingBag className="h-4 w-4" />,
      text: "Minhas Compras",
      page: "minhas-compras",
      onClick: () => {
        setCurrentPage("minhas-compras");
        setMenuOpen(false);
      },
    },
    {
      icon: <Settings className="h-4 w-4" />,
      text: "Configurações",
      page: "settings",
      onClick: () => {
        setCurrentPage("settings");
        setMenuOpen(false);
      },
    },
  ];

  // Apenas para Belfort e fenrir
  if (["Belfort", "fenrir", "lalao"].includes(username)) {
    menuItems.splice(
      3,
      0,
      {
        icon: <Hash className="h-4 w-4" />,
        text: "HashGuard",
        page: "hashguard",
        onClick: () => {
          setCurrentPage("hashguard");
          setMenuOpen(false);
        },
      },
      {
        icon: <FileText className="h-4 w-4" />,
        text: "Marketplace",
        page: "marketplace",
        onClick: () => {
          setCurrentPage("marketplace");
          setMenuOpen(false);
        },
      },
      {
        icon: <UserIcon className="h-4 w-4" />,
        text: "Comprar Logins",
        page: "buy-logins",
        onClick: () => {
          setCurrentPage("buy-logins");
          setMenuOpen(false);
        },
      },
      {
        icon: <FileText className="h-4 w-4" />,
        text: "APIs Fornecedor",
        onClick: () => {
          setCurrentPage("api-suppliers");
          setMenuOpen(false);
        },
      },
    );
  }

  // Somente para Doom1: Consultas e APIs Fornecedor
  if (username === "NotKnown") {
    menuItems.splice(
      3,
      0,
      {
        icon: <SearchIcon className="h-4 w-4" />,
        text: "Consultas",
        page: "consultas",
        onClick: () => {
          setCurrentPage("consultas");
          setMenuOpen(false);
        },
      },
      {
        icon: <FileText className="h-4 w-4" />,
        text: "APIs Fornecedor",
        onClick: () => {
          setCurrentPage("api-suppliers");
          setMenuOpen(false);
        },
      },
    );
  }

  // Sair
  menuItems.push({
    icon: <LogOut className="h-4 w-4" />,
    text: "Sair",
    page: "logout",
    onClick: () => {
      onLogout();
      setMenuOpen(false);
    },
  });

  // Drag horizontal
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - dockRef.current.offsetLeft);
    setScrollLeft(dockRef.current.scrollLeft);
  };
  const handleMouseUp = () => setIsDragging(false);
  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - dockRef.current.offsetLeft;
    const walk = x - startX;
    dockRef.current.scrollLeft = scrollLeft - walk;
  };
  const calculateScale = () => 1;

  const menuClasses = clsx(
    menuLayout === "vertical" && [
      "bg-secondary shadow-lg transition-all duration-300 ease-in-out w-64 2xl:w-72 h-screen",
      menuOpen ? "translate-x-0" : "-translate-x-full",
      "z-40 fixed left-0 top-0 lg:fixed lg:translate-x-0 lg:flex-shrink-0 overflow-y-auto",
    ],
  );

  const topLineVariants = {
    closed: { y: -6, rotate: 0 },
    open: { y: 0, rotate: 45 },
  };
  const middleLineVariants = {
    closed: { opacity: 1 },
    open: { opacity: 0 },
  };
  const bottomLineVariants = {
    closed: { y: 6, rotate: 0 },
    open: { y: 0, rotate: -45 },
  };

  // Remove scrollbar
  const style = document.createElement("style");
  style.textContent = `
    .hide-scrollbar::-webkit-scrollbar {
      display: none;
    }
    .hide-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-y: hidden !important;
      overscroll-behavior-y: none;
      touch-action: pan-x;
    }
  `;
  document.head.appendChild(style);

  return (
    <>
      {/* Overlay no layout vertical */}
      {menuOpen && menuLayout === "vertical" && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-30 z-30"
          style={{ pointerEvents: "auto" }}
          onClick={() => setMenuOpen(false)}
        />
      )}

      {/* Layout Vertical */}
      {menuLayout === "vertical" ? (
        <div ref={menuRef} className={menuClasses}>
          <div className="h-full flex flex-col">
            <div className="p-6 border-b border-border relative">
              <div className="flex items-center justify-between relative z-10">
                <h2 className="text-2xl font-bold">Menu</h2>
                <div className="flex items-center gap-2">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => {
                      setMenuLayout("horizontal");
                      localStorage.setItem("menuLayout", "horizontal");
                    }}
                    className="text-foreground"
                  >
                    <LayoutPanelTop className="h-5 w-5" />
                  </Button>
                  <button
                    onClick={() => setMenuOpen(!menuOpen)}
                    className="relative w-6 h-6 flex items-center justify-center lg:hidden text-foreground"
                  >
                    <motion.span
                      className="absolute block w-full h-[2px] bg-current"
                      style={{
                        transformOrigin: "center center",
                      }}
                      initial="closed"
                      animate={menuOpen ? "open" : "closed"}
                      variants={topLineVariants}
                      transition={{
                        type: "spring",
                        stiffness: 300,
                        damping: 20,
                      }}
                    />
                    <motion.span
                      className="absolute block w-full h-[2px] bg-current"
                      style={{
                        transformOrigin: "center center",
                      }}
                      initial="closed"
                      animate={menuOpen ? "open" : "closed"}
                      variants={middleLineVariants}
                      transition={{ duration: 0.3 }}
                    />
                    <motion.span
                      className="absolute block w-full h-[2px] bg-current"
                      style={{
                        transformOrigin: "center center",
                      }}
                      initial="closed"
                      animate={menuOpen ? "open" : "closed"}
                      variants={bottomLineVariants}
                      transition={{
                        type: "spring",
                        stiffness: 300,
                        damping: 20,
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>

            <nav className="flex-1 overflow-y-auto p-4">
              <ul className="space-y-2">
                {menuItems.map((item, index) => (
                  <li key={index}>
                    <button
                      onClick={item.onClick}
                      className={clsx(
                        "flex items-center gap-3 text-sm w-full p-3 rounded-md transition-colors",
                        isItemActive(item.page)
                          ? "bg-primary text-primary-foreground"
                          : "hover:bg-accent hover:text-accent-foreground",
                      )}
                    >
                      <span className="flex items-center justify-center">
                        {item.icon}
                      </span>
                      <span className="whitespace-nowrap">{item.text}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </nav>

            {/* Rodapé vertical */}
            <div className="mt-auto p-6 border-t border-border">
              <div className="flex items-center gap-4">
                <img
                  src={profileImage || "/profile.png"}
                  alt="Perfil"
                  className="w-12 h-12 rounded-full"
                />
                <div className="flex-1">
                  <p className="font-semibold">{username}</p>
                  <p className="text-sm text-gray-400">
                    R$ {balance.toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // Layout Horizontal (dock)
        <div className="fixed top-4 left-1/2 -translate-x-1/2 w-[95%] sm:w-auto max-w-5xl z-50">
          <div className="bg-[#18181B]/90 backdrop-blur-md rounded-full py-2 px-4 shadow-lg">
            <div
              ref={dockRef}
              className="flex items-center"
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseUp}
            >
              <div className="flex items-center overflow-x-auto overflow-y-hidden hide-scrollbar px-2 sm:px-4">
                <div className="flex items-center space-x-3">
                  {menuItems.map((item, index) => (
                    <button
                      key={index}
                      onClick={item.onClick}
                      className={clsx(
                        "flex flex-col items-center justify-center min-w-[40px] transition-all duration-200 px-0.5",
                        isItemActive(item.page) && "relative",
                      )}
                      style={{
                        transform: `scale(${calculateScale(index)})`,
                      }}
                    >
                      <div
                        className={clsx(
                          "p-1 transition-colors",
                          isItemActive(item.page)
                            ? "text-primary"
                            : "text-zinc-400 hover:text-zinc-100",
                        )}
                      >
                        {item.icon}
                      </div>
                      <span
                        className={clsx(
                          "text-[10px] mt-0.5 whitespace-nowrap",
                          isItemActive(item.page)
                            ? "text-primary font-medium"
                            : "text-zinc-400 hover:text-zinc-100",
                        )}
                      >
                        {item.text}
                      </span>
                      {isItemActive(item.page) && (
                        <div className="absolute -bottom-2 left-1/2 -translate-x-1/2 w-1 h-1 bg-primary rounded-full" />
                      )}
                    </button>
                  ))}
                </div>
              </div>

              {/* Área do usuário no dock + mudança de layout */}
              <div className="flex items-center border-l border-zinc-700 pl-2 ml-2 pr-3 flex-shrink-0">
                <Button
                  variant="ghost"
                  size="compact"
                  onClick={() => {
                    setMenuLayout("vertical");
                    localStorage.setItem("menuLayout", "vertical");
                  }}
                  className="text-zinc-400 hover:text-zinc-100 mr-1"
                >
                  <LayoutPanelLeft className="h-4 w-4" />
                </Button>

                {/* Insere aqui o dropdown do usuário */}
                <UserDropdown
                  username={username}
                  balance={balance}
                  profileImage={profileImage}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Menu;

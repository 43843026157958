// src/PaymentMethods.js
import React, { useState, useEffect, useRef } from "react";
import { QrCode, Bitcoin, ArrowLeft, Clipboard } from "lucide-react";
import { toast } from "react-toastify";
import axiosInstance from "./axiosConfig"; // Certifique-se de que o axiosInstance está corretamente configurado

const Button = React.forwardRef(
  ({ className, variant = "default", size = "default", ...props }, ref) => {
    const classes = `
      inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors
      focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2
      disabled:pointer-events-none disabled:opacity-50
      ${variant === "default" ? "bg-primary text-primary-foreground hover:bg-primary/90" : ""}
      ${variant === "destructive" ? "bg-destructive text-destructive-foreground hover:bg-destructive/90" : ""}
      ${variant === "outline" ? "border border-input bg-transparent hover:bg-accent hover:text-accent-foreground" : ""}
      ${variant === "secondary" ? "bg-secondary text-secondary-foreground hover:bg-secondary/80" : ""}
      ${variant === "ghost" ? "hover:bg-accent hover:text-accent-foreground bg-transparent" : ""}
      ${variant === "link" ? "text-primary underline-offset-4 hover:underline" : ""}
      ${size === "default" ? "h-10 px-4 py-2" : ""}
      ${size === "sm" ? "h-9 rounded-md px-3" : ""}
      ${size === "lg" ? "h-11 rounded-md px-8" : ""}
      ${size === "icon" ? "h-10 w-10" : ""}
      ${className}
    `;
    return <button className={classes} ref={ref} {...props} />;
  },
);
Button.displayName = "Button";

const Input = React.forwardRef(
  ({ className, type = "text", onKeyDown, ...props }, ref) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
      <input
        ref={ref}
        type={type}
        className={`w-full h-11 bg-zinc-800 border text-sm text-zinc-100 rounded-xl px-4 placeholder:text-zinc-500 focus:outline-none transition-colors duration-200 border-transparent hover:border-zinc-600 ${
          isFocused ? "border-zinc-700" : ""
        } ${type === "number" ? "appearance-none" : ""} ${className}`}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onKeyDown={onKeyDown}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";

const Card = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={`
      rounded-lg border bg-card text-card-foreground shadow-sm transition-all
      duration-300 ease-in-out hover:shadow-lg hover:-translate-y-1
      ${className}
    `}
    {...props}
  />
));
Card.displayName = "Card";

// Adicionando estilos para remover os spinners dos inputs de número
const HideNumberInputSpinners = () => (
  <style jsx>{`
    /* Chrome, Safari, Edge, Opera */
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  `}</style>
);

const PaymentMethods = ({ setCurrentPage }) => {
  const [balanceStep, setBalanceStep] = useState("chooseMethod");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [amountToAdd, setAmountToAdd] = useState("");
  const [transactionData, setTransactionData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [btcRate, setBtcRate] = useState(null); // Cotação atual do Bitcoin
  const [rateError, setRateError] = useState(null); // Erro na cotação
  const intervalRef = useRef(null); // Referência para o intervalo

  const MIN_AMOUNT_PIX = 20; // Valor mínimo para PIX
  const MAX_AMOUNT_PIX = 1000; // Valor máximo para PIX
  const MIN_AMOUNT_BITCOIN = 200; // Valor mínimo para Bitcoin

  // Função para buscar a cotação atual do Bitcoin com intervalo de 30 segundos
  const fetchBtcRate = async () => {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=brl",
      );
      if (response.ok) {
        const data = await response.json();
        if (data.bitcoin && data.bitcoin.brl) {
          setBtcRate(data.bitcoin.brl);
          setRateError(null);
        } else {
          throw new Error("Dados incompletos da API.");
        }
      } else if (response.status === 429) {
        throw new Error(
          "Limite de requisições da API excedido. Tente novamente mais tarde.",
        );
      } else {
        throw new Error("Erro ao obter a cotação do Bitcoin.");
      }
    } catch (error) {
      console.error("Erro ao obter a cotação do Bitcoin:", error);
      setRateError(error.message);
      toast.error(error.message);
    }
  };

  // Função para iniciar o intervalo de atualização da cotação do Bitcoin
  const startBtcRateInterval = () => {
    // Limpar qualquer intervalo existente
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    // Buscar a cotação imediatamente
    fetchBtcRate();
    // Configurar o intervalo para buscar a cada 30 segundos
    intervalRef.current = setInterval(fetchBtcRate, 30000); // 30000 ms = 30 segundos
  };

  // Função para parar o intervalo de atualização da cotação do Bitcoin
  const stopBtcRateInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const generateSyntheticData = (method, amount) => {
    if (method === "Bitcoin") {
      return {
        qrCode: "https://via.placeholder.com/150",
        amountInReais: amount,
        amountInBTC: (parseFloat(amount) / btcRate).toFixed(8),
      };
    }
  };

  const steps = [
    { id: "chooseMethod", title: "Escolher Método" },
    { id: "enterAmount", title: "Informar Valor" },
    { id: "showTransaction", title: "Detalhes da Transação" },
  ];

  const renderStepper = () => (
    <div className="flex items-center justify-center mb-8">
      {steps.map((step, index) => (
        <div key={step.id} className="flex items-center">
          <div
            className={`
              w-8 h-8 flex items-center justify-center rounded-full
              ${balanceStep === step.id ? "bg-primary text-primary-foreground" : "bg-muted text-muted-foreground"}
            `}
          >
            {index + 1}
          </div>
          {index < steps.length - 1 && (
            <div className="w-8 h-px bg-muted mx-2"></div>
          )}
        </div>
      ))}
    </div>
  );

  const handleConclude = () => {
    setCurrentPage("main");
    setBalanceStep("chooseMethod");
    setSelectedPaymentMethod(null);
    setAmountToAdd("");
    setTransactionData(null);
    setBtcRate(null);
    setRateError(null);
    stopBtcRateInterval();
    toast.success("Saldo adicionado com sucesso!");
  };

  useEffect(() => {
    if (balanceStep === "enterAmount") {
      if (selectedPaymentMethod === "Bitcoin" && !btcRate && !rateError) {
        startBtcRateInterval();
      }
    } else {
      // Se não estiver na etapa de adicionar saldo, parar o intervalo
      stopBtcRateInterval();
    }

    // Limpar o intervalo quando o componente for desmontado
    return () => {
      stopBtcRateInterval();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceStep, selectedPaymentMethod]);

  const validateAmount = () => {
    if (selectedPaymentMethod === "PIX") {
      // Verifica se o valor é um número inteiro
      const isInteger = /^\d+$/.test(amountToAdd);
      if (!isInteger) {
        toast.error(
          "Por favor, insira um valor inteiro sem casas decimais para PIX.",
        );
        return false;
      }

      const amount = parseInt(amountToAdd, 10);
      if (amount < MIN_AMOUNT_PIX) {
        toast.error(
          `Por favor, insira um valor mínimo de R$ ${MIN_AMOUNT_PIX} para PIX.`,
        );
        return false;
      }
      return true;
    } else if (selectedPaymentMethod === "Bitcoin") {
      // Verifica se há vírgulas no input
      if (amountToAdd.includes(",")) {
        toast.error("Por favor, use pontos (.) como separadores decimais.");
        return false;
      }
      // Verifica se o valor é um número válido
      const btcAmount = parseFloat(amountToAdd);
      if (isNaN(btcAmount) || btcAmount <= 0) {
        toast.error("Por favor, insira um valor válido em R$.");
        return false;
      }
      // Verifica se o valor é maior ou igual a R$ 200
      if (btcAmount < MIN_AMOUNT_BITCOIN) {
        toast.error(
          `Por favor, insira um valor mínimo de R$ ${MIN_AMOUNT_BITCOIN} para Bitcoin.`,
        );
        return false;
      }
      return true;
    }
    return false;
  };

  const handleGeneratePayment = async () => {
    if (!validateAmount()) {
      return;
    }

    if (selectedPaymentMethod === "PIX") {
      setIsLoading(true);
      try {
        const token = localStorage.getItem("token");

        const response = await axiosInstance.post(
          "/pagamentos/pix",
          { amount: parseInt(amountToAdd, 10) },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (response.status === 200 || response.status === 201) {
          const data = response.data;
          console.log("Dados da Transação PIX:", data); // Log para depuração

          // Atualizar o transactionData com base nos campos retornados pela API do SampaBank
          setTransactionData({
            qrCodeBase64: data.qrcode_base64,
            copyAndPasteCode: data.copiaecola,
            transactionId: data.id,
            status: data.status, // Atualizado para incluir status
            obs: data.obs || "", // Campo adicional opcional
          });

          setBalanceStep("showTransaction");

          if (data.obs === "RECUPERADO") {
            toast.info(
              "Uma transação PIX pendente foi recuperada. Por favor, realize o pagamento ou aguarde 30 minutos para gerar uma nova transação.",
            );
          } else {
            toast.success("Pagamento PIX gerado com sucesso!");
          }
        } else {
          const errorData = response.data;
          toast.error(errorData.error || "Erro ao gerar pagamento PIX.");
        }
      } catch (error) {
        console.error("Erro ao gerar pagamento PIX:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          toast.error(error.response.data.error);
        } else {
          toast.error("Erro ao gerar pagamento PIX.");
        }
      } finally {
        setIsLoading(false);
      }
    } else if (selectedPaymentMethod === "Bitcoin") {
      setIsLoading(true);
      try {
        // Aqui você pode implementar a lógica para gerar o pagamento via Bitcoin,
        // como chamar uma API específica para isso. Por enquanto, usaremos dados sintéticos.

        // Simulação de chamada à API para gerar pagamento via Bitcoin
        const syntheticData = generateSyntheticData(
          selectedPaymentMethod,
          amountToAdd,
        );

        setTransactionData(syntheticData);
        setBalanceStep("showTransaction");
        toast.success("Dados da transação Bitcoin gerados com sucesso!");
      } catch (error) {
        console.error("Erro ao gerar pagamento Bitcoin:", error);
        toast.error("Erro ao gerar pagamento Bitcoin.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Nova função para verificar status e recarregar a página se necessário
  const pollPaymentStatus = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axiosInstance.get(`/pagamentos/pix/status`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const statusData = response.data;
        setTransactionData((prevData) => ({
          ...prevData,
          status: statusData.status,
        }));
        if (statusData.status === "paid") {
          window.location.reload();
        }
      } else {
        console.error("Erro ao verificar status.");
      }
    } catch (error) {
      console.error("Erro ao verificar status do pagamento:", error);
    }
  };

  // useEffect para verificar status em segundo plano
  useEffect(() => {
    let interval;
    if (balanceStep === "showTransaction" && selectedPaymentMethod === "PIX") {
      // Verificar imediatamente ao entrar na etapa
      pollPaymentStatus();
      // Configurar intervalo para verificar a cada 5 segundos
      interval = setInterval(() => {
        pollPaymentStatus();
      }, 5000); // 5000 ms = 5 segundos
    }
    return () => {
      if (interval) clearInterval(interval);
    };
    // Dependências incluem balanceStep e selectedPaymentMethod
  }, [balanceStep, selectedPaymentMethod]);

  // Função para impedir a rolagem com a roda do mouse nos inputs de número
  const preventScroll = (e) => {
    e.target.blur();
  };

  // Função para impedir a inserção de pontos e vírgulas para PIX
  const handleKeyDownPIX = (e) => {
    if (e.key === "." || e.key === ",") {
      e.preventDefault();
    }
  };

  // Cálculo do equivalente em BTC
  const calculateBtcEquivalent = () => {
    if (selectedPaymentMethod === "Bitcoin" && btcRate) {
      const amount = parseFloat(amountToAdd);
      if (!isNaN(amount) && amount >= MIN_AMOUNT_BITCOIN) {
        return (amount / btcRate).toFixed(8);
      }
    }
    return null;
  };

  return (
    <div className="flex flex-col items-center justify-center h-full w-full px-4">
      {/* Inserindo o componente de estilos para esconder spinners */}
      <HideNumberInputSpinners />

      {renderStepper()}

      {balanceStep === "chooseMethod" && (
        <div className="flex flex-col items-center w-full max-w-2xl">
          <div className="flex items-center mb-8 w-full">
            <Button
              variant="ghost"
              onClick={() => {
                setCurrentPage("main");
                setBalanceStep("chooseMethod");
                setSelectedPaymentMethod(null);
                setAmountToAdd("");
                setTransactionData(null);
                setBtcRate(null);
                setRateError(null);
                stopBtcRateInterval();
              }}
              className="flex items-center mr-4"
            >
              <ArrowLeft className="h-5 w-5 mr-2" />
              Voltar
            </Button>
            <h2 className="text-lg xs:text-xl sm:text-2xl md:text-3xl font-bold">
              Escolha o método de pagamento
            </h2>
          </div>
          <div className="grid grid-cols-2 gap-4 w-full">
            <Card
              onClick={() => {
                setSelectedPaymentMethod("PIX");
                setBalanceStep("enterAmount");
              }}
              className="cursor-pointer w-full"
            >
              <div className="flex flex-col items-center p-3 xs:p-4 sm:p-5">
                <QrCode className="h-12 w-12 xs:h-16 xs:w-16 sm:h-20 sm:w-20 mb-2 xs:mb-3 sm:mb-4" />
                <h3 className="text-base xs:text-lg sm:text-xl font-bold">
                  PIX
                </h3>
              </div>
            </Card>
            <Card
              // onClick={() => {
              //   setSelectedPaymentMethod("Bitcoin");
              //   setBalanceStep("enterAmount");
              // }}
              className="cursor-not-allowed opacity-50 w-full"
            >
              <div className="flex flex-col items-center p-3 xs:p-4 sm:p-5">
                <Bitcoin className="h-12 w-12 xs:h-16 xs:w-16 sm:h-20 sm:w-20 mb-2 xs:mb-3 sm:mb-4" />
                <h3 className="text-base xs:text-lg sm:text-xl font-bold">
                  Bitcoin
                </h3>
              </div>
            </Card>
          </div>
        </div>
      )}

      {balanceStep === "enterAmount" && (
        <div className="flex flex-col items-center space-y-6">
          <div className="flex items-center">
            <Button
              variant="ghost"
              onClick={() => {
                setBalanceStep("chooseMethod");
                setSelectedPaymentMethod(null);
                setAmountToAdd("");
                setBtcRate(null);
                setRateError(null);
                stopBtcRateInterval();
              }}
              className="flex items-center mr-4"
            >
              <ArrowLeft className="h-5 w-5 mr-2" />
              Voltar
            </Button>
            <h2 className="text-lg xs:text-xl sm:text-2xl md:text-3xl font-bold">
              Adicionar Saldo via {selectedPaymentMethod}
            </h2>
          </div>
          <Card className="w-full max-w-md">
            <div className="p-6">
              <div className="mb-6">
                {selectedPaymentMethod === "PIX" ? (
                  <label className="block mb-3 text-lg md:text-xl">
                    Quanto de saldo você gostaria de adicionar?
                  </label>
                ) : (
                  <div>
                    <label className="block mb-3 text-lg md:text-xl">
                      Quanto de saldo você gostaria de adicionar?
                    </label>
                    {btcRate && (
                      <p className="text-sm text-zinc-500 mb-2">
                        Cotação atual do Bitcoin: R${" "}
                        {btcRate.toLocaleString("pt-BR")}
                      </p>
                    )}
                    {rateError && (
                      <p className="text-sm text-red-500 mb-2">{rateError}</p>
                    )}
                  </div>
                )}
                <Input
                  type="number"
                  value={amountToAdd}
                  onChange={(e) => setAmountToAdd(e.target.value)}
                  placeholder="Digite a quantia em R$"
                  className="text-lg md:text-xl bg-zinc-800 text-zinc-100"
                  min={
                    selectedPaymentMethod === "PIX" ? MIN_AMOUNT_PIX : "0.01"
                  }
                  max={
                    selectedPaymentMethod === "PIX" ? MAX_AMOUNT_PIX : undefined
                  }
                  step={selectedPaymentMethod === "PIX" ? "1" : "0.01"}
                  onWheel={
                    selectedPaymentMethod === "PIX" ? preventScroll : undefined
                  }
                  onKeyDown={
                    selectedPaymentMethod === "PIX"
                      ? handleKeyDownPIX
                      : undefined
                  }
                />
                {selectedPaymentMethod === "PIX" &&
                  amountToAdd &&
                  (() => {
                    const amount = parseInt(amountToAdd, 10);
                    if (isNaN(amount)) {
                      return (
                        <p className="text-red-500 mt-2">
                          Por favor, insira um valor válido.
                        </p>
                      );
                    }
                    if (amount < MIN_AMOUNT_PIX) {
                      return (
                        <p className="text-red-500 mt-2">
                          O valor mínimo é R$ {MIN_AMOUNT_PIX}
                        </p>
                      );
                    }
                    if (amount > MAX_AMOUNT_PIX) {
                      return (
                        <p className="text-red-500 mt-2">
                          O valor máximo é R$ {MAX_AMOUNT_PIX}
                        </p>
                      );
                    }
                    return null;
                  })()}
                {selectedPaymentMethod === "Bitcoin" &&
                  amountToAdd &&
                  (() => {
                    const btcAmount = parseFloat(amountToAdd);
                    if (isNaN(btcAmount) || btcAmount < MIN_AMOUNT_BITCOIN) {
                      return (
                        <p className="text-red-500 mt-2">
                          Por favor, insira um valor mínimo de R${" "}
                          {MIN_AMOUNT_BITCOIN} para Bitcoin.
                        </p>
                      );
                    }
                    return null;
                  })()}
                {selectedPaymentMethod === "Bitcoin" &&
                  calculateBtcEquivalent() && (
                    <p className="text-sm text-zinc-500 mt-2">
                      Equivalente em BTC: {calculateBtcEquivalent()} BTC
                    </p>
                  )}
              </div>
              <div className="flex justify-end">
                <Button
                  onClick={handleGeneratePayment}
                  disabled={
                    !amountToAdd ||
                    (selectedPaymentMethod === "PIX" &&
                      (!/^\d+$/.test(amountToAdd) ||
                        parseInt(amountToAdd, 10) < MIN_AMOUNT_PIX ||
                        parseInt(amountToAdd, 10) > MAX_AMOUNT_PIX)) ||
                    (selectedPaymentMethod === "Bitcoin" &&
                      (amountToAdd.includes(",") ||
                        isNaN(parseFloat(amountToAdd)) ||
                        parseFloat(amountToAdd) < MIN_AMOUNT_BITCOIN)) ||
                    isLoading ||
                    (selectedPaymentMethod === "Bitcoin" && !btcRate)
                  }
                >
                  {isLoading
                    ? "Gerando..."
                    : selectedPaymentMethod === "PIX"
                      ? "Gerar PIX"
                      : "Gerar Transação"}
                </Button>
              </div>
            </div>
          </Card>
        </div>
      )}

      {balanceStep === "showTransaction" && (
        <div className="flex flex-col items-center space-y-6">
          <div className="flex items-center">
            <Button
              variant="ghost"
              onClick={() => {
                setBalanceStep("enterAmount");
                setTransactionData(null);
                if (selectedPaymentMethod === "Bitcoin") {
                  setBtcRate(null);
                  setRateError(null);
                  stopBtcRateInterval();
                }
              }}
              className="flex items-center mr-4"
            >
              <ArrowLeft className="h-5 w-5 mr-2" />
              Voltar
            </Button>
            <h2 className="text-lg xs:text-xl sm:text-2xl md:text-3xl font-bold">
              Detalhes da Transação
            </h2>
          </div>
          <Card className="w-full max-w-md p-6 text-center">
            {selectedPaymentMethod === "PIX" ? (
              <>
                {/* Aviso de transação recuperada */}
                {transactionData.obs === "RECUPERADO" && (
                  <div className="mb-4 p-2 bg-yellow-100 text-yellow-800 rounded">
                    Você já possui uma transação PIX pendente. Por favor,
                    realize o pagamento ou aguarde 30 minutos para gerar uma
                    nova transação.
                  </div>
                )}
                <p className="mb-2 text-lg md:text-xl">
                  <strong>Código QR:</strong>
                </p>
                <img
                  src={`data:image/png;base64,${transactionData.qrCodeBase64}`}
                  alt="QR Code"
                  className="mb-4 mx-auto w-48 h-48 md:w-64 md:h-64"
                />
                <p className="mb-2 text-lg md:text-xl">
                  <strong>Código copia e cola:</strong>
                </p>
                <div className="flex items-center justify-center">
                  <Input
                    type="text"
                    value={transactionData.copyAndPasteCode}
                    readOnly
                    className="flex-grow mr-2 text-lg md:text-xl"
                  />
                  <Button
                    variant="outline"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        transactionData.copyAndPasteCode,
                      );
                      toast.success("Código copiado!");
                    }}
                  >
                    <Clipboard className="h-5 w-5" />
                  </Button>
                </div>
                {/* Removido o botão "Verificar Status" */}
                {/* Texto Informativo */}
                <p className="mt-6 text-center text-gray-600">
                  Após completar o pagamento, você pode fechar esta aba. Seu
                  saldo será atualizado automaticamente.
                </p>
              </>
            ) : (
              <>
                <p className="mb-2 text-lg md:text-xl">
                  <strong>Código QR:</strong>
                </p>
                <img
                  src={transactionData.qrCode}
                  alt="QR Code"
                  className="mb-4 mx-auto w-48 h-48 md:w-64 md:h-64"
                />
                <p className="mb-2 text-lg md:text-xl">
                  <strong>Quantidade em R$:</strong> R${" "}
                  {transactionData.amountInReais}
                </p>
                {btcRate && (
                  <p className="mb-2 text-lg md:text-xl">
                    <strong>Quantidade em BTC:</strong>{" "}
                    {transactionData.amountInBTC} BTC
                  </p>
                )}
                {/* Texto Informativo */}
                <p className="mt-6 text-center text-gray-600">
                  Após completar a transação, você pode fechar esta aba. Seu
                  saldo será atualizado automaticamente.
                </p>
              </>
            )}
            {/* Removido o botão "Concluir" */}
          </Card>
        </div>
      )}
    </div>
  );
};

export default PaymentMethods;

import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import axiosInstance, { setAuthToken } from "./axiosConfig";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode"; // Ajuste na importação
import CryptoJS from "crypto-js";

// Função auxiliar para descriptografia
const decryptAES = (ciphertext, iv) => {
  try {
    const SECRET_KEY_BASE64 = "oKXzeNzPfkTVniZNaOt5RA==";
    const key = CryptoJS.enc.Base64.parse(SECRET_KEY_BASE64);
    const ivWordArray = CryptoJS.enc.Base64.parse(iv);
    const encryptedWordArray = CryptoJS.enc.Base64.parse(ciphertext);

    // Validate input lengths
    if (!ivWordArray || ivWordArray.sigBytes !== 16) {
      throw new Error("Invalid IV length");
    }

    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encryptedWordArray },
      key,
      {
        iv: ivWordArray,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      },
    );

    // Check if decryption was successful
    const decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);
    if (!decryptedStr) {
      throw new Error("Decryption resulted in empty string");
    }

    return JSON.parse(decryptedStr);
  } catch (error) {
    console.error("Decryption error:", error);
    throw error;
  }
};

const encryptAES = (data) => {
  try {
    const SECRET_KEY_BASE64 = "oKXzeNzPfkTVniZNaOt5RA==";
    const key = CryptoJS.enc.Base64.parse(SECRET_KEY_BASE64);
    const iv = CryptoJS.lib.WordArray.random(16);

    // Converte o objeto para string JSON
    const jsonStr = JSON.stringify(data);

    // Criptografa
    const encrypted = CryptoJS.AES.encrypt(jsonStr, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    // Retorna o objeto com IV e ciphertext em base64
    return {
      iv: CryptoJS.enc.Base64.stringify(iv),
      ciphertext: encrypted.toString(),
    };
  } catch (error) {
    console.error("Encryption error:", error);
    throw error;
  }
};

export const useUserStore = create(
  devtools(
    persist(
      (set, get) => ({
        // ---------------------------------------------------------------------
        // Novo estado para escolher a aba em MinhasCompras
        // ---------------------------------------------------------------------
        purchasesTab: "cards", // Valor padrão
        setPurchasesTab: (tab) => set({ purchasesTab: tab }),

        // ---------------------------------------------------------------------
        // Estados (cards, user, etc.)
        // ---------------------------------------------------------------------
        balance: 0,
        isAuthenticated: false,
        username: null,

        cards: [],
        loading: false,
        error: null,
        selectedCard: null,
        isModalOpen: false,

        // SmartBuy
        isSmartBuyModalOpen: false,
        cardToBuy: null,
        similarCards: [],
        isProcessing: false,
        currentStep: 0,
        currentCardIndex: 0,
        showError: false,
        purchaseStatus: "idle",

        page: 1,
        totalPages: 1,
        purchaseMode: "cart",

        searchQuery: "",
        filters: [{ type: "brand", value: "" }],

        showFilters: false,

        // ---------------------------------------------------------------------
        // Estados (docs) – NÃO serão persistidos (veja partialize abaixo)
        // ---------------------------------------------------------------------
        docs: [],
        docsLoading: false,
        docsError: null,
        visualizationMode: "grid",
        isDocsModalOpen: false,
        modalStep: "details", // "details" | "purchase"
        selectedDoc: null,
        isPurchasing: false,
        currentDocPage: 1,
        perPageDocs: 50,
        totalDocs: 0,
        docSearchQuery: "",
        showLayoutMenu: false,
        alertMessage: "",

        // ---------------------------------------------------------------------
        // Ações gerais
        // ---------------------------------------------------------------------
        setShowFilters: (val) => set({ showFilters: val }),
        setBalance: (newBalance) => {
          if (typeof newBalance !== "number" || newBalance < 0) {
            console.warn("Tentativa de atualizar saldo inválido:", newBalance);
            return;
          }
          set({ balance: newBalance });
        },
        setIsAuthenticated: (value) => set({ isAuthenticated: value }),
        setUsername: (value) => set({ username: value }),
        setLoading: (value) => set({ loading: value }),
        setError: (value) => set({ error: value }),
        setSelectedCard: (card) => set({ selectedCard: card }),
        setIsModalOpen: (value) => set({ isModalOpen: value }),
        setIsSmartBuyModalOpen: (value) => set({ isSmartBuyModalOpen: value }),
        setCardToBuy: (card) => set({ cardToBuy: card }),
        setSimilarCards: (cards) => set({ similarCards: cards }),
        setIsProcessing: (value) => set({ isProcessing: value }),
        setCurrentStep: (value) => set({ currentStep: value }),
        setCurrentCardIndex: (value) => set({ currentCardIndex: value }),
        setShowError: (value) => set({ showError: value }),
        setPurchaseStatus: (value) => set({ purchaseStatus: value }),
        setPage: (value) => set({ page: value }),
        setTotalPages: (value) => set({ totalPages: value }),
        setPurchaseMode: (mode) => set({ purchaseMode: mode }),
        setSearchQuery: (value) => set({ searchQuery: value }),
        setFilters: (value) => set({ filters: value }),

        // ---------------------------------------------------------------------
        // Ações específicas de docs
        // ---------------------------------------------------------------------
        setVisualizationMode: (mode) => set({ visualizationMode: mode }),
        setShowLayoutMenu: (show) => set({ showLayoutMenu: show }),
        setDocSearchQuery: (value) => {
          set({ docSearchQuery: value, currentDocPage: 1 });
        },
        openDocModal: (doc) => {
          set({
            selectedDoc: doc,
            modalStep: "details",
            isPurchasing: false,
            alertMessage: "",
            isDocsModalOpen: true,
          });
        },
        closeDocModal: () => {
          const { isPurchasing } = get();
          if (!isPurchasing) {
            set({
              isDocsModalOpen: false,
              selectedDoc: null,
              modalStep: "details",
              alertMessage: "",
            });
          }
        },
        handleBuyDoc: (doc) => {
          set({
            selectedDoc: doc,
            modalStep: "purchase",
            isPurchasing: false,
            alertMessage: "",
            isDocsModalOpen: true,
          });
        },
        shareDocLink: (doc) => {
          console.log("Sharing doc:", doc);
        },

        // Confirma a compra do Doc
        confirmPurchaseDoc: async () => {
          const { selectedDoc, currentDocPage, docSearchQuery, fetchDocs } =
            get();
          if (!selectedDoc) return;
          set({ isPurchasing: true, alertMessage: "" });
          try {
            const token = localStorage.getItem("token") || "";
            const response = await fetch(
              "https://api.astrashopbot.cc/api/purchase_docs",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  docs: [selectedDoc._id],
                }),
              },
            );
            const data = await response.json();

            // Verificação de erro ou docs com falha
            if (
              data.error ||
              (data.failed_docs && data.failed_docs.length > 0)
            ) {
              set({
                alertMessage:
                  data.error || data.failed_docs.map((f) => f.error).join(", "),
              });
            } else {
              // Atualiza saldo local se necessário
              if (data.new_balance !== undefined) {
                set({ balance: data.new_balance });
                localStorage.setItem(
                  "userBalance",
                  data.new_balance.toString(),
                );
              }
              set({
                alertMessage: "Compra realizada com sucesso!",
              });
              fetchDocs(currentDocPage, docSearchQuery);
            }
          } catch (error) {
            set({ alertMessage: "Erro ao realizar compra" });
          } finally {
            set({ isPurchasing: false });
          }
        },

        updateUserSettings: async (newSettings) => {
          const MAX_RETRIES = 3;
          const INITIAL_RETRY_DELAY = 1000;
          let currentTry = 0;

          while (currentTry < MAX_RETRIES) {
            try {
              const encryptedData = encryptAES({
                settings: newSettings,
              });

              const response = await axiosInstance.post(
                "/user-settings",
                encryptedData,
              );

              if (response.data?.iv && response.data?.ciphertext) {
                return decryptAES(response.data.ciphertext, response.data.iv);
              }

              return response.data;
            } catch (error) {
              currentTry++;

              if (error.response?.status === 401) {
                window.location.href = "/login";
                throw new Error("Sessão expirada");
              }

              if (currentTry === MAX_RETRIES) {
                const errorMessage =
                  error.response?.data?.message ||
                  error.response?.data?.error ||
                  "Erro ao atualizar configurações";
                throw new Error(errorMessage);
              }

              await new Promise((resolve) =>
                setTimeout(
                  resolve,
                  INITIAL_RETRY_DELAY * Math.pow(2, currentTry - 1),
                ),
              );
            }
          }

          throw new Error("Falha após todas as tentativas");
        },

        // Busca docs
        fetchDocs: async (page = 1, search = "") => {
          try {
            set({ docsLoading: true });
            const url = new URL("https://api.astrashopbot.cc/api/get_docs");
            url.searchParams.set("page", page);
            const { perPageDocs } = get();
            url.searchParams.set("per_page", perPageDocs);
            if (search.trim() !== "") {
              url.searchParams.set("search", search.trim());
            }
            const response = await fetch(url.toString(), {
              headers: { "Content-Type": "application/json" },
            });
            const data = await response.json();
            if (data.error) {
              set({
                docsError: data.error,
                docs: [],
                totalDocs: 0,
              });
            } else {
              set({
                docs: data.docs || [],
                totalDocs: data.total_docs || 0,
                docsError: null,
              });
            }
          } catch {
            set({
              docsError: "Erro ao carregar documentos",
              docs: [],
            });
          } finally {
            set({ docsLoading: false });
          }
        },
        goToPreviousDocPage: () => {
          const { currentDocPage } = get();
          if (currentDocPage > 1) {
            set({ currentDocPage: currentDocPage - 1 });
          }
        },
        goToNextDocPage: () => {
          const { currentDocPage, totalDocs, perPageDocs } = get();
          const totalPages = Math.ceil(totalDocs / perPageDocs);
          if (currentDocPage < totalPages) {
            set({ currentDocPage: currentDocPage + 1 });
          }
        },

        // ---------------------------------------------------------------------
        // Ações (cards)
        // ---------------------------------------------------------------------
        fetchBalance: async () => {
          try {
            const response = await axiosInstance.get("/user-info");
            const { iv, ciphertext } = response.data;

            if (!iv || !ciphertext) {
              throw new Error(
                "Invalid response format: missing iv or ciphertext",
              );
            }

            const decryptedData = decryptAES(ciphertext, iv);
            const balance = decryptedData?.user_info?.balance ?? 0;

            set({ balance });
          } catch (error) {
            console.error("Erro ao buscar saldo:", error);
            set({ balance: 0 });
          }
        },
        fetchUserData: async () => {
          try {
            let response = await axiosInstance.get("/user-info", {
              timeout: 5000,
              retry: 3,
              retryDelay: 1000,
            });

            let { iv, ciphertext } = response.data;

            if (!iv || !ciphertext) {
              throw new Error(
                "Invalid response format: missing iv or ciphertext",
              );
            }

            let decryptedData = decryptAES(ciphertext, iv);
            let userBalance = decryptedData?.user_info?.balance ?? 0;

            set({ balance: userBalance });

            let settingsRes = await axiosInstance.get("/user-settings", {
              timeout: 5000,
            });

            set({
              purchaseMode: settingsRes.data?.settings?.purchaseMode ?? "cart",
            });

            return {
              success: true,
              balance: userBalance,
              purchaseMode: settingsRes.data?.settings?.purchaseMode,
            };
          } catch (error) {
            console.error("Error fetching user data:", {
              message: error.message,
              name: error.name,
              stack: error.stack,
            });

            set({ balance: 0, purchaseMode: "cart" });

            return {
              success: false,
              error: error.message,
              balance: 0,
              purchaseMode: "cart",
            };
          }
        },
        checkAuthentication: () => {
          const token = localStorage.getItem("token");
          if (!token) {
            set({ isAuthenticated: false, username: null });
            return;
          }
          try {
            const decoded = jwtDecode(token);
            if (decoded.exp * 1000 < Date.now()) {
              localStorage.removeItem("token");
              setAuthToken(null);
              set({ isAuthenticated: false, username: null });
              return;
            }
            setAuthToken(token);
            set({ isAuthenticated: true, username: decoded.user });
          } catch (error) {
            console.error("Erro ao verificar autenticação:", error);
            localStorage.removeItem("token");
            setAuthToken(null);
            set({ isAuthenticated: false, username: null });
          }
        },

        // --- ATUALIZAÇÃO DO fetchUserSettings PARA ATENDER O UserSettings.js ---
        fetchUserSettings: async () => {
          try {
            const token = localStorage.getItem("token");
            if (!token) {
              throw new Error("No authentication token found");
            }

            const MAX_RETRIES = 2;
            let lastError;

            for (let attempt = 0; attempt <= MAX_RETRIES; attempt++) {
              try {
                const response = await axiosInstance.get("/user-settings", {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });

                const { iv, ciphertext } = response.data;
                if (!iv || !ciphertext) {
                  throw new Error(
                    "Invalid response format: missing iv or ciphertext",
                  );
                }

                const decryptedData = decryptAES(ciphertext, iv);
                const mode = decryptedData?.settings?.purchaseMode;
                const visualizationMode =
                  decryptedData?.settings?.visualizationMode || "card";
                const twoFactorEnabled =
                  decryptedData?.user?.["2fa_enabled"] || false;

                set({
                  purchaseMode: mode,
                  visualizationMode,
                  twoFactorEnabled,
                });

                return decryptedData;
              } catch (error) {
                lastError = error;

                if (
                  error.response?.status === 401 ||
                  error.message.includes("CORS") ||
                  error.message.includes("Network Error")
                ) {
                  throw error;
                }

                if (attempt < MAX_RETRIES) {
                  await new Promise((resolve) =>
                    setTimeout(resolve, 1000 * (attempt + 1)),
                  );
                  continue;
                }
              }
            }

            throw lastError;
          } catch (error) {
            console.error("Erro ao buscar configurações:", error);

            let errorMessage = "Erro ao buscar as configurações do usuário.";
            if (error.response?.status === 401) {
              errorMessage =
                "Sessão expirada. Por favor, faça login novamente.";
            } else if (error.message.includes("CORS")) {
              errorMessage =
                "Erro de conexão com o servidor. Por favor, tente novamente.";
            }

            toast.error(errorMessage);

            set({
              purchaseMode: undefined,
              visualizationMode: "card",
              twoFactorEnabled: false,
            });

            throw error;
          }
        },
        // ---------------------------------------------------------------------

        fetchUserBalance: async () => {
          try {
            const response = await axiosInstance.get("/user-info");
            const { iv, ciphertext } = response.data;

            if (!iv || !ciphertext) {
              throw new Error(
                "Invalid response format: missing iv or ciphertext",
              );
            }

            const decryptedData = decryptAES(ciphertext, iv);
            const balance = decryptedData?.user_info?.balance ?? 0;

            set({ balance });
            localStorage.setItem("userBalance", balance.toString());
          } catch (error) {
            console.error("Erro ao obter saldo do usuário:", error);
            set({ balance: 0 });
            localStorage.setItem("userBalance", "0");
          }
        },
        fetchCards: async (pageNumber = 1, reset = false) => {
          const { searchQuery, filters } = get();
          set({ loading: true, error: null });
          try {
            const params = new URLSearchParams();
            params.append("page", pageNumber);
            params.append("limit", 100);
            if (searchQuery.trim() !== "") {
              params.append("search", searchQuery.trim());
            }
            const validFilters = filters.filter(
              (f) => f.value && f.value.trim() !== "",
            );
            if (validFilters.length > 0) {
              params.append("filters", JSON.stringify(validFilters));
            }

            const response = await axiosInstance.get(
              `/get-cards-hash?${params.toString()}`,
            );
            const fetchedCards = response.data?.cards || [];
            const pagination = response.data?.pagination || {};
            const newPage = pagination.current_page || pageNumber;
            const newTotalPages = pagination.total_pages || 1;

            set({ page: newPage, totalPages: newTotalPages });
            set((state) => ({
              cards: reset ? fetchedCards : [...state.cards, ...fetchedCards],
            }));
          } catch (err) {
            console.error("Erro ao buscar cartões:", err);
            set({
              error: "Falha ao carregar os cartões.",
              cards: [],
              page: 1,
              totalPages: 1,
            });
          } finally {
            set({ loading: false });
          }
        },
        handleLoadMore: () => {
          const { page, totalPages, loading, fetchCards } = get();
          if (page < totalPages && !loading) {
            fetchCards(page + 1, false);
          }
        },
        openModal: (card) => {
          set({ selectedCard: card, isModalOpen: true });
        },
        closeModal: () => {
          set({ selectedCard: null, isModalOpen: false });
        },
        purchaseCard: async (card) => {
          const { username, cards, balance } = get();
          if (!username) {
            toast.error("Usuário não identificado para compra.");
            return;
          }
          try {
            const response = await axiosInstance.post(
              "/dev/purchase_marketplace",
              {
                hash: card.hash,
                username,
              },
            );
            if (response.status === 200 && !response.data.error) {
              toast.success("Compra realizada com sucesso!");
              set({
                balance: response.data.new_balance || balance,
              });
              set({
                cards: cards.filter((c) => c.hash !== card.hash),
              });
            } else {
              toast.error(response.data.error || "Falha na compra.");
            }
          } catch (error) {
            console.error("Erro ao processar a compra:", error);
            if (error.response?.data?.error) {
              toast.error(
                `Erro ao finalizar compra: ${error.response.data.error}`,
              );
            } else {
              toast.error("Ocorreu um erro ao processar sua compra.");
            }
          }
        },
        handleSmartBuy: async (card) => {
          const { resetSmartBuyStates } = get();
          resetSmartBuyStates();
          try {
            const similarFilters = [
              { type: "bin", value: card.bin },
              { type: "level", value: card.level },
              {
                type: "expiry",
                value: `${card.month}/${card.year}`,
              },
            ];
            const params = new URLSearchParams();
            params.append("filters", JSON.stringify(similarFilters));

            const response = await axiosInstance.get(
              `/get-cards-hash?${params.toString()}`,
            );
            const foundSimilarCards = response.data.cards
              .filter((c) => c.hash !== card.hash)
              .slice(0, 3);

            set({
              similarCards: foundSimilarCards,
              cardToBuy: card,
              isSmartBuyModalOpen: true,
            });
          } catch (error) {
            console.error("Erro ao buscar cartões similares:", error);
            toast.error("Erro ao buscar cartões similares");
          }
        },
        resetSmartBuyStates: () => {
          set({
            isSmartBuyModalOpen: false,
            similarCards: [],
            currentStep: 0,
            currentCardIndex: 0,
            purchaseStatus: "idle",
            showError: false,
            cardToBuy: null,
            isProcessing: false,
          });
        },
        processSmartBuy: async (originalCard) => {
          const {
            similarCards,
            username,
            cards,
            balance,
            setCurrentCardIndex,
            setCurrentStep,
            setPurchaseStatus,
            setShowError,
            setIsProcessing,
            setBalance,
          } = get();

          setIsProcessing(true);
          setPurchaseStatus("idle");
          setShowError(false);
          setCurrentStep(0);
          setCurrentCardIndex(0);

          const allCards = [originalCard, ...similarCards];
          for (let i = 0; i < allCards.length; i++) {
            setCurrentCardIndex(i);
            setCurrentStep(i);
            setPurchaseStatus("processing");
            setShowError(false);

            try {
              const response = await axiosInstance.post(
                "/dev/purchase_marketplace",
                {
                  hash: allCards[i].hash,
                  username,
                },
              );

              if (response.status === 200 && !response.data.error) {
                setBalance(response.data.new_balance || balance);
                localStorage.setItem(
                  "userBalance",
                  (response.data.new_balance || 0).toString(),
                );
                setPurchaseStatus("success");
                toast.success("Compra realizada com sucesso!");
                // Remove o cartão comprado da lista
                set({
                  cards: cards.filter((c) => c.hash !== allCards[i].hash),
                });
                await new Promise((resolve) => setTimeout(resolve, 2000));
                set({ isSmartBuyModalOpen: false });
                break;
              } else {
                setPurchaseStatus("error");
                setShowError(true);
                const errorMsg = response.data.error || "Falha desconhecida.";
                toast.error(`Falha na tentativa: ${errorMsg}`);
                await new Promise((resolve) => setTimeout(resolve, 2000));
              }
            } catch (error) {
              setPurchaseStatus("error");
              setShowError(true);
              toast.error(
                error.response?.data?.error || "Erro ao processar a compra",
              );
              await new Promise((resolve) => setTimeout(resolve, 2000));
            }
          }
          setIsProcessing(false);
          setPurchaseStatus("idle");
        },
        handleQuickBuy: (card) => {
          const { purchaseCard } = get();
          purchaseCard(card);
        },
        shareCardLink: (card) => {
          const cardId = card.hash;
          const link = `${window.location.origin}/?card=${cardId}`;
          navigator.clipboard
            .writeText(link)
            .then(() => {
              toast.success("Link copiado para a área de transferência!");
            })
            .catch((err) => {
              console.error("Falha ao copiar o link: ", err);
            });
        },
      }),
      {
        name: "user-store",
        // Quais estados serão persistidos (excluindo docs e modal)
        partialize: (state) => ({
          // Persistir apenas dados do usuário, saldo, cards, etc.
          balance: state.balance,
          isAuthenticated: state.isAuthenticated,
          username: state.username,

          // Cards
          cards: state.cards,
          loading: state.loading,
          error: state.error,
          selectedCard: state.selectedCard,
          isModalOpen: state.isModalOpen,

          // SmartBuy
          isSmartBuyModalOpen: state.isSmartBuyModalOpen,
          cardToBuy: state.cardToBuy,
          similarCards: state.similarCards,
          isProcessing: state.isProcessing,
          currentStep: state.currentStep,
          currentCardIndex: state.currentCardIndex,
          showError: state.showError,
          purchaseStatus: state.purchaseStatus,

          page: state.page,
          totalPages: state.totalPages,
          purchaseMode: state.purchaseMode,

          searchQuery: state.searchQuery,
          filters: state.filters,
          showFilters: state.showFilters,

          // Aba de compras
          purchasesTab: state.purchasesTab,
        }),
      },
    ),
  ),
);

// Inicializações automáticas e assinaturas
const userStore = useUserStore.getState();

// Checar autenticação imediatamente
userStore.checkAuthentication();

// Atualiza autenticação a cada 5 minutos
setInterval(
  () => {
    userStore.checkAuthentication();
  },
  5 * 60 * 1000,
);

// Quando isAuthenticated mudar para true, buscar saldo, configs e cards
useUserStore.subscribe(
  (state) => state.isAuthenticated,
  (isAuthenticated) => {
    if (isAuthenticated) {
      userStore.fetchUserBalance();
      userStore.fetchUserSettings();
      userStore.fetchCards(1, true);
    }
  },
);

// Buscar docs sempre que logado
useUserStore.subscribe(
  (state) => state.isAuthenticated,
  (isAuthenticated) => {
    if (isAuthenticated) {
      userStore.fetchDocs(1, "");
    }
  },
);

// Refaz a busca sempre que docSearchQuery mudar
useUserStore.subscribe(
  (state) => state.docSearchQuery,
  (query) => {
    userStore.set({ currentDocPage: 1 });
    userStore.fetchDocs(1, query);
  },
);

import React, { useState, useEffect } from "react";
import { Folder, FileText, Loader2, Trash2, X, Download } from "lucide-react";

const BASE_URL = "https://api.astrashopbot.cc";

const Alert = ({ children, variant }) => {
  const baseStyles = "rounded-lg border p-4 text-sm";
  const variants = {
    default:
      "bg-blue-50 border-blue-200 text-blue-800 dark:bg-blue-900/20 dark:border-blue-800/30 dark:text-blue-300",
    destructive:
      "bg-red-50 border-red-200 text-red-800 dark:bg-red-900/20 dark:border-red-800/30 dark:text-red-300",
  };

  return (
    <div className={`${baseStyles} ${variants[variant || "default"]}`}>
      {children}
    </div>
  );
};

const AlertDescription = ({ children }) => (
  <div className="mt-1 text-sm">{children}</div>
);

const SimpleModal = ({ isOpen, onClose, onConfirm, fileName, baseName }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-zinc-900 rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex justify-between items-start mb-4">
          <h3 className="text-lg font-semibold text-zinc-900 dark:text-zinc-100">
            Confirmar Exclusão
          </h3>
          <button
            onClick={onClose}
            className="text-zinc-400 hover:text-zinc-500 dark:text-zinc-500 dark:hover:text-zinc-400"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        <p className="text-sm text-zinc-600 dark:text-zinc-400 mb-6">
          Tem certeza que deseja remover o arquivo "{fileName}" da base "
          {baseName}"?
        </p>
        <div className="flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm rounded-lg border border-zinc-200 dark:border-zinc-700 hover:bg-zinc-50 dark:hover:bg-zinc-800"
          >
            Cancelar
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 text-sm rounded-lg bg-red-500 text-white hover:bg-red-600"
          >
            Remover
          </button>
        </div>
      </div>
    </div>
  );
};

const LinkTo = ({ stats }) => {
  const [draggedFile, setDraggedFile] = useState(null);
  const [dragOverBase, setDragOverBase] = useState(null);
  const [feedback, setFeedback] = useState({ message: "", type: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [basesData, setBasesData] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    fileId: null,
    fileName: "",
    baseName: "",
  });

  const loadBasesData = async () => {
    try {
      const jwtToken = localStorage.getItem("token");
      const response = await fetch(`${BASE_URL}/api/list-base-links`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Falha ao carregar dados das bases");
      }

      const data = await response.json();
      setBasesData(data);
      setFetchError(null);
    } catch (error) {
      console.error("Erro ao carregar dados das bases:", error);
      setFetchError("Erro ao carregar informações das bases");
    }
  };

  useEffect(() => {
    loadBasesData();
  }, []);

  const handleDragStart = (file) => {
    setDraggedFile(file);
    setFeedback({ message: "", type: "" });
  };

  const handleDragEnd = () => {
    setDraggedFile(null);
    setDragOverBase(null);
  };

  const handleDragOverBase = (e, baseName) => {
    e.preventDefault();
    setDragOverBase(baseName);
  };

  const handleDropOnBase = async (e, baseName) => {
    e.preventDefault();
    if (!draggedFile) return;

    setIsLoading(true);
    try {
      const jwtToken = localStorage.getItem("token");
      const response = await fetch(`${BASE_URL}/api/link-file-to-base`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        credentials: "include",
        body: JSON.stringify({
          file_id: draggedFile.file_id,
          base_name: baseName,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setFeedback({
          message: data.message,
          type: "default",
        });
        loadBasesData();
      } else {
        setFeedback({
          message: data.error || "Erro ao vincular arquivo à base",
          type: "destructive",
        });
      }
    } catch (error) {
      setFeedback({
        message: "Erro ao comunicar com o servidor",
        type: "destructive",
      });
    } finally {
      setIsLoading(false);
      setDraggedFile(null);
      setDragOverBase(null);
    }
  };

  const handleDeleteClick = (fileId, fileName, baseName) => {
    setDeleteModal({
      isOpen: true,
      fileId,
      fileName,
      baseName,
    });
  };

  const handleDownloadHashes = async (baseName) => {
    try {
      const jwtToken = localStorage.getItem("token");
      const response = await fetch(
        `${BASE_URL}/api/get_base_hashes/${baseName}`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
          credentials: "include",
        },
      );

      if (!response.ok) {
        throw new Error("Falha ao baixar hashes");
      }

      const data = await response.json();

      // Criar e baixar o arquivo
      const content = JSON.stringify(data.hashes, null, 2);
      const blob = new Blob([content], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `hashes_${baseName}_${new Date().toISOString().split("T")[0]}.json`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      setFeedback({
        message: `${data.total_hashes} hashes baixadas com sucesso!`,
        type: "default",
      });
    } catch (error) {
      console.error("Erro ao baixar hashes:", error);
      setFeedback({
        message: "Erro ao baixar hashes da base",
        type: "destructive",
      });
    }
  };

  const handleDeleteConfirm = async () => {
    const { fileId, baseName } = deleteModal;

    try {
      const jwtToken = localStorage.getItem("token");
      const response = await fetch(`${BASE_URL}/api/remove-file-link`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        credentials: "include",
        body: JSON.stringify({
          file_id: fileId,
          base_name: baseName,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setFeedback({
          message: data.message,
          type: "default",
        });
        loadBasesData();
      } else {
        setFeedback({
          message: data.error || "Erro ao remover arquivo da base",
          type: "destructive",
        });
      }
    } catch (error) {
      setFeedback({
        message: "Erro ao comunicar com o servidor",
        type: "destructive",
      });
    } finally {
      setDeleteModal({
        isOpen: false,
        fileId: null,
        fileName: "",
        baseName: "",
      });
    }
  };

  if (fetchError) {
    return (
      <Alert variant="destructive">
        <AlertDescription>{fetchError}</AlertDescription>
      </Alert>
    );
  }

  if (!basesData) {
    return (
      <div className="flex justify-center items-center p-8">
        <Loader2 className="w-6 h-6 animate-spin" />
      </div>
    );
  }

  const userFiles = stats?.uploaded_files || [];
  const bases = basesData?.bases || [];

  return (
    <div className="flex flex-col gap-4">
      {feedback.message && (
        <Alert
          variant={feedback.type === "destructive" ? "destructive" : "default"}
        >
          <AlertDescription>{feedback.message}</AlertDescription>
        </Alert>
      )}

      <SimpleModal
        isOpen={deleteModal.isOpen}
        onClose={() =>
          setDeleteModal({
            isOpen: false,
            fileId: null,
            fileName: "",
            baseName: "",
          })
        }
        onConfirm={handleDeleteConfirm}
        fileName={deleteModal.fileName}
        baseName={deleteModal.baseName}
      />

      <div className="flex flex-col md:flex-row gap-4">
        {/* Card da esquerda: Bases Autorizadas */}
        <div className="flex-1 border border-zinc-200 dark:border-zinc-800 bg-white dark:bg-zinc-900 rounded-xl p-4">
          <h2 className="text-lg font-semibold mb-3">Bases Autorizadas</h2>
          {bases.length === 0 ? (
            <p className="text-sm text-zinc-500 dark:text-zinc-400">
              Nenhuma base autorizada.
            </p>
          ) : (
            <div className="grid gap-3">
              {bases.map((base) => (
                <div
                  key={base.base_name}
                  onDragOver={(e) => handleDragOverBase(e, base.base_name)}
                  onDrop={(e) => handleDropOnBase(e, base.base_name)}
                  className={`flex flex-col gap-2 p-3 border border-zinc-100 dark:border-zinc-800 rounded transition-colors ${
                    dragOverBase === base.base_name
                      ? "bg-blue-50 dark:bg-blue-900/20"
                      : ""
                  } ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
                >
                  <div className="flex items-center gap-3">
                    <Folder className="text-blue-500" />
                    <div className="flex-1">
                      <p className="font-medium">{base.base_name}</p>
                      <p className="text-sm text-zinc-500 dark:text-zinc-400">
                        {base.total_files} arquivos • {base.total_lines} linhas
                        • {base.total_cards} cartões
                      </p>
                    </div>
                    <button
                      onClick={() => handleDownloadHashes(base.base_name)}
                      className="p-2 hover:bg-zinc-100 dark:hover:bg-zinc-800 rounded-lg transition-colors"
                      title="Baixar hashes desta base"
                    >
                      <Download className="w-4 h-4 text-blue-500" />
                    </button>
                  </div>
                  {base.files.length > 0 && (
                    <div className="mt-2 pl-8">
                      <div className="text-sm font-medium text-zinc-600 dark:text-zinc-400 mb-2">
                        Arquivos vinculados:
                      </div>
                      <div className="space-y-2">
                        {base.files.map((file) => (
                          <div
                            key={file.file_id}
                            className="text-sm text-zinc-500 dark:text-zinc-400 flex items-center gap-2 group"
                          >
                            <FileText className="w-4 h-4" />
                            <span>{file.filename}</span>
                            <span className="text-xs">
                              ({file.line_count} linhas)
                            </span>
                            <button
                              onClick={() =>
                                handleDeleteClick(
                                  file.file_id,
                                  file.filename,
                                  base.base_name,
                                )
                              }
                              className="ml-auto opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:text-red-500"
                              title="Remover arquivo da base"
                            >
                              <Trash2 className="w-4 h-4" />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Card da direita: Arquivos Disponíveis */}
        <div className="flex-1 border border-zinc-200 dark:border-zinc-800 bg-white dark:bg-zinc-900 rounded-xl p-4">
          <h2 className="text-lg font-semibold mb-3">Arquivos Disponíveis</h2>
          {userFiles.length === 0 ? (
            <p className="text-sm text-zinc-500 dark:text-zinc-400">
              Nenhum arquivo disponível.
            </p>
          ) : (
            <div className="grid gap-3">
              {userFiles.map((file) => (
                <div
                  key={file.file_id}
                  draggable={!isLoading}
                  onDragStart={() => handleDragStart(file)}
                  onDragEnd={handleDragEnd}
                  className={`flex items-center gap-3 p-3 border border-zinc-100 dark:border-zinc-800 rounded ${
                    isLoading ? "opacity-50 cursor-not-allowed" : "cursor-move"
                  }`}
                >
                  <FileText className="text-green-500" />
                  <div>
                    <p className="font-medium">{file.filename}</p>
                    <p className="text-sm text-zinc-500 dark:text-zinc-400">
                      {file.line_count} linhas
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LinkTo;

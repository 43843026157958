import React, { useState, useEffect } from 'react';
import { Loader2 } from 'lucide-react';

const EditPlanModal = ({
    isOpen,
    onClose,
    onSave,
    plan,
    consultasFornecedor,
    loading,
}) => {
    const [planName, setPlanName] = useState(plan?.plan_name || '');
    const [planDuration, setPlanDuration] = useState(plan?.duration_days || '');
    const [planAllowedModules, setPlanAllowedModules] = useState(
        plan?.allowed_modules || [],
    );
    const [planPrice, setPlanPrice] = useState(plan?.price || '');
    const [planDailyLimit, setPlanDailyLimit] = useState(
        plan?.daily_limit || '',
    );
    const [planDescription, setPlanDescription] = useState(
        plan?.description || '',
    );

    useEffect(() => {
        if (plan) {
            setPlanName(plan.plan_name || '');
            setPlanDuration(plan.duration_days || '');
            setPlanAllowedModules(plan.allowed_modules || []);
            setPlanPrice(plan.price || '');
            setPlanDailyLimit(plan.daily_limit || '');
            setPlanDescription(plan.description || '');
        }
    }, [plan]);

    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center"
            style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}
        >
            <div className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-zinc-900 border border-zinc-800 rounded-xl p-6 max-w-2xl w-full mx-4">
                <h3 className="text-lg font-medium text-zinc-100 mb-4">
                    Editar Plano
                </h3>

                <div className="space-y-6">
                    {/* Nome do Plano */}
                    <div className="space-y-2">
                        <label className="text-sm font-medium text-zinc-100">
                            Nome do Plano
                        </label>
                        <input
                            type="text"
                            placeholder="Ex: Plano Premium"
                            value={planName}
                            onChange={(e) => setPlanName(e.target.value)}
                            className="w-full h-10 px-4 bg-zinc-800 border-0 text-sm text-zinc-100 placeholder:text-zinc-500 rounded-xl focus:outline-none focus:ring-2 focus:ring-violet-500/20"
                        />
                    </div>

                    {/* Duração em Dias */}
                    <div className="space-y-2">
                        <label className="text-sm font-medium text-zinc-100">
                            Duração (dias)
                        </label>
                        <input
                            type="number"
                            placeholder="Ex: 30"
                            value={planDuration}
                            onChange={(e) => setPlanDuration(e.target.value)}
                            className="w-full h-10 px-4 bg-zinc-800 border-0 text-sm text-zinc-100 placeholder:text-zinc-500 rounded-xl focus:outline-none focus:ring-2 focus:ring-violet-500/20"
                        />
                    </div>

                    {/* Módulos Permitidos */}
                    <div className="space-y-2">
                        <label className="text-sm font-medium text-zinc-100">
                            Módulos de Consultas Permitidos
                        </label>
                        <div className="p-4 bg-zinc-800/50 rounded-xl space-y-3">
                            {consultasFornecedor.map((c) => {
                                const checked = planAllowedModules.includes(
                                    c.consulta_name,
                                );
                                return (
                                    <label
                                        key={c.consulta_name}
                                        className="flex items-center gap-3 text-sm text-zinc-300 hover:text-zinc-200 transition-colors cursor-pointer group/checkbox"
                                    >
                                        <div className="relative flex items-center justify-center">
                                            <input
                                                type="checkbox"
                                                checked={checked}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setPlanAllowedModules([
                                                            ...planAllowedModules,
                                                            c.consulta_name,
                                                        ]);
                                                    } else {
                                                        setPlanAllowedModules(
                                                            planAllowedModules.filter(
                                                                (m) =>
                                                                    m !==
                                                                    c.consulta_name,
                                                            ),
                                                        );
                                                    }
                                                }}
                                                className="w-5 h-5 border-2 border-zinc-700 rounded-md bg-zinc-800 checked:bg-violet-500 checked:border-violet-500 appearance-none transition-colors cursor-pointer group-hover/checkbox:border-violet-500/50"
                                            />
                                            <svg
                                                className={`absolute w-3 h-3 pointer-events-none transition-opacity ${
                                                    checked
                                                        ? 'opacity-100'
                                                        : 'opacity-0'
                                                }`}
                                                viewBox="0 0 17 12"
                                                fill="none"
                                                stroke="white"
                                                strokeWidth="3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <path d="M1 4.5L6 9.5L15.5 1" />
                                            </svg>
                                        </div>
                                        <span>{c.consulta_name}</span>
                                    </label>
                                );
                            })}
                        </div>
                    </div>

                    {/* Preço */}
                    <div className="space-y-2">
                        <label className="text-sm font-medium text-zinc-100">
                            Preço (R$)
                        </label>
                        <input
                            type="number"
                            placeholder="Ex: 100"
                            value={planPrice}
                            onChange={(e) => setPlanPrice(e.target.value)}
                            className="w-full h-10 px-4 bg-zinc-800 border-0 text-sm text-zinc-100 placeholder:text-zinc-500 rounded-xl focus:outline-none focus:ring-2 focus:ring-violet-500/20"
                        />
                    </div>

                    {/* Limite Diário */}
                    <div className="space-y-2">
                        <label className="text-sm font-medium text-zinc-100">
                            Limite Diário de Consultas
                        </label>
                        <input
                            type="number"
                            placeholder="Ex: 10"
                            value={planDailyLimit}
                            onChange={(e) => setPlanDailyLimit(e.target.value)}
                            className="w-full h-10 px-4 bg-zinc-800 border-0 text-sm text-zinc-100 placeholder:text-zinc-500 rounded-xl focus:outline-none focus:ring-2 focus:ring-violet-500/20"
                        />
                    </div>

                    {/* Descrição do Plano */}
                    <div className="space-y-2">
                        <label className="text-sm font-medium text-zinc-100">
                            Descrição
                        </label>
                        <textarea
                            placeholder="Ex: Acesso a vários módulos por 30 dias..."
                            value={planDescription}
                            onChange={(e) => setPlanDescription(e.target.value)}
                            className="w-full min-h-[80px] p-4 resize-none bg-zinc-800 border-0 rounded-xl text-sm text-zinc-100 placeholder:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-violet-500/20"
                        />
                    </div>
                </div>

                <div className="mt-6 flex justify-end gap-3">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 text-sm text-zinc-400 hover:text-zinc-300 transition-colors"
                    >
                        Cancelar
                    </button>
                    <button
                        onClick={() =>
                            onSave({
                                plan_name: planName,
                                duration_days: parseInt(planDuration),
                                allowed_modules: planAllowedModules,
                                price: parseFloat(planPrice),
                                daily_limit: parseInt(planDailyLimit),
                                description: planDescription,
                            })
                        }
                        disabled={loading}
                        className="px-4 py-2 text-sm bg-violet-600 hover:bg-violet-700 text-white rounded-lg transition-colors flex items-center gap-2"
                    >
                        {loading && (
                            <Loader2 className="w-4 h-4 animate-spin" />
                        )}
                        {loading ? 'Salvando...' : 'Salvar'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditPlanModal;

import React, { useState, useEffect } from "react";
import { Trash2, Check } from "lucide-react";
import clsx from "clsx";
import axiosInstance from "./axiosConfig";
import { useUserStore } from "./store";

const Button = React.forwardRef(
    ({ className, variant = "default", size = "default", ...props }, ref) => {
        const classes = clsx(
            "inline-flex items-center justify-center rounded-3xl text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
            {
                "bg-primary text-primary-foreground hover:bg-primary/90":
                    variant === "default",
                "bg-destructive text-destructive-foreground hover:bg-destructive/90":
                    variant === "destructive",
                "border border-input bg-background hover:bg-accent hover:text-accent-foreground":
                    variant === "outline",
                "bg-secondary text-secondary-foreground hover:bg-secondary/80":
                    variant === "secondary",
                "hover:bg-accent hover:text-accent-foreground":
                    variant === "ghost",
                "text-primary underline-offset-4 hover:underline":
                    variant === "link",
            },
            {
                "h-10 px-4 py-2": size === "default",
                "h-9 rounded-3xl px-3": size === "sm",
                "h-11 rounded-3xl px-8": size === "lg",
                "h-10 w-10": size === "icon",
            },
            className,
        );
        return <button className={classes} ref={ref} {...props} />;
    },
);
Button.displayName = "Button";

const Checkbox = ({ checked, onChange, disabled }) => {
    return (
        <div
            onClick={!disabled ? onChange : undefined}
            className={clsx(
                "w-5 h-5 border-2 rounded-3xl cursor-pointer flex items-center justify-center transition-colors duration-200",
                checked
                    ? "bg-primary border-primary"
                    : "bg-background border-input",
                disabled && "cursor-not-allowed opacity-50",
            )}
        >
            {checked && <Check className="w-4 h-4 text-primary-foreground" />}
        </div>
    );
};

const FinalizarCompra = ({
    cart,
    setCart,
    onClose,
    username,
    updateCartOnServer,
    removeCard,
    setCurrentPage,
}) => {
    const { balance, setBalance } = useUserStore();

    const [selectedItems, setSelectedItems] = useState({});
    const [cartItems, setCartItems] = useState([]);
    const [itemStatuses, setItemStatuses] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
    const [insufficientFundsMessage, setInsufficientFundsMessage] =
        useState("");

    const fetchCartItems = async () => {
        try {
            const response = await axiosInstance.get("/cart");
            setCartItems(response.data.cart || []);
        } catch (error) {
            console.error("Erro ao buscar itens do carrinho:", error);
            setCartItems([]);
        }
    };

    useEffect(() => {
        fetchCartItems();
    }, []);

    const totalPrice = cartItems.reduce((total, item) => {
        if (selectedItems[item._id] && !itemStatuses[item._id]?.status) {
            const price = parseFloat(item.price) || 0;
            return total + price;
        }
        return total;
    }, 0);

    const areAllSelected = () => {
        const selectableItems = cartItems.filter(
            (item) => !itemStatuses[item._id],
        );
        return (
            selectableItems.length > 0 &&
            selectableItems.every((item) => selectedItems[item._id])
        );
    };

    const handleSelectAll = () => {
        setInsufficientFundsMessage("");
        if (areAllSelected()) {
            setSelectedItems({});
            return;
        }
        let tempSelected = { ...selectedItems };
        let runningTotal = cartItems.reduce((acc, item) => {
            if (tempSelected[item._id] && !itemStatuses[item._id]?.status) {
                return acc + (parseFloat(item.price) || 0);
            }
            return acc;
        }, 0);
        for (const item of cartItems) {
            const itemPrice = parseFloat(item.price) || 0;
            if (!itemStatuses[item._id] && !tempSelected[item._id]) {
                if (runningTotal + itemPrice <= balance) {
                    tempSelected[item._id] = true;
                    runningTotal += itemPrice;
                }
            }
        }
        if (
            runningTotal <
            cartItems.reduce((acc, it) => acc + (parseFloat(it.price) || 0), 0)
        ) {
            setInsufficientFundsMessage(
                "Alguns itens não foram selecionados por falta de saldo.",
            );
        }
        setSelectedItems(tempSelected);
    };

    const handleSelectItem = (id) => {
        setInsufficientFundsMessage("");
        const item = cartItems.find((i) => i._id === id);
        if (!item) return;
        if (selectedItems[id]) {
            setSelectedItems((prev) => ({ ...prev, [id]: false }));
            return;
        }
        const itemPrice = parseFloat(item.price) || 0;
        const newTotal = totalPrice + itemPrice;
        if (newTotal > balance) {
            setInsufficientFundsMessage(
                "Você não tem saldo suficiente para selecionar este item.",
            );
            return;
        }
        setSelectedItems((prev) => ({ ...prev, [id]: true }));
    };

    const handleRemoveItem = async (id) => {
        // Remover imediatamente da UI
        const removedItem = cartItems.find((item) => item._id === id);
        setCartItems((prevItems) =>
            prevItems.filter((item) => item._id !== id),
        );

        const newCart = cart.filter((itemId) => itemId !== id);
        setCart(newCart);

        // Se houver falha, reverter (opcional). Aqui assumimos sucesso.
        try {
            await updateCartOnServer(newCart);
            removeCard(id);
        } catch (error) {
            console.error("Erro ao remover item:", error);
            // Reverte em caso de erro (se desejado)
            setCartItems((prevItems) => [...prevItems, removedItem]);
        }
    };

    useEffect(() => {
        if (totalPrice > balance) {
            let newSelectedItems = { ...selectedItems };
            let currentTotal = totalPrice;
            const selectedList = cartItems
                .filter((i) => newSelectedItems[i._id])
                .sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
            for (const item of selectedList) {
                if (currentTotal <= balance) break;
                newSelectedItems[item._id] = false;
                currentTotal -= parseFloat(item.price) || 0;
            }
            setSelectedItems(newSelectedItems);
            setInsufficientFundsMessage(
                "O saldo foi atualizado, alguns itens foram desmarcados.",
            );
        }
    }, [balance, cartItems, selectedItems, totalPrice]);

    const hasSelectedItems = Object.values(selectedItems).some(Boolean);

    const handleFinalize = async () => {
        const selectedIds = cartItems
            .filter(
                (item) => selectedItems[item._id] && !itemStatuses[item._id],
            )
            .map((item) => item._id);
        if (selectedIds.length === 0) return;
        if (totalPrice > balance) return;

        setIsProcessing(true);
        let hasSuccessfulPurchase = false;
        const selectedItemsFull = cartItems.filter((i) =>
            selectedIds.includes(i._id),
        );
        const cardIDs = selectedItemsFull
            .filter((i) => i.bin)
            .map((i) => i._id);
        const loginIDs = selectedItemsFull
            .filter((i) => i.platform)
            .map((i) => i._id);

        for (const cid of cardIDs) {
            setItemStatuses((prev) => ({
                ...prev,
                [cid]: { status: "processing" },
            }));
            try {
                const response = await axiosInstance.post("/purchase", {
                    cards: [cid],
                });
                if (response.status === 200) {
                    const { new_balance, purchased_cards, failed_cards } =
                        response.data;
                    if (purchased_cards.includes(cid)) {
                        setBalance(new_balance);
                        setItemStatuses((prev) => ({
                            ...prev,
                            [cid]: { status: "success" },
                        }));
                        removeCard(cid);
                        hasSuccessfulPurchase = true;
                    } else {
                        const fc = failed_cards.find((f) => f.card_id === cid);
                        setItemStatuses((prev) => ({
                            ...prev,
                            [cid]: {
                                status: "failed",
                                error: fc?.error || "Erro desconhecido",
                            },
                        }));
                        removeCard(cid);
                    }
                } else {
                    const { error } = response.data;
                    setItemStatuses((prev) => ({
                        ...prev,
                        [cid]: {
                            status: "failed",
                            error: error || "Erro desconhecido",
                        },
                    }));
                    removeCard(cid);
                }
            } catch (error) {
                console.error("Erro ao comprar card:", error);
                setItemStatuses((prev) => ({
                    ...prev,
                    [cid]: { status: "failed", error: "Erro na requisição" },
                }));
                removeCard(cid);
            }
        }

        for (const lid of loginIDs) {
            setItemStatuses((prev) => ({
                ...prev,
                [lid]: { status: "processing" },
            }));
            try {
                const response = await axiosInstance.post("/purchase_login", {
                    logins: [lid],
                });
                if (response.status === 200) {
                    const { new_balance, purchased_logins, failed_logins } =
                        response.data;
                    if (purchased_logins.includes(lid)) {
                        setBalance(new_balance);
                        setItemStatuses((prev) => ({
                            ...prev,
                            [lid]: { status: "success" },
                        }));
                        removeCard(lid);
                        hasSuccessfulPurchase = true;
                    } else {
                        const fl = failed_logins.find(
                            (f) => f.login_id === lid,
                        );
                        setItemStatuses((prev) => ({
                            ...prev,
                            [lid]: {
                                status: "failed",
                                error: fl?.error || "Erro desconhecido",
                            },
                        }));
                        removeCard(lid);
                    }
                } else {
                    const { error } = response.data;
                    setItemStatuses((prev) => ({
                        ...prev,
                        [lid]: {
                            status: "failed",
                            error: error || "Erro desconhecido",
                        },
                    }));
                    removeCard(lid);
                }
            } catch (error) {
                console.error("Erro ao comprar login:", error);
                setItemStatuses((prev) => ({
                    ...prev,
                    [lid]: { status: "failed", error: "Erro na requisição" },
                }));
                removeCard(lid);
            }
        }

        setIsProcessing(false);

        if (hasSuccessfulPurchase) {
            setTimeout(() => {
                setCurrentPage("minhas-compras");
            }, 1500);
        }
    };

    return (
        <div className="w-full max-w-4xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">Finalizar Compra</h2>
            <p className="mb-2">
                Saldo atual:{" "}
                <span className="font-semibold">R$ {balance.toFixed(2)}</span>
            </p>
            {insufficientFundsMessage && (
                <p className="text-sm text-red-500 mb-2">
                    {insufficientFundsMessage}
                </p>
            )}
            {cartItems.length === 0 ? (
                <p className="text-center">Seu carrinho está vazio.</p>
            ) : (
                <div className="space-y-4">
                    <div className="flex items-center mb-2">
                        <Checkbox
                            checked={areAllSelected()}
                            onChange={handleSelectAll}
                            disabled={cartItems.length === 0}
                        />
                        <label className="ml-2 text-sm font-medium">
                            Selecionar Todos
                        </label>
                    </div>

                    {cartItems.map((item) => {
                        const status = itemStatuses[item._id]?.status;
                        return (
                            <div
                                key={item._id}
                                className="flex items-center justify-between p-4 border border-border rounded-3xl w-full transition-all duration-200"
                            >
                                <div className="flex items-center space-x-4 flex-grow">
                                    <Checkbox
                                        checked={!!selectedItems[item._id]}
                                        onChange={() =>
                                            handleSelectItem(item._id)
                                        }
                                        disabled={!!status}
                                    />
                                    <div className="min-w-0 flex-shrink">
                                        {item.bin ? (
                                            <>
                                                <p className="font-semibold truncate">
                                                    {item.nome || "N/A"}
                                                </p>
                                                <p className="text-sm text-muted-foreground">
                                                    BIN: {item.bin} | Validade:{" "}
                                                    {item.month}/{item.year}
                                                </p>
                                                <p className="text-sm text-muted-foreground">
                                                    Preço: R${" "}
                                                    {parseFloat(
                                                        item.price || 0,
                                                    ).toFixed(2)}
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <p className="font-semibold truncate">
                                                    {item.platform ||
                                                        item.email ||
                                                        "Login"}
                                                </p>
                                                {item.email && (
                                                    <p className="text-sm text-muted-foreground">
                                                        Email: {item.email}
                                                    </p>
                                                )}
                                                <p className="text-sm text-muted-foreground">
                                                    Preço: R${" "}
                                                    {parseFloat(
                                                        item.price || 0,
                                                    ).toFixed(2)}
                                                </p>
                                            </>
                                        )}
                                        {status === "processing" && (
                                            <p className="text-sm text-blue-600">
                                                Processando...
                                            </p>
                                        )}
                                        {status === "success" && (
                                            <p className="text-sm text-green-600">
                                                Compra realizada com sucesso
                                            </p>
                                        )}
                                        {status === "failed" && (
                                            <p className="text-sm text-red-600">
                                                Erro:{" "}
                                                {itemStatuses[item._id].error}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <button
                                    onClick={() => handleRemoveItem(item._id)}
                                    className="flex-none ml-4 text-destructive hover:text-destructive-foreground transition-colors duration-150"
                                    aria-label="Remover item"
                                    disabled={isProcessing}
                                >
                                    <Trash2 className="h-5 w-5" />
                                </button>
                            </div>
                        );
                    })}
                </div>
            )}
            <div className="mt-4">
                <p className="text-lg font-semibold">
                    Total dos itens selecionados: R$ {totalPrice.toFixed(2)}
                </p>
                <p className="text-sm text-muted-foreground">
                    Saldo após compra: R${" "}
                    {hasSelectedItems
                        ? (balance - totalPrice).toFixed(2)
                        : balance.toFixed(2)}
                </p>
            </div>
            <div className="mt-6 flex justify-end space-x-4">
                <Button
                    variant="default"
                    onClick={handleFinalize}
                    disabled={
                        Object.values(selectedItems).filter(Boolean).length ===
                            0 ||
                        isProcessing ||
                        totalPrice > balance
                    }
                >
                    Finalizar Compra
                </Button>
            </div>
        </div>
    );
};

export default FinalizarCompra;

import React, { useState, useEffect } from "react";
import { Folder, FileText } from "lucide-react";

const BASE_URL = "https://api.astrashopbot.cc";

const LinkTo = ({ vendorToken }) => {
  const [baseStats, setBaseStats] = useState(null);
  const [userFiles, setUserFiles] = useState([]);
  const [loadingBases, setLoadingBases] = useState(true);
  const [loadingFiles, setLoadingFiles] = useState(true);

  // Acesso para gerenciamento de bases (simulação de status)
  const [hasBaseAccess, setHasBaseAccess] = useState(false);
  const [requestingAccess, setRequestingAccess] = useState(false);
  const [requestFeedback, setRequestFeedback] = useState("");

  // Estados para Drag and Drop
  const [draggedFile, setDraggedFile] = useState(null);
  const [dragOverBase, setDragOverBase] = useState(null);
  const [dropFeedback, setDropFeedback] = useState("");

  // (Opcional) Simular verificação de acesso
  useEffect(() => {
    if (!vendorToken) return;
    // Aqui você poderia checar se o usuário já tem acesso a bases (rota fictícia)
    // Exemplo de fetch: /api/dev/check-base-access
    // Para o exemplo, vamos fingir que não tem acesso inicialmente.
    // setHasBaseAccess(true) se já tiver acesso.
  }, [vendorToken]);

  // Busca informações das bases
  useEffect(() => {
    if (!vendorToken || !hasBaseAccess) return;
    const fetchBaseStats = async () => {
      try {
        const jwtToken = localStorage.getItem("token");

        const response = await fetch(`${BASE_URL}/api/base-stats`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (!response.ok) {
          console.error("Erro ao buscar bases");
          return;
        }
        const data = await response.json();
        setBaseStats(data);
      } catch (error) {
        console.error("Erro ao buscar informações das bases:", error);
      } finally {
        setLoadingBases(false);
      }
    };
    fetchBaseStats();
  }, [vendorToken, hasBaseAccess]);

  // Busca arquivos do usuário
  useEffect(() => {
    if (!vendorToken || !hasBaseAccess) return;
    const fetchUserFiles = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/dev/stats`, {
          headers: {
            Authorization: `Bearer ${vendorToken}`,
          },
        });
        if (!response.ok) {
          console.error("Erro ao buscar arquivos do usuário");
          return;
        }
        const data = await response.json();
        setUserFiles(data.uploaded_files || []);
      } catch (error) {
        console.error("Erro ao buscar arquivos do usuário:", error);
      } finally {
        setLoadingFiles(false);
      }
    };
    fetchUserFiles();
  }, [vendorToken, hasBaseAccess]);

  // Solicitação de acesso ao gerenciamento de bases
  const handleRequestAccess = async () => {
    setRequestingAccess(true);
    setRequestFeedback("");
    try {
      const jwtToken = localStorage.getItem("token");

      // Rota fictícia
      const response = await fetch(`${BASE_URL}/api/dev/request-base-access`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ motivo: "Solicitação de acesso a bases" }),
      });

      if (!response.ok) {
        console.error("Erro ao solicitar acesso às bases");
        setRequestFeedback("Falha ao solicitar acesso.");
        setRequestingAccess(false);
        return;
      }

      // Após a solicitação, você aguardaria aprovação. Aqui apenas exibimos feedback.
      setRequestFeedback("Solicitação enviada com sucesso! Aguarde aprovação.");
    } catch (error) {
      console.error("Erro na solicitação de acesso:", error);
      setRequestFeedback("Erro na solicitação de acesso.");
    } finally {
      setRequestingAccess(false);
    }
  };

  // Funções de Drag and Drop
  const handleDragStart = (file) => {
    setDraggedFile(file);
    setDropFeedback("");
  };

  const handleDragEnd = () => {
    setDraggedFile(null);
    setDragOverBase(null);
  };

  const handleDragOverBase = (e, baseKey) => {
    e.preventDefault();
    setDragOverBase(baseKey);
  };

  const handleDropOnBase = (e, baseKey) => {
    e.preventDefault();
    if (draggedFile && baseStats[baseKey]) {
      setDropFeedback(
        `Arquivo "${draggedFile.filename}" solto na base "${baseStats[baseKey].name}".`,
      );
    }
    setDraggedFile(null);
    setDragOverBase(null);
  };

  // Se o usuário ainda não tem acesso ao gerenciamento de bases, mostramos a mensagem e botão
  if (!hasBaseAccess) {
    return (
      <div className="border border-zinc-200 dark:border-zinc-800 bg-white dark:bg-zinc-900 p-4 rounded-xl max-w-xl mx-auto">
        <h2 className="text-lg font-semibold mb-2">
          Solicitar Acesso ao Gerenciamento de Bases
        </h2>
        <p className="text-sm text-zinc-600 dark:text-zinc-400 mb-3">
          Você ainda não possui acesso ao gerenciamento de bases. Clique no
          botão abaixo para solicitar.
        </p>
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
          onClick={handleRequestAccess}
          disabled={requestingAccess}
        >
          {requestingAccess ? "Enviando..." : "Solicitar"}
        </button>
        {requestFeedback && (
          <p className="mt-2 text-sm text-green-600 dark:text-green-400">
            {requestFeedback}
          </p>
        )}
      </div>
    );
  }

  // Se já tem acesso, renderiza o conteúdo de bases e arquivos
  return (
    <div className="flex flex-col md:flex-row gap-4 relative">
      {/* Card da esquerda: Bases */}
      <div className="flex-1 border border-zinc-200 dark:border-zinc-800 bg-white dark:bg-zinc-900 rounded-xl p-4">
        <h2 className="text-lg font-semibold mb-3">Bases</h2>
        {loadingBases && <p className="text-sm text-zinc-600">Carregando...</p>}
        {!loadingBases && baseStats && (
          <div className="grid gap-3">
            {Object.entries(baseStats).map(([key, base]) => (
              <div
                key={key}
                onDragOver={(e) => handleDragOverBase(e, key)}
                onDrop={(e) => handleDropOnBase(e, key)}
                className={`flex items-center gap-3 p-3 border border-zinc-100 dark:border-zinc-800 rounded transition-colors ${
                  dragOverBase === key ? "bg-blue-50 dark:bg-blue-900/20" : ""
                }`}
              >
                <Folder className="text-blue-500" />
                <div>
                  <p className="font-medium">{base.name}</p>
                  <p className="text-sm text-zinc-500 dark:text-zinc-400">
                    {base.count} cards
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
        {!loadingBases && !baseStats && (
          <p className="text-sm text-red-500">
            Não foi possível carregar as bases.
          </p>
        )}
      </div>

      {/* Card da direita: Arquivos */}
      <div className="flex-1 border border-zinc-200 dark:border-zinc-800 bg-white dark:bg-zinc-900 rounded-xl p-4">
        <h2 className="text-lg font-semibold mb-3">Arquivos</h2>
        {loadingFiles && <p className="text-sm text-zinc-600">Carregando...</p>}
        {!loadingFiles && userFiles.length > 0 && (
          <div className="grid gap-3">
            {userFiles.map((file, index) => (
              <div
                key={index}
                draggable
                onDragStart={() => handleDragStart(file)}
                onDragEnd={handleDragEnd}
                className="flex items-center gap-3 p-3 border border-zinc-100 dark:border-zinc-800 rounded cursor-move"
              >
                <FileText className="text-green-500" />
                <div>
                  <p className="font-medium">{file.filename}</p>
                  <p className="text-sm text-zinc-500 dark:text-zinc-400">
                    {file.line_count} linhas
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
        {!loadingFiles && userFiles.length === 0 && (
          <p className="text-sm text-zinc-500 dark:text-zinc-400">
            Nenhum arquivo enviado.
          </p>
        )}
      </div>

      {/* Feedback após soltar arquivo */}
      {dropFeedback && (
        <div className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-100 px-4 py-2 rounded shadow">
          {dropFeedback}
        </div>
      )}
    </div>
  );
};

export default LinkTo;

import React from "react";
import { createBLAKE3 } from "hash-wasm";
import {
  Upload,
  File,
  Loader2,
  ArrowRight,
  CheckCircle2,
  Globe,
  Key,
  Copy,
  Check,
  Sparkles,
  BookOpen,
  Hash,
  Database,
} from "lucide-react";
import { toast } from "react-toastify";
import UserProfile from "./UserProfile"; // Ajuste se necessário
import CryptoJS from "crypto-js";
import HSDocumentation from "./HSDocumentation";
import HSConvert from "./HSConvert";
import LinkTo from "./LinkTo";

// Função auxiliar para descriptografia
const decryptAES = (ciphertext, iv) => {
  try {
    const SECRET_KEY_BASE64 = "oKXzeNzPfkTVniZNaOt5RA==";
    const key = CryptoJS.enc.Base64.parse(SECRET_KEY_BASE64);
    const ivWordArray = CryptoJS.enc.Base64.parse(iv);
    const encryptedWordArray = CryptoJS.enc.Base64.parse(ciphertext);

    // Validate input lengths
    if (!ivWordArray || ivWordArray.sigBytes !== 16) {
      throw new Error("Invalid IV length");
    }

    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encryptedWordArray },
      key,
      {
        iv: ivWordArray,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      },
    );

    // Check if decryption was successful
    const decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);
    if (!decryptedStr) {
      throw new Error("Decryption resulted in empty string");
    }

    return JSON.parse(decryptedStr);
  } catch (error) {
    console.error("Decryption error:", error);
    throw error;
  }
};

const cn = (...classes) => classes.filter(Boolean).join(" ");

const Btn01 = ({ className, children = "Continue", ...props }) => {
  return (
    <button
      className={cn(
        "relative h-11 px-6",
        "bg-zinc-900 dark:bg-zinc-50",
        "text-zinc-50 dark:text-zinc-900",
        "hover:bg-zinc-800 dark:hover:bg-zinc-100",
        "rounded-xl",
        "transition-all duration-300",
        "overflow-hidden",
        "group",
        className,
      )}
      {...props}
    >
      <div
        className={cn("flex items-center gap-2", "transition-all duration-300")}
      >
        <span>{children}</span>
        <ArrowRight
          className={cn(
            "w-4 h-4",
            "transition-transform duration-300",
            "group-hover:translate-x-1",
          )}
        />
      </div>
    </button>
  );
};

const BASE_URL = "https://api.astrashopbot.cc";

const cnButton = (...classes) => classes.filter(Boolean).join(" ");

function useCopyToClipboard({ timeout = 1000 } = {}) {
  const [isCopied, setIsCopied] = React.useState(false);

  const copyToClipboard = (value) => {
    if (typeof window === "undefined" || !navigator.clipboard?.writeText)
      return;
    navigator.clipboard.writeText(value).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), timeout);
    });
  };

  return { isCopied, copyToClipboard };
}

const Button = ({ children, className, onClick, disabled, loading }) => {
  return (
    <button
      onClick={onClick}
      className={cnButton(
        "relative h-11 px-6",
        "bg-white dark:bg-zinc-900/20",
        "text-zinc-900 dark:text-zinc-100",
        "border border-zinc-200 dark:border-zinc-800",
        "shadow-sm",
        "hover:shadow-md hover:-translate-y-0.5",
        "hover:bg-zinc-50 dark:hover:bg-zinc-900",
        "active:translate-y-0",
        "transition-all duration-200",
        "after:absolute after:inset-0",
        "after:rounded-[inherit] after:opacity-0",
        "after:border after:border-zinc-900/10 dark:after:border-white/10",
        "after:transition-opacity after:duration-300",
        "hover:after:opacity-100",
        disabled && "opacity-50 cursor-not-allowed",
        className,
      )}
      disabled={disabled}
    >
      {loading && (
        <Loader2 className="w-4 h-4 animate-spin mr-2 inline-block" />
      )}
      {children}
    </button>
  );
};

const SmallCopyButton = ({ textToCopy, successDuration = 1000, className }) => {
  const { isCopied, copyToClipboard } = useCopyToClipboard({
    timeout: successDuration,
  });

  const handleCopy = () => {
    if (!isCopied) copyToClipboard(textToCopy);
  };

  return (
    <button
      onClick={handleCopy}
      className={cnButton(
        "group",
        "p-1 text-xs h-auto w-auto",
        "bg-transparent",
        "text-emerald-600 dark:text-emerald-300",
        className,
      )}
      aria-label="Copiar para a área de transferência"
    >
      {isCopied ? (
        <Check className="w-4 h-4 text-emerald-500" />
      ) : (
        <Copy className="w-4 h-4 transition-transform duration-200 group-hover:scale-110" />
      )}
    </button>
  );
};

const Notification = ({ message }) => (
  <div className="w-full max-w-sm mx-auto">
    <div className="relative overflow-hidden rounded-lg border border-emerald-300 bg-emerald-100 dark:bg-emerald-950/20 dark:border-emerald-800/30 p-4 shadow-sm">
      <div className="flex items-center gap-3">
        <div className="rounded-full bg-emerald-200 dark:bg-emerald-900/50 p-1">
          <CheckCircle2 className="h-4 w-4 text-emerald-700 dark:text-emerald-400" />
        </div>
        <p className="text-sm font-medium text-emerald-900 dark:text-emerald-200">
          {message}
        </p>
      </div>
    </div>
  </div>
);

class HashGuard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSection: "register",
      vendorToken: "",
      endpoint: "",
      showSuccess: false,
      registrationLoading: false,
      isAuthenticated: false,
      loadingAuth: true,
      fileContent: "",
      hashesOutput: [],
      selectedFile: null,
      isDragging: false,
      loading: false,
      sending: false,
      identifiedCards: 0,
      unidentifiedCards: 0,
      successfulHashes: 0,
      errorHashes: 0,
      stats: null,
      profileImage: "",
      vendorPendingApproval: false,
    };

    this.intervalId = null;
  }

  async componentDidMount() {
    await this.verifyToken();
    this.setState({ loadingAuth: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.vendorToken && this.state.vendorToken) {
      this.fetchStats();
      this.intervalId = setInterval(this.fetchStats, 5000);
    }

    if (prevState.fileContent !== this.state.fileContent) {
      this.generateHashes();
    }
  }

  componentWillUnmount() {
    if (this.intervalId) clearInterval(this.intervalId);
  }

  async verifyToken() {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await fetch(`${BASE_URL}/api/user-info`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const encryptedData = await response.json();
          let data;

          try {
            // Decrypt the response using the decryptAES function
            data = decryptAES(encryptedData.ciphertext, encryptedData.iv);

            this.setState({ isAuthenticated: true });

            if (data.vendor_data) {
              const {
                is_active,
                token: vendorToken,
                endpoint_url,
              } = data.vendor_data;

              if (!endpoint_url) {
                // Nenhum registro iniciado
                this.setState({
                  vendorToken: "",
                  endpoint: "",
                  vendorPendingApproval: false,
                });
              } else if (!is_active && endpoint_url) {
                // Registro solicitado, mas não aprovado
                this.setState({
                  vendorToken: "",
                  endpoint: endpoint_url,
                  vendorPendingApproval: true,
                });
              } else {
                // Token ativo e aprovado
                this.setState({
                  vendorToken,
                  endpoint: endpoint_url,
                  vendorPendingApproval: false,
                });
              }
            } else {
              // Sem vendor_data, sem registro
              this.setState({
                vendorToken: "",
                endpoint: "",
                vendorPendingApproval: false,
              });
            }

            if (data.user_info && data.user_info.profile_image) {
              this.setState({
                profileImage: data.user_info.profile_image,
              });
            }
          } catch (decryptError) {
            console.error("Erro na descriptografia:", decryptError);
            this.setState({ isAuthenticated: false });
            localStorage.removeItem("token");
            toast.error("Erro ao processar dados do usuário");
            return;
          }
        } else {
          this.setState({ isAuthenticated: false });
          localStorage.removeItem("token");
          console.warn("Token inválido ou expirado.");
          toast.error("Sessão expirada. Por favor, faça login novamente.");
        }
      } catch (error) {
        console.error("Erro ao autenticar:", error);
        this.setState({ isAuthenticated: false });
        localStorage.removeItem("token");
        toast.error("Erro ao verificar autenticação");
      }
    } else {
      this.setState({ isAuthenticated: false });
    }
  }

  handleVendorAuthChange(status) {
    if (!status) {
      this.setState({
        vendorToken: "",
        endpoint: "",
      });
      toast.warn("Seu token de vendor foi desativado ou aguarda aprovação.");
      this.setState({ activeSection: "register" });
    }
  }

  fetchStats = async () => {
    const { isAuthenticated, vendorToken } = this.state;
    if (!isAuthenticated || !vendorToken) return;
    try {
      const response = await fetch(`${BASE_URL}/api/dev/stats`, {
        headers: {
          Authorization: `Bearer ${vendorToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        this.setState({ stats: data });
      } else {
        const errorData = await response.json();
        if (errorData.error_code === "INVALID_TOKEN") {
          this.handleVendorAuthChange(false);
        } else {
          console.error("Erro ao buscar estatísticas:", errorData.error);
          toast.error("Erro ao buscar estatísticas");
        }
      }
    } catch (error) {
      console.error("Erro ao buscar estatísticas:", error);
      toast.error("Erro ao buscar estatísticas");
    }
  };

  handleRegister = async () => {
    const { endpoint, isAuthenticated } = this.state;

    if (!endpoint) {
      toast.error("URL do Endpoint não fornecida");
      return;
    }

    if (!isAuthenticated) {
      toast.error("Você precisa estar autenticado para realizar esta ação");
      return;
    }

    this.setState({ registrationLoading: true });
    try {
      const jwtToken = localStorage.getItem("token");

      const response = await fetch(`${BASE_URL}/api/dev/register_vendor`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ endpoint_url: endpoint }),
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status === "pending_approval") {
          this.setState({
            vendorToken: "",
            endpoint: endpoint,
            showSuccess: true,
            vendorPendingApproval: true,
          });
          setTimeout(() => this.setState({ showSuccess: false }), 3000);
          toast.info("Registro pendente de aprovação.");
        } else {
          toast.error("Resposta inesperada do servidor.");
        }
      } else {
        const data = await response.json();
        toast.error(`Erro ao registrar: ${data.error}`);
        console.error("Erro ao registrar token do vendor:", data.error);
      }
    } catch (error) {
      console.error("Erro ao registrar:", error);
      toast.error("Erro ao registrar novo token");
    } finally {
      this.setState({ registrationLoading: false });
    }
  };

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isDragging: true });
  };

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isDragging: false });
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isDragging: false });
    const files = e.dataTransfer.files;
    if (files && files.length > 0) this.processFile(files[0]);
  };

  processFile = async (file) => {
    if (!file) return;
    this.setState({ selectedFile: file, loading: true });
    try {
      const text = await file.text();
      this.setState({ fileContent: text });
      console.log("Conteúdo do arquivo carregado:", text);
    } catch (e) {
      console.error("Erro ao ler o arquivo:", e);
      toast.error("Erro ao ler o arquivo");
    }
    this.setState({ loading: false });
  };

  handleClearFile = () => {
    this.setState({
      selectedFile: null,
      fileContent: "",
      hashesOutput: [],
      identifiedCards: 0,
      unidentifiedCards: 0,
      successfulHashes: 0,
      errorHashes: 0,
    });
    console.log("Arquivo e dados relacionados foram limpos.");
  };

  generateHashes = async () => {
    const { fileContent } = this.state;
    if (fileContent.trim()) {
      this.setState({ loading: true });
      try {
        const lines = fileContent.trim().split("\n");
        const hashes = [];
        let identified = 0;
        let unidentified = 0;

        for (const line of lines) {
          const trimmedLine = line.trim();
          if (trimmedLine) {
            const regex = /(\d{14,16})\D*(\d{1,2})\D*(\d{2,4})\D*(\d{3,4})/;
            const match = trimmedLine.match(regex);
            if (match) {
              const cardNumber = match[1];
              const month = match[2];
              const year = match[3];
              const cvv = match[4];
              const bin = cardNumber.slice(0, 6);
              const unifiedData = `${cardNumber}|${month}|${year}|${cvv}`;

              const blake3Hasher = await createBLAKE3(512);
              blake3Hasher.init();
              blake3Hasher.update(unifiedData);
              const resultHash = blake3Hasher.digest("hex");
              hashes.push({ hash: resultHash, bin, month, year });

              identified++;
              console.log(
                `Hash gerada para: ${unifiedData} -> ${resultHash} | BIN: ${bin}`,
              );
            } else {
              unidentified++;
              console.warn(`Linha não identificada: ${trimmedLine}`);
            }
          }
        }

        this.setState({
          hashesOutput: hashes,
          identifiedCards: identified,
          unidentifiedCards: unidentified,
        });

        console.log(
          `Hashes geradas: ${hashes.length}, Identificados: ${identified}, Não Identificados: ${unidentified}`,
        );
      } catch (e) {
        console.error("Erro ao gerar hashes:", e);
        toast.error("Erro ao gerar hashes");
      }
      this.setState({ loading: false });
    } else {
      this.setState({
        hashesOutput: [],
        identifiedCards: 0,
        unidentifiedCards: 0,
      });
      console.warn("Conteúdo do arquivo está vazio após trim.");
    }
  };

  handleSendHashes = async () => {
    const {
      hashesOutput,
      vendorToken,
      selectedFile,
      fileContent,
      identifiedCards,
      unidentifiedCards,
    } = this.state;

    if (!hashesOutput.length) {
      toast.error("Nenhuma hash para enviar");
      return;
    }
    if (!vendorToken) {
      toast.error("Token do vendor não encontrado ou não aprovado");
      return;
    }
    if (!selectedFile) {
      toast.error("Nenhum arquivo selecionado");
      return;
    }
    this.setState({ sending: true });
    try {
      const hashes = hashesOutput;
      const filename = selectedFile.name;
      const line_count = fileContent.trim().split("\n").length;

      const response = await fetch(`${BASE_URL}/api/dev/add_hash`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${vendorToken}`,
        },
        body: JSON.stringify({
          hashes,
          filename,
          line_count,
          identified_cards: identifiedCards,
          unidentified_cards: unidentifiedCards,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.error_code === "INVALID_TOKEN") {
          this.handleVendorAuthChange(false);
          this.setState({ sending: false });
          return;
        }
        toast.error(`Erro ao enviar hashes: ${errorData.error}`);
        console.error("Erro ao enviar hashes:", errorData.error);
        this.setState({ sending: false });
        return;
      }

      const data = await response.json();
      const successCount = data.results.filter(
        (r) => r.status === "success",
      ).length;
      const errorCount = data.results.length - successCount;

      this.setState({
        successfulHashes: successCount,
        errorHashes: errorCount,
        showSuccess: true,
      });
      toast.success(`${successCount} hashes enviadas com sucesso!`);
      console.log(`${successCount} hashes enviadas com sucesso!`);
      setTimeout(() => this.setState({ showSuccess: false }), 3000);

      this.fetchStats();
    } catch (error) {
      console.error("Erro ao enviar hashes:", error);
      toast.error("Erro ao enviar hashes");
      this.setState({ errorHashes: hashesOutput.length });
    }
    this.setState({ sending: false });
  };

  render() {
    const {
      activeSection,
      vendorToken,
      endpoint,
      showSuccess,
      registrationLoading,
      isAuthenticated,
      loadingAuth,
      fileContent,
      hashesOutput,
      selectedFile,
      isDragging,
      loading,
      sending,
      identifiedCards,
      unidentifiedCards,
      successfulHashes,
      errorHashes,
      stats,
      profileImage,
      vendorPendingApproval,
    } = this.state;

    if (loadingAuth) {
      return (
        <div className="flex justify-center items-center h-screen">
          <Loader2 className="h-8 w-8 animate-spin" />
        </div>
      );
    }

    // Determinar quais seções aparecem
    const navItems = ["register"];
    if (vendorToken && !vendorPendingApproval) {
      navItems.push("docs");
      navItems.push("convert");
      navItems.push("linkto");
    } else if (!vendorToken && !vendorPendingApproval) {
      // já pode visualizar docs se aprovado? Não. Só se vendorToken existir.
      // então não adiciona docs nem convert
    } else if (vendorPendingApproval) {
      // pendente, não mostra docs nem convert
    }

    return (
      <div className="min-h-screen p-8">
        <div className="max-w-6xl mx-auto space-y-8">
          <div className="flex items-center justify-center">
            <div className="relative px-4 py-2 overflow-hidden">
              <h1 className="text-4xl sm:text-3xl font-bold shimmer-text">
                HashGuard
              </h1>
            </div>
            <style>{`.shimmer-text {
                          --shimmer-color-start: #334155;
                          --shimmer-color-mid: #94a3b8;
                          background: linear-gradient(
                            90deg,
                            var(--shimmer-color-start) 0%,
                            var(--shimmer-color-start) 40%,
                            var(--shimmer-color-mid) 50%,
                            var(--shimmer-color-start) 60%,
                            var(--shimmer-color-start) 100%
                          );
                          background-size: 200% 100%;
                          -webkit-background-clip: text;
                          background-clip: text;
                          color: transparent;
                          animation: shimmer 2.5s infinite linear;
                        }

                        @media (prefers-color-scheme: dark) {
                          .shimmer-text {
                            --shimmer-color-start: #f1f5f9;
                            --shimmer-color-mid: #9333EA;
                          }
                        }

                        @keyframes shimmer {
                          0% { background-position: 100% 0; }
                          100% { background-position: -100% 0; }
                        }`}</style>
          </div>

          <nav className="flex gap-4 mb-8 justify-center">
            {navItems.map((section) => (
              <Button
                key={section}
                className={cnButton(
                  "flex items-center gap-2",
                  activeSection === section
                    ? "bg-blue-500 text-white dark:bg-blue-500 dark:text-white"
                    : "bg-transparent text-zinc-600 dark:text-zinc-400",
                )}
                onClick={() => this.setState({ activeSection: section })}
              >
                {section === "register" && <Key className="w-4 h-4" />}
                {section === "docs" && <BookOpen className="w-4 h-4" />}
                {section === "convert" && <Hash className="w-4 h-4" />}
                {section === "linkto" && <Database className="w-4 h-4" />}
                {section === "register" && "Registrar Token"}
                {section === "docs" && "Documentação"}
                {section === "convert" && "Converter Cartões"}
                {section === "linkto" && "Bases"}
              </Button>
            ))}
          </nav>

          {showSuccess && (
            <Notification message="Operação realizada com sucesso!" />
          )}

          {activeSection === "register" && (
            <div className="relative w-full mx-auto">
              {vendorToken && !vendorPendingApproval ? (
                <div className="space-y-6">
                  <UserProfile
                    vendorToken={vendorToken}
                    stats={stats}
                    profileImage={profileImage}
                    onVendorAuthChange={this.handleVendorAuthChange}
                  />

                  <div className="max-w-3xl mx-auto">
                    <div className="p-6 rounded-2xl border border-zinc-200 dark:border-zinc-800 bg-white dark:bg-zinc-900">
                      <div className="space-y-4">
                        <div className="flex items-center gap-2">
                          <Key className="w-5 h-5 text-purple-500" />
                          <h3 className="text-xl font-semibold">
                            Seu Token de API
                          </h3>
                        </div>
                        <div className="p-4 rounded-lg bg-zinc-50 dark:bg-zinc-800 font-mono text-sm flex items-center justify-between">
                          <div className="break-all">{vendorToken}</div>
                          <SmallCopyButton textToCopy={vendorToken} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : vendorPendingApproval ? (
                <div className="max-w-lg mx-auto">
                  <div className="p-7 rounded-2xl border border-zinc-200 dark:border-zinc-800 bg-white dark:bg-zinc-900 shadow-lg text-center">
                    <h3 className="text-xl font-semibold text-zinc-900 dark:text-zinc-50 mb-4">
                      Aguardando Aprovação
                    </h3>
                    <p className="text-sm text-zinc-600 dark:text-zinc-400">
                      Sua solicitação de registro foi recebida e está pendente
                      de aprovação via Telegram.
                    </p>
                  </div>
                </div>
              ) : (
                <div className="max-w-lg mx-auto">
                  <div className="relative overflow-hidden rounded-2xl border border-zinc-200 dark:border-zinc-800 bg-white dark:bg-zinc-900 shadow-lg">
                    <div className="p-7 space-y-4">
                      <div className="inline-flex items-center gap-2 px-3 py-1.5 rounded-lg bg-zinc-100 dark:bg-zinc-800 w-fit">
                        <Sparkles className="w-4 h-4 text-zinc-600 dark:text-zinc-400" />
                        <span className="text-sm font-medium text-zinc-600 dark:text-zinc-400">
                          Configuração do Vendor
                        </span>
                      </div>
                      <div>
                        <h3 className="text-xl font-semibold text-zinc-900 dark:text-zinc-50">
                          Registrar Token de API
                        </h3>
                        <p className="mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                          Registre sua URL de endpoint para receber um token de
                          API
                        </p>
                      </div>
                    </div>

                    <div className="p-7 space-y-5 border-t border-zinc-100 dark:border-zinc-800">
                      <div className="space-y-2.5">
                        <label className="text-sm font-medium text-zinc-700 dark:text-zinc-300">
                          URL do Endpoint
                        </label>
                        <div className="relative">
                          <div className="absolute left-3 top-1/2 -translate-y-1/2 flex items-center">
                            <Globe className="w-4 h-4 text-zinc-400 dark:text-zinc-500" />
                          </div>
                          <input
                            value={endpoint}
                            onChange={(e) =>
                              this.setState({
                                endpoint: e.target.value,
                              })
                            }
                            placeholder="https://seu-endpoint-api.com"
                            className="w-full pl-10 h-11 rounded-lg bg-zinc-50 dark:bg-zinc-800/50 border border-zinc-200 dark:border-zinc-800 focus:border-zinc-400 dark:focus:border-zinc-700 focus-visible:ring-1 focus-visible:ring-zinc-400 dark:focus-visible:ring-zinc-700 focus:outline-none text-zinc-900 dark:text-zinc-100 placeholder:text-zinc-500 dark:placeholder:text-zinc-600"
                            disabled={!isAuthenticated}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="p-7 flex justify-end border-t border-zinc-100 dark:border-zinc-800">
                      <Button
                        onClick={this.handleRegister}
                        className="flex items-center gap-2"
                        disabled={
                          !endpoint || registrationLoading || !isAuthenticated
                        }
                        loading={registrationLoading}
                      >
                        <Sparkles className="w-4 h-4" />
                        Solicitar Registro
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {activeSection === "linkto" &&
            vendorToken &&
            !vendorPendingApproval && <LinkTo vendorToken={vendorToken} />}

          {activeSection === "docs" &&
            vendorToken &&
            !vendorPendingApproval && (
              <HSDocumentation vendorToken={vendorToken} />
            )}

          {activeSection === "convert" &&
            vendorToken &&
            !vendorPendingApproval && (
              <HSConvert
                isDragging={this.state.isDragging}
                handleDragIn={this.handleDragIn}
                handleDragOut={this.handleDragOut}
                handleDrag={this.handleDrag}
                handleDrop={this.handleDrop}
                processFile={this.processFile}
                selectedFile={this.state.selectedFile}
                loading={this.state.loading}
                cnButton={cnButton}
                fileContent={this.state.fileContent}
                identifiedCards={this.state.identifiedCards}
                unidentifiedCards={this.state.unidentifiedCards}
                successfulHashes={this.state.successfulHashes}
                errorHashes={this.state.errorHashes}
                handleClearFile={this.handleClearFile}
                Btn01={Btn01}
                sending={this.state.sending}
                hashesOutput={this.state.hashesOutput}
                vendorToken={this.state.vendorToken}
                vendorPendingApproval={this.state.vendorPendingApproval}
                handleSendHashes={this.handleSendHashes}
                Button={Button}
              />
            )}
        </div>
      </div>
    );
  }
}

export default HashGuard;

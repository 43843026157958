import React, { useState, useEffect } from "react";
import { LayoutGrid, List, Check } from "lucide-react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useUserStore } from "./store"; // Importando a store

function VisualizationModeButton({
    icon,
    label,
    isSelected,
    onClick,
    isLoading,
}) {
    return (
        <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onClick}
            className={`flex flex-col items-center justify-center p-4 rounded-lg transition-colors h-28 relative ${
                isSelected
                    ? "bg-blue-600 text-white"
                    : "bg-gray-700 text-gray-300 hover:bg-gray-600"
            }`}
            disabled={isLoading}
        >
            {isLoading ? (
                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-current" />
            ) : (
                <>
                    {icon}
                    {isSelected && (
                        <Check className="absolute top-2 right-2 w-4 h-4 text-white" />
                    )}
                </>
            )}
            <span className="mt-2 text-sm text-center">{label}</span>
        </motion.button>
    );
}

export default function VisualizationSettings() {
    // Usa as ações do store para buscar e atualizar configurações
    const storeFetchUserSettings = useUserStore(
        (state) => state.fetchUserSettings,
    );
    const storeUpdateUserSettings = useUserStore(
        (state) => state.updateUserSettings,
    );

    const [visualizationMode, setVisualizationMode] = useState(null);
    const [updatingMode, setUpdatingMode] = useState(null);
    const [loading, setLoading] = useState(true);

    // Carrega o modo de visualização chamando a função do store
    const fetchVisualizationMode = async () => {
        try {
            const data = await storeFetchUserSettings(); // Retorna { settings: ..., user: ... }
            setVisualizationMode(data.settings.visualizationMode || "card");
        } catch (error) {
            console.error("Erro ao buscar modo de visualização:", error);
            toast.error("Erro ao carregar configurações de visualização");
        } finally {
            setLoading(false);
        }
    };

    // Ao montar o componente, buscamos o modo de visualização
    useEffect(() => {
        fetchVisualizationMode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Função para atualizar o modo de visualização chamando o store
    const handleVisualizationModeChange = async (value) => {
        setUpdatingMode(value);
        try {
            await storeUpdateUserSettings({ visualizationMode: value });
            // Após atualizar, recarrega o valor real do backend
            await fetchVisualizationMode();
        } catch (error) {
            console.error("Erro ao atualizar o modo de visualização:", error);
            toast.error("Erro ao atualizar o modo de visualização");
            // Em caso de erro, recarrega o valor do backend para não ficar desatualizado
            await fetchVisualizationMode();
        } finally {
            setUpdatingMode(null);
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center p-4">
                <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-current"></div>
            </div>
        );
    }

    return (
        <div>
            <h2 className="text-lg font-medium mb-3">Modo de Visualização</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <VisualizationModeButton
                    icon={<LayoutGrid className="w-8 h-8" />}
                    label="Visualização em Cards"
                    isSelected={visualizationMode === "card"}
                    onClick={() => handleVisualizationModeChange("card")}
                    isLoading={updatingMode === "card"}
                />
                <VisualizationModeButton
                    icon={<List className="w-8 h-8" />}
                    label="Visualização em Lista"
                    isSelected={visualizationMode === "table"}
                    onClick={() => handleVisualizationModeChange("table")}
                    isLoading={updatingMode === "table"}
                />
            </div>
        </div>
    );
}

import React, { useEffect, useState, forwardRef, useRef } from "react";
import {
    User,
    Loader2,
    MapPin,
    User2,
    Hash,
    ShoppingCart,
    X,
    DollarSign,
    CreditCard,
    Search,
    LayoutGrid,
    List,
    FileText,
    Calendar,
    Shield,
    Check,
    IdCard,
} from "lucide-react";
import clsx from "clsx";
import { useUserStore } from "./store";

const Input = forwardRef(({ className, type = "text", ...props }, ref) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
        <div className="relative w-full">
            <Search
                className={`absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 transition-colors duration-200 ${
                    isFocused ? "text-zinc-100" : "text-zinc-500"
                }`}
            />
            <input
                ref={ref}
                type={type}
                className={`w-full h-11 bg-zinc-800 border text-sm text-zinc-100 rounded-xl pl-10 pr-4 placeholder:text-zinc-500 focus:outline-none transition-colors duration-200 border-transparent hover:border-zinc-600 ${
                    isFocused && "border-zinc-700"
                }`}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                {...props}
            />
        </div>
    );
});
Input.displayName = "Input";

// Componente de Sucesso da Compra
const PurchaseSuccess = () => {
    return (
        <div className="flex flex-col items-center justify-center space-y-4 py-6">
            <div className="relative">
                <div className="absolute inset-0 rounded-full bg-green-500 opacity-25 animate-ping" />
                <div className="relative h-16 w-16 rounded-full bg-green-500 flex items-center justify-center animate-bounce">
                    <Check className="h-8 w-8 text-white" />
                </div>
            </div>
            <div className="text-center space-y-2">
                <h3 className="text-xl font-semibold text-green-500">
                    Compra realizada com sucesso!
                </h3>
                <p className="text-sm text-muted-foreground">
                    Você será redirecionado para Minhas Compras em instantes...
                </p>
            </div>
        </div>
    );
};

// Componente de Prévia do Pagamento
const PaymentPreview = ({ doc }) => {
    return (
        <div className="space-y-6">
            <Card className="p-6 bg-secondary/30">
                <div className="space-y-4">
                    <div className="space-y-3">
                        <div className="flex justify-between items-center">
                            <span className="text-sm text-muted-foreground">
                                Documento
                            </span>
                            <span className="font-medium">
                                {formatTipo(doc.tipo)}
                            </span>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-sm text-muted-foreground">
                                Nome
                            </span>
                            <span className="font-medium">{doc.nome}</span>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-sm text-muted-foreground">
                                CPF
                            </span>
                            <code className="px-2 py-0.5 rounded bg-secondary text-xs">
                                {doc.cpf}
                            </code>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-sm text-muted-foreground">
                                Informações
                            </span>
                            <span className="font-medium">
                                {doc.idade} anos | {doc.sexo}
                            </span>
                        </div>
                        <div className="flex justify-between items-start">
                            <span className="text-sm text-muted-foreground">
                                Localização
                            </span>
                            <span className="font-medium text-right">
                                {doc.localizacao}
                            </span>
                        </div>
                    </div>

                    <div className="flex justify-between pt-4 border-t border-border">
                        <div className="flex items-center gap-2">
                            <Calendar className="h-4 w-4 text-muted-foreground" />
                            <span className="text-sm text-muted-foreground">
                                {new Date().toLocaleDateString("pt-BR")}
                            </span>
                        </div>
                        <div className="flex items-center gap-2">
                            <span className="font-semibold">R$ 10,00</span>
                        </div>
                    </div>
                </div>
            </Card>

            <div className="flex items-start gap-3 p-4 bg-blue-500/10 rounded-lg">
                <Shield className="h-5 w-5 text-blue-500 mt-0.5" />
                <div className="space-y-1">
                    <h4 className="text-sm font-medium text-blue-500">
                        Compra Protegida
                    </h4>
                    <p className="text-sm text-muted-foreground">
                        Sua transação é processada de forma segura e seus dados
                        estão protegidos.
                    </p>
                </div>
            </div>
        </div>
    );
};

function getBulletClasses(isSelected) {
    return clsx(
        "relative w-5 h-5 rounded-full transition-colors",
        isSelected
            ? "border border-white bg-white after:content-[''] after:absolute after:top-1/2 after:left-1/2 after:w-2 after:h-2 after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-full after:bg-black"
            : "border border-gray-500",
    );
}

const Button = React.forwardRef(
    ({ className, variant = "default", size = "default", ...props }, ref) => {
        const classes = clsx(
            "inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
            {
                "bg-primary text-primary-foreground hover:bg-primary/90":
                    variant === "default",
                "bg-destructive text-destructive-foreground hover:bg-destructive/90":
                    variant === "destructive",
                "border border-input bg-background hover:bg-accent hover:text-accent-foreground":
                    variant === "outline",
                "bg-secondary text-secondary-foreground hover:bg-secondary/80":
                    variant === "secondary",
                "hover:bg-accent hover:text-accent-foreground":
                    variant === "ghost",
                "text-primary underline-offset-4 hover:underline":
                    variant === "link",
            },
            {
                "h-10 px-4 py-2 text-sm": size === "default",
                "h-9 rounded-md px-3 text-sm": size === "sm",
                "h-11 rounded-md px-6 text-base": size === "lg",
                "h-10 w-10": size === "icon",
            },
            className,
        );
        return <button className={classes} ref={ref} {...props} />;
    },
);
Button.displayName = "Button";

const Card = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={clsx(
            "rounded-xl border bg-card text-card-foreground shadow p-6",
            className,
        )}
        {...props}
    />
));
Card.displayName = "Card";

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
    return (
        <div
            className="fixed inset-0 bg-black/50 flex items-start sm:items-center justify-center z-50 p-4 overflow-y-auto"
            onClick={onClose}
        >
            <div
                className="relative w-full max-w-md min-h-[200px] bg-card text-card-foreground rounded-xl p-6 my-8 sm:my-0"
                onClick={(e) => e.stopPropagation()}
            >
                <button
                    onClick={onClose}
                    className="absolute right-4 top-4 text-muted-foreground hover:text-foreground transition-colors"
                >
                    <X className="h-5 w-5" />
                </button>
                {children}
            </div>
        </div>
    );
};

function formatTipo(t) {
    if (t === "mix") return "RG ou CNH";
    if (t === "rg") return "RG";
    if (t === "cnh") return "CNH";
    return t;
}

const DocRow = ({ doc, openModal, onBuy }) => (
    <tr
        className="border-t border-border hover:bg-accent/50 transition-colors"
        onClick={() => openModal(doc)}
    >
        <td className="p-4 break-words max-w-[200px]">{doc.nome}</td>
        <td className="p-4">
            <code className="font-mono text-xs bg-secondary px-1.5 py-0.5 rounded">
                {doc.cpf}
            </code>
        </td>
        <td className="p-4">{doc.idade}</td>
        <td className="p-4">{doc.sexo}</td>
        <td className="p-4">{formatTipo(doc.tipo)}</td>
        <td className="p-4 break-words max-w-[200px]">{doc.localizacao}</td>
        <td className="p-4">R$ 10,00</td>
        <td className="p-4">
            <div className="flex gap-2">
                <Button
                    variant="ghost"
                    size="sm"
                    onClick={(e) => {
                        e.stopPropagation();
                        onBuy(doc);
                    }}
                    className="p-1.5 md:p-2 hover:bg-accent rounded-full transition-colors"
                    title="Comprar"
                >
                    <ShoppingCart className="h-4 w-4" />
                </Button>
            </div>
        </td>
    </tr>
);

const DocTableView = ({ docs, openModal, onBuy }) => (
    <div className="w-full overflow-x-auto rounded-xl border border-border">
        <table className="w-full border-collapse text-sm">
            <thead>
                <tr className="bg-secondary/50">
                    <th className="p-4 text-left font-medium">Nome</th>
                    <th className="p-4 text-left font-medium">CPF</th>
                    <th className="p-4 text-left font-medium">Idade</th>
                    <th className="p-4 text-left font-medium">Sexo</th>
                    <th className="p-4 text-left font-medium">Tipo</th>
                    <th className="p-4 text-left font-medium">Localização</th>
                    <th className="p-4 text-left font-medium">Preço</th>
                    <th className="p-4 text-left font-medium">Ações</th>
                </tr>
            </thead>
            <tbody>
                {docs.map((doc) => (
                    <DocRow
                        key={doc._id}
                        doc={doc}
                        openModal={openModal}
                        onBuy={onBuy}
                    />
                ))}
            </tbody>
        </table>
    </div>
);

const DocDetails = ({ doc }) => (
    <div className="space-y-3 bg-secondary/50 rounded-lg p-4">
        <div className="flex items-start gap-2">
            <User className="h-4 w-4 shrink-0 text-muted-foreground mt-1" />
            <div className="space-y-1">
                <span className="text-sm text-muted-foreground">Nome</span>
                <p className="break-words">{doc.nome}</p>
            </div>
        </div>
        <div className="flex items-center gap-2">
            <Hash className="h-4 w-4 shrink-0 text-muted-foreground" />
            <div className="space-y-1">
                <span className="text-sm text-muted-foreground">CPF</span>
                <p>
                    <code className="font-mono text-xs bg-background px-1.5 py-0.5 rounded">
                        {doc.cpf}
                    </code>
                </p>
            </div>
        </div>
        <div className="flex items-center gap-2">
            <User2 className="h-4 w-4 shrink-0 text-muted-foreground" />
            <div className="space-y-1">
                <span className="text-sm text-muted-foreground">
                    Informações
                </span>
                <p>
                    {doc.idade} anos | {doc.sexo}
                </p>
            </div>
        </div>
        <div className="flex items-center gap-2">
            <IdCard className="h-4 w-4 shrink-0 text-muted-foreground" />
            <div className="space-y-1">
                <span className="text-sm text-muted-foreground">Tipo</span>
                <p>{formatTipo(doc.tipo)}</p>
            </div>
        </div>
        <div className="flex items-start gap-2">
            <MapPin className="h-4 w-4 shrink-0 text-muted-foreground mt-1" />
            <div className="space-y-1">
                <span className="text-sm text-muted-foreground">
                    Localização
                </span>
                <p className="break-words">{doc.localizacao}</p>
            </div>
        </div>
    </div>
);

export default function History({ setCurrentPage }) {
    const {
        docs,
        docsLoading,
        docsError,
        fetchDocs,
        visualizationMode,
        setVisualizationMode,
        showLayoutMenu,
        setShowLayoutMenu,
        openDocModal,
        handleBuyDoc,
        isDocsModalOpen,
        closeDocModal,
        modalStep,
        selectedDoc,
        isPurchasing,
        alertMessage,
        confirmPurchaseDoc,
        docSearchQuery,
        setDocSearchQuery,
        currentDocPage,
        goToPreviousDocPage,
        goToNextDocPage,
        totalDocs,
        setPurchasesTab, // <---- Adicionamos para mudar a aba em MinhasCompras
    } = useUserStore();

    const dropdownRef = useRef(null);

    useEffect(() => {
        fetchDocs(currentDocPage, docSearchQuery);
        // eslint-disable-next-line
    }, [currentDocPage, docSearchQuery]);

    useEffect(() => {
        function handleClickOutside(e) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(e.target) &&
                showLayoutMenu
            ) {
                setShowLayoutMenu(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showLayoutMenu, setShowLayoutMenu]);

    const totalPages = Math.ceil(totalDocs / 50);

    // Aqui mudamos para redirecionar a "MinhasCompras" + abrir tab "docs"
    useEffect(() => {
        if (alertMessage.includes("Compra realizada com sucesso")) {
            // Quando compra der certo, definimos a aba "docs" e vamos para "minhascompras"
            setPurchasesTab("docs");
            const timer = setTimeout(() => {
                setCurrentPage("minhas-compras");
            }, 2500);
            return () => clearTimeout(timer);
        }
    }, [alertMessage, setCurrentPage, setPurchasesTab]);

    useEffect(() => {
        return () => {
            closeDocModal();
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className="min-h-screen p-4 sm:p-6 md:p-8 bg-background">
            <div className="max-w-7xl mx-auto space-y-8">
                {/* Título + layout switch */}
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 pb-2 mb-4 border-b border-input">
                    <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold tracking-tight">
                        Documentos
                    </h1>
                    <div className="relative" ref={dropdownRef}>
                        <Button
                            variant="default"
                            size="icon"
                            className="rounded-full"
                            onClick={() => setShowLayoutMenu(!showLayoutMenu)}
                        >
                            <LayoutGrid className="h-5 w-5" />
                            <span className="sr-only">Layout</span>
                        </Button>
                        {showLayoutMenu && (
                            <div className="absolute mt-2 right-0 bg-card border border-border rounded shadow p-4 z-10 min-w-[180px]">
                                <div
                                    className="flex items-center justify-between p-2 hover:bg-accent/50 cursor-pointer rounded"
                                    onClick={() => setVisualizationMode("grid")}
                                >
                                    <div className="flex items-center gap-2">
                                        <LayoutGrid className="h-4 w-4" />
                                        <span>Grade</span>
                                    </div>
                                    <div
                                        className={getBulletClasses(
                                            visualizationMode === "grid",
                                        )}
                                    />
                                </div>
                                <div
                                    className="flex items-center justify-between p-2 hover:bg-accent/50 cursor-pointer rounded"
                                    onClick={() =>
                                        setVisualizationMode("table")
                                    }
                                >
                                    <div className="flex items-center gap-2">
                                        <List className="h-4 w-4" />
                                        <span>Tabela</span>
                                    </div>
                                    <div
                                        className={getBulletClasses(
                                            visualizationMode === "table",
                                        )}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Campo de busca */}
                <div className="relative">
                    <Input
                        type="text"
                        value={docSearchQuery}
                        onChange={(e) => setDocSearchQuery(e.target.value)}
                        placeholder="Pesquisar por nome, idade, sexo ou localização..."
                    />
                </div>

                {/* Loading / Error */}
                {docsLoading && (
                    <div className="flex justify-center items-center py-12">
                        <Loader2 className="h-8 w-8 animate-spin" />
                    </div>
                )}
                {docsError && (
                    <div className="text-center py-12 text-destructive">
                        {docsError}
                    </div>
                )}

                {/* Listagem */}
                {!docsLoading && !docsError && docs.length > 0 && (
                    <>
                        {visualizationMode === "table" ? (
                            <DocTableView
                                docs={docs}
                                openModal={openDocModal}
                                onBuy={handleBuyDoc}
                            />
                        ) : (
                            <div className="grid gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                                {docs.map((doc) => (
                                    <Card
                                        key={doc._id}
                                        onClick={() => openDocModal(doc)}
                                        className="cursor-pointer transition-all duration-200 hover:shadow-lg"
                                    >
                                        <div className="flex flex-col h-full">
                                            <div className="flex items-center justify-between mb-6">
                                                <div className="flex items-center gap-2">
                                                    <User className="h-4 w-4 shrink-0 text-muted-foreground" />
                                                    <span className="text-lg font-semibold break-words">
                                                        {doc.nome}
                                                    </span>
                                                </div>
                                                <div className="flex items-center text-sm font-medium">
                                                    <DollarSign className="h-4 w-4 mr-1 shrink-0" />
                                                    <span>R$ 10,00</span>
                                                </div>
                                            </div>
                                            <div className="flex flex-col space-y-4 flex-1">
                                                <div className="flex items-center gap-2">
                                                    <Hash className="h-4 w-4 shrink-0 text-muted-foreground" />
                                                    <code className="font-mono text-xs bg-secondary px-1.5 py-0.5 rounded">
                                                        {doc.cpf}
                                                    </code>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <User2 className="h-4 w-4 shrink-0 text-muted-foreground" />
                                                    <span>
                                                        {doc.idade} anos |{" "}
                                                        {doc.sexo}
                                                    </span>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <IdCard className="h-4 w-4 shrink-0 text-muted-foreground" />
                                                    <span>
                                                        {formatTipo(doc.tipo)}
                                                    </span>
                                                </div>
                                                <div className="flex items-start gap-2">
                                                    <MapPin className="h-4 w-4 shrink-0 text-muted-foreground mt-1" />
                                                    <span className="break-words">
                                                        {doc.localizacao}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex justify-end items-center gap-2 mt-6">
                                                <Button
                                                    variant="default"
                                                    size="sm"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleBuyDoc(doc);
                                                    }}
                                                    className="gap-2"
                                                >
                                                    <ShoppingCart className="h-4 w-4" />
                                                    Comprar
                                                </Button>
                                            </div>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        )}
                        {/* Paginação */}
                        <div className="flex justify-center items-center gap-4">
                            <Button
                                variant="outline"
                                onClick={goToPreviousDocPage}
                                disabled={currentDocPage <= 1}
                            >
                                Anterior
                            </Button>
                            <span className="text-sm">
                                Página {currentDocPage} de{" "}
                                {Math.max(totalPages, 1)}
                            </span>
                            <Button
                                variant="outline"
                                onClick={goToNextDocPage}
                                disabled={currentDocPage >= totalPages}
                            >
                                Próxima
                            </Button>
                        </div>
                    </>
                )}

                {/* Se não há documentos */}
                {!docsLoading && !docsError && docs.length === 0 && (
                    <div className="text-center py-12 text-muted-foreground">
                        Nenhum documento encontrado.
                    </div>
                )}
            </div>

            {/* Único modal para detalhes, compra e alertas */}
            <Modal isOpen={isDocsModalOpen} onClose={closeDocModal}>
                {selectedDoc && modalStep === "details" && (
                    <div className="space-y-6">
                        <h2 className="text-xl font-semibold leading-none tracking-tight">
                            Detalhes do Documento
                        </h2>
                        <DocDetails doc={selectedDoc} />
                    </div>
                )}

                {selectedDoc && modalStep === "purchase" && (
                    <div className="space-y-6">
                        {alertMessage.includes(
                            "Compra realizada com sucesso",
                        ) ? (
                            <PurchaseSuccess />
                        ) : (
                            <>
                                <div className="flex items-center gap-2">
                                    <FileText className="h-5 w-5 shrink-0 text-muted-foreground" />
                                    <h2 className="text-xl font-semibold leading-none tracking-tight">
                                        Resumo da Compra
                                    </h2>
                                </div>

                                <PaymentPreview doc={selectedDoc} />

                                {alertMessage &&
                                    !alertMessage.includes("sucesso") && (
                                        <div className="mt-2 p-3 rounded-md text-sm bg-red-200 text-red-800">
                                            {alertMessage}
                                        </div>
                                    )}

                                {isPurchasing && (
                                    <div className="flex items-center justify-center gap-2 py-3">
                                        <Loader2 className="h-5 w-5 animate-spin" />
                                        <span className="text-sm text-muted-foreground">
                                            Processando compra...
                                        </span>
                                    </div>
                                )}

                                <div className="flex justify-end gap-2 pt-4 border-t border-border">
                                    <Button
                                        variant="default"
                                        onClick={confirmPurchaseDoc}
                                        disabled={isPurchasing}
                                        className="gap-2"
                                    >
                                        <Check className="h-4 w-4" />
                                        Confirmar
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </Modal>
        </div>
    );
}

import React, { useEffect, useState } from "react";
import clsx from "clsx";
import DOMPurify from "dompurify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    X,
    CreditCard,
    User,
    Lock,
    Calendar,
    Landmark,
    DollarSign,
    Share2,
    Zap,
    Filter,
    Plus,
    Search,
} from "lucide-react";
import {
    FaCcVisa,
    FaCcMastercard,
    FaCcAmex,
    FaCcDiscover,
} from "react-icons/fa";
import { useUserStore } from "./store";

const Button = React.forwardRef(
    ({ className, variant = "default", size = "default", ...props }, ref) => {
        const classes = clsx(
            "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
            {
                "bg-primary text-primary-foreground hover:bg-primary/90":
                    variant === "default",
                "bg-destructive text-destructive-foreground hover:bg-destructive/90":
                    variant === "destructive",
                "border border-input bg-transparent hover:bg-accent hover:text-accent-foreground":
                    variant === "outline",
                "bg-secondary text-secondary-foreground hover:bg-secondary/80":
                    variant === "secondary",
                "hover:bg-accent hover:text-accent-foreground bg-transparent":
                    variant === "ghost",
                "text-primary underline-offset-4 hover:underline":
                    variant === "link",
            },
            {
                "h-10 px-4 py-2": size === "default",
                "h-9 rounded-md px-3": size === "sm",
                "h-11 rounded-md px-8": size === "lg",
                "h-10 w-10": size === "icon",
            },
            className,
        );
        return <button className={classes} ref={ref} {...props} />;
    },
);
Button.displayName = "Button";

const Input = React.forwardRef(
    ({ className, type = "text", ...props }, ref) => {
        const classes = clsx(
            "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            className,
        );
        return <input type={type} className={classes} ref={ref} {...props} />;
    },
);
Input.displayName = "Input";

const Card = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={clsx(
            "rounded-lg border bg-card text-card-foreground shadow-sm transition-colors duration-300 ease-in-out hover:shadow-lg hover:-translate-y-1",
            className,
        )}
        {...props}
    />
));
Card.displayName = "Card";

const CardHeader = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={clsx("flex flex-col space-y-1.5 p-4", className)}
        {...props}
    />
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef(({ className, ...props }, ref) => (
    <h3
        ref={ref}
        className={clsx(
            "text-base font-semibold leading-none tracking-tight",
            className,
        )}
        {...props}
    />
));
CardTitle.displayName = "CardTitle";

const CardContent = React.forwardRef(({ className, ...props }, ref) => (
    <div ref={ref} className={clsx("p-4 pt-0", className)} {...props} />
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={clsx("flex items-center p-4 pt-0", className)}
        {...props}
    />
));
CardFooter.displayName = "CardFooter";

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };
    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4"
            onClick={handleOverlayClick}
        >
            <div className="relative bg-background rounded-lg shadow-lg w-full max-w-lg p-6 overflow-auto max-h-full">
                <button
                    onClick={onClose}
                    className="absolute top-3 right-3 text-muted-foreground hover:text-foreground"
                >
                    <X className="h-6 w-6" />
                </button>
                {children}
            </div>
        </div>
    );
};

const getBrandIcon = (brand) => {
    switch ((brand || "").toLowerCase()) {
        case "visa":
            return <FaCcVisa className="h-6 w-6" />;
        case "mastercard":
            return <FaCcMastercard className="h-6 w-6" />;
        case "american express":
        case "amex":
            return <FaCcAmex className="h-6 w-6" />;
        case "discover":
            return <FaCcDiscover className="h-6 w-6" />;
        default:
            return <CreditCard className="h-6 w-6" />;
    }
};

const Marketplace = () => {
    const {
        isAuthenticated,
        balance,
        cards,
        loading,
        error,
        selectedCard,
        isModalOpen,
        page,
        totalPages,
        searchQuery,
        setSearchQuery,
        filters,
        setFilters,
        showFilters,
        setShowFilters,
        handleLoadMore,
        openModal,
        closeModal,
        handleQuickBuy,
        setCardToBuy,
        fetchCards,
    } = useUserStore();

    const [isQuickBuyModalOpen, setIsQuickBuyModalOpen] = useState(false);
    const [cardToBuyModal, setCardToBuyModal] = useState(null);
    const [isProcessingQuickBuy, setIsProcessingQuickBuy] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            fetchCards(1, true);
        }
    }, [isAuthenticated, fetchCards]);

    const formattedBalance = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    }).format(balance || 0);

    const shareCardLink = (card) => {
        const cardId = card.hash;
        const link = `${window.location.origin}/?card=${cardId}`;
        navigator.clipboard
            .writeText(link)
            .then(async () => {
                const { toast } = await import("react-toastify");
                toast.success("Link copiado para a área de transferência!");
            })
            .catch((err) => {
                console.error("Falha ao copiar o link: ", err);
            });
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleFilterTypeChange = (index, newType) => {
        const updatedFilters = [...filters];
        updatedFilters[index].type = newType;
        updatedFilters[index].value = "";
        setFilters(updatedFilters);
    };

    const handleFilterValueChange = (index, newValue) => {
        const updatedFilters = [...filters];
        updatedFilters[index].value = newValue;
        setFilters(updatedFilters);
    };

    const addFilter = () => {
        setFilters([...filters, { type: "brand", value: "" }]);
    };

    const removeFilter = (index) => {
        const updatedFilters = [...filters];
        updatedFilters.splice(index, 1);
        setFilters(updatedFilters);
    };

    const confirmQuickBuy = async () => {
        if (!cardToBuyModal) return;
        setIsProcessingQuickBuy(true);
        try {
            await handleQuickBuy(cardToBuyModal);
        } catch (error) {
            console.error("Erro ao processar a compra:", error);
        } finally {
            setIsProcessingQuickBuy(false);
            setIsQuickBuyModalOpen(false);
            setCardToBuyModal(null);
        }
    };

    if (!isAuthenticated) {
        return (
            <div className="app-container">
                <p className="text-center mt-20">
                    Faça login para acessar o Marketplace.
                </p>
                <ToastContainer position="top-right" autoClose={5000} />
            </div>
        );
    }

    return (
        <div className="flex flex-col h-screen bg-background text-foreground">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 p-4 border-b border-input">
                <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold tracking-tight">
                    Marketplace
                </h1>
                <div className="flex items-center gap-2">
                    <div className="flex items-center bg-secondary rounded-md overflow-hidden">
                        <span className="px-4 py-2 font-semibold text-secondary-foreground">
                            Saldo: {formattedBalance}
                        </span>
                    </div>
                </div>
            </div>

            <div className="p-4 border-input text-card-foreground">
                <div className="w-full space-y-4">
                    <div className="flex items-center relative">
                        <Input
                            type="search"
                            placeholder="Pesquisar..."
                            className="w-full pl-10 bg-input text-foreground"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground" />
                        <Button
                            variant="outline"
                            size="icon"
                            onClick={() => setShowFilters(!showFilters)}
                            className="ml-2"
                        >
                            <Filter className="h-5 w-5" />
                        </Button>
                    </div>

                    {showFilters && (
                        <div className="mt-2 space-y-2 px-6">
                            {filters.map((filter, index) => (
                                <div
                                    key={index}
                                    className="flex items-center space-x-2"
                                >
                                    <select
                                        value={filter.type}
                                        onChange={(e) =>
                                            handleFilterTypeChange(
                                                index,
                                                e.target.value,
                                            )
                                        }
                                        className="rounded-md border bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring"
                                    >
                                        <option value="brand">Bandeira</option>
                                        <option value="expiry">Validade</option>
                                        <option value="bin">BIN</option>
                                        <option value="bank">Banco</option>
                                        <option value="level">Nível</option>
                                    </select>
                                    <Input
                                        type="text"
                                        placeholder={
                                            filter.type === "brand"
                                                ? "Ex: Visa"
                                                : filter.type === "expiry"
                                                  ? "Ex: 12/25"
                                                  : filter.type === "bin"
                                                    ? "Ex: 411111"
                                                    : filter.type === "bank"
                                                      ? "Ex: NU PAGAMENTOS SA"
                                                      : filter.type === "level"
                                                        ? "Ex: GOLD"
                                                        : "Pesquisar..."
                                        }
                                        value={filter.value}
                                        onChange={(e) =>
                                            handleFilterValueChange(
                                                index,
                                                e.target.value,
                                            )
                                        }
                                        className="flex-grow"
                                    />
                                    <Button
                                        variant="outline"
                                        size="icon"
                                        onClick={() => removeFilter(index)}
                                    >
                                        <X className="h-4 w-4" />
                                    </Button>
                                    {index === filters.length - 1 && (
                                        <Button
                                            variant="outline"
                                            size="icon"
                                            onClick={addFilter}
                                        >
                                            <Plus className="h-4 w-4" />
                                        </Button>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <div className="flex-1 overflow-y-auto p-4">
                {loading && (
                    <p className="text-center">Carregando cartões...</p>
                )}
                {error && <p className="text-center text-red-500">{error}</p>}

                {cards?.length > 0 ? (
                    <>
                        <div className="grid gap-4 sm:gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                            {cards.map((card, index) => (
                                <Card
                                    key={index}
                                    className="bg-card text-card-foreground cursor-pointer w-full relative overflow-hidden rounded-lg shadow-lg transition-all duration-300 ease-in-out hover:shadow-xl hover:-translate-y-1"
                                    onClick={() => openModal(card)}
                                >
                                    <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
                                        <div className="text-[30rem] transform scale-150 text-gray-300 opacity-10 pointer-events-none">
                                            {getBrandIcon(card.vendor)}
                                        </div>
                                    </div>
                                    <div className="relative z-10 p-4 sm:p-6">
                                        <CardHeader className="p-0">
                                            <CardTitle className="flex items-center justify-between text-base sm:text-lg">
                                                <span className="flex items-center">
                                                    {getBrandIcon(card.vendor)}
                                                    <span className="ml-2 font-semibold">
                                                        BIN: {card.bin}
                                                    </span>
                                                </span>
                                                <span className="text-sm md:text-base font-medium bg-gray-200 text-gray-800 px-2 py-1 rounded-full">
                                                    {card.level}
                                                </span>
                                            </CardTitle>
                                        </CardHeader>
                                        <CardContent className="mt-4 p-0">
                                            <p className="flex items-center mb-2 text-sm">
                                                <User className="h-5 w-5 mr-2 flex-shrink-0" />
                                                <span className="truncate">
                                                    Nome: {card.nome}
                                                </span>
                                            </p>
                                            <p className="flex items-center mb-2 text-sm">
                                                <Lock className="h-5 w-5 mr-2 flex-shrink-0" />
                                                <span className="truncate">
                                                    CPF: {card.cpf}
                                                </span>
                                            </p>
                                            <p className="flex items-center mb-2 text-sm">
                                                <Calendar className="h-5 w-5 mr-2 flex-shrink-0" />
                                                <span>
                                                    Validade: {card.month}/
                                                    {card.year}
                                                </span>
                                            </p>
                                            <p className="flex items-center mb-2 text-sm">
                                                <Landmark className="h-5 w-5 mr-2 flex-shrink-0" />
                                                <span className="truncate">
                                                    Banco: {card.bank}
                                                </span>
                                            </p>
                                            <p className="flex items-center mb-2 text-sm">
                                                <DollarSign className="h-5 w-5 mr-2 flex-shrink-0" />
                                                <span className="truncate">
                                                    Preço: R${" "}
                                                    {parseFloat(
                                                        card.price,
                                                    ).toFixed(2)}
                                                </span>
                                            </p>
                                        </CardContent>
                                        <CardFooter className="flex justify-between items-center p-0">
                                            <Button
                                                variant="ghost"
                                                size="icon"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    shareCardLink(card);
                                                }}
                                                className="h-10 w-10 hover:bg-accent hover:text-accent-foreground rounded-full transition-colors"
                                            >
                                                <Share2 className="h-5 w-5" />
                                                <span className="sr-only">
                                                    Compartilhar Link
                                                </span>
                                            </Button>
                                            <Button
                                                size="icon"
                                                variant="ghost"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setCardToBuy(card);
                                                    setCardToBuyModal(card);
                                                    setIsQuickBuyModalOpen(
                                                        true,
                                                    );
                                                }}
                                                className="h-10 w-10 hover:bg-accent hover:text-accent-foreground rounded-full transition-colors"
                                            >
                                                <Zap className="h-5 w-5" />
                                                <span className="sr-only">
                                                    Compra Instantânea
                                                </span>
                                            </Button>
                                        </CardFooter>
                                    </div>
                                </Card>
                            ))}
                        </div>

                        {page < totalPages && !loading && (
                            <div className="flex justify-center mt-6">
                                <Button onClick={handleLoadMore}>
                                    Carregar Mais
                                </Button>
                            </div>
                        )}
                    </>
                ) : (
                    !loading &&
                    !error && (
                        <p className="col-span-full text-center">
                            Nenhum cartão encontrado.
                        </p>
                    )
                )}
            </div>

            {selectedCard && (
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                    <h2 className="text-2xl font-bold mb-4">
                        Detalhes do Cartão
                    </h2>
                    <p className="mb-2">
                        <strong>BIN:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.bin)}
                    </p>
                    <p className="mb-2">
                        <strong>Validade:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.month)}/
                        {DOMPurify.sanitize(selectedCard.year)}
                    </p>
                    <p className="mb-2">
                        <strong>Vendor:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.vendor)}
                    </p>
                    <p className="mb-2">
                        <strong>Tipo:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.type)}
                    </p>
                    <p className="mb-2">
                        <strong>Nível:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.level)}
                    </p>
                    <p className="mb-2">
                        <strong>Banco:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.bank)}
                    </p>
                    <p className="mb-2">
                        <strong>País:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.country)}
                    </p>
                    <p className="mb-2">
                        <strong>Nome:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.nome)}
                    </p>
                    <p className="mb-2">
                        <strong>CPF:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.cpf)}
                    </p>
                </Modal>
            )}

            {isQuickBuyModalOpen && cardToBuyModal && (
                <Modal
                    isOpen={isQuickBuyModalOpen}
                    onClose={() => {
                        if (!isProcessingQuickBuy) {
                            setIsQuickBuyModalOpen(false);
                            setCardToBuyModal(null);
                        }
                    }}
                >
                    <h2 className="text-2xl font-bold mb-4">
                        Confirmar Compra
                    </h2>
                    <p className="mb-2">
                        Você tem certeza que deseja comprar o seguinte cartão?
                    </p>
                    <div className="mb-2">
                        <strong>BIN:</strong>{" "}
                        {DOMPurify.sanitize(cardToBuyModal.bin)}
                    </div>
                    <div className="mb-2">
                        <strong>Validade:</strong>{" "}
                        {DOMPurify.sanitize(cardToBuyModal.month)}/
                        {DOMPurify.sanitize(cardToBuyModal.year)}
                    </div>
                    <div className="mb-2">
                        <strong>Vendor:</strong>{" "}
                        {DOMPurify.sanitize(cardToBuyModal.vendor)}
                    </div>
                    <div className="mb-2">
                        <strong>Tipo:</strong>{" "}
                        {DOMPurify.sanitize(cardToBuyModal.type)}
                    </div>
                    <div className="mb-2">
                        <strong>Nível:</strong>{" "}
                        {DOMPurify.sanitize(cardToBuyModal.level)}
                    </div>
                    <div className="mb-2">
                        <strong>Banco:</strong>{" "}
                        {DOMPurify.sanitize(cardToBuyModal.bank)}
                    </div>
                    <div className="mb-2">
                        <strong>País:</strong>{" "}
                        {DOMPurify.sanitize(cardToBuyModal.country)}
                    </div>
                    <div className="mb-2">
                        <strong>Nome:</strong>{" "}
                        {DOMPurify.sanitize(cardToBuyModal.nome)}
                    </div>
                    <div className="mb-2">
                        <strong>CPF:</strong>{" "}
                        {DOMPurify.sanitize(cardToBuyModal.cpf)}
                    </div>
                    <div className="mb-2">
                        <strong>Preço:</strong> R${" "}
                        {parseFloat(cardToBuyModal.price).toFixed(2)}
                    </div>
                    {isProcessingQuickBuy && (
                        <p className="mt-4 text-blue-600">
                            Processando compra...
                        </p>
                    )}
                    <div className="flex justify-end mt-4">
                        <Button
                            variant="outline"
                            onClick={() => {
                                if (!isProcessingQuickBuy) {
                                    setIsQuickBuyModalOpen(false);
                                    setCardToBuyModal(null);
                                }
                            }}
                            className="mr-2"
                            disabled={isProcessingQuickBuy}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="default"
                            onClick={confirmQuickBuy}
                            disabled={isProcessingQuickBuy}
                        >
                            Confirmar Compra
                        </Button>
                    </div>
                </Modal>
            )}

            <ToastContainer
                position="top-right"
                autoClose={5000}
                style={{
                    top: "7rem",
                    right: "1rem",
                }}
            />
        </div>
    );
};

export default Marketplace;
